import {Component, Input, OnInit, SimpleChanges, TemplateRef, ViewChild} from '@angular/core';
import {ToastrService} from "ngx-toastr";
import {ActivatedRoute, Router} from "@angular/router";

import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {MatPaginator, MatPaginatorModule, PageEvent} from '@angular/material';
import {Sort} from '@angular/material/sort';
import {MatMenuTrigger} from "@angular/material/menu";
import {UserFilterDTO} from "../../../../types/UserFilterDTO";
import {PaginationUserDTO} from "../../../../types/PaginationUserDTO";
import {UserService} from "../../../../_services/adminUserService/user-service";
import {UserDTO} from "../../../../types/UserDTO";
import {OrganizationService} from "../../../../_services/adminOrganizationService/organization-service";
import {AuthService} from "../../../../../_services/authService/auth.service";


@Component({
  selector: 'app-e-admin-list-organization-member',
  templateUrl: 'list-organization-member.html',
  styleUrls: ['list-organization-member.css']
})
export class eAdminListOrganizationMemberComponent implements OnInit {

  private _userFilterDto: UserFilterDTO;

  organizationId: string;

  get userFilterDto_(): UserFilterDTO {
    return this._userFilterDto;
  }

  userList: any;

  sort: Sort;

  @ViewChild('confirmation')
  private confirmationTemplate: TemplateRef<any>;

  modalRef: BsModalRef;

  header: string;

  constructor(private userService: UserService,
              private toastr: ToastrService,
              private router: Router,
              private route: ActivatedRoute,
              private authService: AuthService,
              private organizationService: OrganizationService,
              private modalService: BsModalService) {}

  sortAndFilterRecords() {
    this.organizationService.getMembers(this.organizationId).subscribe(data => {
      this.userList = data;
    });
  }

  edit(user) {
    this.router.navigateByUrl('/sparkecademy-admin-organization/'+this.organizationId+'/member/edit/' + user.id).then(r => {
    });
  }

  view(user) {
    this.router.navigateByUrl('/sparkecademy-admin-organization/'+this.organizationId+'/member/view/' + user.id).then(r => {
    });
  }

  deactivate(user) {
    this.userList.organizationMembers.filter(e => e == user).map(e => e.isActiveStr = 'Inactive');
    this.organizationService.deactivateMember(this.organizationId, user.id).subscribe(e => {
      this.toastr.success("User Inactivated successfully!!", "Success")
    });
  }

  ngOnInit() {
    this.organizationId = this.route.snapshot.paramMap.get('id');
    this.sortAndFilterRecords();

    this.organizationService.get(this.organizationId).subscribe(data=>{
      this.header = data.name+ " Members";
    });
  }
}
