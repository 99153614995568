import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {AdminUserService} from "../../../../_services/admin/adminUserService/admin-user.service";
import {IDDTO} from "../../../../types/IDDTO";
import {MergeIDDTO} from "../../../../types/MergeIDDTO";

@Component({
  selector: 'app-find-for-merge',
  templateUrl: './find-for-merge.html',
  styleUrls: ['./find-for-merge.component.css']
})
export class FindForMergeComponent implements OnInit {

  findAccount = this.fb.group({
    entityId: [''],
    entity: 'User',
  }, {validator: []});

  @Output() loadEditor=new EventEmitter<any>();

  @Input() currentAccountId : number;

  errorMessage : string;

  entities: object[] = [{name: 'User', checked: true}, {name: 'Account', checked:false}];

  constructor(private fb: FormBuilder, private adminUserService : AdminUserService) { }

  ngOnInit() {
  }

  loadMerge() {
    this.errorMessage = "";
      console.log(this.findAccount);
      let dto : MergeIDDTO = new MergeIDDTO(this.currentAccountId, Number(this.findAccount.controls['entityId'].value));;
      if (this.findAccount.controls['entity'].value == 'User') {
        this.adminUserService.getAccountMergeInfo(dto).subscribe(
          data => {
            console.log(data);
            if(data.success){
              this.loadEditor.emit(data);
            } else {
              this.errorMessage = data.errorMessage;
            }
          }
        );
      } else {
          this.adminUserService.getUserMergeInfo(dto).subscribe(
            data => {
              console.log(data);
              if(data.success){
                this.loadEditor.emit(data);
              } else {
                this.errorMessage = data.errorMessage;
              }
            }
          );
      }

  }
}
