import { Component, OnInit } from '@angular/core';
import {ErrorMessageService} from "../../_services/errorMessageService/error-message.service";

@Component({
  selector: 'app-error-message',
  templateUrl: `error-message.html`,
  styleUrls: ['./error-message.component.css']
})
export class ErrorMessageComponent implements OnInit {

  constructor(public errorMessageService : ErrorMessageService) { }

  ngOnInit() {
  }



}
