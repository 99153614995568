import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";
import {ActivatedRoute} from '@angular/router';
import {Observable} from "rxjs";
import {UserService} from "../../../../_services/adminUserService/user-service";
import {UserDTO} from "../../../../types/UserDTO";
import {OrganizationService} from "../../../../_services/adminOrganizationService/organization-service";

@Component({
  selector: 'app-e-organization-member-create-document',
  templateUrl: './create-organization-member.html',
  styleUrls: ['./create-organization-member.component.css']
})
export class eAdminCreateOrganizationMemberComponent implements OnInit {

  header: string = "Add New Member";

  userId: string;

  displayButtonToForcefullySubmitData: boolean = false;

  successMsg: string;

  organizationId: string;

  createUserForm = this.fb.group({
    firstName: [''],
    lastName: [''],
    email: ['', [Validators.required, Validators.email]],
    isManager: [false],
  });

  constructor(private fb: FormBuilder,
              private organizationService: OrganizationService,
              private toastr: ToastrService,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.organizationId = this.route.snapshot.paramMap.get('id');
    this.userId = this.route.snapshot.paramMap.get('memberId');
    if (this.userId != null) {
      this.header = "Edit Member";
      this.loadData();
    }
  }

  loadData() {
    this.organizationService.getMember(this.organizationId, this.userId).subscribe(data => {
      this.createUserForm.get("firstName").setValue(data.firstName);
      this.createUserForm.get("lastName").setValue(data.lastName);
      this.createUserForm.get("email").setValue(data.email);
      this.createUserForm.get("isManager").setValue(data.isManager);
    });
  }

  onSubmit() {
    if (this.userId == null) {


      let userDTO = new UserDTO(
        this.getField("firstName"),
        this.getField("lastName"),
        this.getField("email"),
        null,
        null,
        null, null,
        null,
        null,
        this.getField("isManager"),
        "SPARK_ECADEMY",
        null,
      );


      this.organizationService.addMember(this.organizationId, userDTO, this.displayButtonToForcefullySubmitData).subscribe(data => {
        this.displayButtonToForcefullySubmitData = false;
        if (!data.success) {
          this.toastr.error(data.errorMessage, "Error");
        }
        if (data.success) {
          if (data.message.indexOf('Member already') > -1) {
            this.displayButtonToForcefullySubmitData = true;
            this.successMsg = data.message;
          } else {
            this.toastr.success("Success", data.message)
            this.router.navigateByUrl('/sparkecademy-admin-organization/' + this.organizationId + '/manage-members');
          }
        }
      });
    } else {

      let userDTO = new UserDTO(
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        this.getField("isManager"),
        "SPARK_ECADEMY",
        null,
      );

      this.organizationService.editMember(this.organizationId, this.userId, userDTO).subscribe(data => {
        if (data.success) {
          this.toastr.success("Success", "Member updated successfully!!")
          this.router.navigateByUrl('/sparkecademy-admin-organization/' + this.organizationId + '/manage-members');
        }
      });
    }
  }

  getField(fieldName: string) {
    return this.createUserForm.get(fieldName).value;
  }

  reset() {
    this.router.navigateByUrl('/sparkecademy-admin-organization/' + this.organizationId + '/manage-members');
  }
}
