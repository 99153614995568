import { Injectable } from '@angular/core';


import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {UserCurriculumService} from "../../_services/userCurriculumService/user-curriculum.service";
import {Observable} from "rxjs";
import {ModuleFile} from "../../types/ModuleFile";
import {Module} from "../../types/Module";

@Injectable({
  providedIn: 'root'
})
export class CurrentModuleResolver implements Resolve<Observable<Module>> {
  constructor(private curriculumService: UserCurriculumService) {}

  resolve(route: ActivatedRouteSnapshot) : Observable<Module>{
    console.log("cmr param" + route.params['moduleId']);
    return this.curriculumService.getCurrentModule(route.params['moduleId']);
  }
}

@Injectable({
  providedIn: 'root'
})
export class CurrentTTModuleResolver implements Resolve<Observable<Module>> {
  constructor(private curriculumService: UserCurriculumService) {}

  resolve(route: ActivatedRouteSnapshot) : Observable<Module>{
    console.log("cmr param" + route.params['moduleId']);
    return this.curriculumService.getCurrentTTModule(route.params['moduleId']);
  }
}

@Injectable({
  providedIn: 'root'
})
export class CurrentFolderSecondaryPageResolver implements Resolve<Observable<ModuleFile>> {
  constructor(private curriculumService: UserCurriculumService) {}

  resolve(route: ActivatedRouteSnapshot) : Observable<ModuleFile>{
    console.log("cmr param" + route.params['moduleId']);
    return this.curriculumService.getCurrentFolder(route.params['moduleId']);
  }
}
