export class CreditLicenseDTO {

  oracleOrderNumber: string;
  sku: string;
  email: string;
  credits: number;

  constructor(oracleOrderNumber: string, sku: string, email: string, credits: number) {
    this.oracleOrderNumber = oracleOrderNumber;
    this.sku = sku;
    this.email = email;
    this.credits = credits;
  }
}
