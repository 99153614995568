import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {Curriculum} from "../../types/Curriculum";
import {Module} from "../../types/Module";
import {LicenseExpirationDateDTO} from "../../types/LicenseExpirationDateDTO";

@Component({
  selector: 'app-university-module',
  templateUrl: './university-module.html',
  styleUrls: ['./university-module.component.css']
})
export class UniversityModuleComponent implements OnInit {

  curriculums: Curriculum[];

  licenseExpirationDate: LicenseExpirationDateDTO;

  navEntries : any;


  currentCurriculum : Curriculum;

  moduleArray: Module[];

  displayImage: boolean = false;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.data.subscribe(data =>
    {
      console.log(data);
      this.curriculums = data.curriculums;
      console.log(this.curriculums);
      this.navEntries = data.navEntries;
      console.log(this.curriculums);
      this.licenseExpirationDate = data.licenseExpirationDate;

      this.moduleArray = data.uniLinks;

      this.currentCurriculum = data.currentCurriculum;
      console.log(this.currentCurriculum);

      if(this.currentCurriculum.bannerLink){
        this.displayImage = true;
      } else {
        this.displayImage = false;
      }
    });
  }

}
