import { Component, OnInit } from '@angular/core';
import {Curriculum} from "../../types/Curriculum";
import {ActivatedRoute, Router} from "@angular/router";
import {LicenseDTO} from "../../types/LicenseDTO";
import {FormBuilder, Validators} from "@angular/forms";
import {RegistrationDTO} from "../../types/RegistrationDTO";
import {ErrorMessageService} from "../../_services/errorMessageService/error-message.service";
import {AccountService} from "../../_services/accountService/account.service";
import {RoleInfo} from "../../types/RoleInfo";
import {LicenseExpirationDateDTO} from "../../types/LicenseExpirationDateDTO";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-license-info',
  templateUrl: './license-info.html',
  styleUrls: ['./license-info.component.css']
})
export class LicenseInfoComponent implements OnInit {
  curriculums : Curriculum[];

  licenseExpirationDate: LicenseExpirationDateDTO;

  navEntries : any;

  regInfo:RegistrationDTO;

  licenseDTOList : Array<LicenseDTO>;

  typedDTOList : Array<LicenseDTO> = new Array<LicenseDTO>();

  licenseDTOListList : Array<Array<LicenseDTO>> = new Array<Array<LicenseDTO>>();
  addNewLicense = this.fb.group({
    license: ['', Validators.required]
  });


  pageName : string = "LicenseInfo";

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private errorMessageService: ErrorMessageService
    , private accountService: AccountService) { }

  ngOnInit() {
    this.route.data.subscribe(data =>
    {
      console.log("in license info component");
      console.log(data);
      this.curriculums = data.curriculums;
      console.log(this.curriculums);
      this.navEntries = data.navEntries;
      this.licenseExpirationDate = data.licenseExpirationDate;

      this.licenseDTOList = data.licenseDTOList;

      this.licenseDTOList.forEach(
        licenseDTO =>{
          console.log(licenseDTO.roleInfoList);
          licenseDTO.roleInfoList = licenseDTO.roleInfoList.filter(role => role.type !== 'ADMIN');
          console.log(licenseDTO.roleInfoList);
        }
      );


      console.log(this.licenseDTOList);

      this.errorMessageService.clear();


      this.licenseDTOList.forEach( licenseDTO => {

        let typedDTO : LicenseDTO;
        typedDTO = new LicenseDTO(licenseDTO.licenseKey, licenseDTO.expirationDate, licenseDTO.roleInfoList, licenseDTO.licenseId);
        typedDTO.revoked = licenseDTO.revoked;
        let count = 0;

        typedDTO.roleInfoList.forEach(roleInfo => {

          if(count % 2 == 0){
            typedDTO.processedRoleInfoList.push(new Array<RoleInfo>());
          }

          let temp : Array<RoleInfo> = new Array<RoleInfo>();
          temp = typedDTO.processedRoleInfoList.pop();
          temp.push(roleInfo);
          typedDTO.processedRoleInfoList.push(temp);
          count++;
        });

        this.typedDTOList.push(typedDTO);
      });

      /*

      let count = 0;
      if(data.secondaryModules){
        data.secondaryModules.forEach( secondaryModule => {
          let secondaryModuleType : SecondaryModule;
          secondaryModuleType = new SecondaryModule(secondaryModule.id, secondaryModule.navText, secondaryModule.curriculumId, secondaryModule.moduleId, secondaryModule.secondaryModuleType, secondaryModule.iconPath, secondaryModule.url);
          if(count % 2 == 0 ){
            this.secondaryModulesList.push(new Array<SecondaryModule>());
          }
          let temp : Array<SecondaryModule> = new Array<SecondaryModule>();
          temp = this.secondaryModulesList.pop();
          temp.push(secondaryModuleType);
          this.secondaryModulesList.push(temp);
          count++;
        })
      }
      console.log("secondary modules");
      console.log(this.secondaryModulesList);

    */

    });

    console.log("typed dto list");
    console.log(this.typedDTOList);
  }

  onSubmit() {
    this.regInfo = new RegistrationDTO(null, null, null, null, null, this.addNewLicense.get('license').value);
    this.errorMessageService.clear();
    this.accountService.addAdditionalLicense(this.regInfo).subscribe(
      data => {
        if(data.success + '' === 'true') {
          console.log("good new license info");
          window.location.reload();
        } else {
          console.log("bad new license info");
          this.errorMessageService.add(data.errorMessage||data.error);
        }
      }
    );
  }
}
