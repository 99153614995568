import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ToastrService} from "ngx-toastr";
import {ActivatedRoute, Router} from "@angular/router";
import {CourseContentService} from "../../../../_services/adminCourseService/course-content-service";
import {CourseService} from "../../../../_services/adminCourseService/course-service";
import {FormBuilder, Validators} from "@angular/forms";
import {CourseDTO} from "../../../../types/CourseDTO";
import {DocumentDTO} from "../../../../types/DocumentDTO";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-e-admin-list-course-contents',
  templateUrl: 'list-course-contents.html',
  styleUrls: ['list-course-contents.component.css']
})
export class eAdminListCourseContentsComponent implements OnInit {

  courseId: string;
  header: string = "";
  courseLessonsSummary: any;
  lessonIds: any;
  courseDTO: CourseDTO;
  lessonToEdit: string;

  @Input() documentList: DocumentDTO[];

  @ViewChild('editLessonName')
  private editTemplate: TemplateRef<any>;

  modalRef: BsModalRef;

  createCourseLessonForm = this.fb.group({
    title: ['', Validators.required],
  }, {validator: []});

  editCourseLessonForm = this.fb.group({
    title: ['', Validators.required],
  }, {validator: []});

  constructor(private fb: FormBuilder,
              private courseContentService: CourseContentService,
              private courseService: CourseService,
              private toastr: ToastrService,
              private router: Router,
              private route: ActivatedRoute,
              private modalService: BsModalService) {
  }

  ngOnInit() {
    this.courseId = this.route.snapshot.paramMap.get('id');
    this.courseContentService.findAll(this.courseId).subscribe(data => {
      this.courseLessonsSummary = data.courseLessonSummaries;
    });
    if (this.courseId != null) {
      this.loadData(this.courseId);
    }
  }

  addNewLesson() {
    let title = this.createCourseLessonForm.get("title").value;
    if (title == null || title == '') {
      this.toastr.error('Invalid input for lesson title', "Error");
      return;
    }
    this.courseContentService.addLesson(this.courseId, title).subscribe(
      lessonId => {
        this.router.navigateByUrl("/sparkecademy-admin-course/" + this.courseId + "/lesson/" + lessonId + "/content/edit");
      });
  }

  editLesson() {
    let title = this.editCourseLessonForm.get("title").value;
    if (title == null || title == '') {
      this.toastr.error('Invalid input for lesson title', "Error");
      return;
    }
    this.courseContentService.editLessonTitle(this.courseId, this.lessonToEdit, title).subscribe(
      data => {
        if (data.success) {
          this.modalRef.hide();
          this.ngOnInit();
        }
      });
  }


  editModal(lessonId: string, lessonTitle: string) {
    this.lessonToEdit = lessonId;
    this.editCourseLessonForm.controls["title"].setValue(lessonTitle);
    this.modalRef = this.modalService.show(this.editTemplate, {class: 'modal-sm'});
  }

  loadData(courseId) {
    this.courseId = courseId;
    this.courseService.get(this.courseId).subscribe(data => {
      let title = data.title;
      let code = data.code;
      let description = data.description;
      let subTitle = data.subTitle;
      let categoryId = (data.categoryId == null ? null : data.categoryId.toString());
      let cost = data.cost;
      let length = data.length;
      let courseId = data.courseId;
      let categoryImg = data.courseCategory.img;
      let categoryName = data.courseCategory.name;
      let tagName = data.courseTag.name;
      let tagId = data.tagId == null ? null : data.tagId.toString();
      this.header = title;
      this.courseDTO = new CourseDTO(title, subTitle, description, length, cost, tagId, code, categoryId, null,null, null, null, categoryName, courseId, tagName, categoryImg);
    });
  }
}
