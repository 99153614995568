import {Component, OnInit} from '@angular/core';
import {Curriculum} from "../../types/Curriculum";
import {LicenseExpirationDateDTO} from "../../types/LicenseExpirationDateDTO";
import {ActivatedRoute} from "@angular/router";
import {FormBuilder} from "@angular/forms";
import {UserCurriculumService} from "../../_services/userCurriculumService/user-curriculum.service";

@Component({
  selector: 'bulk-user-update',
  templateUrl: './bulk-user-update.html',
  styleUrls: ['./bulk-user-update.component.css']
})
export class BulkUserUpdateComponent implements OnInit {

  bulkUpdateUserList = this.fb.group({
    file:[null]
  }, {validator: []});


  message;
  success : boolean;

  callReturned : boolean = true;
  curriculums: Curriculum[];
  navEntries: any;
  licenseExpirationDate: LicenseExpirationDateDTO;

  constructor(private route: ActivatedRoute, private fb: FormBuilder,  private userCurriculumService : UserCurriculumService) {
  }

  ngOnInit() {
    this.route.data.subscribe(data => {
      console.log("in user management component");
      console.log(data);
      this.curriculums = data.curriculums;
      this.navEntries = data.navEntries;
      this.licenseExpirationDate = data.licenseExpirationDate;
    });
  }


  onFileChange(event) {
    if(event.target.files.length > 0) {
      let file = event.target.files[0];
      this.bulkUpdateUserList.get('file').setValue(file);
      console.log(this.bulkUpdateUserList);
    }
  }

  private prepareSave(): any {
    let input = new FormData();
    // This can be done a lot prettier; for example automatically assigning values by looping through `this.form.controls`, but we'll keep it as simple as possible here
    if(this.bulkUpdateUserList.get('file').value) {
      input.append('FILE', this.bulkUpdateUserList.get('file').value);
    }
    return input;
  }

  onSubmit() {
    this.callReturned = false;
    this.message = null;
    this.userCurriculumService.bulkUpdateUsers( this.prepareSave()).subscribe(data => {
      console.log(data);
      if(data.success){
        if(data.detailedLog){
          this.message =  data.detailedLog;
        }else {
          this.message = "Changes saved";
        }
      } else {
        this.message = data.errorMessage||data.message;
        if (typeof this.message === 'string') {
          this.message = [this.message];
        }
      }

      this.callReturned = true;
    });
  }
}
