export class UpdateLicenseDTO{
  //updateMap : Map<string, number>;
  updateMap = {};
  expirationDate : Date;
  licenseId : number;

  constructor(updateMap : Map<string, number>, expirationDate : Date, licenseId : number){
    this.updateMap = updateMap;
    this.expirationDate = expirationDate;
    this.licenseId = licenseId;

    updateMap.forEach((val : number, key: string) => {
      this.updateMap[key] = val
    });
  }

}
