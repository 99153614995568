import { Injectable } from '@angular/core';


import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {UserCurriculumService} from "../../_services/userCurriculumService/user-curriculum.service";
import {Curriculum} from "../../types/Curriculum";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TtPermissionsResolver implements Resolve<any> {
  constructor(private curriculumService: UserCurriculumService) {}

  resolve(route: ActivatedRouteSnapshot) : Observable<any>{
    //console.log("in resolver");
    //return this.curriculumService.getUserCurriculums();
    return this.curriculumService.hasTTPermission(route.params['id']);
  }

}
