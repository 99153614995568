import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Curriculum} from "../../types/Curriculum";
import {Module} from "../../types/Module";
import {SecondaryModule} from "../../types/SecondaryModule";
import {ModuleFile} from "../../types/ModuleFile";
import {SecondaryModuleFile} from "../../types/SecondaryModuleFile";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {LicenseExpirationDateDTO} from "../../types/LicenseExpirationDateDTO";

@Component({
  selector: 'app-secondary-module-file-list',
  templateUrl: './secondary-module-file-list.html',
  styleUrls: ['./secondary-module-file-list.component.css']
})
export class SecondaryModuleFileListComponent implements OnInit {

  curriculums : Curriculum[];
  licenseExpirationDate: LicenseExpirationDateDTO;
  curriculumId : number;
  currentSecondaryModule : SecondaryModule;

  navEntries : any;


  secondaryModuleFilesArray : Array<SecondaryModuleFile> = new Array<SecondaryModuleFile>();
  currentCurriculum : Curriculum;
  currentModule: Module;
  currentFolder: ModuleFile;

  currentFolderParent: Module;

  curriculumBreadcrumb: string;

  moduleBreadcrumb: string;

  moduleFileBreadcrumb: string;

  moduleFolderBreadcrumb: string;

  fileNameVal: string;
  audioFileNameVal: string;
  navTextVal: string;
  hasSpanishFileVal:boolean;
  isMusic: boolean;
  isVideo: boolean;
  isDownloadable: boolean;
  isFolder: boolean;

  safeHtml: SafeHtml;

  constructor(private route: ActivatedRoute, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.route.data.subscribe(data =>
    {
      console.log(data);

      data.secondaryModuleFiles.forEach( secondaryModuleFile => {
        let typedFile : SecondaryModuleFile;
        console.log(secondaryModuleFile);
        typedFile = new SecondaryModuleFile(secondaryModuleFile.id, secondaryModuleFile.navText, secondaryModuleFile.filePath,
          secondaryModuleFile.name, secondaryModuleFile.secondaryModuleId, secondaryModuleFile.music, secondaryModuleFile.video,
          secondaryModuleFile.curriculumId, secondaryModuleFile.downloadable, secondaryModuleFile.folder, secondaryModuleFile.parentFolderId,
          secondaryModuleFile.moduleId);
        this.secondaryModuleFilesArray.push(typedFile);
      });

      this.currentCurriculum = data.currentCurriculum;


      this.navEntries = data.navEntries;
      this.licenseExpirationDate = data.licenseExpirationDate;
      console.log(this.navEntries);

      this.currentModule = data.currentModule;
      this.currentFolder = data.currentFolder;
      this.currentFolderParent = data.currentFolderParent;

      this.curriculums = data.curriculums;
      this.curriculumId = this.route.snapshot.params['curriculumId'];
      this.currentSecondaryModule = new SecondaryModule(data.currentSecondaryModule.id, data.currentSecondaryModule.navText,
        data.currentSecondaryModule.curriculumId, data.currentSecondaryModule.moduleId, data.currentSecondaryModule.secondaryModuleType,
        data.currentSecondaryModule.iconPath, data.currentSecondaryModule.url, data.currentSecondaryModule.moduleFileId);
    });

    console.log("@@@@@@@@@@@@@@@@@@breadcrumb");
    this.curriculumBreadcrumb = '/module/'+this.currentCurriculum.id;
    console.log(this.currentModule);
    console.log(this.currentFolder);
    if(this.currentModule != null) {
      this.moduleBreadcrumb = '/moduleFileList/' + this.currentModule.moduleId + '/' + this.currentModule.curriculumId;
      console.log(this.moduleBreadcrumb);
    }

    if(this.currentFolder != null){
      console.log(this.currentFolderParent);
      this.moduleBreadcrumb = '/moduleFileList/' + this.currentFolderParent.moduleId + '/' + this.currentFolder.curriculumId;
      console.log(this.moduleBreadcrumb);
      this.moduleFileBreadcrumb = '/moduleFolderContents/' + this.currentFolder.id;
      console.log(this.moduleFileBreadcrumb);
      this.moduleFolderBreadcrumb='/moduleFileList/'+this.currentFolder.parentFolderId + '/' + this.currentFolder.curriculumId;
      console.log(this.moduleFolderBreadcrumb);
    }
  }

  loadFile(moduleFile : SecondaryModuleFile){
    console.log("in load file: " + moduleFile.filePath);
    console.log(moduleFile);

    this.fileNameVal = moduleFile.filePath;
    this.audioFileNameVal = moduleFile.filePath;
    this.navTextVal = moduleFile.navText;
    this.isMusic = moduleFile.music;
    this.isVideo = moduleFile.video;
    this.isDownloadable = moduleFile.downloadable;
    if(moduleFile.video) {
      this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(moduleFile.filePath);
    }
  }

}
