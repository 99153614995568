import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {UserCourseService} from "../../../_services/userCourseService/user-course-service";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ToastrService} from "ngx-toastr";
import {CourseTagDTO} from "../../../types/CourseTagDTO";
import {CourseCategoryDTO} from "../../../types/CourseCategoryDTO";
import {AuthService} from "../../../../_services/authService/auth.service";

@Component({
  selector: 'app-course-signup',
  templateUrl: 'course-signup.html',
  styleUrls: ['course-signup.component.css']
})
export class eCourseSignupComponent implements OnInit {

  courseTags: CourseTagDTO[];
  courseCategories: CourseCategoryDTO[];

  courseUrl: string;
  header: string = " Sign up";
  title: string;
  subTitle: string;

  creditRequired: number;
  creditRequiredMessage: string;

  courseCost: number;
  courseId: number;

  isAlreadyEnrolled: boolean;

  modalRef: BsModalRef;

  @ViewChild('confirmation')
  private confirmationTemplate: TemplateRef<any>;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private toastr: ToastrService,
              private modalService: BsModalService,
              private authService: AuthService,
              private userCourseService: UserCourseService) {
  }


  ngOnInit() {

    this.route.params.subscribe(routeParams => {
      this.courseUrl = routeParams.courseUrl;
      if (typeof this.courseUrl !== 'undefined') {
        this.loadData();
      }
    });

  }

  enrollInACourse() {

    this.modalRef = this.modalService.show(this.confirmationTemplate, {class: 'modal-sm'});
  }

  loadData() {
    this.userCourseService.getSummary(this.courseUrl).subscribe(data => {
      this.courseCost = data.courseCost;
      this.creditRequired = data.creditRequired;
      this.title = data.title;
      this.courseId = data.courseId;
      this.header = 'Sign up for "' + this.title + '"';
      this.isAlreadyEnrolled = data.isAlreadyEnrolled;
      if (this.isAlreadyEnrolled) {
        this.title = 'You are already enroll in ' + this.title + '.';
        return;
      }
      if (this.courseCost == 0.0) {
        this.title = 'Please confirm that you want to enroll in ' + this.title + '.';
      } else {
        this.title = 'The following credits would be used to enroll in this course:';
      }
      this.subTitle = ' ' + this.authService.getCurrentUserName() + '  :  ' + this.courseCost + ' credit';

      if (this.creditRequired != null) {
        this.creditRequiredMessage = 'You need ' + this.creditRequired + ' more credit to sign up.';
      }
    });
  }


  confirm(): void {
    this.userCourseService.enrollInACourse(this.courseId).subscribe(data => {
      this.toastr.success("You are successfully enrolled to this course", "Success")
      this.modalRef.hide();
      this.router.navigateByUrl("/sparkecademy-catalog/courses/" + this.courseUrl);
    });
  }

  decline(): void {
    this.modalRef.hide();
  }

}
