import { Injectable } from '@angular/core';


import { Resolve } from '@angular/router';
import {Observable, Subject} from "rxjs";
import {LicenseDTO} from "../../types/LicenseDTO";
import {AccountService} from "../../_services/accountService/account.service";
import {AuthService} from "../../_services/authService/auth.service";

@Injectable({
  providedIn: 'root'
})
export class IsAdminResolver implements Resolve<boolean> {
  constructor(private authService : AuthService) {}

  resolve() : Observable<boolean>{
    console.log("In is admin resolver");
    return this.authService.isUserAdmin();
  }
}
