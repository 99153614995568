import { Component, OnInit } from '@angular/core';

export class ResetPasswordDTO {

  token: string;
  password: string;
  constructor(password:string, token:string){
    this.token = token;
    this.password = password;
  }


}
