import { Injectable } from '@angular/core';


import { Resolve } from '@angular/router';
import {UserCurriculumService} from "../../_services/userCurriculumService/user-curriculum.service";
import {Curriculum} from "../../types/Curriculum";
import {Observable} from "rxjs";
import {AccountUserDTO} from "../../types/AccountUserDTO";
import {AccountService} from "../../_services/accountService/account.service";
import {ShoolGroupDTO} from "../../types/SchoolGroupDTO";

@Injectable({
  providedIn: 'root'
})
export class AccountUserResolver implements Resolve<Observable<ShoolGroupDTO[]>> {
  constructor(private accountService : AccountService) {}

  resolve() : Observable<ShoolGroupDTO[]>{
    console.log("in resolver");
    return this.accountService.getAccountUsers();
  }
}
