import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {ActivatedRoute, Router} from "@angular/router";
import {CourseContentService} from "../../../../_services/adminCourseService/course-content-service";
import {CourseLessonContentDTO} from "../../../../types/CourseLessonContentDTO";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";


@Component({
  selector: 'app-e-admin-create-course-contents',
  templateUrl: './create-course-contents.html',
  styleUrls: ['./create-course-contents.component.css']
})
export class eAdminCreateCourseContentComponent implements OnInit {

  header: string = "Lesson Title";
  courseId: string;
  lessonId: string;
  recordIndexToDelete: number;
  courseLessonContents: CourseLessonContentDTO[];

  modalRef: BsModalRef;

  @ViewChild('confirmation')
  private confirmationTemplate: TemplateRef<any>;

  createCourseContentsForm = this.fb.group({
    courseContent: this.fb.array([
      this.fb.group({
        caption: [''],
        type: [''],
        file: [''],
        fileUrl: [''],
        id: [''],
        safeHtml: ['']
      })
    ])
  }, {validator: []});


  constructor(private fb: FormBuilder,
              private courseContentService: CourseContentService,
              private toastr: ToastrService,
              private router: Router,
              private route: ActivatedRoute,
              private modalService: BsModalService,
              private sanitizer: DomSanitizer) {

  }

  ngOnInit() {
    this.courseId = this.route.snapshot.paramMap.get('courseId');
    this.lessonId = this.route.snapshot.paramMap.get('lessonId');
    this.courseContentService.findLessonContents(this.courseId, this.lessonId).subscribe(
      data => {
        this.header = data.lesson.title;
        this.courseLessonContents = data.lesson.lessonContents;

        for (let i = 0; i < this.courseLessonContents.length; i++) {
          this.addNewCourseContentData(this.courseLessonContents[i]);
        }
      }
    );
  }

  get courseContentFormArr(): FormArray {
    return this.createCourseContentsForm.get('courseContent') as FormArray;
  }

  addNewCourseContentData(data: CourseLessonContentDTO) {
    this.courseContentFormArr.push(
      this.fb.group({
        caption: [data.caption],
        type: [data.type],
        file: [data.file],
        fileUrl: [data.fileUrl],
        id: [data.id],
        safeHtml: [this.sanitizer.bypassSecurityTrustHtml(data.caption)]
      }));
  }

  onSubmit(index) {

    let formArray = this.courseContentFormArr;
    let input = new FormData();
    let isEditRecord = formArray.at(index).get("id").value != null;
    input.append('id', formArray.at(index).get("id").value);
    input.append('file', formArray.at(index).get("file").value);
    input.append('caption', formArray.at(index).get("caption").value);
    input.append('type', formArray.at(index).get("type").value);

    this.courseContentService.addLessonContent(this.courseId, this.lessonId, input).subscribe(data => {
      try {
        formArray.at(index).patchValue({"id": data});
        if (isEditRecord) {
          this.toastr.success("Lesson Content Updated Successfully", "Success");
        } else {
          this.toastr.success("Lesson Content Created Successfully", "Success");
        }
      } catch (ex) {
        alert(ex);
      }

    });
  }

  onDelete(index) {
    this.recordIndexToDelete = index;
    this.modalRef = this.modalService.show(this.confirmationTemplate, {class: 'modal-sm'});
  }

  onFileChange(index, $event) {
    if ($event.target.files.length > 0) {
      let file = $event.target.files[0];
      this.courseContentFormArr.at(index).patchValue({"file": file});
    }
  }

  confirm(): void {

    let formArray = this.courseContentFormArr;
    let id = formArray.at(this.recordIndexToDelete).get("id").value;
    if (id == null) {
      this.courseContentFormArr.removeAt(this.recordIndexToDelete);
    } else {
      this.courseContentService.removeLessonContent(this.courseId, this.lessonId, id).subscribe(data => {
        this.courseContentFormArr.removeAt(this.recordIndexToDelete);
      });
    }
    this.toastr.success("Lesson Content deleted successfully!!", "Success")
    this.modalRef.hide();
  }

  decline(): void {
    this.modalRef.hide();
  }
}
