import {Component, Input, OnInit} from '@angular/core';
import {Curriculum} from "../../types/Curriculum";
import {Module} from "../../types/Module";

@Component({
  selector: 'app-module-list',
  templateUrl: './module-list.html',
  styleUrls: ['./module-list.component.css']
})
export class ModuleListComponent implements OnInit {

  @Input() moduleArray: Module[];
  @Input() curriculumId: number;
  @Input() ttModule: boolean;
  @Input() accessingViaTrial: boolean;

  constructor() { }

  ngOnInit() {
    console.log(this.moduleArray);
  }

}
