export class CourseEnrollmentFilterDTO {

  userName: string;
  sortBy: string;
  sortOrder: string;
  pageIndex: number;
  pageSize: number = 20;
  length: number;

  constructor(pageIndex: number,
              pageSize: number,
              length: number,
              userName?: string,
              sortBy?: string,
              sortOrder?: string
  ) {
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;
    this.length = length;
    this.userName = userName;
    this.sortBy = sortBy;
    this.sortOrder = sortOrder;
  }
}
