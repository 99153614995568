import { Component, OnInit } from '@angular/core';
import {UserCurriculumService} from "../../_services/userCurriculumService/user-curriculum.service";
import {Curriculum} from "../../types/Curriculum";
import {AdminCurriculumService} from "../../_services/admin/adminCurriculumService/admin-curriculum.service";

@Component({
  selector: 'app-admin-program-editor-top',
  templateUrl: './admin-program-editor-top.html',
  styleUrls: ['./admin-program-editor-top.component.css']
})
export class AdminProgramEditorTopComponent implements OnInit {

  editorLoaded : boolean = false;

  loadCurriculumObj : Curriculum = null;

  editorToggle : boolean = false;

  constructor(private curriculumService : AdminCurriculumService) { }

  ngOnInit() {



  }

  loadCurriculum(curriculum){
    console.log("loadCurriculum()");
    console.log(curriculum);
    this.editorLoaded = true;

    this.loadCurriculumObj = curriculum;
    console.log("load curriculum obj");
    console.log(this.loadCurriculumObj);
  }

  closeEditor(){
    this.editorLoaded = false;
    this.loadCurriculumObj = null;
  }

  reloadEditor(dto){
    this.curriculumService.getCurrentCurriculum(dto.id).subscribe(
      data =>{
        this.loadCurriculumObj=data;
        this.editorToggle = !this.editorToggle;
      }
    )
  }
}
