import {Component, Input, OnInit} from '@angular/core';
import {CourseCategoryDTO} from "../../types/CourseCategoryDTO";
import {CourseTagDTO} from "../../types/CourseTagDTO";
import {ActivatedRoute} from "@angular/router";
import {DomSanitizer} from "@angular/platform-browser";
import {AdminAuthService} from "../../../_services/admin/adminAuthService/admin-auth.service";

@Component({
  selector: 'app-e-admin-nav',
  templateUrl: './admin-nav.html',
  styleUrls: ['./admin-nav.component.css']
})
export class eAdminNavComponent implements OnInit {
  @Input() activePage: string;

  @Input() courseCategories: CourseCategoryDTO[];
  @Input() courseTags: CourseTagDTO[];


  constructor(private route: ActivatedRoute, private sanitizer: DomSanitizer, private adminAuthService: AdminAuthService) {}

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.courseTags = data.courseTags;
      this.courseCategories = data.courseCategories;
    });
  }

  logout(){
    this.adminAuthService.logout(true);
  }
}
