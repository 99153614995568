import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Curriculum} from "../../../types/Curriculum";

@Component({
  selector: 'app-admin-program-editor-mid',
  templateUrl: `./admin-program-editor-mid.html`,
  styleUrls: ['./admin-program-editor-mid.component.css']
})
export class AdminProgramEditorMidComponent implements OnInit {

  _curriculum : Curriculum;

  @Output() closeEditorEmit=new EventEmitter<true>();
  @Output() reloadEditorEmit=new EventEmitter<Curriculum>();

  selected : number;

  constructor() { }

  ngOnInit() {
  }

  @Input()
  set curriculum(curriculum){
    this._curriculum = curriculum;
  }

  closeEditor(){
    this.closeEditorEmit.emit(true);
  }

  reloadEditor(event){
    this.reloadEditorEmit.emit(event);
  }

  setSelectedValue(value){
    this.selected = value;
  }

}
