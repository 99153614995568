import {Component, OnInit} from '@angular/core';
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";
import {ActivatedRoute} from '@angular/router';
import {OnSiteCourseService} from "../../../_services/adminLiveCourseService/on-site-course.service";
import {CourseService} from "../../../_services/adminCourseService/course-service";

@Component({
  selector: 'app-e-admin-view-onSite-course',
  templateUrl: 'admin-view-onSite-course.html',
  styleUrls: ['admin-view-onSite-course.component.css']
})
export class eAdminViewOnSiteCourseComponent implements OnInit {

  onSiteCourseEnrollments: any;
  onSiteCourseId: number;
  onSiteCourse: any;
  header: any;
  courseId: any;

  constructor(private onSiteCourseService: OnSiteCourseService,
              private courseService: CourseService,
              private toastr: ToastrService,
              private router: Router,
              private route: ActivatedRoute) {
    this.onSiteCourseId = parseInt(this.route.snapshot.paramMap.get('onSiteCourseTokenId'));
    this.courseId = parseInt(this.route.snapshot.paramMap.get('id'));
    if (this.onSiteCourseId != null) {
      this.loadData();
    }
  }

  ngOnInit() {
  }

  loadData() {

    this.courseService.get(this.courseId).subscribe(data => {
      this.header = "Course Code for " + data.title;
    });

    this.onSiteCourseService.get(this.onSiteCourseId).subscribe(data => {
      this.onSiteCourse = data;
    });

    this.onSiteCourseService.findEnrollments(this.onSiteCourseId).subscribe(data => {
      this.onSiteCourseEnrollments = data;
    });
  }
}
