export class OrganizationCourseDTO {
  id: number;
  courseId: number;
  courseName: string;
  level: string;

  constructor(courseId: number,level: string, courseName?: string, id?: number) {
    this.id = id;
    this.courseId = courseId;
    this.courseName = courseName;
    this.level = level;
  }
}
