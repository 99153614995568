import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-account-menu',
  templateUrl: './account-menu.html',
  styleUrls: ['./account-menu.component.css']
})
export class AccountMenuComponent implements OnInit {
  @Input()  activePage : string;


  licenseInfo : boolean = false;
  userManagement : boolean = false;
  bulkUserUpdate : boolean = false;
  updateProfile : boolean = false;


  constructor() { }

  ngOnInit() {
    this.userManagement = (this.activePage === "UserManagement");
    this.licenseInfo = (this.activePage === "LicenseInfo");
    this.bulkUserUpdate = (this.activePage === "BulkUserUpdate");
    this.updateProfile = (this.activePage === "UpdateProfile");


  }

}
