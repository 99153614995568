import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-account-merge-top-level-container',
  templateUrl: './account-merge-top-level-container.html',
  styleUrls: ['./account-merge-top-level-container.component.css']
})
export class AccountMergeTopLevelContainerComponent implements OnInit {

  editorLoaded : boolean = false;

  @Input() currentAccountId : number;



  @Output() reloadNoTabSelectedEmit = new EventEmitter<boolean>();

  @Output() reloadTabSelectedEmit = new EventEmitter<boolean>();
  inputObj : any;

  constructor() { }

  ngOnInit() {
  }

  loadEditor(data){
    console.log("load merge editor");
    console.log(data);
    this.editorLoaded =true;
    this.inputObj = data;
  }

  reloadTabSelected(data){

    this.reloadTabSelectedEmit.emit(true);
  }

  reloadNoTabSelected(data){
    this.reloadNoTabSelectedEmit.emit(true);
  }

}
