export class CourseCategoryDTO {

  categoryId: number;
  name: string;
  colorCode: string;
  url: string;
  img: string;
  isActive: boolean;
  isActiveStr: string;

  constructor(categoryId: number,
              name: string,
              colorCode: string,
              url: string,
              img: string,
              isActive?: boolean
  ) {
    this.categoryId = categoryId;
    this.name = name;
    this.colorCode = colorCode;
    this.url = url;
    this.img = img;
    this.isActive = isActive;
  }
}
