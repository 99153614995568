import { Injectable } from '@angular/core';
import { User } from '../../types/User'
import {MOCKUSER} from'../../types/MockUsers';
import {Observable} from "rxjs";
import {of} from "rxjs";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {RegistrationDTO} from "../../types/RegistrationDTO";
import {RegResponse} from "../../types/RegResponse";
import {AuthService} from "../authService/auth.service";
import {LicenseDTO} from "../../types/LicenseDTO";
import {AccountUserDTO} from "../../types/AccountUserDTO";
import {ShoolGroupDTO} from "../../types/SchoolGroupDTO";
import {RoleInfo} from "../../types/RoleInfo";
import {UpdateAccountRoleDTO} from "../../types/UpdateAccountRoleDTO";
import {SelfRegistrationDTO} from "../../types/SelfRegistrationDTO";
import {UpdateUserDTO} from "../../types/UpdateUserDTO";
import {LicenseExpirationDateDTO} from "../../types/LicenseExpirationDateDTO";




  @Injectable({
  providedIn: 'root'
})
export class AccountService {

    private registrationEndpoint = '/account/register';
    private registrationTrialEndpoint = '/account/registerTrial';
    private submitInfoEndpoint = '/account/submitInfo';
    private verifyEmailEndpoint = '/account/verifyEmail';
    private accountLicenseInoEndpoint = '/account/getAccountLicenseInfo';
    private addAdditionalLicenseEndpoint = '/account/addNewLicense';
    private getAccountUsersEndpoint = '/account/getAccountUsers';
    private getAccountroleStatsEndpoint = '/account/getAccountRoleStats';
    private updateAccountUserRolesEndpoint = '/account/updateAccountUserRoles'
    private deleteUserEndpoint = '/account/deleteUser';
    private createUserEndpoint = '/account/createUser';
    private resetUserPasswordEndpoint = '/account/resetUserPassword';
    private updateUserEndpoint = '/account/updateUser';
    private acceptEulaEndpoint = '/account/acceptEula';
    private earlierLicenseExpirationDateEndpoint = '/account/getEarlierLicenseExpirationDate';

    //For local
    // private base = 'http://localhost:8050';
    //for prod
    private base = 'https://sparkfamily.org/api';
    //for prod
    // private base = 'http://localhost:8050';

  constructor(private http: HttpClient, private authService : AuthService) { }


  registerAccountMock(): Observable<User>{
    return of(MOCKUSER);
  }


    public registerAccount(regInfo: RegistrationDTO) : Observable<RegResponse>{
      return this.http.post<RegResponse>(this.base + this.registrationEndpoint, regInfo);
    }

    public registerTrialAccount(regInfo: RegistrationDTO) : Observable<RegResponse>{
      return this.http.post<RegResponse>(this.base + this.registrationTrialEndpoint, regInfo);
    }

    public submitInfo(regInfo: SelfRegistrationDTO) : Observable<RegResponse>{
      return this.http.post<RegResponse>(this.base + this.submitInfoEndpoint, regInfo);
    }

    public verifyEmail(email: string) : Observable<any>{
      return this.http.post<RegResponse>(this.base + this.verifyEmailEndpoint, email);
    }


    public getAccountLicenseInfo() : Observable<LicenseDTO[]>{
      return this.http.get<LicenseDTO[]>(this.base + this.accountLicenseInoEndpoint);
    }

    public getEarlierLicenseExpirationDate() : Observable<LicenseExpirationDateDTO>{
      return this.http.get<LicenseExpirationDateDTO>(this.base + this.earlierLicenseExpirationDateEndpoint);
    }

    public addAdditionalLicense(regInfo: RegistrationDTO) : Observable<RegResponse> {
      return this.http.post<RegResponse>(this.base + this.addAdditionalLicenseEndpoint, regInfo)
    }

    public getAccountUsers() : Observable<ShoolGroupDTO[]> {
      return this.http.get<ShoolGroupDTO[]>(this.base + this.getAccountUsersEndpoint);
    }

    public getAccountRoleStats() : Observable<RoleInfo[]> {
      return this.http.get<RoleInfo[]>(this.base + this.getAccountroleStatsEndpoint);
    }

    public updateAccountUserRoles(dtos : UpdateAccountRoleDTO[]) : Observable<any> {
      return this.http.post<any>(this.base + this.updateAccountUserRolesEndpoint, dtos)
    }

    public deleteUser(dto : AccountUserDTO) : Observable<any> {
      return this.http.post<any>(this.base + this.deleteUserEndpoint, dto);
    }

    public createNewUser(dto : RegistrationDTO) : Observable<any> {
      return this.http.post<any>(this.base + this.createUserEndpoint, dto);
    }

    public resetPassword(email : string) : Observable<any> {
      return this.http.post<any>(this.base + this.resetUserPasswordEndpoint, email);
    }

    public updateUser(dto: UpdateUserDTO) : Observable<any> {
      return this.http.post<any>(this.base + this.updateUserEndpoint, dto);
    }

    public acceptEula() : Observable<any> {
      return this.http.get<any>(this.base + this.acceptEulaEndpoint);
    }
}
