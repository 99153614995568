import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ErrorMessageService} from "../errorMessageService/error-message.service";
import {Curriculum} from "../../types/Curriculum";
import {Observable, of} from "rxjs";
import {catchError} from "rxjs/operators";
import {Module} from "../../types/Module";
import {ModuleFile} from "../../types/ModuleFile";
import {Breadcrumb} from "../../types/Breadcrumb";
import {HomePageEditDTO} from "../../types/HomePageEditDTO";
import {SecondaryModuleFile} from "../../types/SecondaryModuleFile";

@Injectable({
  providedIn: 'root'
})
export class UserCurriculumService {

  //for local
  // private curriculumUrl: string = "http://localhost:8050/curriculum";
  //for qa
  // private curriculumUrl : string  = "http://sparkfamily.org:8050/curriculum";
  //for production
  private curriculumUrl : string  = "https://sparkfamily.org/api/curriculum";

  //for local
  // private ttModuleUrl: string = "http://localhost:8050/ttmodule";
  //for qa
  // private ttModuleUrl : string  = "http://sparkfamily.org:8050/ttmodule";
  //for production
  private ttModuleUrl : string  = "https://sparkfamily.org/api/ttmodule";

  private bulkUpdateUsersUrl: string = this.curriculumUrl + "/bulkUpdate";


  constructor(private http: HttpClient, private errorService: ErrorMessageService) {
  }

  public getUserCurriculums(): Observable<Curriculum[]> {
    return this.http.get<Curriculum[]>(this.curriculumUrl + '/usercurriculums').pipe(catchError(this.handleError('getUserCurriculums', [])));
  }

  public getUserNavEntries(): Observable<Curriculum[]> {
    return this.http.get<Curriculum[]>(this.curriculumUrl + '/userNavEntries').pipe(catchError(this.handleError('userNavEntries', [])));
  }

  public isCurriculumRestricted(id: number): Observable<any> {
    return this.http.get<any>(this.curriculumUrl + '/isrestricted/' + id).pipe(catchError(this.handleError('isRestricted', true)));
  }

  public getCurriculumModules(id: number): Observable<Module[]> {
    console.log(id);
    return this.http.get<Module[]>(this.curriculumUrl + '/curriculumModuleList/' + id).pipe(catchError(this.handleError('getCurriculumModules', [])));
  }

  public accessingCurriculumViaTrial(curriculumId: number): Observable<any> {
    console.log(curriculumId);
    return this.http.get<any>(this.curriculumUrl + '/accessingCurriculumViaTrial/' + curriculumId).pipe(catchError(this.handleError('accessingCurriculumViaTrial', [])));
  }

  public accessingModuleFolderContentsViaTrial(moduleFolderParentId: number): Observable<any> {
    console.log(moduleFolderParentId);
    return this.http.get<any>(this.curriculumUrl + '/accessingModuleFolderContentsViaTrial/' + moduleFolderParentId).pipe(catchError(this.handleError('accessingCurriculumViaTrial', [])));
  }

  public getSecondaryModules(moduleId: number): Observable<any> {
    console.log("getSecondaryModules");
    console.log(moduleId);
    return this.http.get<any>(this.curriculumUrl + '/getSecondaryModulesForMod/' + moduleId).pipe(catchError(this.handleError('getSecondaryModules', [])));
  }

  public getFolderSecondaryModules(moduleId: number): Observable<any> {
    console.log("getSecondaryModules for folder");
    console.log(moduleId);
    return this.http.get<any>(this.curriculumUrl + '/getSecondaryModulesForModForFold/' + moduleId).pipe(catchError(this.handleError('getSecondaryModules', [])));
  }

  public getSecondaryModuleFiles(secondaryModuleId: number): Observable<any> {
    console.log("getSecondaryModuleFiles");
    console.log(secondaryModuleId);
    return this.http.get<any>(this.curriculumUrl + "/getSecondaryModuleFiles/" + secondaryModuleId).pipe(catchError(this.handleError('getSecondaryModuleFiles', [])));
  }

  public getCurrentSecondaryModule(secondaryModuleId: number): Observable<any> {
    console.log("getCurrentSecondaryModule");
    console.log(secondaryModuleId);
    return this.http.get<any>(this.curriculumUrl + "/getCurrentSecondaryModule/" + secondaryModuleId).pipe(catchError(this.handleError('getCurrentSecondaryModule', [])));
  }

  public getModuleFiles(id: number): Observable<ModuleFile[]> {
    console.log(id);
    return this.http.get<ModuleFile[]>(this.curriculumUrl + '/moduleFileList/' + id).pipe(catchError(this.handleError('getModuleFiles', [])));
  }

  public getFolderFiles(id: number): Observable<ModuleFile[]> {
    console.log(id);
    return this.http.get<ModuleFile[]>(this.curriculumUrl + '/moduleFolderFiles/' + id).pipe(catchError(this.handleError('getFolderFiles', [])));
  }

  public getCurrentFolder(id: number): Observable<ModuleFile> {
    console.log(id);
    return this.http.get<ModuleFile>(this.curriculumUrl + '/getCurrentFolder/' + id).pipe(catchError(this.handleError('getCurrentFolder', null)));
  }

  public getCurrentFolderParent(id: number): Observable<Module> {
    console.log(id);
    return this.http.get<any>(this.curriculumUrl + '/getCurrentFolderParent/' + id).pipe(catchError(this.handleError('getCurrentFolderParent', null)));
  }

  public getCurrentCurriculum(id: number): Observable<Curriculum> {
    console.log(id);
    return this.http.get<Curriculum>(this.curriculumUrl + '/getCurrentCurriculum/' + id).pipe(catchError(this.handleError('getCurrentCurriculum', null)));
  }

  public getShowEula(id: number): Observable<Curriculum> {
    console.log(id);
    return this.http.get<Curriculum>(this.curriculumUrl + '/getShowEula/' + id).pipe(catchError(this.handleError('getCurrentCurriculum', null)));
  }


  public getCurrentModule(id: number): Observable<Module> {
    console.log(id);
    return this.http.get<Curriculum>(this.curriculumUrl + '/getCurrentModule/' + id).pipe(catchError(this.handleError('getCurrentModule', null)));
  }

  public getCurrentTTModule(id: number): Observable<any> {
    console.log(id);
    return this.http.get<any>(this.ttModuleUrl + '/getCurrentTTModule/' + id).pipe(catchError(this.handleError('getCurrentTTModule', null)));
  }

  public getBreadcrumb(id: number, type: string, isTT: boolean, isSM: boolean): Observable<Breadcrumb[]> {
    console.log(id + " | " + type);
    return this.http.get<Breadcrumb[]>(this.curriculumUrl + '/getBreadcrumbs/' + type + '/' + id + '/' + isTT + '/' + isSM).pipe(catchError(this.handleError('getBreadcrumb', null)));
  }

  public getUniversityLinks(): Observable<any> {
    return this.http.get<any>(this.curriculumUrl + '/getUniversityLinks/').pipe(catchError(this.handleError('getUniveristyLinks', null)));
  }


  public hasTTPermission(id: number) {
    return this.http.get<any>(this.curriculumUrl + '/hasTTPermission/' + id).pipe(catchError(this.handleError('hasTTPermission', null)));
  }

  public getTTModules(id: number) {
    return this.http.get<any>(this.ttModuleUrl + '/ttModuleList/' + id).pipe(catchError(this.handleError('getTTModules', null)));
  }

  public getTTModuleFiles(id: number) {
    return this.http.get<any>(this.ttModuleUrl + '/ttModuleFileList/' + id).pipe(catchError(this.handleError('getTTModuleFiles', null)));
  }

  public getTTFolderFiles(id: number): Observable<ModuleFile[]> {
    console.log(id);
    return this.http.get<ModuleFile[]>(this.ttModuleUrl + '/ttModuleFolderFiles/' + id).pipe(catchError(this.handleError('getFolderFiles', [])));
  }

  public getSecondaryFolderFiles(id: number): Observable<ModuleFile[]> {
    console.log(id);
    return this.http.get<ModuleFile[]>(this.curriculumUrl + '/secondaryModuleFolderFiles/' + id).pipe(catchError(this.handleError('getFolderFiles', [])));
  }

  public getTTCurrentFolder(id: number): Observable<ModuleFile> {
    console.log(id);
    return this.http.get<ModuleFile>(this.ttModuleUrl + '/getTTCurrentFolder/' + id).pipe(catchError(this.handleError('getCurrentFolder', null)));
  }

  public getSecondaryCurrentFolder(id: number): Observable<ModuleFile> {
    console.log(id);
    return this.http.get<SecondaryModuleFile>(this.curriculumUrl + '/getSecondaryCurrentFolder/' + id).pipe(catchError(this.handleError('getCurrentFolder', null)));
  }

  public getTTCurrentFolderParent(id: number): Observable<Module> {
    console.log(id);
    return this.http.get<any>(this.ttModuleUrl + '/getTTCurrentFolderParent/' + id).pipe(catchError(this.handleError('getCurrentFolderParent', null)));
  }

  public getHomePageContent(): Observable<any> {
    console.log("get home page content");
    return this.http.get<any>(this.curriculumUrl + '/homePageContent/').pipe(catchError(this.handleError('getHomePageConent', null)));
  }


  public updateHomePageContent(dto: HomePageEditDTO): Observable<any> {
    console.log("get home page content");
    return this.http.post<any>(this.curriculumUrl + '/updateHomePageContent/', dto).pipe(catchError(this.handleError('getHomePageConent', null)));
  }


  public getSeHomePageContent(): Observable<any> {
    console.log("get se home page content");
    return this.http.get<any>(this.curriculumUrl + '/seHomePageContent/').pipe(catchError(this.handleError('seHomePageContent', null)));
  }

  public getSearchLessonTags(): Observable<String[]> {
    return this.http.get<String[]>(this.curriculumUrl + '/getTags').pipe(catchError(this.handleError('getSearchLessonTags', [])));
  }

  public getSearchLessons(curriculumId, tags): Observable<any> {
    return this.http.post<String[]>(this.curriculumUrl + '/getLessonsByTags/'+curriculumId, tags);
  }

  bulkUpdateUsers(updateData) {
    return this.http.post<any>(this.bulkUpdateUsersUrl, updateData);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log("user cur service catch error");
      console.log("error1");
      console.error(error);
      console.log("error1");
      this.errorService.add("Error generating content");

      return of(result as T);
    };
  }


}
