import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Module} from "../../../../../../types/Module";
import {ModuleFile} from "../../../../../../types/ModuleFile";
import {FormControl, FormGroup} from "@angular/forms";
import {AdminCurriculumService} from "../../../../../../_services/admin/adminCurriculumService/admin-curriculum.service";
import {DisplayOrderUpdateDTO} from "../../../../../../types/DisplayOrderUpdateDTO";
import {IDDTO} from "../../../../../../types/IDDTO";

@Component({
  selector: 'app-admin-tt-module-file-selector',
  templateUrl: './admin-tt-module-file-selector.html',
  styleUrls: ['./admin-tt-module-file-selector.component.css']
})
export class AdminTtModuleFileSelectorComponent implements OnInit {

  _module : Module;

  moduleFiles : ModuleFile[];
  mainSelectorIsLoaded : boolean = false;
  displayOrderForm: FormGroup;


  @Output() reloadSelector=new EventEmitter<boolean>();
  @Output() loadCreateNew=new EventEmitter<boolean>();
  @Output() loadModuleFolderEditorEmit = new EventEmitter<ModuleFile>();
  @Output() loadModuleFileEditorEmit = new EventEmitter<ModuleFile>();

  showConfirm : Map<string, boolean> = new Map<string, boolean>();

  constructor(private curriculumService : AdminCurriculumService) { }

  ngOnInit() {
  }


  @Input()
  set module(module){
    this._module = module;
    this.curriculumService.getTTModuleChildrenFiles(Number(this._module.id)).subscribe(data => {
      console.log(data);
      this.moduleFiles = data;


      const formGroup = new FormGroup({ });

      this.moduleFiles.forEach(moduleFile => {
        this.showConfirm.set(module.id, false);
        console.log(moduleFile);
        formGroup.addControl(moduleFile.navText, new FormControl(moduleFile.id));
        formGroup.controls[moduleFile.navText].setValue(moduleFile.displayOrder);
      });

      this.displayOrderForm = formGroup;

      console.log(this.displayOrderForm);

      this.mainSelectorIsLoaded = true;
    });
  }

  loadModuleFileEditor(moduleFile){
    console.log("in load module file editor");
    this.loadModuleFileEditorEmit.emit(moduleFile);
  }
  loadModuleFolderEditor(moduleFolder){
    console.log("in load module folder editor");
    this.loadModuleFolderEditorEmit.emit(moduleFolder);
  }

  deleteModule(module){
    this.showConfirm.set(module.id, true);
  }

  confirmedDelete(module){
    /*console.log("Load module editor");
    console.log(module);
    this.loadModule.emit(module);*/
    this.curriculumService.deleteTTModuleFile(new IDDTO(Number(module.id))).subscribe(data => {
      this.reloadSelector.emit(true);
    });
  }

  saveDisplayOrderChanges() {
    let updateMap : Map<String, Number> = new Map<String, Number>();
    this.moduleFiles.forEach( moduleFile => {
      updateMap.set(moduleFile.id, this.displayOrderForm.controls[moduleFile.navText].value);
    });
    updateMap.forEach((val : number, key: string) => {
      updateMap[key] = val
    });

    let displayUpdateDTO : DisplayOrderUpdateDTO = new DisplayOrderUpdateDTO(updateMap);

    this.curriculumService.updateTTModuleFileDisplayOrder(displayUpdateDTO).subscribe(data => {
      console.log(data);
      this.reloadSelector.emit(true);
    });
  }

  createNewModuleFile(){
    this.loadCreateNew.emit(true);
  }

}
