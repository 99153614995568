import { Component, OnInit } from '@angular/core';
import {EmailValidation} from "../../helpers/validators";
import {FormBuilder, Validators} from "@angular/forms";
import {AdminLicenseService} from "../../_services/admin/adminLicenseService/admin-license.service";
import {CreateKeyDTO} from "../../types/CreateKeyDTO";

@Component({
  selector: 'app-gen-license-form',
  templateUrl: './gen-license-form.html',
  styleUrls: ['./gen-license-form.component.css']
})
export class GenLicenseFormComponent implements OnInit {
  createLicense = this.fb.group({
    email: ['', EmailValidation],
    referenceNumber: [''],
    licenseDuration: ['', Validators.required],
    K2PEEnable: false,
    PE36Enable:false,
    MSPEEnable:false,
    HSPEEnable:false,
    IPEEnable:false,
    ABCCAEnable:false,
    ASEnable:false,
    ECEnable:false,
    K2PEQty: [{value:'', disabled: true}],
    PE36Qty: [{value:'', disabled: true}],
    MSPEQty: [{value:'', disabled: true}],
    HSPEQty: [{value:'', disabled: true}],
    IPEQty: [{value:'', disabled: true}],
    ABCCAQty: [{value:'', disabled: true}],
    ASQty: [{value:'', disabled: true}],
    ECQty: [{value:'', disabled: true}]
  }, {validator: []});


  submitted : boolean = false;

  spinProgressWheel : boolean = false;

  createdLicense : string;

  hadError: boolean = false;
  errorMessage : string;

  constructor(private fb: FormBuilder, private licenseService : AdminLicenseService) { }

  ngOnInit() {
    this.createLicense.get('K2PEEnable').valueChanges.subscribe(v => {
      if(v){
        this.createLicense.get('K2PEQty').enable();
      } else {
        this.createLicense.get('K2PEQty').disable();
      }
    });

    this.createLicense.get('PE36Enable').valueChanges.subscribe(v => {
      if(v){
        this.createLicense.get('PE36Qty').enable();
      } else {
        this.createLicense.get('PE36Qty').disable();
      }
    });

    this.createLicense.get('MSPEEnable').valueChanges.subscribe(v => {
      if(v){
        this.createLicense.get('MSPEQty').enable();
      } else {
        this.createLicense.get('MSPEQty').disable();
      }
    });

    this.createLicense.get('HSPEEnable').valueChanges.subscribe(v => {
      if(v){
        this.createLicense.get('HSPEQty').enable();
      } else {
        this.createLicense.get('HSPEQty').disable();
      }
    });

    this.createLicense.get('IPEEnable').valueChanges.subscribe(v => {
      if(v){
        this.createLicense.get('IPEQty').enable();
      } else {
        this.createLicense.get('IPEQty').disable();
      }
    });

    this.createLicense.get('ABCCAEnable').valueChanges.subscribe(v => {
      if(v){
        this.createLicense.get('ABCCAQty').enable();
      } else {
        this.createLicense.get('ABCCAQty').disable();
      }
    });

    this.createLicense.get('ASEnable').valueChanges.subscribe(v => {
      if(v){
        this.createLicense.get('ASQty').enable();
      } else {
        this.createLicense.get('ASQty').disable();
      }
    });

    this.createLicense.get('ECEnable').valueChanges.subscribe(v => {
      if(v){
        this.createLicense.get('ECQty').enable();
      } else {
        this.createLicense.get('ECQty').disable();
      }
    });


  }

  createLicenseSubmit(){
    this.submitted = true;
    this.spinProgressWheel = true;

    let types : Array<string> = new Array<string>();
    let qty = new Map<string, string>();
    console.log(qty);
    if(this.createLicense.get('K2PEEnable').value ){
      types.push('K2PE');
      qty.set('K2PE', this.createLicense.get('K2PEQty').value);
    }
    console.log(qty);
    if(this.createLicense.get('PE36Enable').value ){
      types.push('PE36');
      qty.set('PE36', this.createLicense.get('PE36Qty').value);
    }
    console.log(qty);
    if(this.createLicense.get('MSPEEnable').value ){
      types.push('MSPE');
      qty.set('MSPE', this.createLicense.get('MSPEQty').value);
    }
    console.log(qty);
    if(this.createLicense.get('HSPEEnable').value ){
      types.push('HSPE');
      qty.set('HSPE', this.createLicense.get('HSPEQty').value);
    }
    console.log(qty);
    if(this.createLicense.get('IPEEnable').value) {
      types.push('IPE');
      qty.set('IPE', this.createLicense.get('IPEQty').value);
    }
    console.log(qty);
    if(this.createLicense.get('ABCCAEnable').value) {
      types.push('ABCCA');
      qty.set('ABCCA', this.createLicense.get('ABCCAQty').value);
    }
    console.log(qty);
    if(this.createLicense.get('ASEnable').value){
      types.push('AS');
      qty.set('AS', this.createLicense.get('ASQty').value);
    }
    console.log(qty);
    if(this.createLicense.get('ECEnable').value){
      types.push('EC');
      qty.set('EC', this.createLicense.get('ECQty').value);
    }

    console.log(qty);
    let dto : CreateKeyDTO = new CreateKeyDTO(this.createLicense.get('email').value,
      this.createLicense.get('referenceNumber').value,this.createLicense.get('licenseDuration').value,
      types, qty);


    this.licenseService.createLicenseCall(dto).subscribe( data => {
      console.log(data);
      if(data.error){
        this.hadError = true;
        this.spinProgressWheel = false;
        this.errorMessage = data.errorMessage;
      } else if(data.license) {
        this.createdLicense = data.license;
        this.spinProgressWheel = false;
      } else {
        this.hadError;
        this.errorMessage = "Error creating license key";
        this.spinProgressWheel = false;
      }
    });

  }
}
