import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AccountUserDTO} from "../../../types/AccountUserDTO";
import {FormBuilder, Validators} from "@angular/forms";
import {UpdateUserDTO} from "../../../types/UpdateUserDTO";
import {AccountService} from "../../../_services/accountService/account.service";
import {DisplayMessageService} from "../../../_services/displayMessageService/display-message.service";
import {ErrorMessageService} from "../../../_services/errorMessageService/error-message.service";

@Component({
  selector: 'app-user-school-editor',
  templateUrl: './user-school-editor.html',
  styleUrls: ['./user-school-editor.component.css']
})
export class UserSchoolEditorComponent implements OnInit {

  updateSchool = this.fb.group({
    SCHOOL:['', Validators.required]
  }, {validator: []});

  @Output() updated=new EventEmitter<Boolean>();

  _user : AccountUserDTO;
  constructor(private fb: FormBuilder, private userService : AccountService, private displayMessageService : DisplayMessageService,
              private errorMessageService : ErrorMessageService) { }

  ngOnInit() {
  }

  @Input() set user(user){
    this._user = user;
    console.log(user);
    if(user){
      this.updateSchool.controls['SCHOOL'].setValue(this._user.school);
    }
  }

  saveSchoolChanges(){
    let userUpdateDTO : UpdateUserDTO = new UpdateUserDTO();

    userUpdateDTO.id = this._user.userId;
    userUpdateDTO.school = this.updateSchool.controls['SCHOOL'].value;

    this.userService.updateUser(userUpdateDTO).subscribe( data => {
          if(data.success){
            this.displayMessageService.add("School changes saved");
            this.updated.emit(true);
          } else if(data.errorMessage) {
            this.errorMessageService.add(data.errorMessage);
          }
      });
  }


}
