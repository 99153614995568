export class SecondaryModule {
/*
Long id;

    String navText;

    Long curriculumId;

    Long moduleId;

    String secondaryModuleType;

    String iconPath;
 */

  id : number;
  navText : string;
  curriculumId: number;
  moduleId: number;
  moduleFileId: number;
  secondaryModuleType : string;
  iconPath: string;
  url: string;

  constructor(id : number, navText : string, curriculumId : number, moduleId : number, secondaryModuleType : string, iconPath : string, url : string, moduleFileId: number){
    this.id = id;
    this.navText = navText;
    this.curriculumId = curriculumId;
    this.moduleId = moduleId;
    this.secondaryModuleType = secondaryModuleType;
    this.iconPath = iconPath;
    this.url = url;
    this.moduleFileId = moduleFileId;
  }
}
