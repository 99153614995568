import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {
  EmailValidation,
  PasswordValidation,
  RepeatEmailValidator,
  RepeatPasswordValidator
} from "../../helpers/validators";
import {ErrorMessageService} from "../../_services/errorMessageService/error-message.service";
import {AccountService} from "../../_services/accountService/account.service";
import {AuthService} from "../../_services/authService/auth.service";
import {SelfRegistrationDTO} from "../../types/SelfRegistrationDTO";


@Component({
  selector: 'app-info-capture',
  templateUrl: './info-capture.html',
  styleUrls: ['./info-capture.component.css']
})
export class InfoCaptureComponent implements OnInit {

  submitted : boolean = false;

  captureForm = this.fb.group({
    firstName:['', Validators.required],
    lastName: ['', Validators.required],
    email: ['', EmailValidation],
    school: [''],
    phone: [''],
    address: [''],
    city: [''],
    state: [''],
    zip: [''],
    expDate: [''],
    K2PE: false,
    PE36:false,
    MSPE:false,
    HSPE:false,
    IPE:false,
    ABCCA:false,
    AS:false,
    EC:false
  }, {validator: []});

  constructor(private fb: FormBuilder, private errorMessageService: ErrorMessageService, private accountService: AccountService) { }

  ngOnInit() {
  }

  sendInfo(){
    this.submitted = true;


    let types : Array<string> = new Array<string>();

    this.captureForm.get('K2PE').value ? types.push('K2PE'): null;
    this.captureForm.get('PE36').value ? types.push('PE36'): null;
    this.captureForm.get('MSPE').value ? types.push('MSPE'): null;
    this.captureForm.get('HSPE').value ? types.push('HSPE'): null;
    this.captureForm.get('IPE').value ? types.push('IPE'): null;
    this.captureForm.get('ABCCA').value ? types.push('ABCCA'): null;
    this.captureForm.get('AS').value ? types.push('AS'): null;
    this.captureForm.get('EC').value ? types.push('EC'): null;

    console.log(types);
    let dto : SelfRegistrationDTO = new SelfRegistrationDTO(this.captureForm.get('email').value,
      this.captureForm.get('firstName').value,this.captureForm.get('lastName').value,
      this.captureForm.get('school').value, this.captureForm.get('expDate').value,
      this.captureForm.get('address').value, this.captureForm.get('city').value,
      this.captureForm.get('state').value, this.captureForm.get('zip').value,
      types);

    this.errorMessageService.clear();
    console.log(dto);
    this.accountService.submitInfo(dto).subscribe();
    window.scrollTo(0,0);
  }
}
