import {Component, Input, OnInit} from '@angular/core';
import {AdminUserService} from "../../../_services/admin/adminUserService/admin-user.service";
import {AdminCurriculumService} from "../../../_services/admin/adminCurriculumService/admin-curriculum.service";
import {FormBuilder, Validators} from "@angular/forms";
import {UserCurriculumService} from "../../../_services/userCurriculumService/user-curriculum.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-e-admin-dashboard-message',
  templateUrl: './dashboardMessage.html',
  styleUrls: ['./dashboardMessage.component.css']
})
export class eAdminDashboardMessageComponent implements OnInit {

  dashboardMessageForm = this.fb.group({
    contents: ['', Validators.required]
  }, {validator: []});


  constructor(private fb: FormBuilder,
              private curriculumService: UserCurriculumService,
              private toastr: ToastrService,
              private adminCurriculumService: AdminCurriculumService) {
  }

  ngOnInit() {
    this.curriculumService.getSeHomePageContent().subscribe(data => {
      this.dashboardMessageForm.get("contents").setValue(data.contents);
    });
  }

  onSubmit() {
    let contents = this.dashboardMessageForm.get("contents").value;
    this.adminCurriculumService.updateSeHomePageContent(contents).subscribe(data => {
      if (data.success) {
        this.toastr.success("Dashboard contents updated successfully!!","Success")
      }
    });
  }
}
