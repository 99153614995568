export class NavEntryUpdateDTO {

  id:number;
  navText :string;
  displayOrder :number;
  curriculumCheckMap = {};

  constructor(
    id:number,
    navText :string,
    displayOrder :number,
    checkedCurs :  Map<number, boolean>){
    this.id = id;
    this.navText = navText;
    this.displayOrder = displayOrder;
    checkedCurs.forEach((val: boolean, key: number) => {
      this.curriculumCheckMap[key] = val;
    });

  }


}
