export class CourseTagDTO {

  tagId: number;
  name: string;
  colorCode: string;
  url: string;
  sortOrder: number;
  isActive: boolean;
  isActiveStr: string;

  constructor(tagId: number,
              name: string,
              colorCode: string,
              url: string,
              sortOrder?: number,
              isActive?: boolean
  ) {
    this.tagId = tagId;
    this.name = name;
    this.colorCode = colorCode;
    this.url = url;
    this.sortOrder = sortOrder;
    this.isActive = isActive;
  }
}

