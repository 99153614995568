export class UserFilterDTO {

  showOnlySuperUsers: boolean;
  organization: string;
  name: string;
  email: string;
  sortBy: string;
  sortOrder: string;
  pageIndex: number;
  pageSize: number = 20;
  length: number;

  constructor(pageIndex: number,
              pageSize: number,
              length: number,
              showOnlySuperUsers?: boolean,
              organization?: string,
              name?: string,
              email?: string,
              sortBy?: string,
              sortOrder?: string
  ) {
    this.showOnlySuperUsers = showOnlySuperUsers;
    this.organization = organization;
    this.name = name;
    this.email = email;
    this.sortBy = sortBy;
    this.sortOrder = sortOrder;
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;
    this.length = length;
  }
}
