import {UserRoleInfo} from "./UserRoleInfo";
import {last} from "rxjs/operators";

export class UpdateSecondaryModuleDTO {

  id: string;
  curriculumId : number;
  type : string;
  url : string;
  constructor(id: string, curriculumId : number, type: string, url : string){
    this.id = id;
    this.curriculumId = curriculumId;
    this.type = type;
    this.url = url;
  }

}
