import {Injectable} from '@angular/core';
import {Observable, of} from "rxjs";
import {catchError} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {ErrorMessageService} from "../../../_services/errorMessageService/error-message.service";
import {DocumentDTO} from "../../types/DocumentDTO";
import {DocumentFilterDTO} from "../../types/DocumentFilterDTO";

import {ToastrService} from "ngx-toastr";

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  private urlBase : string = "https://sparkfamily.org/api/se/resource/";
  // private urlBase: string = "http://localhost:8050/se/resource/";

  private addDocumentUrl: string = this.urlBase + "add";
  private editDocumentUrl: string = this.urlBase + "edit";
  private getDocumentUrl: string = this.urlBase + "get";
  private removeDocumentUrl: string = this.urlBase + "remove";
  private filterDocumentUrl: string = this.urlBase + "findAll";

  constructor(private http: HttpClient, private router: Router, private errorService: ErrorMessageService, private toastr: ToastrService) {
  }

  add(formData: FormData) {
    return this.http.post<any>(this.addDocumentUrl, formData).pipe(catchError(this.handleError('DocumentService: AddDocument', [])));
  }

  edit(id: string, formData: FormData) {
    return this.http.post<any>(this.editDocumentUrl + "/" + id, formData).pipe(catchError(this.handleError('DocumentService: EditDocument', [])));
  }

  get(id: number) {
    return this.http.get<any>(this.getDocumentUrl + '/' + id).pipe(catchError(this.handleError('DocumentService: GetDocument', [])));
  }

  remove(id: number) {
    return this.http.delete<any>(this.removeDocumentUrl + '/' + id).pipe(catchError(this.handleError('DocumentService: RemoveDocument', [])));
  }

  filter(tag: string, name: string): Observable<any> {
    let params = {tag: tag, name: name};
    return this.http.get<any>(this.filterDocumentUrl, {params: params}).pipe(catchError(this.handleError('DocumentService: FilterDocuments', [])));
  }

  findAll(): Observable<any> {
    return this.http.get<any>(this.filterDocumentUrl).pipe(catchError(this.handleError('DocumentService: FindAll', [])));
  }

  clear() {
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log("user cur service catch error");
      console.log("error1");
      console.error(error);
      console.log("error1");
      this.errorService.add("Error generating content");
      this.toastr.error(error.error, "Error")
      return of(result as T);
    };
  }
}
