import { Component, OnInit } from '@angular/core';
import {Validators} from "@angular/forms";
import { FormBuilder } from '@angular/forms';
import {AuthService} from "../../_services/authService/auth.service";
import {ErrorMessageService} from "../../_services/errorMessageService/error-message.service";

@Component({
  selector: 'app-login',
  templateUrl: `./login.html`,
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm = this.fb.group({
    email: ['', Validators.required],
    password: ['', Validators.required]
  });


  constructor(private fb: FormBuilder, private authService : AuthService, private errorMessageService : ErrorMessageService) { }

  ngOnInit() {
    localStorage.removeItem("currentUser");
    this.errorMessageService.clear();
    this.authService.healthCheck().subscribe()
  }

  onSubmit() {
    // TODO: Use EventEmitter with form value
    console.warn(this.loginForm.value);
    this.authService.login(this.loginForm.get("email").value, this.loginForm.get("password").value).subscribe();
  }
}
