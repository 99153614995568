export class CourseLessonQuizDTO {
  id: number;
  question: string;
  answers: string;
  correctAnswer: string;
  questionType: string;

  constructor(questionType: string, id?: number, question?: string,
              answers?: string, correctAnswer?: string) {
    this.id = id;
    this.question = question;
    this.answers = answers;
    this.correctAnswer = correctAnswer;
    this.questionType = questionType;
  }
}
