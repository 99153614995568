import { Component, OnInit } from '@angular/core';
import {EmailValidation} from "../../helpers/validators";
import {FormBuilder} from "@angular/forms";
import {AdminLicenseService} from "../../_services/admin/adminLicenseService/admin-license.service";
import {OracleOrderNumberDTO} from "../../types/OracleOrderNumberDTO";
import {LicenseByKeyDisplay} from "../../types/LicenseByKeyDisplay";
import {StringDTO} from "../../types/StringDTO";

@Component({
  selector: 'app-key-lookup',
  templateUrl: './key-lookup.html',
  styleUrls: ['./key-lookup.component.css']
})
export class KeyLookupComponent implements OnInit {

  findKey = this.fb.group({
    orderNumber: [''],
  });

  returned : boolean = false;

  displayList : Array<LicenseByKeyDisplay> = new Array<LicenseByKeyDisplay>();
  constructor(private fb: FormBuilder, private licenseService : AdminLicenseService) { }

  ngOnInit() {
  }

  findKeyByOrder(){
    this.displayList = new Array<LicenseByKeyDisplay>();
    let dto : OracleOrderNumberDTO = new OracleOrderNumberDTO(this.findKey.get('orderNumber').value);

    this.licenseService.getKeyByOrderNumber(dto).subscribe(data => {
      console.log(data);
      if(data.dtos){
        console.log(data.dtos);
        data.dtos.forEach(license => {
          let dto : LicenseByKeyDisplay = new LicenseByKeyDisplay(license.licenseKey, license.isActivated, license.isRevoked);
          this.displayList.push(dto);
          console.log(this.displayList);
          this.returned = true;
        });
      }
      /*this.returnValue = data.response;
      this.returnValues = this.returnValue.split(",");*/
    });

  }

  activatedUnrevoke(key : string){
    this.returned = false;
    console.log("activatedUnrevoke");
    this.licenseService.unRevokeLicenseByKey(new StringDTO(key)).subscribe(data =>{
      this.findKeyByOrder();
    });
  }

  activatedRevoke(key : string){
    this.returned = false;
    console.log("activatedRevoke");
    this.licenseService.revokeLicenseByKey(new StringDTO(key)).subscribe(data =>{
      this.findKeyByOrder();
    });
  }

  unActivatedUnrevoke(key : string){
    this.returned = false;
    console.log("unActivatedUnrevoke");
    this.licenseService.unRevokeBlc(new StringDTO(key)).subscribe(data =>{
      this.findKeyByOrder();
    });
  }

  unActivatedRevoke(key : string){
    this.returned = false;
    console.log("unActivatedRevoke");
    this.licenseService.revokeBlc(new StringDTO(key)).subscribe(data =>{
      this.findKeyByOrder();
    });
  }

}
