import {Component, Input, OnInit} from '@angular/core';
import {Curriculum} from "../../../../types/Curriculum";
import {Module} from "../../../../types/Module";

@Component({
  selector: 'app-admin-module-editor-container',
  templateUrl: './admin-module-editor-container.html',
  styleUrls: ['./admin-module-editor-container.component.css']
})
export class AdminModuleEditorContainerComponent implements OnInit {


  _curriculum : Curriculum;

  _editorModule : Module;

  toggle : boolean = false;

  moduleEditorToggle : boolean = false;

  editorLoaded : boolean = false;

  createNewLoaded : boolean = false;

  constructor() { }

  ngOnInit() {
  }


  @Input()
  set curriculum(curriculum){
    console.log("in module editor container set curriculum method");
    this._curriculum = curriculum;
  }


  loadCreateNew(data){
    this.createNewLoaded = true;
    this.editorLoaded = false;
  }

  reloadSelector(data){
    this.toggle = !this.toggle;
    this.createNewLoaded = false;
    this.editorLoaded = false;
  }

  loadModuleEditor(data){
    console.log("loadModuleEditor");
    console.log(data);
    this._editorModule = data;
    this.createNewLoaded = false;
    this.editorLoaded = true;
  }

  closeModuleEditor(data){
    console.log("in close module editor");
    this._editorModule = null;
    this.createNewLoaded = false;
    this.editorLoaded = false;
  }

  closeNewModule(data){
    this.createNewLoaded = false;
    this.editorLoaded = false;
  }
}
