import { Component, OnInit } from '@angular/core';
import {ModuleFile} from "../../types/ModuleFile";
import {Curriculum} from "../../types/Curriculum";
import {Breadcrumb} from "../../types/Breadcrumb";
import {DisplayCrumb} from "../../types/DisplayCrumb";
import {Module} from "../../types/Module";
import {ActivatedRoute} from "@angular/router";
import {TTModuleFile} from "../../types/TTModuleFile";
import {LicenseExpirationDateDTO} from "../../types/LicenseExpirationDateDTO";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Component({
  selector: 'app-tt-module-file-list',
  templateUrl: './tt-module-file-list.html',
  styleUrls: ['./tt-module-file-list.component.css']
})
export class TtModuleFileListComponent implements OnInit {


  moduleFilesArray : Array<TTModuleFile> = [];
  curriculums : Curriculum[];
  curriculumId : number;

  licenseExpirationDate: LicenseExpirationDateDTO;

  navEntries : any;


  fileNameVal: string;
  audioFileNameVal: string;
  navTextVal: string;
  isMusic: boolean;
  isVideo: boolean;
  safeHtml: SafeHtml;
  isDownloadable: boolean;

  breadCrumbs : Breadcrumb[];
  displayCrumb : Array<DisplayCrumb> = [];

  currentModule : Module;

  constructor(private route: ActivatedRoute, private sanitizer: DomSanitizer) { }

  ngOnInit() {

    this.route.data.subscribe(data =>
    {
      console.log("in module file list component");
      console.log(data);

      if(data.moduleFiles){
        data.moduleFiles.forEach( moduleFile => {
          let typedFile : TTModuleFile;
          console.log(moduleFile);
          typedFile = new TTModuleFile(moduleFile.id, moduleFile.navText, moduleFile.fileName, moduleFile.success, moduleFile.error, moduleFile.folder, moduleFile.hasSpanishFile, moduleFile.curriculumId, moduleFile.moduleId, moduleFile.music, moduleFile.downloadable);
          typedFile.video = moduleFile.video;
          console.log(typedFile);
          this.moduleFilesArray.push(typedFile);
        });
        console.log(this.moduleFilesArray);licenseExpirationDate: LicenseExpirationDateDTO;
      }

      this.curriculums = data.curriculums;
      console.log(this.curriculums);
      this.navEntries = data.navEntries;
      console.log(this.navEntries);
      this.licenseExpirationDate = data.licenseExpirationDate;
      this.curriculumId = this.route.snapshot.params['curriculumId'];
      this.breadCrumbs = data.breadcrumbs;
      console.log(this.breadCrumbs);
      this.currentModule = data.currentModule;
      console.log(this.currentModule);

      this.breadCrumbs.forEach( breadCrumb => {
        let url : string;
        console.log(this.currentModule.curriculumId);
        if(breadCrumb.displayName === 'SPARKuniversity / ') {
          url = '/university/' + this.currentModule.curriculumId;
        } else {
          url = '/module/' + this.currentModule.curriculumId;
        }
        console.log(url);
        let display : DisplayCrumb;
        display = new DisplayCrumb(breadCrumb.displayName, url);
        this.displayCrumb.push(display);
        console.log(this.displayCrumb);
      });
    });
  }


  loadFile(moduleFile : TTModuleFile){
    console.log("in load file: " + moduleFile.fileName);
    console.log(moduleFile);

    this.fileNameVal = moduleFile.fileName;
    this.audioFileNameVal = moduleFile.fileName;
    this.navTextVal = moduleFile.navText;
    this.isMusic = moduleFile.music;
    this.isDownloadable = moduleFile.downloadable;

    this.isVideo = moduleFile.video;
    this.isDownloadable = moduleFile.downloadable;
    if(moduleFile.video) {
      this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(moduleFile.fileName);
    }
  }
}
