import { Injectable } from '@angular/core';


import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {UserCurriculumService} from "../../_services/userCurriculumService/user-curriculum.service";
import {Observable} from "rxjs";
import {ModuleFile} from "../../types/ModuleFile";
import {Module} from "../../types/Module";

@Injectable({
  providedIn: 'root'
})
export class CurrentFolderResolver implements Resolve<Observable<ModuleFile>> {
  constructor(private curriculumService: UserCurriculumService) {}

  resolve(route: ActivatedRouteSnapshot) : Observable<ModuleFile>{
    console.log("cmr param" + route.params['parentId']);
    return this.curriculumService.getCurrentFolder(route.params['parentId']);
  }
}


@Injectable({
  providedIn: 'root'
})
export class CurrentTTFolderResolver implements Resolve<Observable<ModuleFile>> {
  constructor(private curriculumService: UserCurriculumService) {}

  resolve(route: ActivatedRouteSnapshot) : Observable<ModuleFile>{
    console.log("cmr param" + route.params['parentId']);
    return this.curriculumService.getTTCurrentFolder(route.params['parentId']);
  }
}


@Injectable({
  providedIn: 'root'
})
export class CurrentSecondaryFolderResolver implements Resolve<Observable<ModuleFile>> {
  constructor(private curriculumService: UserCurriculumService) {}

  resolve(route: ActivatedRouteSnapshot) : Observable<ModuleFile>{
    console.log("cmr param" + route.params['parentId']);
    return this.curriculumService.getSecondaryCurrentFolder(route.params['parentId']);
  }
}


@Injectable({
  providedIn: 'root'
})
export class CurrentFolderParentResolver implements Resolve<Observable<Module>> {
  constructor(private curriculumService: UserCurriculumService) {}

  resolve(route: ActivatedRouteSnapshot) : Observable<Module>{
    console.log("current folder parent param" + route.params['moduleId']);
    return this.curriculumService.getCurrentFolderParent(route.params['moduleId']);
  }
}


@Injectable({
  providedIn: 'root'
})
export class CurrentTTFolderParentResolver implements Resolve<Observable<Module>> {
  constructor(private curriculumService: UserCurriculumService) {}

  resolve(route: ActivatedRouteSnapshot) : Observable<Module>{
    console.log("current folder parent param" + route.params['moduleId']);
    return this.curriculumService.getTTCurrentFolderParent(route.params['moduleId']);
  }
}
