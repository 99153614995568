import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModuleFile} from "../../../../../../../types/ModuleFile";
import {FormControl, FormGroup} from "@angular/forms";
import {AdminCurriculumService} from "../../../../../../../_services/admin/adminCurriculumService/admin-curriculum.service";
import {IDDTO} from "../../../../../../../types/IDDTO";
import {DisplayOrderUpdateDTO} from "../../../../../../../types/DisplayOrderUpdateDTO";

@Component({
  selector: 'app-module-folder-contents-selector-two',
  templateUrl: './module-folder-contents-selector-two.html',
  styleUrls: ['./module-folder-contents-selector-two.component.css']
})
export class ModuleFolderContentsSelectorTwoComponent  implements OnInit {


  //use which one of these values is populated to determine the table you should be operating on
  _moduleFolder : ModuleFile;
  _ttModuleFolder: ModuleFile;

  moduleFiles : ModuleFile[];
  mainSelectorIsLoaded : boolean = false;
  displayOrderForm: FormGroup;


  @Output() closeModuleFolderEditorEmit = new EventEmitter<boolean>();
  @Output() reloadSelector=new EventEmitter<boolean>();
  @Output() loadCreateNew=new EventEmitter<boolean>();
  @Output() loadModuleFolderEditorEmit = new EventEmitter<ModuleFile>();
  @Output() loadModuleFileEditorEmit = new EventEmitter<ModuleFile>();

  showConfirm : Map<string, boolean> = new Map<string, boolean>();

  constructor(private curriculumService : AdminCurriculumService) { }

  ngOnInit() {
    console.log("init moduel folder contents selector two")
  }

  @Input()
  set moduleFolder(moduleFolder){
    console.log("set module folder for module folder contents selector");
    console.log(moduleFolder);
    if(moduleFolder) {
      console.log("in set moduleFolder");
      console.log(moduleFolder);
      this._moduleFolder = moduleFolder;
      console.log(this._moduleFolder);
      this.curriculumService.getModuleFolderChildrenFiles(Number(this._moduleFolder.id)).subscribe(data => {
        console.log(data);
        this.moduleFiles = data;


        const formGroup = new FormGroup({});

        this.moduleFiles.forEach(moduleFile => {
          this.showConfirm.set(moduleFile.id, false);
          console.log(moduleFile);
          formGroup.addControl(moduleFile.navText, new FormControl(moduleFile.id));
          formGroup.controls[moduleFile.navText].setValue(moduleFile.displayOrder);
        });

        this.displayOrderForm = formGroup;

        console.log(this.displayOrderForm);

        this.mainSelectorIsLoaded = true;
      });
    }
  }


  @Input()
  set ttModuleFolder(moduleFolder){
    if(moduleFolder){
      console.log("in set moduleFolder");
      console.log(moduleFolder);
      console.log(moduleFolder.id);
      this._ttModuleFolder = moduleFolder;
      console.log(this._ttModuleFolder);
      console.log(this._ttModuleFolder.id);
      this.curriculumService.getTTModuleFolderChildrenFiles(Number(this._ttModuleFolder.id)).subscribe(data => {
        console.log(data);
        this.moduleFiles = data;


        const formGroup = new FormGroup({ });

        this.moduleFiles.forEach(moduleFile => {
          this.showConfirm.set(moduleFile.id, false);
          console.log(moduleFile);
          formGroup.addControl(moduleFile.navText, new FormControl(moduleFile.id));
          formGroup.controls[moduleFile.navText].setValue(moduleFile.displayOrder);
        });

        this.displayOrderForm = formGroup;

        console.log(this.displayOrderForm);

        this.mainSelectorIsLoaded = true;
      });
    }
  }


  loadModuleFileEditor(moduleFile){
    console.log("in load module file editor");
    this.loadModuleFileEditorEmit.emit(moduleFile);
  }
  loadModuleFolderEditor(moduleFolder){
    console.log("in load module folder editor");
    this.loadModuleFolderEditorEmit.emit(moduleFolder);
  }


  deleteModule(module){
    this.showConfirm.set(module.id, true);
  }

  confirmedDelete(module){
    /*console.log("Load module editor");
    console.log(module);
    this.loadModule.emit(module);*/
    if(this._ttModuleFolder) {
      this.curriculumService.deleteTTModuleFile(new IDDTO(Number(module.id))).subscribe(data => {
        this.reloadSelector.emit(true);
      });
    }

    if(this._moduleFolder){
      this.curriculumService.deleteModuleFile(new IDDTO(Number(module.id))).subscribe(data => {
        this.reloadSelector.emit(true);
      });
    }
  }


  saveDisplayOrderChanges() {
    let updateMap : Map<String, Number> = new Map<String, Number>();
    this.moduleFiles.forEach( moduleFile => {
      updateMap.set(moduleFile.id, this.displayOrderForm.controls[moduleFile.navText].value);
    });
    updateMap.forEach((val : number, key: string) => {
      updateMap[key] = val
    });

    let displayUpdateDTO : DisplayOrderUpdateDTO = new DisplayOrderUpdateDTO(updateMap);

    this.curriculumService.updateModuleFileDisplayOrder(displayUpdateDTO).subscribe(data => {
      console.log(data);
      this.reloadSelector.emit(true);
    });
  }

  createNewModuleFile(){
    this.loadCreateNew.emit(true);
  }

}
