import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {ErrorMessageService} from "../../errorMessageService/error-message.service";
import {Observable, of} from "rxjs";
import {Module} from "../../../types/Module";
import {catchError} from "rxjs/operators";
import {DisplayOrderUpdateDTO} from "../../../types/DisplayOrderUpdateDTO";
import {CreateNewModuleDTO} from "../../../types/CreateNewModuleDTO";
import {UpdateModuleDTO} from "../../../types/UpdateModuleDTO";
import {ModuleFile} from "../../../types/ModuleFile";
import {UpdateSecondaryModuleDTO} from "../../../types/UpdateSecondaryModuleDTO";
import {CreateNewSecondaryModuleDTO} from "../../../types/CreateNewSecondaryModuleDTO";
import {SecondaryModuleFile} from "../../../types/SecondaryModuleFile";
import {SecondaryModule} from "../../../types/SecondaryModule";
import {IDDTO} from "../../../types/IDDTO";
import {Curriculum} from "../../../types/Curriculum";
import {HomePageEditDTO} from "../../../types/HomePageEditDTO";
import {NavEntryUpdateDTO} from "../../../types/NavEntryUpdateDTO";
import {CurriculumTreeResponseDTO} from "../../../types/CurriculumTreeResponseDTO";
import {CurriculumTreeRequestDTO} from "../../../types/CurriculumTreeRequestDTO";

@Injectable({
  providedIn: 'root'
})
export class AdminCurriculumService {

  //for local
  // private urlBase : string = "http://localhost:8050/admin/curriculum";
  //for qa
  // private urlBase : string = "http://sparkfamily.org:8050/admin/curriculum";
  //for production
  private urlBase : string = "https://sparkfamily.org/api/admin/curriculum";

  private updateUrl : string  = this.urlBase + "/update";
  private createNewModuleFileUrl : string = this.urlBase + "/createNewModuleFile";
  private updateModuleFileUrl : string = this.urlBase + "/updateModuleFile";
  private createNewNavEntryUrl : string = this.urlBase + '/createNewNavEntry';
  private deleteNavEntryUrl : string = this.urlBase + '/deleteNavEntry';
  private saveNavEntryUrl : string = this.urlBase + '/saveNavEntry';

  constructor(private http: HttpClient, private router: Router, private errorService : ErrorMessageService) { }


  update(updateData){
    return this.http.post<any>(this.updateUrl, updateData);
  }

  createNewModuleFile(updateData){
    return this.http.post<any>(this.createNewModuleFileUrl, updateData);
  }

  updateModuleFile(updateData){
    return this.http.post<any>(this.updateModuleFileUrl, updateData);
  }

  public getAllNavEntries(): Observable<any> {
    return this.http.get<any>(this.urlBase + '/getAllNavEntries/').pipe(catchError(this.handleError('AdminService: getNavEntries', [])));
  }

  public createNewNavEntry(updateDTO : NavEntryUpdateDTO): Observable<any>{
    return this.http.post<any>(this.createNewNavEntryUrl, updateDTO).pipe(catchError(this.handleError('AdminService: createNewNavEntry', [])));
  }

  public deleteNavEntry(iddto : IDDTO): Observable<any>{
    return this.http.post<any>(this.deleteNavEntryUrl, iddto).pipe(catchError(this.handleError('AdminService: deleteNavEntry', [])));
  }

  public saveNavEntry(updateDTO : NavEntryUpdateDTO): Observable<any>{
    return this.http.post<any>(this.saveNavEntryUrl, updateDTO).pipe(catchError(this.handleError('AdminService: saveNavEntryUrl', [])));
  }

  public getCurriculumModules(id: number): Observable<Module[]> {
    console.log(id);
    return this.http.get<Module[]>(this.urlBase + '/curriculumModuleList/'+id).pipe(catchError(this.handleError('AdminService: getCurriculumModules', [])));
  }

  public getTTModules(id: number): Observable<Module[]> {
    console.log(id);
    return this.http.get<Module[]>(this.urlBase + '/ttModuleList/'+id).pipe(catchError(this.handleError('AdminService: getTTModules', [])));
  }

  public getModuleSecondaryModules(id: number): Observable<SecondaryModule[]> {
    console.log(id);
    return this.http.get<SecondaryModule[]>(this.urlBase + '/getSecondaryModulesForMod/'+id).pipe(catchError(this.handleError('AdminService: getCurriculumModules', [])));
  }

  public getModuleFileSecondaryModules(id: number): Observable<SecondaryModule[]> {
    console.log(id);
    return this.http.get<SecondaryModule[]>(this.urlBase + '/getSecondaryModulesForModForFold/'+id).pipe(catchError(this.handleError('AdminService: getCurriculumModules', [])));
  }

  public getModuleChildrenFiles(id : number): Observable<ModuleFile[]>{
    console.log(id);
    return this.http.get<ModuleFile[]>(this.urlBase + '/getModuleChildrenFiles/'+id).pipe(catchError(this.handleError('AdminService: getModuleChildrenFiles', [])));
  }

  public getTTModuleChildrenFiles(id : number): Observable<ModuleFile[]>{
    console.log(id);
    return this.http.get<ModuleFile[]>(this.urlBase + '/getTTModuleChildrenFiles/'+id).pipe(catchError(this.handleError('AdminService: getModuleChildrenFiles', [])));
  }

  public getSecondaryModuleChildrenFiles(id : number): Observable<SecondaryModuleFile[]>{
    console.log(id);
    return this.http.get<SecondaryModuleFile[]>(this.urlBase + '/getSecondaryModuleFiles/'+id).pipe(catchError(this.handleError('AdminService: getSecondaryModuleChildrenFiles', [])));
  }

  public getModuleFolderChildrenFiles(id : number): Observable<ModuleFile[]>{
    console.log("getModuleFolderChildrenFiles");
    console.log(id);
    return this.http.get<ModuleFile[]>(this.urlBase + '/getModuleFolderChildrenFiles/'+id).pipe(catchError(this.handleError('AdminService: getModuleFolderChildrenFiles', [])));
  }

  public getCurriculumTree(dto : CurriculumTreeRequestDTO): Observable<CurriculumTreeResponseDTO[]>{
    console.log("getCurriculumTree");
    console.log(dto);
    return this.http.post<CurriculumTreeResponseDTO[]>(this.urlBase + '/getCurriculumTree', dto).pipe(catchError(this.handleError('AdminService: getCurriculumTree', [])));
  }

  public getTTModuleFolderChildrenFiles(id : number): Observable<ModuleFile[]>{
    console.log("getTTModuleFolderChildrenFiles");
    console.log(id);
    return this.http.get<ModuleFile[]>(this.urlBase + '/getTTModuleFolderChildrenFiles/'+id).pipe(catchError(this.handleError('AdminService: getTTModuleFolderChildrenFiles', [])));
  }

  public getSecondaryModuleFolderChildrenFiles(id : number): Observable<ModuleFile[]>{
    console.log("getSecondaryModuleFolderChildrenFiles");
    console.log(id);
    return this.http.get<ModuleFile[]>(this.urlBase + '/getSecondaryModuleFolderChildrenFiles/'+id).pipe(catchError(this.handleError('AdminService: getTTModuleFolderChildrenFiles', [])));
  }

  public deleteModule(dto : IDDTO): Observable<any>{
    console.log(dto);
    return this.http.post<any>(this.urlBase + '/module/delete/', dto).pipe(catchError(this.handleError('AdminService: deleteModule', [])));
  }

  public deleteTTModule(dto : IDDTO): Observable<any>{
    console.log(dto);
    return this.http.post<any>(this.urlBase + '/ttModule/delete/', dto).pipe(catchError(this.handleError('AdminService: deleteModule', [])));
  }

  public deleteSecondaryModule(dto : IDDTO): Observable<any>{
    console.log(dto);
    return this.http.post<any>(this.urlBase + '/secondaryModule/delete/', dto).pipe(catchError(this.handleError('AdminService: deleteModule', [])));
  }

  public deleteSecondaryModuleFile(dto : IDDTO): Observable<any>{
    console.log(dto);
    return this.http.post<any>(this.urlBase + '/secondaryModuleFile/delete/', dto).pipe(catchError(this.handleError('AdminService: deleteModule', [])));
  }

  public deleteModuleFile(dto : IDDTO): Observable<any>{
    console.log(dto);
    return this.http.post<any>(this.urlBase + '/moduleFile/delete/', dto).pipe(catchError(this.handleError('AdminService: deleteModule', [])));
  }

  public deleteTTModuleFile(dto : IDDTO): Observable<any>{
    console.log(dto);
    return this.http.post<any>(this.urlBase + '/ttModuleFile/delete/', dto).pipe(catchError(this.handleError('AdminService: deleteModule', [])));
  }

  public updateDisplayOrder(dto : DisplayOrderUpdateDTO): Observable<any> {
    console.log(dto);
    return this.http.post<any>(this.urlBase + '/module/displayOrderUpdate/', dto).pipe(catchError(this.handleError('AdminService: updateDisplayOrder', [])));
  }

  public updateNavEntryDisplayOrder(dto : DisplayOrderUpdateDTO): Observable<any> {
    console.log(dto);
    return this.http.post<any>(this.urlBase + '/navEntry/displayOrderUpdate/', dto).pipe(catchError(this.handleError('AdminService: updateDisplayOrder', [])));
  }

  public updateNavEntryCurriculumDisplayOrder(dto : DisplayOrderUpdateDTO): Observable<any> {
    console.log(dto);
    return this.http.post<any>(this.urlBase + '/navEntryCurriculum/displayOrderUpdate', dto).pipe(catchError(this.handleError('AdminService: updateDisplayOrder', [])));
  }

  public updateModuleFileDisplayOrder(dto : DisplayOrderUpdateDTO): Observable<any> {
    console.log(dto);
    return this.http.post<any>(this.urlBase + '/moduleFile/displayOrderUpdate/', dto).pipe(catchError(this.handleError('AdminService: updateDisplayOrder', [])));
  }

  public updateSecondaryModuleFileDisplayOrder(dto : DisplayOrderUpdateDTO): Observable<any> {
    console.log(dto);
    return this.http.post<any>(this.urlBase + '/secondaryModuleFile/displayOrderUpdate/', dto).pipe(catchError(this.handleError('AdminService: updateDisplayOrder', [])));
  }

  public updateTTModuleDisplayOrder(dto : DisplayOrderUpdateDTO): Observable<any> {
    console.log(dto);
    return this.http.post<any>(this.urlBase + '/ttModule/displayOrderUpdate/', dto).pipe(catchError(this.handleError('AdminService: updateTTModuleFileDisplayOrder', [])));
  }

  public updateTTModuleFileDisplayOrder(dto : DisplayOrderUpdateDTO): Observable<any> {
    console.log(dto);
    return this.http.post<any>(this.urlBase + '/ttModuleFile/displayOrderUpdate/', dto).pipe(catchError(this.handleError('AdminService: updateTTModuleFileDisplayOrder', [])));
  }

  public createNewModule(dto : CreateNewModuleDTO): Observable<any> {
    console.log(dto);
    return this.http.post<any>(this.urlBase + '/createNewModule/', dto).pipe(catchError(this.handleError('AdminService: createNewModule', [])));
  }

  public createNewTTModule(dto : CreateNewModuleDTO): Observable<any> {
    console.log(dto);
    return this.http.post<any>(this.urlBase + '/createNewTTModule/', dto).pipe(catchError(this.handleError('AdminService: createNewModule', [])));
  }

  public createNewSecondaryModule(dto : CreateNewSecondaryModuleDTO): Observable<any> {
    console.log(dto);
    return this.http.post<any>(this.urlBase + '/createNewSecondaryModule/', dto).pipe(catchError(this.handleError('AdminService: createNewSecondaryModule', [])));
  }

  public updateSecondaryModule(dto : UpdateSecondaryModuleDTO): Observable<any> {
    console.log(dto);
    return this.http.post<any>(this.urlBase + '/updateSecondaryModule/', dto).pipe(catchError(this.handleError('AdminService: updateSecondaryModule', [])));
  }

  public updateModule(dto : UpdateModuleDTO): Observable<any> {
    console.log(dto);
    return this.http.post<any>(this.urlBase + '/updateModule/', dto).pipe(catchError(this.handleError('AdminService: updateModule', [])));
  }

  public updateTTModule(dto : UpdateModuleDTO): Observable<any> {
    console.log(dto);
    return this.http.post<any>(this.urlBase + '/updateTTModule/', dto).pipe(catchError(this.handleError('AdminService: updateTTModule', [])));
  }

  public updateModuleFolderNavText(dto : UpdateModuleDTO): Observable<any>{
    console.log("in updateModuleFolderNavText");
    console.log(dto);
    return this.http.post<any>(this.urlBase + '/updateModuleFolderNavText/', dto).pipe(catchError(this.handleError('AdminService: updateModuleFolderNavText', [])));
  }

  public getUserCurriculums(): Observable<Curriculum[]> {
    return this.http.get<Curriculum[]>(this.urlBase + '/usercurriculums').pipe(catchError(this.handleError('getUserCurriculums', [])));
  }


  private handleError<T> (operation = 'operation', result?:T){
    return (error: any): Observable<T> => {
      console.log("user cur service catch error");
      console.log("error1");
      console.error(error);
      console.log("error1");
      this.errorService.add("Error generating content");

      return of(result as T);
    };
  }


  public getHomePageContent(): Observable<any> {
    console.log("get home page content");
    return this.http.get<any>(this.urlBase + '/homePageContent/').pipe(catchError(this.handleError('getHomePageConent', null)));
  }


  public updateHomePageContent(dto : HomePageEditDTO): Observable<any> {
    console.log("get home page content");
    return this.http.post<any>(this.urlBase + '/updateHomePageContent/', dto).pipe(catchError(this.handleError('getHomePageConent', null)));
  }


  public getCurrentCurriculum(id: number): Observable<Curriculum> {
    console.log(id);
    return this.http.get<Curriculum>(this.urlBase + '/getCurrentCurriculum/'+id).pipe(catchError(this.handleError('getCurrentCurriculum', null)));
  }


  public updateSeHomePageContent(dto : string): Observable<any> {
    console.log("get se home page content");
    return this.http.post<any>(this.urlBase + '/seHomePageContent/', dto).pipe(catchError(this.handleError('getHomePageConent', null)));
  }

}
