import { Injectable } from '@angular/core';


import { Resolve } from '@angular/router';
import {Observable} from "rxjs";
import {RoleInfo} from "../../types/RoleInfo";
import {AccountService} from "../../_services/accountService/account.service";

@Injectable({
  providedIn: 'root'
})
export class RoleCountsResolver implements Resolve<Observable<RoleInfo[]>> {
  constructor(private accountService : AccountService) {}

  resolve() : Observable<RoleInfo[]>{
    console.log("in role counts resolver");
    return this.accountService.getAccountRoleStats();
  }
}
