import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-intro',
  templateUrl: `./intro.html`,
  styleUrls: ['./intro.component.css']
})
export class IntroComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    localStorage.removeItem("currentUser");
  }

}
