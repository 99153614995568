import {Injectable} from '@angular/core';


import {Resolve} from '@angular/router';
import {Observable} from "rxjs";
import {UserCourseService} from "../../_services/userCourseService/user-course-service";
import {CourseCategoryDTO} from "../../types/CourseCategoryDTO";
import {CourseTagDTO} from "../../types/CourseTagDTO";
import {CourseCategoryService} from "../../_services/adminCourseService/course-category-service";

@Injectable({
  providedIn: 'root'
})
export class CourseCategoryResolver implements Resolve<Observable<CourseCategoryDTO[]>> {
  constructor(private courseCategoryService: CourseCategoryService) {
  }

  resolve(): Observable<CourseCategoryDTO[]> {
    return this.courseCategoryService.getAllCourseCategories();
  }
}

@Injectable({
  providedIn: 'root'
})
export class CourseTagResolver implements Resolve<CourseTagDTO[]> {
  constructor(private courseCategoryService: CourseCategoryService) {
  }

  resolve(): Observable<CourseTagDTO[]> {
    console.log("in resolver");
    return this.courseCategoryService.getAllCourseTags();
  }
}
