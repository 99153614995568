import { Injectable } from "@angular/core";
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree} from "@angular/router";

//import all the guards in the application
import {AuthGuard} from "../../helpers/guards/auth.guard";
import {RestrictedCurriculumGuard} from "./restricted.curriculum.guard";

import {AuthService} from "../../_services/authService/auth.service";
import {UserCurriculumService} from "../../_services/userCurriculumService/user-curriculum.service";
import {GUARDS} from "../../types/Guards";
import {isObservable, Observable} from "rxjs";
import {ResetPasswordGuard} from "./reset.password.guard";
import {AccountService} from "../../_services/accountService/account.service";
import {AdminGuard} from "./admin.guard";
import {LoginPageGuard} from "./loginPage.guard";
import {AdminDashboardRoutingModule} from "../../admin-dashboard/admin-dashboard-routing.module";
import {AdminDashboardGuard} from "./AdminDashboardGuard.guard";
import {AdminAuthService} from "../../_services/admin/adminAuthService/admin-auth.service";
import {AdminOrManagerDashboardGuard} from "./AdminOrManagerDashboardGuard.guard";

@Injectable({
  providedIn: 'root'
})
export class MasterGuard implements CanActivate {

  //you may need to include dependencies of individual guards if specified in guard constructor
  constructor(private authService: AuthService, private adminAuthService : AdminAuthService, private curriculumService : UserCurriculumService, private accountServer : AccountService, private router: Router) {}

  private route: ActivatedRouteSnapshot;
  private state: RouterStateSnapshot;

  //This method gets triggered when the route is hit
  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    console.log("in master guard");
    this.route = route;
    this.state = state;

    if (!route.data) {
      Promise.resolve(true);
      return;
    }

    //this.route.data.guards is an array of strings set in routing configuration

    if (!this.route.data.guards || !this.route.data.guards.length) {
      Promise.resolve(true);
      return;
    }
    return this.executeGuards();
  }

  //Execute the guards sent in the route data
  private executeGuards(guardIndex: number = 0): Promise<boolean> {
    return this.activateGuard(this.route.data.guards[guardIndex])
      .then(() => {
        if (guardIndex < this.route.data.guards.length - 1) {
          return this.executeGuards(guardIndex + 1);
        } else {
          return Promise.resolve(true);
        }
      })
      .catch(() => {
        return Promise.reject(false);
      });
  }

  //Create an instance of the guard and fire canActivate method returning a promise
  private activateGuard(guardKey: string): Promise<boolean> {

    let guard: AuthGuard | RestrictedCurriculumGuard | ResetPasswordGuard | AdminGuard | LoginPageGuard | AdminDashboardGuard | AdminOrManagerDashboardGuard;

    switch (guardKey) {
      case GUARDS.AUTHGUARD:
        guard = new AuthGuard(this.router, this.authService);
        break;
      case GUARDS.RESTRICTEDCURRICULUMGUARD:
        guard = new RestrictedCurriculumGuard(this.router, this.authService, this.curriculumService);
        break;
      case GUARDS.RESETPASSWORDGUARD:
        guard = new ResetPasswordGuard(this.router, this.authService);
        break;
      case GUARDS.ADMINGUARD:
        guard = new AdminGuard(this.router, this.authService);
        break;
      case GUARDS.LOGINGUARD:
        guard = new LoginPageGuard(this.router, this.authService);
        break;
      case GUARDS.ADMINDASHBOARDGUARD:
        guard = new AdminDashboardGuard(this.router, this.adminAuthService);
        break;
      case GUARDS.ADMINORMANAGERDASHBOARDGUARD:
        guard = new AdminOrManagerDashboardGuard(this.router, this.adminAuthService, this.authService);
        break;
      default:
        break;
    }

    return guard.canActivate(this.route, this.state);
  }
}
