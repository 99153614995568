export class CreditLicenseFilterDTO {

  oracleOrderNumber: string;
  licenseKey: string;
  unUsed: boolean;

  sortBy: string;
  sortOrder: string;
  pageIndex: number;
  pageSize: number = 20;

  constructor(sortBy: string,
              sortOrder: string,
              pageIndex: number,
              pageSize: number,
              licenseKey?: string,
              oracleOrderNumber?: string,
              unUsed?: boolean) {
    this.sortBy = sortBy;
    this.sortOrder = sortOrder;
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    this.oracleOrderNumber = oracleOrderNumber;
    this.licenseKey = licenseKey;
    this.unUsed = unUsed;
  }
}
