import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {ActivatedRoute, Router} from "@angular/router";
import {OrganizationService} from "../../../../_services/adminOrganizationService/organization-service";
import {CourseService} from "../../../../_services/adminCourseService/course-service";
import {PaginationCourseDTO} from "../../../../types/PaginationCourseDTO";
import {PageEvent} from "@angular/material/paginator";
import {OrganizationCourseDTO} from "../../../../types/OrganizationCourseDTO";
import {AuthService} from "../../../../../_services/authService/auth.service";

@Component({
  selector: 'app-e-admin-create-organization',
  templateUrl: './create-organization-course.html',
  styleUrls: ['./create-organization-course.component.css']
})
export class eAdminCreateOrganizationCourseComponent implements OnInit {

  header: string = "Add New Course";
  organizationId: string;
  organizationCourseId: string;
  courseId: string;
  paginationCourseDTO: PaginationCourseDTO;
  pageEvent_?: PageEvent;

  createOrganizationCourseForm = this.fb.group({
    courseId: ['', Validators.required],
    level: [''],
  }, {validator: []});

  constructor(private fb: FormBuilder,
              private organizationService: OrganizationService,
              private courseService: CourseService,
              private authService: AuthService,
              private toastr: ToastrService,
              private router: Router,
              private route: ActivatedRoute) {
    this.pageEvent_ = new PageEvent();
    this.pageEvent_.pageSize = 1000;
    this.courseService.findAll(this.pageEvent_).subscribe(data => {
      this.paginationCourseDTO = data;
    });
  }

  ngOnInit() {
    this.organizationId = this.route.snapshot.paramMap.get('id');
    this.organizationCourseId = this.route.snapshot.paramMap.get('organizationCourseId');
    if (this.organizationId != null && this.organizationCourseId != null) {
      this.loadData(this.organizationId, this.organizationCourseId);
    }
  }

  loadData(organizationId, organizationCourseId) {


    this.organizationService.getCourse(organizationId, organizationCourseId).subscribe(data => {
      this.courseId = data.courseId;
      this.createOrganizationCourseForm.get("courseId").setValue(data.courseId);
      this.createOrganizationCourseForm.get("level").setValue(data.level);
    });
  }

  onSubmit() {

    let organizationCourseDTO = new OrganizationCourseDTO(
      this.getField("courseId"),
      this.getField("level")
    );

    if (this.organizationCourseId == null) {
      this.organizationService.addCourse(this.organizationId, organizationCourseDTO).subscribe(data => {
        if (!data.success) {
          this.toastr.error(data.errorMessage, "Error");
        }
        if (data.success) {
          this.toastr.success("Success", "Course created successfully!!")
          this.router.navigateByUrl('/sparkecademy-admin-organization/' + this.organizationId + '/courses');
        }
      });
    } else {
      this.organizationService.editCourse(this.organizationId, this.organizationCourseId, organizationCourseDTO).subscribe(data => {
        if (data.success) {
          this.toastr.success("Success", "Course updated successfully!!")
          this.router.navigateByUrl('/sparkecademy-admin-organization/' + this.organizationId + '/courses');
        }
      });
    }
  }

  getField(fieldName: string) {
    return this.createOrganizationCourseForm.get(fieldName).value;
  }
}
