import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";

@Component({
  selector: 'app-admin-file-view',
  templateUrl: './admin-file-view.html',
  styleUrls: ['./admin-file-view.component.css']
})
export class AdminFileViewComponent implements OnInit {


  _fileName:SafeResourceUrl;

  _hasSpanishFile:boolean;

  englishFileName:string;

  spanishFileName:string;

  english:boolean;

  dangerousUrl:string;

  isIe:boolean;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
    console.log("checking if ie ");
    console.log(this.detectIE());
    if(this.detectIE()){
      this.isIe = true;
    } else {
      this.isIe = false;
    }
  }

  @Input()
  set fileName(file : string){
    console.log("admin: set file name method");
    console.log(file);
    if(file) {
      this.dangerousUrl = file;
      this._fileName = this.sanitizer.bypassSecurityTrustResourceUrl('/assets/' + this.dangerousUrl);
      this.englishFileName = "/assets/" + file;
      this.spanishFileName = "/assets/sp_" + file;
      this.english = true;
    }
  }

  @Input()
  set hasSpanishFile(hasSpanishFile : boolean){
    if(hasSpanishFile) {
      this._hasSpanishFile = hasSpanishFile;
      console.log(this._hasSpanishFile);
    }
  }



  changeLanguage(isEnglish : boolean){
    this.english = isEnglish;
  }


  /**
   * detect IE
   * returns version of IE or false, if browser is not Internet Explorer
   */
  detectIE() {

    let ua;
    ua = window.navigator.userAgent;

    // Test values; Uncomment to check result …

    // IE 10
    // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

    // IE 11
    // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

    // IE 12 / Spartan
    // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

    // Edge (IE 12+)
    // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

    let msie;
    msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    let trident;
    trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      var rv = ua.indexOf('rv:');
      return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    let edge;
    edge = ua.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
  }

}
