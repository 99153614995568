import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {AdminLicenseService} from "../../_services/admin/adminLicenseService/admin-license.service";
import {AdminGenerateLicenseKeyComponent} from "../admin-generate-license-key/admin-generate-license-key.component";

@Component({
  selector: 'app-account-edit-license',
  templateUrl: './account-edit-license.html',
  styleUrls: ['./account-edit-license.component.css']
})
export class AccountEditLicenseComponent implements OnInit {

  findLicense = this.fb.group({ key: [] }, {validator: []});
  isEditMode = false;
  existingLicenseInfo: any;
  @ViewChild(AdminGenerateLicenseKeyComponent) generateLicenseKeyComponent: AdminGenerateLicenseKeyComponent;

  constructor(private fb: FormBuilder,
              private accountLicenseService: AdminLicenseService) {
  }

  ngOnInit() {}

  findLicenseBySearchParams() {
    this.accountLicenseService.getLicenseInfoByLicenseKey(this.findLicense.get('key').value).subscribe(licenseInfo => {
      this.isEditMode = true;
      this.existingLicenseInfo = licenseInfo;
      this.generateLicenseKeyComponent.refreshData(this.existingLicenseInfo);
    });
  }
}
