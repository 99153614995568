import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CustomEmailValidator, RepeatEmailValidator, RepeatPasswordValidator} from "../../../helpers/validators";
import {FormBuilder} from "@angular/forms";
import {AccountService} from "../../../_services/accountService/account.service";
import {DisplayMessageService} from "../../../_services/displayMessageService/display-message.service";
import {ErrorMessageService} from "../../../_services/errorMessageService/error-message.service";
import {AccountUserDTO} from "../../../types/AccountUserDTO";
import {UpdateUserDTO} from "../../../types/UpdateUserDTO";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-user-password-editor',
  templateUrl: './user-password-editor.html',
  styleUrls: ['./user-password-editor.component.css']
})
export class UserPasswordEditorComponent implements OnInit {

  userUpdateForm = this.fb.group({
    email: [''],
    password: [''],
    passwordConfirm: [''],
  }, {validator: [RepeatPasswordValidator]});

  _user : AccountUserDTO;

  @Output() updated=new EventEmitter<Boolean>();

  constructor(private fb: FormBuilder,
              private userService : AccountService,
              private displayMessageService : DisplayMessageService,
              private toastr: ToastrService,
              private errorMessageService : ErrorMessageService) { }

  ngOnInit() {
  }

  @Input() set user(user){
    this._user = user;
    this.userUpdateForm.controls['email'].setValue(user.email);
    console.log(user);
  }


  saveChanges(){
    let userUpdateDTO : UpdateUserDTO = new UpdateUserDTO();

    userUpdateDTO.id = this._user.userId;
    userUpdateDTO.password = this.userUpdateForm.controls['password'].value;
    userUpdateDTO.email = this.userUpdateForm.controls['email'].value;

    this.userService.updateUser(userUpdateDTO).subscribe( data => {
      if(data.success){
        this.displayMessageService.add("Email/Password changes saved");
        this.updated.emit(true);
      } else if(data.errorMessage) {
        this.toastr.error(data.errorMessage, "Error")
      }
    });
  }
}
