import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorMessageService {

  errorMessage: string[] = [];

  add(message: string) {
    this.errorMessage.push(message);
  }

  addAll(messages: string[]) {
    for(let message in messages) {
      console.log("adding " + message);
      this.add(messages[message]);
    }
  }

  clear() {
    this.errorMessage = [];
  }

}
