import { Injectable } from '@angular/core';


import { Resolve } from '@angular/router';
import {UserCurriculumService} from "../../_services/userCurriculumService/user-curriculum.service";
import {Curriculum} from "../../types/Curriculum";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class NavResolver implements Resolve<Observable<Curriculum[]>> {
  constructor(private curriculumService: UserCurriculumService) {}

  resolve() : Observable<Curriculum[]>{
    console.log("in resolver");
    return this.curriculumService.getUserCurriculums();
  }
}


@Injectable({
  providedIn: 'root'
})
export class NavEntryResolver implements Resolve<any> {
  constructor(private curriculumService: UserCurriculumService) {}

  resolve() : Observable<Curriculum[]>{
    console.log("in resolver");
    return this.curriculumService.getUserNavEntries();
  }
}
