import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {DocumentService} from "../../../_services/adminDocumentService/document-service";
import {DocumentDTO} from "../../../types/DocumentDTO";

@Component({
  selector: 'app-e-admin-filter-document',
  templateUrl: 'filter-document.html',
  styleUrls: ['filter-document.component.css']
})
export class eAdminFilterDocumentComponent implements OnInit {

  @Output() documentList = new EventEmitter<DocumentDTO[]>();

  filterDocuments = this.fb.group({
    tag: [''],
    name: [''],
  }, {validator: []});

  constructor(private fb: FormBuilder, private documentService: DocumentService) {

  }

  ngOnInit() {
  }

  onSubmit() {
    let tag = this.filterDocuments.get("tag").value;
    let name = this.filterDocuments.get("name").value;
    tag = tag == null ? "" : tag;
    name = name == null ? "" : name;
    this.documentService.filter(tag, name).subscribe(data => {
        console.log(data);
        this.documentList.emit(data);
      }
    );
  }

  resetForm() {
    this.documentService.findAll().subscribe(data => {
        this.documentList.emit(data);
      }
    );
  }

}
