import { Component, OnInit } from '@angular/core';
import {ModuleFile} from "../../types/ModuleFile";
import {Curriculum} from "../../types/Curriculum";
import {ActivatedRoute, Router} from "@angular/router";
import {UserCurriculumService} from "../../_services/userCurriculumService/user-curriculum.service";
import {FileAudio} from "../../types/FileAudio";
import {Breadcrumb} from "../../types/Breadcrumb";
import {DisplayCrumb} from "../../types/DisplayCrumb";
import {SecondaryModule} from "../../types/SecondaryModule";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {LicenseExpirationDateDTO} from "../../types/LicenseExpirationDateDTO";

@Component({
  selector: 'app-module-folder-contents',
  templateUrl: './module-folder-contents.html',
  styleUrls: ['./module-folder-contents.component.css']
})
export class ModuleFolderContentsComponent implements OnInit {


  moduleFilesArray : Array<ModuleFile> = [];
  curriculums : Curriculum[];
  licenseExpirationDate: LicenseExpirationDateDTO;
  navEntries : any;

  fileNameVal: string;
  audioFileNameVal: string;
  navTextVal: string;
  hasSpanishFileVal:boolean;
  isMusic: boolean;
  isVideo: boolean;
  isDownloadable: boolean;

  safeHtml: SafeHtml;

  currentFolderId: string;

  folderFrom: string;

  currentFolder: ModuleFile;

  currentFolderHasParent : boolean = false;

  breadCrumbs : Breadcrumb[];
  displayCrumb : Array<DisplayCrumb> = [];

  secondaryModulesList: Array<Array<SecondaryModule>> = [];

  accessingViaTrial : boolean;

  constructor(private route: ActivatedRoute, private curriculumService : UserCurriculumService,  private router: Router, private sanitizer: DomSanitizer) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit() {

    this.route.data.subscribe(data =>
    {
      console.log("in module folder contents component");
      console.log(data);

      if(data.moduleFiles) {
        data.moduleFiles.forEach(moduleFile => {
          let typedFile: ModuleFile;
          console.log(moduleFile);
          typedFile = new ModuleFile(moduleFile.id, moduleFile.navText, moduleFile.fileName, moduleFile.success, moduleFile.error, moduleFile.folder, moduleFile.hasSpanishFile, moduleFile.curriculumId, moduleFile.moduleId, moduleFile.music, moduleFile.video, moduleFile.downloadable, moduleFile.trialAccessible);
          this.moduleFilesArray.push(typedFile);
        });
        this.accessingViaTrial = data.accessingViaTrial.accessingViaTrial;
        console.log("accessing via trial");
        console.log(this.accessingViaTrial);
      }
      console.log(this.moduleFilesArray);
      this.curriculums = data.curriculums;
      console.log(this.curriculums);
      this.navEntries = data.navEntries;
      this.licenseExpirationDate = data.licenseExpirationDate;

      this.currentFolder = data.currentFolder;
      console.log(this.currentFolder);

      this.breadCrumbs = data.breadcrumbs;
      console.log(this.breadCrumbs);

      this.breadCrumbs.forEach( breadCrumb => {
        /*let url : string;
        console.log(this.currentModule.curriculumId);
        url = '/module/' + this.currentModule.curriculumId;
        console.log(url);
        let display : DisplayCrumb;
        display = new DisplayCrumb(breadCrumb.displayName, url);
        this.displayCrumb.push(display);
        console.log(this.displayCrumb);*/
        let url : string;
        let display : DisplayCrumb;
        if("curriculum" == breadCrumb.type){
          console.log(breadCrumb.curriculumId);
          console.log("display name");
          console.log(breadCrumb.displayName);
          if(breadCrumb.displayName === 'University -> '){
            url = '/university/' + breadCrumb.curriculumId;
          } else {
            url = '/module/' + breadCrumb.curriculumId;
          }
          console.log(url);
          display = new DisplayCrumb(breadCrumb.displayName, url);
          this.displayCrumb.push(display);
          console.log(this.displayCrumb);
        } else if("module" == breadCrumb.type){
          console.log(breadCrumb.curriculumId);
          url = '/moduleFileList/'+breadCrumb.moduleId + '/' +breadCrumb.curriculumId;
          console.log(url);
          display = new DisplayCrumb(breadCrumb.displayName, url);
          this.displayCrumb.push(display);
          console.log(this.displayCrumb);
        } else if("folder" == breadCrumb.type){
          console.log(breadCrumb.curriculumId);
          url = '/moduleFolderContents/'+breadCrumb.parentFolderId;
          console.log(url);
          display = new DisplayCrumb(breadCrumb.displayName, url);
          this.displayCrumb.push(display);
          console.log(this.displayCrumb);
        }
      });

      console.log(this.breadCrumbs);
      console.log(this.displayCrumb);

      let count = 0;
      if(data.secondaryModules){
        data.secondaryModules.forEach( secondaryModule => {
          let secondaryModuleType : SecondaryModule;
          secondaryModuleType = new SecondaryModule(secondaryModule.id, secondaryModule.navText, secondaryModule.curriculumId, secondaryModule.moduleId, secondaryModule.secondaryModuleType, secondaryModule.iconPath, secondaryModule.url, secondaryModule.moduleFileId);
          if(count % 2 == 0 ){
            this.secondaryModulesList.push(new Array<SecondaryModule>());
          }
          let temp : Array<SecondaryModule> = new Array<SecondaryModule>();
          temp = this.secondaryModulesList.pop();
          temp.push(secondaryModuleType);
          this.secondaryModulesList.push(temp);
          count++;
        })
      }
      console.log("secondary modules");
      console.log(this.secondaryModulesList);
    });

    console.log(this.route.snapshot);
    if(this.route.snapshot.params['parentId']){
      console.log("current folder id")
      this.currentFolderId = this.route.snapshot.params['parentId'];
      console.log(this.currentFolderId);
      console.log("current folder object");
      console.log(this.currentFolder);

      if(this.currentFolder.parentFolderId != 'null'){
        this.currentFolderHasParent = true;
      }
    }

  }

  loadFile(moduleFile : ModuleFile){
    console.log("in load file: " + moduleFile.fileName);
    console.log(moduleFile);

    this.fileNameVal = moduleFile.fileName;
    this.hasSpanishFileVal = moduleFile.hasSpanishFile;
    this.audioFileNameVal = moduleFile.fileName;
    this.navTextVal = moduleFile.navText;
    this.isMusic = moduleFile.music;
    this.isVideo = moduleFile.video;
    this.isDownloadable = moduleFile.downloadable;
    if(moduleFile.video) {
      this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(moduleFile.fileName);
    }
  }

}
