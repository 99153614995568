import {Component, Input, OnInit, SimpleChanges, TemplateRef, ViewChild} from '@angular/core';
import {DocumentDTO} from "../../../types/DocumentDTO";
import {DocumentService} from "../../../_services/adminDocumentService/document-service";
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";

import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-e-user-list-document',
  templateUrl: 'list-document.html',
  styleUrls: ['list-document.component.css']
})
export class eUserListDocumentComponent implements OnInit {

  @Input() documentList: DocumentDTO[];

  @ViewChild('confirmation')
  private confirmationTemplate: TemplateRef<any>;

  modalRef: BsModalRef;


  constructor(private documentService: DocumentService,
              private toastr: ToastrService,
              private router: Router,
              private modalService: BsModalService) {
    documentService.findAll().subscribe(data => {
      this.documentList = data;
    });
  }


  decline(): void {
    this.modalRef.hide();
  }

  ngOnInit() {
  }
}
