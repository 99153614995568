import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from "@angular/router";
import {AdminAuthService} from "../../_services/admin/adminAuthService/admin-auth.service";
import {AuthService} from "../../_services/authService/auth.service";

@Injectable({
  providedIn: 'root'
})
export class AdminOrManagerDashboardGuard {

  constructor(
    private router: Router,
    private adminAuthService: AdminAuthService,
    private authService: AuthService
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    return new Promise((resolve) => {
      if (localStorage.getItem("gotoUser") != null) {
        this.router.navigate(['home']);
        localStorage.removeItem("gotoUser");
        return;
      }

      console.log("Check is logged in");
      let currentUser = this.adminAuthService.getCurrentUserValue();
      if (!currentUser) {
        currentUser = this.authService.getCurrentUserValue();
      }
      console.log(currentUser);
      if (currentUser) {
        console.log("user logged in")
        return resolve(true);
      }

      this.router.navigate(['admin']);
      return resolve(false);
    });
  }
}
