import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-create-new-tt-module',
  template: `
    <p>
      admin-create-new-tt-module works!
    </p>
  `,
  styleUrls: ['./admin-create-new-tt-module.component.css']
})
export class AdminCreateNewTtModuleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
