import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  EventEmitter
} from '@angular/core';
import {AccountUserDTO} from "../../types/AccountUserDTO";
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {RoleInfo} from "../../types/RoleInfo";
import {UserRoleInfo} from "../../types/UserRoleInfo";
import {AccountService} from "../../_services/accountService/account.service";
import {UpdateAccountRoleDTO} from "../../types/UpdateAccountRoleDTO";
import {ErrorMessageService} from "../../_services/errorMessageService/error-message.service";
import {DisplayMessageService} from "../../_services/displayMessageService/display-message.service";


@Component({
  selector: 'app-user-editor',
  templateUrl: './user-editor.html',
  styleUrls: ['./user-editor.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserEditorComponent implements OnInit {


  _user : AccountUserDTO;

  @Input()  roleCounts :  RoleInfo[];

  @Output() roleCountsChange=new EventEmitter<Boolean>();

  form: FormGroup;



  constructor(private fb: FormBuilder, private accountService : AccountService, private errorService : ErrorMessageService,
              private displayService : DisplayMessageService) { }


  /*@Input()
  set roleCounts(roleInfo : RoleInfo[]){
    console.log("set role counts");
    console.log(roleInfo);
    this._roleCounts = roleInfo;
  }*/

  @Input()
  set user(user : AccountUserDTO){
    this._user = user;

    if(this._user) {
      this.displayService.clear();
      this.errorService.clear();
      console.log("loading user");
      console.log(this._user);

      const formGroup = {};

      for(let role in this._user.roleInfo)
      {
        console.log(role);
        formGroup[this._user.roleInfo[role].roleType] = new FormControl(this._user.roleInfo[role].hasRole);
      }

      console.log(formGroup);

      this.form = new FormGroup(formGroup);
    }
  }

  ngOnInit() {
    this.displayService.clear();
    this.errorService.clear();
    }



  onSubmit(form){
    console.log(form);
    let dtos : Array<UpdateAccountRoleDTO> = new Array<UpdateAccountRoleDTO>();
    for(let key in form){
      console.log(form[key]);
      console.log(dtos);
      dtos.push(new UpdateAccountRoleDTO(key, form[key], this._user.userId));
    }


    this.errorService.clear();
    this.displayService.clear();

    this.accountService.updateAccountUserRoles(dtos).subscribe( data => {
      console.log("update account user roles response");
      console.log(data);
      console.log(data.userDTO.roleInfo);
      console.log(data.roleCounts);
      //errorServic
      this.errorService.addAll(data.messages.error);
      this.displayService.addAll(data.messages.message);
      this.roleCountsChange.emit(true);
    });
  }

  updated(){
    this.roleCountsChange.emit(true);
  }
}
