import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {CourseService} from "../../../../_services/adminCourseService/course-service";
import {ToastrService} from "ngx-toastr";
import {ActivatedRoute, Router} from "@angular/router";
import {CourseDTO} from "../../../../types/CourseDTO";
import {CourseEnrollmentDTO} from "../../../../types/CourseEnrollmentDTO";

@Component({
  selector: 'app-e-admin-create-course-enrollments',
  templateUrl: './edit-course-enrollments.html',
  styleUrls: ['./edit-course-enrollments.component.css']
})
export class eAdminEditCourseEnrollmentsComponent implements OnInit {

  courseId: string;
  courseEnrollmentId: string;

  courseEnrollment: CourseEnrollmentDTO;

  createCourseEnrollmentForm = this.fb.group({
    year: [''],
    month: [''],
    date: [''],
  }, {validator: []});

  constructor(private fb: FormBuilder,
              private courseService: CourseService,
              private toastr: ToastrService,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.courseId = this.route.snapshot.paramMap.get('id');
    this.courseEnrollmentId = this.route.snapshot.paramMap.get('enrollmentId');
    if (this.courseEnrollmentId != null) {
      this.loadData();
    }
  }

  loadData() {
    this.courseService.getEnrollment(this.courseId, this.courseEnrollmentId).subscribe(data => {
      this.courseEnrollment = data;
      let certificateDate = data.dateCertificate;
      let date = certificateDate.split('/');
      this.createCourseEnrollmentForm.get("month").setValue(date[0]);
      this.createCourseEnrollmentForm.get("date").setValue(date[1]);
      this.createCourseEnrollmentForm.get("year").setValue(date[2]);
    });
  }

  onSubmit() {
    let courseEnrollmentDTO = new CourseEnrollmentDTO(
      this.getField("month")
      + '/'
      + this.getField("date")
      + '/'
      + this.getField("year"));


    this.courseService.updateEnrollmentDate(this.courseId, this.courseEnrollmentId, courseEnrollmentDTO).subscribe(data => {
      if (data.success) {
        this.toastr.success("Success", "Course completion date updated successfully!!")
        this.router.navigateByUrl('/sparkecademy-admin-course/' + this.courseId + '/enrollments');
      }
    });
  }

  getField(fieldName: string) {
    return this.createCourseEnrollmentForm.get(fieldName).value;
  }
}
