import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AccountUserDTO} from "../../../../types/AccountUserDTO";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {UpdateAccountRoleDTO} from "../../../../types/UpdateAccountRoleDTO";
import {AdminUserService} from "../../../../_services/admin/adminUserService/admin-user.service";

@Component({
  selector: 'app-admin-account-user-role-editor',
  templateUrl: './admin-account-user-role-editor.html',
  styleUrls: ['./admin-account-user-role-editor.component.css']
})
export class AdminAccountUserRoleEditorComponent implements OnInit {

  _user : AccountUserDTO;


  roleTypeList: Array<Array<String>> = [];
  flatListArray: Array<String> = [];

  //roleForm = this.fb.group({ }, {validator: []});

  roleForm: FormGroup;

  responseMessages : Array<String> = new Array<String>();


  @Output() reloadStats=new EventEmitter<boolean>();

  @Output() reloadTree=new EventEmitter<boolean>();

  constructor(private fb: FormBuilder, private adminUserService : AdminUserService) { }

  ngOnInit() {
  }


  @Input()
    set user(user : AccountUserDTO){
   if(user){
     this._user = user;
      this.roleTypeList = new Array<Array<String>>();
      this.flatListArray = new Array<String>();

     let count : number = 0;
     this._user.roleInfoArray.forEach( roleInfo => {
       let role : String;
       role = roleInfo.roleType;
       if(count % 2 == 0 ){
         this.roleTypeList.push(new Array<String>());
       }
       let temp : Array<String> = new Array<String>();
       temp = this.roleTypeList.pop();
       temp.push(role);
       this.flatListArray.push(role);
       this.roleTypeList.push(temp);
       count++;
     });

     console.log("Role type list");
    console.log(this.roleTypeList);
    console.log("Flat List");
    console.log(this.flatListArray);
     const formGroup = new FormGroup({ });

     this._user.roleInfoArray.forEach(roleInfo => {
       console.log(roleInfo);
       formGroup.addControl(roleInfo.roleType, new FormControl(roleInfo.hasRole));
     });

     this.roleForm = formGroup;
   }

  }


  onSubmit(form){
    console.log("updateUser");
    console.log(this.roleForm);
    let dtos : Array<UpdateAccountRoleDTO> = new Array<UpdateAccountRoleDTO>();
    for(let key in form){
      console.log(form[key]);
      console.log(dtos);
      dtos.push(new UpdateAccountRoleDTO(key, form[key], this._user.userId));
    }

    console.log(dtos);
    this.adminUserService.updateUserRoles(dtos).subscribe(data => {

      console.log(data);

      if(data.success === true){
        this.responseMessages = new Array<String>();
        console.log("in success");
        console.log(data.responseDetails);
        console.log(data.responseDetails.error);
        console.log(data.responseDetails.messages);
        if(data.responseDetails.error) {
          data.responseDetails.error.forEach(error => {
            console.log(error);
            this.responseMessages.push(error);
          });
        }
        if(data.responseDetails.message) {
          data.responseDetails.message.forEach(message => {
            console.log(message);
            this.responseMessages.push(message);
          });
        }
        this.reloadStats.emit(true);
        this.reloadTree.emit(true);
      } else {
        this.responseMessages.push(data.errorMessage);
      }

    });


    console.log(dtos);

  }
}
