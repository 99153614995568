import {Injectable} from '@angular/core';
import {UserFilterDTO} from "../../types/UserFilterDTO";
import {UserDTO} from "../../types/UserDTO";
import {Observable, of} from "rxjs";
import {catchError} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {ErrorMessageService} from "../../../_services/errorMessageService/error-message.service";
import {ToastrService} from "ngx-toastr";
import {PageEvent} from "@angular/material/paginator";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  // private urlBase: string = "http://localhost:8050/admin/user/";
  private urlBase: string = "https://sparkfamily.org/api/admin/user/";
  // private urlBase : string = "http://localhost:8050/admin/user/";

  private addUserUrl: string = this.urlBase + "createNewUser";
  private editUserUrl: string = this.urlBase + "edit";
  private getUserUrl: string = this.urlBase + "get";
  private removeUserUrl: string = this.urlBase + "delete";
  private removeMultipleUserUrl: string = this.urlBase + "deleteMultipleUsers";
  private filterUserUrl: string = this.urlBase + "filterUsers";
  private activateUserUrl: string = this.urlBase + "activate";
  private deActivateUserUrl: string = this.urlBase + "deActivate";


  private creditTransactionByIdHistoryUrl: string = this.urlBase + "@id/credits";

  private enrolledCouresUrl: string = this.urlBase + "@id/courses";

  private addCreditsTransactionsUrl: string = this.urlBase + "@id/addTransaction";

  constructor(private http: HttpClient, private router: Router, private errorService: ErrorMessageService, private toastr: ToastrService) {
  }

  add(userDTO: UserDTO) {
    return this.http.post<any>(this.addUserUrl, userDTO).pipe(catchError(this.handleError('ManageUserService: AddUser', [])));
  }

  edit(id: string, userDTO: UserDTO) {
    return this.http.post<any>(this.editUserUrl + "/" + id, userDTO).pipe(catchError(this.handleError('ManageUserService: EditUser', [])));
  }

  get(id: number) {
    return this.http.get<any>(this.getUserUrl + '/' + id).pipe(catchError(this.handleError('ManageUserService: GetUser', [])));
  }

  remove(id: number) {
    return this.http.post<any>(this.removeUserUrl, id).pipe(catchError(this.handleError('ManageUserService: RemoveUser', [])));
  }

  removeMultiple(id: number[]) {
    return this.http.post<any>(this.removeMultipleUserUrl, id).pipe(catchError(this.handleError('ManageUserService: RemoveUser', [])));
  }

  activate(id: number) {
    return this.http.post<any>(this.activateUserUrl, id).pipe(catchError(this.handleError('ManageUserService: RemoveUser', [])));
  }

  deactivate(id: number) {
    return this.http.post<any>(this.deActivateUserUrl, id).pipe(catchError(this.handleError('ManageUserService: RemoveUser', [])));
  }

  filter(dto: UserFilterDTO): Observable<any> {
    return this.http.post<any>(this.filterUserUrl, dto).pipe(catchError(this.handleError('ManageUserService: FilterRecords', [])));
  }

  findAll(event?: PageEvent): Observable<any> {
    return this.http.post<any>(this.filterUserUrl, null).pipe(catchError(this.handleError('ManageUserService: FindAll', [])));
  }

  getCreditTransactionByIdHistory(id?: number): Observable<any> {
    let url = this.creditTransactionByIdHistoryUrl.replace("@id", id.toString());
    return this.http.get<any>(url).pipe(catchError(this.handleError('ManageUserService: getCreditTransactionHistory', [])));
  }

  getEnrolledCoursesById(id?: number): Observable<any> {
    let url = this.enrolledCouresUrl.replace("@id", id.toString());
    return this.http.get<any>(url).pipe(catchError(this.handleError('ManageUserService: getEnrolledCoursesById', [])));
  }

  addCreditsTransaction(userId: number, amount: number): Observable<any> {
    let url = this.addCreditsTransactionsUrl.replace("@id", userId.toString());
    return this.http.post<any>(url, amount).pipe(catchError(this.handleError('ManageUserService: addCreditsTransaction', [])));
  }

  clear() {
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log("user cur service catch error");
      console.log("error1");
      console.error(error);
      console.log("error1");
      this.errorService.add("Error generating content");
      this.toastr.error(error.error, "Error")
      return of(result as T);
    };
  }
}
