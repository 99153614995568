import { Injectable } from '@angular/core';


import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {UserCurriculumService} from "../../_services/userCurriculumService/user-curriculum.service";
import {Observable} from "rxjs";
import {ModuleFile} from "../../types/ModuleFile";

@Injectable({
  providedIn: 'root'
})
export class SecondaryModuleListResolver implements Resolve<any> {
  constructor(private curriculumService: UserCurriculumService) {}

  resolve(route: ActivatedRouteSnapshot) : Observable<any>{
    console.log("in secondary module list resolver");
    console.log("mflr param" + route.params['moduleId']);
    return this.curriculumService.getSecondaryModules(route.params['moduleId']);
  }
}

@Injectable({
  providedIn: 'root'
})
export class FolderSecondaryModuleListResolver implements Resolve<any> {
  constructor(private curriculumService: UserCurriculumService) {}

  resolve(route: ActivatedRouteSnapshot) : Observable<any>{
    console.log("in folder secondary module list resolver");
    console.log("mflr param" + route.params['parentId']);
    return this.curriculumService.getFolderSecondaryModules(route.params['parentId']);
  }
}

@Injectable({
  providedIn: 'root'
})
export class SecondaryModuleFileListResolver implements Resolve<any> {
  constructor(private curriculumService: UserCurriculumService) {}

  resolve(route: ActivatedRouteSnapshot) : Observable<any>{
    console.log("in secondary module file list resolver");
    console.log("mflr param" + route.params['secondaryModuleId']);
    return this.curriculumService.getSecondaryModuleFiles(route.params['secondaryModuleId']);
  }
}



@Injectable({
  providedIn: 'root'
})
export class CurrentSecondaryModuleResolver implements Resolve<any> {
  constructor(private curriculumService: UserCurriculumService) {}

  resolve(route: ActivatedRouteSnapshot) : Observable<any>{
    console.log("in secondary module file list resolver");
    console.log("mflr param" + route.params['secondaryModuleId']);
    return this.curriculumService.getCurrentSecondaryModule(route.params['secondaryModuleId']);
  }
}

