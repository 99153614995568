import { Injectable } from '@angular/core';


import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {UserCurriculumService} from "../../_services/userCurriculumService/user-curriculum.service";
import {Curriculum} from "../../types/Curriculum";
import {Observable} from "rxjs";
import {AccountUserDTO} from "../../types/AccountUserDTO";
import {AccountService} from "../../_services/accountService/account.service";
import {ShoolGroupDTO} from "../../types/SchoolGroupDTO";

@Injectable({
  providedIn: 'root'
})
export class AccessingViaTrialResolver implements Resolve<Observable<any>> {
  constructor(private curriculumService : UserCurriculumService) {}

  resolve( route: ActivatedRouteSnapshot) : Observable<any>{
    console.log("in resolver");
    return this.curriculumService.accessingCurriculumViaTrial( route.params['id']);
  }
}


@Injectable({
  providedIn: 'root'
})
export class AccessingModuleViaTrialResolver implements Resolve<Observable<any>> {
  constructor(private curriculumService : UserCurriculumService) {}

  resolve( route: ActivatedRouteSnapshot) : Observable<any>{
    console.log("in resolver");
    return this.curriculumService.accessingCurriculumViaTrial( route.params['curriculumId']);
  }
}

@Injectable({
  providedIn: 'root'
})
export class AccessingModuleFolderContentsViaTrialResolver implements Resolve<Observable<any>> {
  constructor(private curriculumService : UserCurriculumService) {}

  resolve( route: ActivatedRouteSnapshot) : Observable<any>{
    console.log("in resolver");
    return this.curriculumService.accessingModuleFolderContentsViaTrial( route.params['parentId']);
  }
}
