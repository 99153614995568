import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NestedTreeControl} from "@angular/cdk/tree";
import {MatDialog, MatDialogConfig, MatDialogRef, MatTreeNestedDataSource} from "@angular/material";
import {EditorNode} from "../admin-account-user-editor.component";
import {AccountUserDTO} from "../../../../../types/AccountUserDTO";
import {AdminDeleteUserComponent} from "../../admin-delete-user/admin-delete-user.component";

@Component({
  selector: 'app-admin-account-user-editor-user-tree',
  templateUrl: `./admin-account-user-editor-user-tree.html`,
  styleUrls: ['./admin-account-user-editor-user-tree.component.css']
})
export class AdminAccountUserEditorUserTreeComponent implements OnInit {



  treeControl = new NestedTreeControl<EditorNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<EditorNode>();

  @Output() loadUser = new EventEmitter<AccountUserDTO>();


  @Output() reloadEditor=new EventEmitter<boolean>();


  constructor(private dialog: MatDialog) { }

  ngOnInit() {
  }

  @Input()
  set inputData(source){
    console.log(source);
    if(source){
      this.dataSource.data = source;
    }
  }

  loadEditor(node){
    this.loadUser.emit(node.userDTO);
    console.log("loadEditor");
    console.log(node);
    console.log(node.userDTO);
  }
  deleteUser(node){
    console.log("delete user");
    console.log(node);
    this.deleteUserDialogOpen(node.userDTO.userId);
  }
  deleteUserDialogOpen(userId){
    let deleteUserDialogConfig = new MatDialogConfig();
    deleteUserDialogConfig.data={id: userId};
    deleteUserDialogConfig.autoFocus = true;
    deleteUserDialogConfig.disableClose = true;
    console.log(userId);

    let dialogRef : MatDialogRef<AdminDeleteUserComponent>  = this.dialog.open(AdminDeleteUserComponent, deleteUserDialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      console.log("delete closed");
      console.log(data);
      if(data){
        this.reloadEditor.emit(true);
      }
    });

    console.log("open delete user");
  }
  hasChild = (_: number, node: EditorNode) => !!node.children && node.children.length > 0;
}
