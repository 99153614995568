import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-downloadable-view',
  templateUrl: './downloadable-view.html',
  styleUrls: ['./downloadable-view.component.css']
})
export class DownloadableViewComponent implements OnInit {

  _fileName1 : string;
  _fileName2 : string;
  _displayName : string;

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit() {
  }



  @Input()
  set fileName(fileName : string){
    if(fileName && !(this._fileName1)) {
      console.log(fileName);
      this._fileName1 = "/assets/" + fileName;
      this.cdr.detectChanges();
      this._fileName2 = null;
    } else {
      console.log(fileName);
      this._fileName2 = "/assets/" + fileName;
      this.cdr.detectChanges();
      this._fileName1 = null;
    }
  }

  @Input()
  set displayName(displayName : string){
    if(displayName) {
      console.log(displayName);
      this._displayName = displayName;
      this.cdr.detectChanges();
    }
  }
}
