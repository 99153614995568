import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Curriculum} from "../../../../../../types/Curriculum";
import {FormBuilder} from "@angular/forms";
import {AdminCurriculumService} from "../../../../../../_services/admin/adminCurriculumService/admin-curriculum.service";
import {CreateNewModuleDTO} from "../../../../../../types/CreateNewModuleDTO";
import {CreateNewSecondaryModuleDTO} from "../../../../../../types/CreateNewSecondaryModuleDTO";
import {ModuleFile} from "../../../../../../types/ModuleFile";
import {Module} from "../../../../../../types/Module";

@Component({
  selector: 'app-admin-create-new-secondary-module',
  templateUrl: './admin-create-new-secondary-module.html',
  styleUrls: ['./admin-create-new-secondary-module.component.css']
})
export class AdminCreateNewSecondaryModuleComponent implements OnInit {

  _curriculum : Curriculum;
  createNewSecondaryModule = this.fb.group({
    TYPE:null,
    URL:['']
  }, {validator: []});

  _parentModule : Module;
  _parentModuleFile: ModuleFile;
  @Input() parentType : string;
  @Output() newModuleCreated=new EventEmitter<boolean>();
  @Output() closeNewModuleEmit=new EventEmitter<boolean>();

  types: object[] = [{name: 'ASSESSMENTS', checked: true}, {name: 'UNIT_PLANNING', checked:false},
    {name: 'ACTIVITY_CARDS', checked:false}, {name: 'MUSIC', checked:false}, {name: 'VIDEO', checked:false},
    {name: 'EQUIPMENT', checked:false}, {name: 'SOCIAL_DISTANCE', checked:false}];

  constructor(private fb: FormBuilder, private adminCurriculumService : AdminCurriculumService) { }

  ngOnInit() {
  }

  saveNewSecondaryModule(){

    let dto : CreateNewSecondaryModuleDTO = new CreateNewSecondaryModuleDTO(
      Number(this._curriculum.id),
      this.createNewSecondaryModule.controls['TYPE'].value,
      '', '',
      this.createNewSecondaryModule.controls['URL'].value);

    dto.parentType = this.parentType;
    if(this._parentModule){
      dto.parentId = this._parentModule.id;
    }
    if(this._parentModuleFile){
      dto.parentId = this._parentModuleFile.id;
    }
    console.log(dto);
    console.log(this._curriculum);
    console.log(this.createNewSecondaryModule);
    this.adminCurriculumService.createNewSecondaryModule(dto).subscribe(data =>{
      this.newModuleCreated.emit(true);
    });
  }

  closeNewSecondaryModule(){
    this.closeNewModuleEmit.emit(true);
  }


  @Input()
  set curriculum(curriculum){
    this._curriculum = curriculum;
  }

  @Input()
  set parentModule(module){
    this._parentModule = module;
  }

  @Input()
  set parentModuleFile(moduleFile){
    this._parentModuleFile = moduleFile;
  }
}
