export class UserDTO {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  gradeLevel: string;
  school: string;
  organization: number;
  organizationName: string;
  status: string;
  lastLoginDate: string;
  password: string;
  canUserLogin: boolean;
  isManager: boolean;
  isSelected: boolean;
  credits: number;
  ecademyRole: string;
  userType: string;

  constructor(firstName: string, lastName: string,
              email: string, gradeLevel: string, school: string,
              status: string, lastLoginDate: string, password: string,
              canUserLogin: boolean, isManager: boolean, userType: string, organization?: number,
              credits?: number, ecademyRole?: string) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.gradeLevel = gradeLevel;
    this.school = school;
    this.status = status;
    this.lastLoginDate = lastLoginDate;
    this.password = password;

    this.canUserLogin = canUserLogin;
    this.isManager = isManager;
    this.userType = userType;

    this.credits = credits;
    this.ecademyRole = ecademyRole;
    this.organization = organization;
  }
}
