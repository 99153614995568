export class CourseLessonContentDTO {
  id: number;
  caption: string;
  type: string;
  fileUrl: string;
  file: File;

  constructor(id: number, caption: string, type: string, fileUrl: string, file?: File) {
    this.id = id;
    this.caption = caption;
    this.type = type;
    this.fileUrl = fileUrl;
    this.file = file;
  }
}
