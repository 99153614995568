import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {
  EmailValidation,
  PasswordValidation,
  RepeatEmailValidator,
  RepeatPasswordValidator
} from "../../helpers/validators";
import {AuthService} from "../../_services/authService/auth.service";
import {RegistrationDTO} from "../../types/RegistrationDTO";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {ResetPasswordDTO} from "../../types/ResetPasswordDTO";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  token : string;

  accountUpdateForm = this.fb.group({
    password: ['', PasswordValidation],
    passwordConfirm: ['']
  }, {validator: [RepeatPasswordValidator]});

  constructor(private fb: FormBuilder, private authService: AuthService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.token = this.route.snapshot.params['token'];
  }


  onSubmit() {

    let updatePassword: string;

    if(this.accountUpdateForm.get('password').value){
      updatePassword = this.accountUpdateForm.get('password').value;
    }
    console.log(this.token);
    let accountInfo = new ResetPasswordDTO(updatePassword,this.token);


    this.authService.updatePassword(accountInfo).subscribe();

  }
}
