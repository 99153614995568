import {Component, OnInit, ViewChild} from '@angular/core';
import {SelectedComponent} from "../types/SelectedComponent";
import {MatSidenav} from "@angular/material";
import {AdminAuthService} from "../../_services/admin/adminAuthService/admin-auth.service";


@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.html',
  styleUrls: ['./admin-home.component.css']
})
export class AdminHomeComponent implements OnInit {

  selectedComponent : string;

  public GEN_LICENSE : string = "GEN_LICENSE";
  public GEN_LICENSE_1 : string = "GEN_LICENSE_1";
  public ACCOUNT_COMP : string = "ACCOUNT_COMP";
  public FIND_LICENSE : string = "FIND_LICENSE";
  public ACCOUNT_EDIT : string = "ACCOUNT_EDIT";
  public LICENSE_EDIT : string = "LICENSE_EDIT";
  public HOME_PAGE_EDIT : string = "HOME_PAGE_EDIT";
  public PROGRAM_EDIT : string = "PROGRAM_EDIT";
  public PROGRAM_EDIT_NEW : string = "PROGRAM_EDIT_NEW";
  public NAV_ENTRY_EDIT : string = "NAV_ENTRY_EDIT";
  public USER_FILE_IMPORT : string = "USER_FILE_IMPORT";
  public BULK_USER_UPDATE : string = "BULK_USER_UPDATE";
  public SPARK_ECADEMY : string = "SPARK_ECADEMY";

  // @ts-ignore
  @ViewChild('sidenav', {static: false}) sidenav: MatSidenav;

  reason = '';

  constructor(private adminAuthService : AdminAuthService) { }

  ngOnInit() {
  }

  loadComponent(selected : string){
    console.log(selected);
    this.selectedComponent = selected;
    console.log(this.selectedComponent);
    this.sidenav.close();
  }

  close(reason: string) {
    this.reason = reason;
    this.sidenav.close();
  }

  onResetLicense(value : boolean){
    console.log("onResetLicense");
    if(this.selectedComponent === this.GEN_LICENSE){
      this.selectedComponent = this.GEN_LICENSE_1;
    } else if(this.selectedComponent === this.GEN_LICENSE_1){
      this.selectedComponent = this.GEN_LICENSE
    }
  }

  logout(){
    this.adminAuthService.logout(true);
  }
}
