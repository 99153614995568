import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ModuleFile} from "../../types/ModuleFile";
import {Curriculum} from "../../types/Curriculum";
import {FileAudio} from "../../types/FileAudio";
import {Breadcrumb} from "../../types/Breadcrumb";
import {Module} from "../../types/Module";
import {DisplayCrumb} from "../../types/DisplayCrumb";
import {SecondaryModule} from "../../types/SecondaryModule";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {LicenseExpirationDateDTO} from "../../types/LicenseExpirationDateDTO";

@Component({
  selector: 'app-module-file-list',
  templateUrl: './moduleFileList.html',
  styleUrls: ['./module-file-list.component.css']
})
export class ModuleFileListComponent implements OnInit {

  moduleFilesArray : Array<ModuleFile> = [];
  curriculums : Curriculum[];
  licenseExpirationDate: LicenseExpirationDateDTO;
  navEntries : any;
  curriculumId : number;

  fileNameVal: string;
  audioFileNameVal: string;
  navTextVal: string;
  hasSpanishFileVal:boolean;
  isMusic: boolean;
  isVideo: boolean;
  isDownloadable : boolean;

  safeHtml: SafeHtml;

  breadCrumbs : Breadcrumb[];
  displayCrumb : Array<DisplayCrumb> = [];

  secondaryModulesList: Array<Array<SecondaryModule>> = [];
  currentModule : Module;

  accessingViaTrial: boolean;

  constructor(private route: ActivatedRoute, private sanitizer: DomSanitizer) { }

  ngOnInit() {

    this.route.data.subscribe(data =>
    {
      console.log("in module file list component");
      console.log(data);

      data.moduleFiles.forEach( moduleFile => {
        let typedFile : ModuleFile;
        console.log(moduleFile);
        typedFile = new ModuleFile(moduleFile.id, moduleFile.navText, moduleFile.fileName, moduleFile.success, moduleFile.error, moduleFile.folder, moduleFile.hasSpanishFile, moduleFile.curriculumId, moduleFile.moduleId, moduleFile.music, moduleFile.video, moduleFile.downloadable, moduleFile.trialAccessible);
        this.moduleFilesArray.push(typedFile);
      });
      console.log(this.moduleFilesArray);
      this.curriculums = data.curriculums;
      console.log(this.curriculums);
      this.navEntries = data.navEntries;
      this.licenseExpirationDate = data.licenseExpirationDate;
      this.curriculumId = this.route.snapshot.params['curriculumId'];
      this.breadCrumbs = data.breadcrumbs;
      console.log(this.breadCrumbs);
      this.currentModule = data.currentModule;
      console.log(this.currentModule);

      this.accessingViaTrial = data.accessingViaTrial.accessingViaTrial;
      console.log("accessing via trial");
      console.log(this.accessingViaTrial);
      console.log(data.accessingViaTrial.accessingViaTrial);
      console.log(data.accessingViaTrial.value);


      this.breadCrumbs.forEach( breadCrumb => {
          let url : string;
          console.log(this.currentModule.curriculumId);
          console.log("@@@@@@@@");
          console.log(breadCrumb.displayName);
          if(breadCrumb.displayName === 'SPARKuniversity / ') {
            url = '/university/' + this.currentModule.curriculumId;
          } else {
            url = '/module/' + this.currentModule.curriculumId;
          }
          console.log(url);
          let display : DisplayCrumb;
          display = new DisplayCrumb(breadCrumb.displayName, url);
          this.displayCrumb.push(display);
          console.log(this.displayCrumb);
      });
      /*
      Long id;

    String navText;

    Long curriculumId;

    Long moduleId;

    String secondaryModuleType;

    String iconPath;
       */
      let count = 0;
      if(data.secondaryModules){
        data.secondaryModules.forEach( secondaryModule => {
          let secondaryModuleType : SecondaryModule;
          secondaryModuleType = new SecondaryModule(secondaryModule.id, secondaryModule.navText, secondaryModule.curriculumId, secondaryModule.moduleId, secondaryModule.secondaryModuleType, secondaryModule.iconPath, secondaryModule.url, secondaryModule.moduleFileId);
          if(count % 2 == 0 ){
            this.secondaryModulesList.push(new Array<SecondaryModule>());
          }
          let temp : Array<SecondaryModule> = new Array<SecondaryModule>();
          temp = this.secondaryModulesList.pop();
          temp.push(secondaryModuleType);
          this.secondaryModulesList.push(temp);
          count++;
        })
      }
      console.log("secondary modules");
      console.log(this.secondaryModulesList);
    });
  }


  loadFile(moduleFile : ModuleFile){
    console.log("in load file: " + moduleFile.fileName);
    console.log(moduleFile);

    this.fileNameVal = moduleFile.fileName;
    this.hasSpanishFileVal = moduleFile.hasSpanishFile;
    this.audioFileNameVal = moduleFile.fileName;
    this.navTextVal = moduleFile.navText;
    this.isMusic = moduleFile.music;
    this.isVideo = moduleFile.video;
    this.isDownloadable = moduleFile.downloadable;
    if(moduleFile.video) {
      this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(moduleFile.fileName);
    }
  }

}
