import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {AccountUserDTO} from "../../types/AccountUserDTO";
import {AccountService} from "../../_services/accountService/account.service";
import {ErrorMessageService} from "../../_services/errorMessageService/error-message.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-delete-user-dialog',
  templateUrl:'./delete-user-dialog.html',
  styleUrls: ['./delete-user-dialog.component.css']
})
export class DeleteUserDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<DeleteUserDialogComponent>,
              public accountService : AccountService, public errorService: ErrorMessageService, private router: Router) {
  }


  ngOnInit() {
    console.log(this.data);
    console.log(this.data.user);
    this.errorService.clear();
  }

  deleteUser(user : AccountUserDTO){
    console.log(user);
    this.accountService.deleteUser(user).subscribe( data => {
      console.log("delete user response")
      console.log(data);

      if(data.error.length > 0){
        this.errorService.clear();
        this.errorService.addAll(data.error);
      } else {

        this.dialogRef.close();

        window.location.reload();
      }

    });
  }

  close(){
    this.dialogRef.close();

  }

}
