import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {ActivatedRoute, Router} from "@angular/router";
import {CourseCategoryService} from "../../../../_services/adminCourseService/course-category-service";
import {CourseCategoryDTO} from "../../../../types/CourseCategoryDTO";
import {CourseTagDTO} from "../../../../types/CourseTagDTO";

@Component({
  selector: 'app-e-admin-create-course-category',
  templateUrl: './create-course-tag.html',
  styleUrls: ['./create-course-tag.component.css']
})
export class eAdminCreateCourseTagComponent implements OnInit {

  header: string = "Add New Course Tag";
  courseTagId: string;

  createCourseTagForm = this.fb.group({
    name: ['', Validators.required],
    colorCode: ['', Validators.required],
    url: ['', Validators.required],
    sortOrder: ['', Validators.required],
    isActive: [false]
  }, {validator: []});

  constructor(private fb: FormBuilder,
              private courseCategoryService: CourseCategoryService,
              private toastr: ToastrService,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.courseTagId = this.route.snapshot.paramMap.get('id');
    if (this.courseTagId != null) {
      this.header = "Edit Course Tag";
      this.loadData();
    }
  }

  loadData() {
    this.courseCategoryService.getCourseTag(this.courseTagId).subscribe(data => {
      this.createCourseTagForm.get("name").setValue(data.name);
      this.createCourseTagForm.get("colorCode").setValue(data.colorCode);
      this.createCourseTagForm.get("url").setValue(data.url);
      this.createCourseTagForm.get("sortOrder").setValue(data.sortOrder);
      this.createCourseTagForm.get("isActive").setValue(data.isActive == null ? null : data.isActive);
    });
  }

  onSubmit() {
    let courseTagDTO = new CourseTagDTO(
      null,
      this.getField("name"),
      this.getField("colorCode"),
      this.getField("url"),
      this.getField("sortOrder"),
      this.getField("isActive")
    );


    if (this.courseTagId == null) {
      this.courseCategoryService.addCourseTag(courseTagDTO).subscribe(data => {
        if (!data.success) {
          this.toastr.error(data.errorMessage, "Error");
        }
        if (data.success) {
          this.toastr.success("Success", "Course Tag created successfully!!")
          this.router.navigateByUrl('/sparkecademy-admin-course-tag');
        }
      });
    } else {
      this.courseCategoryService.editCourseTag(this.courseTagId, courseTagDTO).subscribe(data => {
        if (data.success) {
          this.toastr.success("Success", "Course Tag updated successfully!!")
          this.router.navigateByUrl('/sparkecademy-admin-course-tag');
        }
      });
    }
  }

  reset() {
    this.router.navigateByUrl('/sparkecademy-admin-course-tag');
  }

  getField(fieldName: string) {
    return this.createCourseTagForm.get(fieldName).value;
  }
}
