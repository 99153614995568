import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {AdminCurriculumService} from "../../../../../_services/admin/adminCurriculumService/admin-curriculum.service";
import {CreateNewModuleDTO} from "../../../../../types/CreateNewModuleDTO";
import {Curriculum} from "../../../../../types/Curriculum";

@Component({
  selector: 'app-admin-create-new-module',
  templateUrl: './admin-create-new-module.html',
  styleUrls: ['./admin-create-new-module.component.css']
})
export class AdminCreateNewModuleComponent implements OnInit {

  _curriculum : Curriculum;
  createNewModule = this.fb.group({
    NAV_TEXT:[''],
  }, {validator: []});

  @Output() newModuleCreated=new EventEmitter<boolean>();
  @Output() closeNewModuleEmit=new EventEmitter<boolean>();

  @Input() tableType;

  constructor(private fb: FormBuilder, private adminCurriculumService : AdminCurriculumService) { }

  ngOnInit() {
  }

  saveNewModule(){

    let dto : CreateNewModuleDTO = new CreateNewModuleDTO(this.createNewModule.controls['NAV_TEXT'].value, Number(this._curriculum.id));
    console.log(dto);
    console.log(this._curriculum);
    console.log(this.createNewModule);
    if(this.tableType =='MODULE' || this.tableType == null) {
      this.adminCurriculumService.createNewModule(dto).subscribe(data => {
        this.newModuleCreated.emit(true);
      });
    }
    if(this.tableType=='TT_MODULE'){
      this.adminCurriculumService.createNewTTModule(dto).subscribe(data => {
        this.newModuleCreated.emit(true);
      });
    }

  }

  closeNewModule(){
   this.closeNewModuleEmit.emit(true);
  }


  @Input()
  set curriculum(curriculum){
    this._curriculum = curriculum;
  }
}
