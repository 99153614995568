import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {PaginationCourseDTO} from "../../../types/PaginationCourseDTO";
import {Sort} from "@angular/material/sort";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";
import {MatMenuTrigger} from "@angular/material/menu";
import {CourseFilterDTO} from "../../../types/CourseFilterDTO";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {CourseService} from "../../../_services/adminCourseService/course-service";

@Component({
  selector: 'app-e-admin-list-course',
  templateUrl: 'list-course.html',
  styleUrls: ['list-course.component.css']
})
export class eAdminListCourseComponent implements OnInit {

  private _courseFilterDto: CourseFilterDTO;

  @Input() set courseFilterDto_(_courseFilterDto: CourseFilterDTO) {

    this._courseFilterDto = _courseFilterDto;

    this.pageEvent_ = new PageEvent();
    this.pageEvent_.pageSize = 50;
    this.pageEvent_.length = 0;
    this.pageEvent_.pageIndex = 0;
    this.sortAndFilterRecords();
  }

  get courseFilterDto_(): CourseFilterDTO {
    return this._courseFilterDto;
  }

  paginationCourseList: PaginationCourseDTO;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  pageEvent?: Event;
  pageEvent_?: PageEvent;
  sort: Sort;

  @ViewChild('confirmation')
  private confirmationTemplate: TemplateRef<any>;

  modalRef: BsModalRef;

  recordToDelete: number;

  constructor(private courseService: CourseService,
              private toastr: ToastrService,
              private router: Router,
              private modalService: BsModalService) {
  }

  sortAndFilterRecords() {
    let courseFilterDto;

    if (typeof this.pageEvent_ !== 'undefined') {
      courseFilterDto = new CourseFilterDTO(this.pageEvent_.pageIndex, this.pageEvent_.pageSize, this.pageEvent_.length);
    }

    if (typeof this.courseFilterDto_ !== 'undefined') {
      courseFilterDto.title = this.courseFilterDto_.title;
      courseFilterDto.categoryId = this.courseFilterDto_.categoryId;
      courseFilterDto.tagId = this.courseFilterDto_.tagId;
    }

    if (typeof this.sort != 'undefined') {
      courseFilterDto.sortBy = this.sort.active;
      courseFilterDto.sortOrder = this.sort.direction;
    }

    this.courseService.filter(courseFilterDto).subscribe(data => {
      this.paginationCourseList = data;
    });
    return event;
  }

  findRecords(event?: PageEvent) {
    this.pageEvent_ = event;
    return this.sortAndFilterRecords();
  }

  sortRecords(sort: Sort) {
    this.sort = sort;
    this.sortAndFilterRecords();
  }

  edit(course) {
    this.router.navigateByUrl('sparkecademy-admin-course/edit/' + course.id).then(r => {
    });
  }

  buildContents(course) {
    this.router.navigateByUrl('sparkecademy-admin-course/' + course.id + '/contents').then(r => {
    });
  }

  enrollments(course) {
    this.router.navigateByUrl('sparkecademy-admin-course/' + course.id + '/enrollments').then(r => {
    });
  }

  view(course) {
    this.router.navigateByUrl('sparkecademy-admin-course/view/' + course.id).then(r => {
    });
  }

  inActivate(course) {
    this.courseService.inactivate(course.id).subscribe(data => {
      if (data.success) {
        this.toastr.success("Course InActivated successfully!!", "Success")
        return this.sortAndFilterRecords();
      }
    });
  }

  activate(course) {
    this.courseService.activate(course.id).subscribe(data => {
      if (data.success) {
        this.toastr.success("Course activated successfully!!", "Success")
        return this.sortAndFilterRecords();
      }
    });
  }


  remove(course) {
    this.modalRef = this.modalService.show(this.confirmationTemplate, {class: 'modal-sm'});
    this.recordToDelete = course.id;
  }


  confirm(): void {
    this.courseService.remove(this.recordToDelete).subscribe(data => {
      if (data.success) {
        this.toastr.success("Course removed successfully!!", "Success")
        this.sortAndFilterRecords();
      }
    });
    this.modalRef.hide();
  }


  decline(): void {
    this.modalRef.hide();
  }

  ngOnInit() {
  }


  @ViewChild(MatMenuTrigger) ddTrigger: MatMenuTrigger;

  value: string;
  selectedValue: string;

  cancelClick(ev: MouseEvent) {
    ev.stopPropagation();
  }

  onCancel() {
    this.value = undefined;
    this.ddTrigger.closeMenu();
  }

  onSave() {
    this.selectedValue = this.value;
    this.value = undefined;
    this.ddTrigger.closeMenu();
  }
}
