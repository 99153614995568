export class CurriculumTreeRequestDTO {

  curriculumId: number;
  moduleId: number;
  moduleFolderId: number;
  secondaryModuleId: number;
  secondaryModuleFolderId: number;
  ttModuleId: number;
  ttModuleFolderId: number;
  requestType: String;

  constructor(curriculumId: number = null, moduleId: number = null, moduleFolderId: number = null, secondaryModuleId: number = null, secondaryModuleFolderId: number = null, ttModuleId: number = null, ttModuleFolderId: number = null, requestType: String = null) {
    this.curriculumId = curriculumId;
    this.moduleId = moduleId;
    this.moduleFolderId = moduleFolderId;
    this.secondaryModuleId = secondaryModuleId;
    this.secondaryModuleFolderId = secondaryModuleFolderId;
    this.ttModuleId = ttModuleId;
    this.ttModuleFolderId = ttModuleFolderId;
    this.requestType = requestType;
  }
}
