import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  UrlSegment,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import {UserCurriculumService} from "../../_services/userCurriculumService/user-curriculum.service";
import {AuthService} from "../../_services/authService/auth.service";

@Injectable({
  providedIn: 'root'
})
export class AdminGuard {
  constructor(
    private router: Router,
    private authService: AuthService
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot):  Promise<boolean>  {
    return new Promise((resolve) => {
      console.log("in admin guard");
      const currentUser = this.authService.getCurrentUserValue();
      if(!currentUser) {
        console.log("logged out and haven't been redirected to intro yet, handle it now");
        this.router.navigate(['intro'], {queryParams: {returnUrl: state.url}});
      }


      this.authService.isUserAdmin().subscribe(data => {
        if(data.isAdmin === 'true' || data.isAdmin === true){
            console.log("is admin");
            console.log(data.isAdmin);
            resolve(true);
        } else {
            console.log("is admin");
            console.log(data.isAdmin);
            this.router.navigate(['admin-restricted']);
            resolve(false);
        }
      });

      return resolve(false);

    });
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }
}
