import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Curriculum} from "../../../../../../types/Curriculum";
import {Module} from "../../../../../../types/Module";
import {FormControl, FormGroup} from "@angular/forms";
import {AdminCurriculumService} from "../../../../../../_services/admin/adminCurriculumService/admin-curriculum.service";
import {DisplayOrderUpdateDTO} from "../../../../../../types/DisplayOrderUpdateDTO";
import {SecondaryModule} from "../../../../../../types/SecondaryModule";
import {ModuleFile} from "../../../../../../types/ModuleFile";
import {IDDTO} from "../../../../../../types/IDDTO";

@Component({
  selector: 'app-admin-secondary-module-editor-selector',
  templateUrl:'admin-secondary-module-editor-selector.html',
  styleUrls: ['./admin-secondary-module-editor-selector.component.css']
})
export class AdminSecondaryModuleEditorSelectorComponent implements OnInit {

  _module : Module;

  _curriculum : Curriculum;

  _moduleFile : ModuleFile;

  mainSelectorIsLoaded : boolean = false;

  modules : SecondaryModule[];

  showConfirm : Map<string, boolean> = new Map<string, boolean>();

@Output() loadSecondaryModule=new EventEmitter<SecondaryModule>();

@Output() loadCreateNewSecondary=new EventEmitter<boolean>();

@Output() reloadSecondarySelector=new EventEmitter<boolean>();


  constructor(private curriculumService : AdminCurriculumService) { }

  ngOnInit() {

  }

@Input()
set module(module){
    console.log("AdminSecondaryModuleEditorSelectorComponent");
    this._module = module;
    console.log("_module");
    console.log(this._module);
    if(this._module) {
      this.curriculumService.getModuleSecondaryModules(Number(this._module.id)).subscribe(data => {
        this.showConfirm.set(module.id, false);
        console.log(data);
        this.modules = data;
        this.mainSelectorIsLoaded = true;
      });
    }

  }

@Input()
set curriculum(curriculum){
    this._curriculum = curriculum;
}

@Input()
set moduleFile(moduleFile){
  console.log("AdminSecondaryModuleEditorSelectorComponent");
    this._moduleFile = moduleFile;
    console.log("_moduleFile");
    console.log(this._moduleFile);
  if(this._moduleFile){
    this.curriculumService.getModuleFileSecondaryModules(Number(this._moduleFile.id)).subscribe(data => {
      console.log(data);

      this.modules = data;
      this.mainSelectorIsLoaded = true;
    });
  }
}

  loadSecondaryModuleEditor(module){
    console.log("Load module editor");
    console.log(module);
    this.loadSecondaryModule.emit(module);
  }


  createNewSecondaryModule(){
    this.loadCreateNewSecondary.emit(true);
  }

  deleteModule(module){
    this.showConfirm.set(module.id, true);
  }
  confirmedDelete(module){
    /*console.log("Load module editor");
    console.log(module);
    this.loadModule.emit(module);*/
    this.curriculumService.deleteSecondaryModule(new IDDTO(Number(module.id))).subscribe(data => {
      this.reloadSecondarySelector.emit(true);
    });
  }
}
