import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AccountUserDTO} from "../../../types/AccountUserDTO";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {UpdateUserDTO} from "../../../types/UpdateUserDTO";
import {CustomEmailValidator, RepeatEmailValidator, RepeatPasswordValidator} from "../../../helpers/validators";
import {RegistrationDTO} from "../../../types/RegistrationDTO";
import {AdminUserService} from "../../../_services/admin/adminUserService/admin-user.service";
import {RoleInfo} from "../../../types/RoleInfo";

@Component({
  selector: 'app-admin-user-editor',
  templateUrl: './admin-user-editor.html',
  styleUrls: ['./admin-user-editor.component.css']
})
export class AdminUserEditorComponent implements OnInit {

  _user : UpdateUserDTO;

  originalValues : UpdateUserDTO;

  callInProgress : boolean = false;

  callSuccess : boolean = false;
  callCompleted : boolean = false;
  errorMessage : string;
  @Output() userChange=new EventEmitter<UpdateUserDTO>();

  @Output() closeEditor = new EventEmitter<boolean>();



  userUpdateForm = this.fb.group({
    firstName:[''],
    lastName: [''],
    email: ['', CustomEmailValidator],
    emailConfirm: ['', CustomEmailValidator],
    password: [''],
    passwordConfirm: [''],
    school: ['']
  }, {validator: [RepeatPasswordValidator, RepeatEmailValidator]});

  constructor(private fb: FormBuilder, private userService : AdminUserService) { }

  ngOnInit() {
  }

  @Input()
  set user(user : UpdateUserDTO){
    this._user = user;

    if(this._user) {
      console.log("loading user");
      console.log(this._user);

      this.userUpdateForm.get('firstName').setValue(user.firstName);
      this.userUpdateForm.get('lastName').setValue(user.lastName);
      this.userUpdateForm.get('email').setValue(user.email);
      this.userUpdateForm.get('emailConfirm').setValue(user.email);
      this.userUpdateForm.get('school').setValue(user.school);

      this.originalValues = new UpdateUserDTO();
      this.originalValues.firstName = user.firstName;
      this.originalValues.lastName = user.lastName;
      this.originalValues.email = user.email;
      this.originalValues.school = user.school;

    }
  }

  editUserSubmit(){
    console.warn(this.userUpdateForm.value);
    console.log("in update profile submit function");

    let updateSendDTO : UpdateUserDTO = new UpdateUserDTO();

    if(this.originalValues.email != this.userUpdateForm.get('email').value){
      updateSendDTO.email = this.userUpdateForm.get('email').value;
    }

    if(this.originalValues.firstName != this.userUpdateForm.get('firstName').value){
      updateSendDTO.firstName = this.userUpdateForm.get('firstName').value;
    }

    if(this.originalValues.lastName != this.userUpdateForm.get('lastName').value){
      updateSendDTO.lastName = this.userUpdateForm.get('lastName').value;
    }

    if(this.userUpdateForm.get('password').value){
      updateSendDTO.password = this.userUpdateForm.get('password').value;
    }

    if(this.originalValues.school != this.userUpdateForm.get('school').value){
      updateSendDTO.school = this.userUpdateForm.get('school').value;
    }

    updateSendDTO.id = this._user.id;

    this.callInProgress = true;
    this.callCompleted = false;
    this.callSuccess = false;
    this.userService.updateUser(updateSendDTO).subscribe( data =>{
      console.log(data);
      this.callInProgress = false;
      if(data.success) {
        this.userUpdateForm.get('firstName').setValue(data.firstName);
        this.userUpdateForm.get('lastName').setValue(data.lastName);
        this.userUpdateForm.get('email').setValue(data.email);
        this.userUpdateForm.get('emailConfirm').setValue(data.email);
        this.userUpdateForm.get('school').setValue(data.school);

        this.setOriginalValues(data);

        this.userChange.emit(data);
        this.callSuccess = true;
      } else {
        this.errorMessage = data.errorMessage;
        this.callSuccess = false;
      }
      this.callCompleted = true;
      this.callInProgress = false;
    });
    //this.userChange.emit(updateSendDTO);
  }


  setOriginalValues(user){
    this.originalValues = new UpdateUserDTO();
    this.originalValues.firstName = user.firstName;
    this.originalValues.lastName = user.lastName;
    this.originalValues.email = user.email;
    this.originalValues.school = user.school;
  }
  resetForm(){

    this.userUpdateForm.get('firstName').setValue(this.originalValues.firstName);
    this.userUpdateForm.get('lastName').setValue(this.originalValues.lastName);
    this.userUpdateForm.get('email').setValue(this.originalValues.email);
    this.userUpdateForm.get('emailConfirm').setValue(this.originalValues.email);
    this.userUpdateForm.get('school').setValue(this.originalValues.school);
    this.userUpdateForm.get('password').setValue("");
    this.userUpdateForm.get('passwordConfirm').setValue("");

  }

  closeEditorEmit(){
    this.closeEditor.emit(true);
  }

  onSubmit(){

  }
}
