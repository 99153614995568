import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Curriculum} from "../../../../../../types/Curriculum";
import {FormBuilder} from "@angular/forms";
import {AdminCurriculumService} from "../../../../../../_services/admin/adminCurriculumService/admin-curriculum.service";
import {Module} from "../../../../../../types/Module";
import {CreateNewModuleDTO} from "../../../../../../types/CreateNewModuleDTO";
import {UpdateModuleDTO} from "../../../../../../types/UpdateModuleDTO";

@Component({
  selector: 'app-edit-module-form',
  templateUrl: `./edit-module-form.html`,
  styleUrls: ['./edit-module-form.component.css']
})
export class EditModuleFormComponent implements OnInit {

  _curriculum : Curriculum;
  _module : Module;

  @Input() tableType;

  editModule = this.fb.group({
    NAV_TEXT:[''],
    trialAccessible:false,
  }, {validator: []});

  successMessage : string;

  @Output() closeEditorEmit=new EventEmitter<true>();
  @Output() reloadEditorEmit=new EventEmitter<Module>();



  constructor(private fb: FormBuilder, private adminCurriculumService : AdminCurriculumService) { }

  ngOnInit() {

  }

  saveModule(){
    this.successMessage = null;
    let dto : UpdateModuleDTO = new UpdateModuleDTO(this.editModule.controls['NAV_TEXT'].value, Number(this._module.id),
      this.editModule.get('trialAccessible').value);
    console.log(dto);
    console.log(this._curriculum);
    if('MODULE' == this.tableType || this.tableType == null) {
      this.adminCurriculumService.updateModule(dto).subscribe(data => {
        console.log(data);
        if (data.success) {
          this.successMessage = "Changes saved";
        }
      });
    }

    if('TT_MODULE' == this.tableType){
      this.adminCurriculumService.updateTTModule(dto).subscribe(data => {
        console.log(data);
        if (data.success) {
          this.successMessage = "Changes saved";
        }
      });
    }

  }



  @Input()
  set curriculum(curriculum){
    this._curriculum = curriculum;
  }

  @Input()
  set module(module){
    console.log("set module in module editor");
    console.log(module);
    this._module = module;
    this.editModule.controls['NAV_TEXT'].setValue(this._module.navText);
    this.editModule.controls['trialAccessible'].setValue(this._module.trialAccessible);
  }


}
