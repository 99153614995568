export class OrganizationDTO {
  id: number;
  name: string;
  joinCode: string;
  credits: number;
  isActive: boolean;
  isActiveStr: string;
  isCreditsRequired: boolean;
  userCount: number;
  courseCount: number;
  managers: string;


  constructor(name: string, joinCode: string, credits: number,
              isActive: boolean, isCreditsRequired: boolean,
              userCount: number, courseCount: number, id?: number, managers?: string) {
    this.name = name;
    this.joinCode = joinCode;
    this.credits = credits;
    this.isActive = isActive;
    this.isActiveStr = (this.isActive ? "Yes" : "No");
    this.isCreditsRequired = isCreditsRequired;
    this.userCount = userCount;
    this.courseCount = courseCount;
    this.id = id;
    this.managers = managers;
  }
}
