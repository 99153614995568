import {LessonQuizSubmissionDTO} from "./LessonQuizSubmissionDTO";

export class LessonQuizSubmissionRequestDTO {

  lessonQuizSubmissionDTO: LessonQuizSubmissionDTO[];

  constructor(lessonQuizSubmissionDTO: LessonQuizSubmissionDTO[]) {
    this.lessonQuizSubmissionDTO = lessonQuizSubmissionDTO;
  }
}
