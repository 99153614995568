
import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  UrlSegment,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import {UserCurriculumService} from "../../_services/userCurriculumService/user-curriculum.service";
import {AuthService} from "../../_services/authService/auth.service";
import {AccountService} from "../../_services/accountService/account.service";

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordGuard {
  constructor(
    private router: Router,
    private authService: AuthService
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot):  Promise<boolean>  {
    return new Promise((resolve) => {
      console.log("in password reset guard");

      this.authService.isResetTokenValid(next.params.token).subscribe(data => {
        console.log(data);
        console.log(data.valid);

        if(data.valid === 'true' || data.valid === true){
          console.log("valid true");
          resolve(true);
        } else {
          console.log("valid false");
          this.router.navigateByUrl("/forgot-password/reset-error");
        }

      });
      return resolve(false);

    });
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }
}
