import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Curriculum} from "../../../types/Curriculum";
import {FormBuilder, FormControl} from "@angular/forms";
import {AdminCurriculumService} from "../../../_services/admin/adminCurriculumService/admin-curriculum.service";
import {NavEntry} from "../../../types/NavEntry";
import {NavEntryUpdateDTO} from "../../../types/NavEntryUpdateDTO";

@Component({
  selector: 'app-admin-nav-entry-editor-edit-nav-entry',
  templateUrl: './admin-nav-entry-editor-edit-nav-entry.html',
  styleUrls: ['./admin-nav-entry-editor-edit-nav-entry.component.css']
})
export class AdminNavEntryEditorEditNavEntryComponent implements OnInit {


  editNavEntry = this.fb.group({
    NAV_TEXT: [''],
  }, {validator: []});

  editorLoaded : boolean =  false;

  typedCurriculum : Array<Array<Curriculum>> = new Array<Array<Curriculum>>();

  @Output() closeNavEntryEditEmit=new EventEmitter<boolean>();


  @Output() reloadSelector=new EventEmitter<boolean>();

  @Input() inputNavEntry : NavEntry;
  constructor(private curriculumService : AdminCurriculumService, private fb: FormBuilder) { }

  ngOnInit() {

    this.editNavEntry.controls['NAV_TEXT'].setValue(this.inputNavEntry.navText);
    this.curriculumService.getUserCurriculums().subscribe(data => {
      let count : number = 0;
      data.forEach(curriculum => {
        let curriculumTyped = new Curriculum('true', '', curriculum.name,
          curriculum.navText, curriculum.bodyText, curriculum.id,
          curriculum.restricted + '', curriculum.bannerLink, curriculum.useHtml, curriculum.accessingViaTrial);
        if(count % 2 == 0 ){
          this.typedCurriculum.push(new Array<Curriculum>());
        }

        let temp : Array<Curriculum> = new Array<Curriculum>();
        temp = this.typedCurriculum.pop();
        temp.push(curriculumTyped);
        this.typedCurriculum.push(temp);
        count++;

        this.editNavEntry.addControl(curriculum.navText, new FormControl(false));
      });

      this.inputNavEntry.navEntryCurriculums.forEach(navEntryCurriculum => {
        this.editNavEntry.controls[navEntryCurriculum.curriculums.navText].setValue(true);
      });

      console.log(this.typedCurriculum);
      this.editorLoaded = true;
    });

  }


  saveNavEntry(){

    let idEnabledMap = new Map<number, boolean>();
    this.typedCurriculum.forEach(list => {
      list.forEach(curriculum => {
        idEnabledMap.set(Number(curriculum.id), this.editNavEntry.get(curriculum.navText).value);
      });
    });
    console.log(idEnabledMap);

    let updateDTO = new NavEntryUpdateDTO(this.inputNavEntry.id, this.editNavEntry.get('NAV_TEXT').value,
      this.inputNavEntry.displayOrder, idEnabledMap);

    this.curriculumService.saveNavEntry(updateDTO).subscribe(data => {
      console.log("create Save Nav Entry call has returned");
      this.closeNavEntryEditEmit.emit(true);
    });

  }

  closeNavEntry(){
    console.log("close nav entry");
    this.closeNavEntryEditEmit.emit(true);

  }

}
