import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AdminAccountUsersDTO} from "../../../types/AdminAccountUsersDTO";

@Component({
  selector: 'app-admin-account-editor-mid-container',
  templateUrl: './admin-account-editor-mid-container.html',
  styleUrls: ['./admin-account-editor-mid-container.component.css']
})
export class AdminAccountEditorMidContainerComponent implements OnInit {

  _adminAccountUsers : AdminAccountUsersDTO;

  licenseToggle : boolean = false;

  mergeToggle : boolean = false;

  @Output() closeEditor = new EventEmitter<boolean>();


  @Output() reloadEditor = new EventEmitter<boolean>();

  @Output() reloadEditorFocusLicense = new EventEmitter<boolean>();

  selected : number;

  constructor() { }

  ngOnInit() {
  }



  @Input()
  set adminAccountUser(adminAccountUsers : AdminAccountUsersDTO){
    this._adminAccountUsers = adminAccountUsers;

    if(this._adminAccountUsers) {
      /*console.log("loading user");
      console.log(this._user);

      this.userUpdateForm.get('firstName').setValue(user.firstName);
      this.userUpdateForm.get('lastName').setValue(user.lastName);
      this.userUpdateForm.get('email').setValue(user.email);
      this.userUpdateForm.get('emailConfirm').setValue(user.email);
      this.userUpdateForm.get('school').setValue(user.school);

      this.originalValues = new UpdateUserDTO();
      this.originalValues.firstName = user.firstName;
      this.originalValues.lastName = user.lastName;
      this.originalValues.email = user.email;
      this.originalValues.school = user.school;*/

    }
  }

  @Input()
  set index(index){
    this.selected = index;
  }
  onReloadEditor(reload){

  }

  back(){
    this.closeEditor.emit(true);
  }

  reloadEditorCall(){
    this.reloadEditor.emit(true);
  }

  reloadLicenseDisplay(data){
    this.licenseToggle = !this.licenseToggle;
    this.reloadEditorFocusLicense.emit(true);
  }

  setSelectedValue(value){
    this.selected = value;
  }

  reloadAll(data){
    this.reloadEditor.emit(true);
  }

  reloadMerge(data){
    this.mergeToggle = !this.mergeToggle;
  }
}
