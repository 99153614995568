import {AfterViewInit, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, Validators} from "@angular/forms";
import {LicenseService} from "../../../_services/adminLicenseService/license-service";
import {ToastrService} from "ngx-toastr";
import {CreditLicenseFilterDTO} from "../../../types/CreditLicenseFilterDTO";
import {PageEvent} from "@angular/material/paginator";
import {OrganizationFilterDTO} from "../../../types/OrganizationFilterDTO";
import {Sort} from "@angular/material/sort";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-e-admin-course-catalog',
  templateUrl: 'admin-license-catalog.html',
  styleUrls: ['admin-license-catalog.component.css']
})
export class eAdminAssignLicenseComponent implements OnInit {

  paginationLicenseList: any;
  pageEvent?: PageEvent;
  sort: Sort;
  recordToDelete: number;
  modalRef: BsModalRef;

  @ViewChild('confirmation')
  private confirmationTemplate: TemplateRef<any>;


  filterLicenses = this.fb.group({
    licenseKey: [''],
    oracleOrderNumber: [''],
    onlyUnUsed: ['']
  }, {validator: []});


  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private toastr: ToastrService,
              private licenseService: LicenseService,
              private modalService: BsModalService
  ) {

  }

  ngOnInit() {
    this.pageEvent = new PageEvent();
    this.pageEvent.pageIndex = 0;
    this.pageEvent.pageSize = 20;
    this.pageEvent.length = 0;
    this.findRecords(this.pageEvent);
  }

  findRecords(event?: PageEvent, licenseKey?: string, oracleOrderNumber?: string, unUsed?: boolean) {
    try {
      if (event != null) {
        this.pageEvent = event;
      }
      let sortColumn = this.sort == null ? "dateCreated" : this.sort.active;
      let sortOrder = this.sort == null ? "desc" : this.sort.direction;
      let creditLicenseFilterDTO = new CreditLicenseFilterDTO(sortColumn, sortOrder, 0, 100, licenseKey, oracleOrderNumber, unUsed);
      this.licenseService.findAll(creditLicenseFilterDTO).subscribe(data => {
        this.paginationLicenseList = data;
      });
    } catch (e) {
      alert(e);
    }
  }

  sortRecords(sort: Sort) {
    this.sort = sort;
    this.findRecords();
  }


  onSubmit() {
    let licenseKey = this.filterLicenses.get("licenseKey").value;
    let oracleOrderNumber = this.filterLicenses.get("oracleOrderNumber").value;
    let onlyUnUsed = this.filterLicenses.get("onlyUnUsed").value;

    licenseKey = (licenseKey == '') ? null : licenseKey;
    oracleOrderNumber = (oracleOrderNumber == '') ? null : oracleOrderNumber;
    onlyUnUsed = (onlyUnUsed == '') ? null : onlyUnUsed;

    this.findRecords(null, licenseKey, oracleOrderNumber, onlyUnUsed);
  }

  remove(index) {
    this.recordToDelete = index;
    this.modalRef = this.modalService.show(this.confirmationTemplate, {class: 'modal-sm'});
  }


  resetForm() {
    let creditLicenseFilterDTO = new CreditLicenseFilterDTO("dateCreated", "desc", 0, 100);
    this.licenseService.findAll(creditLicenseFilterDTO).subscribe(data => {
      this.paginationLicenseList = data;
    });
  }

  confirm(): void {
    this.licenseService.remove(this.recordToDelete).subscribe(data => {
      this.onSubmit();
    });
    this.modalRef.hide();
  }

  decline(): void {
    this.modalRef.hide();
  }
}
