import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {UserCurriculumService} from "../../_services/userCurriculumService/user-curriculum.service";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TtModuleFileResolver implements Resolve<any> {
  constructor(private curriculumService: UserCurriculumService) {
  }

  resolve(route: ActivatedRouteSnapshot) : Observable<any>{
    //console.log("in resolver");
    return this.curriculumService.getTTModuleFiles(route.params['moduleId']);
  }
}
