import {Component, Inject, OnInit} from '@angular/core';
import {AccountUserDTO} from "../../types/AccountUserDTO";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {AccountService} from "../../_services/accountService/account.service";
import {ErrorMessageService} from "../../_services/errorMessageService/error-message.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-accept-eula-dialog',
  templateUrl: './accept-eula-dialog.html',
  styleUrls: ['./accept-eula-dialog.component.css']
})
export class AcceptEulaDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<AcceptEulaDialogComponent>,
              public accountService : AccountService, public errorService: ErrorMessageService, private router: Router) { }

  ngOnInit() {
  }

  acceptEula(){
    this.accountService.acceptEula().subscribe( data => {
        console.log("accept eula call return");
        console.log(data);
        this.dialogRef.close();
    });
  }

}
