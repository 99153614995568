import {Component, Input, OnInit} from '@angular/core';
import {UserFilterDTO} from "../../types/UserFilterDTO";

@Component({
  selector: 'app-e-admin-manage-user',
  templateUrl: './manage-user.html',
  styleUrls: ['./manage-user.component.css']
})
export class eAdminManageUserComponent implements OnInit {

  userFilterDto_: UserFilterDTO;

  dataToFilter($event) {
    this.userFilterDto_ = $event;
  }

  constructor() {
  }

  ngOnInit() {
  }
}
