import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {FormArray, FormBuilder, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {ActivatedRoute, Router} from "@angular/router";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {CourseLessonContentDTO} from "../../../types/CourseLessonContentDTO";
import {CourseContentService} from "../../../_services/adminCourseService/course-content-service";
import {CourseTagDTO} from "../../../types/CourseTagDTO";
import {CourseCategoryDTO} from "../../../types/CourseCategoryDTO";
import {CourseLessonQuizDTO} from "../../../types/CourseLessonQuizDTO";
import {LessonQuizSubmissionRequestDTO} from "../../../types/LessonQuizSubmissionRequestDTO";
import {LessonQuizSubmissionDTO} from "../../../types/LessonQuizSubmissionDTO";
import {UserCourseService} from "../../../_services/userCourseService/user-course-service";
import Player from '@vimeo/player';

@Component({
  selector: 'app-e-course-details',
  templateUrl: './course-details.html',
  styleUrls: ['./course-details.component.css']
})
export class eCourseDetailsComponent implements OnInit {

  header: string = "Lesson Title";
  status: string;
  courseUrl: string;
  lessonId: string;

  nextLessonId: number;
  prevLessonId: number;
  nextLessonName: string;
  nextLessonNameMessage: string;
  prevLessonName: string;

  hasVideo: boolean = false;
  hasVideoFinished: boolean = false;

  canGotoNextLesson: boolean = false;

  answersPercentage: number = -1;

  courseLessonContents: CourseLessonContentDTO[];
  courseLessonQuizs: CourseLessonQuizDTO[];

  quizAnswerErrorMessage: string;
  quizAnswerSuccessMessage: string;

  modalRef: BsModalRef;

  @ViewChild('confirmation')
  private confirmationTemplate: TemplateRef<any>;

  courseTags: CourseTagDTO[];
  courseCategories: CourseCategoryDTO[];

  createCourseContentsForm = this.fb.group({
    courseContent: this.fb.array([
      this.fb.group({
        caption: [''],
        type: [''],
        file: [''],
        fileUrl: [''],
        id: [''],
        safeHtml: ['']
      })
    ])
  }, {validator: []});

  createCourseQuizsForm = this.fb.group({
    courseQuiz: this.fb.array([
      this.fb.group({
        question: [''],
        type: [''],
        answers: [''],
        answer: [null],
        error: [''],
        id: [''],
        className: ['']
      })
    ])
  }, {validator: []});


  constructor(private fb: FormBuilder,
              private courseContentService: CourseContentService,
              private userCourseService: UserCourseService,
              private toastr: ToastrService,
              private router: Router,
              private route: ActivatedRoute,
              private modalService: BsModalService,
              private sanitizer: DomSanitizer,
              private cd: ChangeDetectorRef) {

  }

  ngOnInit() {

    this.route.params.subscribe(routeParams => {
      this.lessonId = routeParams.lessonId;
      this.courseUrl = routeParams.courseUrl;

      this.canGotoNextLesson = false;
      this.answersPercentage = -1;
      this.hasVideo = false;
      this.hasVideoFinished = false;

      this.quizAnswerErrorMessage = '';
      this.quizAnswerSuccessMessage = '';

      if (typeof this.courseLessonContents !== 'undefined') {
        while (this.courseLessonContents.length) {
          this.courseLessonContents.pop();
        }
      }

      if (typeof this.courseLessonQuizs !== 'undefined') {
        while (this.courseLessonQuizs.length) {
          this.courseLessonQuizs.pop();
        }
      }


      this.createCourseContentsForm = this.fb.group({
        courseContent: this.fb.array([
          this.fb.group({
            caption: [''],
            type: [''],
            file: [''],
            fileUrl: [''],
            id: [''],
            safeHtml: ['']
          })
        ])
      }, {validator: []});

      this.createCourseQuizsForm = this.fb.group({
        courseQuiz: this.fb.array([
          this.fb.group({
            question: [''],
            type: [''],
            answers: [''],
            answer: [null],
            error: [''],
            id: [''],
            className: ['']
          })
        ])
      }, {validator: []});


      this.courseLessonQuizs = new Array();
      if (typeof this.lessonId !== 'undefined' && typeof this.courseUrl !== 'undefined') {
        this.userCourseService.findLessonContentsByCourseUrl(this.courseUrl, this.lessonId).subscribe(
          data => {
            this.header = data.lesson.title;
            this.status = data.lesson.status;
            this.courseLessonContents = data.lesson.lessonContents;
            if (this.status == 'FINISHED') {
              this.canGotoNextLesson = true;
            }

            for (let i = 0; i < this.courseLessonContents.length; i++) {
              this.addNewCourseContentData(this.courseLessonContents[i]);
            }
          }
        );


        this.userCourseService.findQuizContentsByCourseUrl(this.courseUrl, this.lessonId).subscribe(
          data => {
            this.header = data.lesson.title;
            this.courseLessonQuizs = data.lesson.lessonQuiz;
            for (let i = 0; i < this.courseLessonQuizs.length; i++) {
              try {
                this.addNewCourseQuizData(this.courseLessonQuizs[i]);
              } catch (e) {
                alert(e);
              }
            }
          }
        );


        try {
          this.userCourseService.getNextAndPreviousLesson(this.lessonId).subscribe(data => {
            this.prevLessonId = data.prevLessonId;
            this.nextLessonId = data.nextLessonId;
            this.prevLessonName = 'Previous Lesson : ' + data.prevLessonName;
            if (data.nextLessonName != null) {
              this.nextLessonNameMessage = 'Finish this lesson<br/><br/>Next Lesson : ' + data.nextLessonName;
              this.nextLessonName = data.nextLessonName;
            } else {
              this.nextLessonNameMessage = 'Finish Course';
            }

          });
        } catch (ex) {
          alert(ex);
        }
      }

      this.ngAfterViewInit();
    });
  }


  ngAfterViewInit(): void {
    let that = this;
    setTimeout(function () {
      try {
        let iframe = document.querySelector('iframe');
        console.log('is Iframe : ');
        console.log(iframe);
        if (typeof iframe !== 'undefined' && iframe != null) {
          if (iframe.getAttribute("src") != null) {
            if (iframe.getAttribute("src").indexOf('vimeo') > -1) {
              that.hasVideo = true;
              that.hasVideoFinished = false;

              const player = new Player(iframe);
              player.on('ended', function (data) {
                that.hasVideoFinished = true;
                that.cd.detectChanges();
              });
            } else {
              that.hasVideoFinished = true;
            }
          } else {
            that.hasVideoFinished = true;
          }
        } else {
          that.hasVideoFinished = true;
        }
      } catch (e) {
        alert(e);
      }
    }, 2000);
  }


  get courseContentFormArr(): FormArray {
    return this.createCourseContentsForm.get('courseContent') as FormArray;
  }

  get courseQuizFormArr(): FormArray {
    return this.createCourseQuizsForm.get('courseQuiz') as FormArray;
  }

  addNewCourseContentData(data: CourseLessonContentDTO) {
    this.courseContentFormArr.push(
      this.fb.group({
        caption: [data.caption],
        type: [data.type],
        file: [data.file],
        fileUrl: [this.sanitizer.bypassSecurityTrustResourceUrl('https://qa1.sparkfamily.org' + data.fileUrl)],
        id: [data.id],
        safeHtml: [this.sanitizer.bypassSecurityTrustHtml(data.caption)]
      }));
  }


  addNewCourseQuizData(data: CourseLessonQuizDTO) {
    if (data.answers != null) {
      let possibleAnswers = data.answers.split("\n");
      this.courseQuizFormArr.push(
        this.fb.group({
          question: [data.question],
          type: [data.questionType],
          answers: [possibleAnswers],
          answer: [null],
          error: [''],
          id: [data.id],
          className: ['']
        }));
    } else {
      this.courseQuizFormArr.push(
        this.fb.group({
          question: [data.question],
          type: [data.questionType],
          answers: [null],
          answer: [null],
          error: [''],
          id: [data.id],
          className: ['']
        }));
    }
  }

  gotoNextLesson(lessonId) {
    if (this.canGotoNextLesson) {
      this.router.navigateByUrl("/sparkecademy-catalog/courses/" + this.courseUrl + "/lessons/" + lessonId);
    } else {
      this.toastr.error("You need to complete the lesson first", "Error");
    }
  }

  gotoPrevLesson(lessonId) {
    this.router.navigateByUrl("/sparkecademy-catalog/courses/" + this.courseUrl + "/lessons/" + lessonId);
  }


  finishCurrentLesson() {
    if (!this.hasVideoFinished) {
      this.toastr.error("You need to complete the lesson first", "Error");
      return
    }


    if (this.courseQuizFormArr.length > 1) {

      if (this.answersPercentage == -1) {
        this.toastr.error("Please answer all questions", "Error");
        return
      }

      if (this.answersPercentage < 80) {
        this.toastr.error("Please re-check answers", "Error");
        return
      }
    }

    this.userCourseService.finishCurrentLesson(this.lessonId).subscribe(data => {
      if (data.success) {
        this.canGotoNextLesson = true;
        if (this.nextLessonId == null) {
          this.toastr.success("You have successfully completed this course!!");
          this.router.navigateByUrl("/sparkecademy-catalog/courses/" + this.courseUrl);
        } else {
          this.gotoNextLesson(this.nextLessonId);
        }
      }
    });
  }

  submitAnswers() {
    try {
      this.quizAnswerErrorMessage = this.quizAnswerSuccessMessage = '';
      let answersToQuestion = new Array();
      let isValidated = true;
      for (let i = 0; i < this.courseQuizFormArr.length; i++) {
        let id = this.courseQuizFormArr.at(i).get("id").value;
        let answer = this.courseQuizFormArr.at(i).get("answer").value;

        if (id != '') {
          if (answer == null) {
            isValidated = false;
            this.courseQuizFormArr.at(i).get("error").setValue("Please answer this question");
          } else {
            answersToQuestion.push(new LessonQuizSubmissionDTO(id, answer));
            this.courseQuizFormArr.at(i).get("error").setValue("");
          }
        }
      }

      if (isValidated) {

        let request = new LessonQuizSubmissionRequestDTO(answersToQuestion);
        this.userCourseService.submitQuizAnswers(request).subscribe(data => {
          let wrongAnswers = data.wrongAnswers;
          this.answersPercentage = parseFloat(data.scoring);

          this.courseQuizFormArr.controls.filter(e => wrongAnswers.indexOf(e.get("id").value) == -1).forEach(e => {
            e.get("className").setValue("correct-answer");
          });

          for (let i = 0; i < wrongAnswers.length; i++) {
            this.courseQuizFormArr.controls.filter(e => e.get("id").value == wrongAnswers[i])[0].get("className").setValue("incorrect-answer")
          }
          if (this.answersPercentage < 80) {
            this.quizAnswerErrorMessage = 'Your scoring : ' + this.answersPercentage + '. You must achieve at least 80% scoring';
          } else {
            this.quizAnswerSuccessMessage = 'Great!! You have cleared this quiz';
          }
        });
      }

    } catch (e) {
      alert(e);
    }
  }
}
