import {AccountUserDTO} from "./AccountUserDTO";

class List {
}

export class AdminAccountUsersDTO {

  schoolUserMap : Map<String, Array<AccountUserDTO>> = null;

  id : number;

  constructor(id : number, schoolUserMap : Map<String, Array<AccountUserDTO>>){
    this.id = id;
    this.schoolUserMap = schoolUserMap;
  }
}
