import {Injectable} from "@angular/core";
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {AuthService} from "../../_services/authService/auth.service";
import {Observable, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {User} from "../../types/User";
import {AdminAuthService} from "../../_services/admin/adminAuthService/admin-auth.service";


@Injectable()
export class JwtInterceptor implements HttpInterceptor{

  constructor(private authService: AuthService, private adminAuthService : AdminAuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log("in jwt token interceptor");
    const testCloned = request.clone();
    let currentUser : User;
    if(!this.adminAuthService.useAdminUser()) {
      if (testCloned.url.includes('register')) {
        currentUser = this.authService.getCurrentUserValueNoLogout();
      } else {
        currentUser = this.authService.getCurrentUserValue();
      }
    } else {
      currentUser = this.adminAuthService.getCurrentUserValue();
    }
    if(currentUser && currentUser.token){
      const cloned = request.clone({
        headers: request.headers.set("Authorization",
          "Bearer " + currentUser.token)
      });
      console.log("jwt intercept url");
      console.log(cloned.url);
      console.log(cloned.body);

      /*return next.handle(cloned).pipe(catchError(err => {
        console.log("jwt filter error hanlders");
        console.log("error");
        console.log(err);
        console.log("error");
        if (err.status === 401) {
          // auto logout if 401 response returned from api
          this.authService.logout();
          location.reload(true);
        }*/

      return next.handle(cloned).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.error instanceof ErrorEvent) {
            // client-side error or network error
            console.log("jwt interceptor client error");
            console.log(error);
          } else {
            console.log("in catch error else");
            console.log(error);
            if (error.status === 401) {
              this.authService.logout(true);
            }
          }
          return throwError(error);
        })
      );
    }else if(testCloned.url.includes('register')){
      return next.handle(testCloned)
    }else
    {  //should redirect to login
      return next.handle(request);
    }

  }

}


