export class OnSiteCourseDTO {

  validFrom: string;
  validTo: string;
  courseDate: string;
  enrollmentAllowed: number;
  courseId: string;
  liveCourseId: number;


  constructor(validFrom: string,
              validTo: string,
              courseDate: string,
              enrollmentAllowed: number,
              courseId: string,
              liveCourseId?: number) {
    this.validFrom = validFrom;
    this.validTo = validTo;
    this.courseDate = courseDate;
    this.enrollmentAllowed = enrollmentAllowed;
    this.courseId = courseId;
    this.liveCourseId = liveCourseId;
  }
}
