import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {
  EmailValidation,
  PasswordValidation,
  RepeatEmailValidator,
  RepeatPasswordValidator, CheckboxCount
} from "../../helpers/validators";
import {AccountService} from "../../_services/accountService/account.service";
import {AuthService} from "../../_services/authService/auth.service";
import {ErrorMessageService} from "../../_services/errorMessageService/error-message.service";
import {ActivatedRoute, Router} from "@angular/router";
import {RegistrationDTO} from "../../types/RegistrationDTO";
import {TrialRegistrationDTO} from "../../types/TrialRegistrationDTO";

@Component({
  selector: 'app-free-trial',
  templateUrl: './free-trial.html',
  styleUrls: ['./free-trial.component.css']
})
export class FreeTrialComponent implements OnInit {


  registrationForm = this.fb.group({
    firstName:['', Validators.required],
    lastName: ['', Validators.required],
    email: ['', EmailValidation],
    emailConfirm: ['', EmailValidation],
    password: ['', PasswordValidation],
    passwordConfirm: [''],
    school: ['', Validators.required],
    K2PE: false,
    PE36:false,
    MSPE:false,
    HSPE:false,
    ABCCA:false,
    AS:false,
    EC:false
  }, {validator: [RepeatPasswordValidator, RepeatEmailValidator, CheckboxCount]});



  constructor(private fb: FormBuilder, private accountService: AccountService, private authService: AuthService, private errorMessageService: ErrorMessageService,
              private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {

  }
/*

  threeOrLessChecked(){
    let count:number = 0;
    if(this.registrationForm.get('K2PE').value){
      count++;
    }

    if(this.registrationForm.get('PE36').value){
      count++;
    }

    if(this.registrationForm.get('MSPE').value){
      count++;
    }

    if(this.registrationForm.get('HSPE').value){
      count++;
    }

    if(this.registrationForm.get('ABCCA').value){
      count++;
    }

    if(this.registrationForm.get('AS').value){
      count++;
    }

    if(this.registrationForm.get('EC').value){
      count++;
    }

    console.log("three or less checked");
    console.log(count >=1 && count <=3);
    return count >= 1 && count <= 3;
  }
*/

  onSubmit() {
    let regInfo: TrialRegistrationDTO;

    let curriculums: String;

    curriculums="";

    if (this.registrationForm.get('K2PE').value) {
      curriculums += 'K2PE,';
    }

    if (this.registrationForm.get('PE36').value) {
      curriculums += 'PE36,';
    }

    if (this.registrationForm.get('MSPE').value) {
      curriculums += 'MSPE,';
    }

    if (this.registrationForm.get('HSPE').value) {
      curriculums += 'HSPE,';
    }

    if (this.registrationForm.get('ABCCA').value) {
      curriculums += 'ABCCA,';
    }

    if (this.registrationForm.get('AS').value) {
      curriculums += 'AS,';
    }

    if (this.registrationForm.get('EC').value) {
      curriculums += 'EC,';
    }

    curriculums = curriculums.slice(0, -1);

    // TODO: Use EventEmitter with form value
    console.warn(this.registrationForm.value);
    regInfo = new TrialRegistrationDTO(this.registrationForm.get('email').value, this.registrationForm.get('firstName').value, this.registrationForm.get('lastName').value, this.registrationForm.get('password').value, this.registrationForm.get('school').value, '', curriculums);
    this.errorMessageService.clear();
    this.accountService.registerTrialAccount(regInfo).subscribe(
      data => {
        if (data.success + '' === 'true') {
          console.log("good signup info");
          this.authService.login(regInfo.email, regInfo.password).subscribe();
        } else {
          console.log("bad signup info");
          this.errorMessageService.add(data.error);
        }
      }
    );
  }

}
