import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  UrlSegment,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import {UserCurriculumService} from "../../_services/userCurriculumService/user-curriculum.service";
import {AuthService} from "../../_services/authService/auth.service";

@Injectable({
  providedIn: 'root'
})
export class RestrictedCurriculumGuard {
  constructor(
    private router: Router,
    private authService: AuthService,
    private userCurriculumService: UserCurriculumService
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot):  Promise<boolean>  {
  return new Promise((resolve) => {
  console.log("in restricted curriculum guard");
  const currentUser = this.authService.getCurrentUserValue();
  if(!currentUser) {
    console.log("logged out and haven't been redirected to intro yet, handle it now");
    this.router.navigate(['intro'], {queryParams: {returnUrl: state.url}});
  }

  console.log(next.params);
  console.log(next.params.id);
  this.userCurriculumService.isCurriculumRestricted(next.params.id).subscribe(data => {
    console.log(data);
    console.log(data.isRestricted);
    let restricted = data.isRestricted == 'true' ? true : false;
    if(restricted){
      this.router.navigate(['restrictedcurriculum/' + next.params.id]);
    } else {
      resolve(true);
    }
  });
  return resolve(false);

  });
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }
}
