import { Component, OnInit } from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {UserCurriculumService} from "../../_services/userCurriculumService/user-curriculum.service";
import {HomePageEditDTO} from "../../types/HomePageEditDTO";
import {AdminCurriculumService} from "../../_services/admin/adminCurriculumService/admin-curriculum.service";

@Component({
  selector: 'app-home-page-editor',
  templateUrl: './home-page-editor.html',
  styleUrls: ['./home-page-editor.component.css']
})
export class HomePageEditorComponent implements OnInit {

  editHomepage = this.fb.group({
    ABOUT:[''],
    WHAT:[''],
    NEW:[''],
    ABOUT_ADMIN:[''],
    WHAT_ADMIN:[''],
    NEW_ADMIN:[''],
  }, {validator: []});


  isLoaded :boolean = false;

  success :boolean = false;

  constructor(private fb: FormBuilder, private userService : AdminCurriculumService) { }

  ngOnInit() {
    this.userService.getHomePageContent().subscribe( data =>{
      console.log(data);
      Object.keys(data).forEach(key => {
          console.log(data[key]);
            this.editHomepage.controls[key].setValue(data[key].columnContent);
        }
      );
      this.isLoaded = true;
    });

  }

  save(){
    this.success = false;
    let dto : HomePageEditDTO = new HomePageEditDTO();
    dto.about= this.editHomepage.controls['ABOUT'].value;
    dto.what= this.editHomepage.controls['WHAT'].value;
    dto.newContent= this.editHomepage.controls['NEW'].value;
    dto.aboutAdmin= this.editHomepage.controls['ABOUT_ADMIN'].value;
    dto.whatAdmin= this.editHomepage.controls['WHAT_ADMIN'].value;
    dto.newAdmin= this.editHomepage.controls['NEW_ADMIN'].value;
    console.log(this.editHomepage);
    console.log(this.editHomepage.controls['NEW_ADMIN']);
    console.log(this.editHomepage.controls['NEW_ADMIN'].value);
    console.log(dto);
    this.userService.updateHomePageContent(dto).subscribe(data =>{
      this.success = true;
    });
    console.log(dto);
  }

}
