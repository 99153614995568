import { Component, OnInit } from '@angular/core';
import {NavEntry} from "../../types/NavEntry";

@Component({
  selector: 'app-admin-nav-entry-editor-top',
  templateUrl: './admin-nav-entry-editor-top.html',
  styleUrls: ['./admin-nav-entry-editor-top.component.css']
})
export class AdminNavEntryEditorTopComponent implements OnInit {

  editorLoaded : boolean = false;

  createNewLoaded : boolean = false;

  editorCurriculumOrderLoaded : boolean = false;

  selectorToggle : boolean = false;

  editorToggle : boolean = false;

  createNewToggle : boolean = false;

  editorCurriculumOrderToggle : boolean = false;

  loadNavObject : NavEntry;


  constructor() { }

  ngOnInit() {
  }


  loadNavEntry(curriculum){
    console.log("loadNavEntry()");
    console.log(curriculum);
    this.editorLoaded = true;
    this.createNewLoaded = false;
    this.loadNavObject = curriculum;
    console.log("load nav entry obj");
    console.log(this.loadNavObject);
  }

  reloadSelector(){
    this.selectorToggle = !this.selectorToggle;
  }

  loadCreateNewNavEntry(){
    this.editorLoaded = false;
    this.createNewLoaded = true;
    console.log(this.editorLoaded);
    console.log(this.createNewLoaded);
    console.log(this.createNewToggle);
  }

  loadNavEntryOrderEditor(obj){
    this.editorLoaded = false;
    this.editorCurriculumOrderLoaded = true;
    this.createNewLoaded = false;
    this.loadNavObject = obj;
    console.log(this.editorLoaded);
    console.log(this.createNewLoaded);
    console.log(this.createNewToggle);
  }

  closeCreateNewNavEntry(){
    console.log("in close create new nav entry");
    this.editorLoaded = false;
    this.createNewLoaded = false;
    this.selectorToggle = !this.selectorToggle;
    this.createNewToggle = !this.createNewToggle;

  }

  closeNavCurriculumDisplayOrderEdit(){
    console.log("in close create new nav entry");
    this.editorLoaded = false;
    this.createNewLoaded = false;
    this.editorCurriculumOrderLoaded = false;
    this.editorToggle = !this.editorToggle;
    this.selectorToggle = !this.selectorToggle;

  }

  closeCreateEditNavEntry(){
    console.log("in close create new nav entry");
    this.editorLoaded = false;
    this.createNewLoaded = false;
    this.editorCurriculumOrderToggle = !this.editorCurriculumOrderToggle;
    this.selectorToggle = !this.selectorToggle;
  }


}
