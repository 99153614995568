import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {CourseService} from "../../../_services/adminCourseService/course-service";
import {ToastrService} from "ngx-toastr";
import {ActivatedRoute, Router} from "@angular/router";
import {CourseDTO} from "../../../types/CourseDTO";
import {CourseCategoryDTO} from "../../../types/CourseCategoryDTO";
import {CourseCategoryService} from "../../../_services/adminCourseService/course-category-service";
import {CourseTagDTO} from "../../../types/CourseTagDTO";

@Component({
  selector: 'app-e-admin-create-course',
  templateUrl: './create-course.html',
  styleUrls: ['./create-course.component.css']
})
export class eAdminCreateCourseComponent implements OnInit {

  header: string = "Add New Course";
  courseId: string;
  courseCategories: CourseCategoryDTO[];
  courseTags: CourseTagDTO[];

  createCourseForm = this.fb.group({
    title: ['', Validators.required],
    subTitle: [''],
    code: ['', Validators.required],
    description: [''],
    categoryId: ['', Validators.required],
    cost: [''],
    length: [''],
    isOnsite: [false],
    tagId: ['', Validators.required]
  }, {validator: []});

  constructor(private fb: FormBuilder,
              private courseService: CourseService,
              private courseCategoryService: CourseCategoryService,
              private toastr: ToastrService,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.courseId = this.route.snapshot.paramMap.get('id');
    if (this.courseId != null) {
      this.header = "Edit Course";
      this.loadData(this.courseId);
    }
    this.loadCategoriesAndTags();
  }

  loadData(courseId) {
    this.courseId = courseId;
    this.courseService.get(this.courseId).subscribe(data => {
      this.createCourseForm.get("title").setValue(data.title);
      this.createCourseForm.get("subTitle").setValue(data.subTitle);
      this.createCourseForm.get("code").setValue(data.code);
      this.createCourseForm.get("description").setValue(data.description);
      this.createCourseForm.get("categoryId").setValue(data.categoryId == null ? null : data.categoryId);
      this.createCourseForm.get("cost").setValue(data.cost);
      this.createCourseForm.get("length").setValue(data.length);
      this.createCourseForm.get("tagId").setValue(data.tagId == null ? null : data.tagId);
      this.createCourseForm.get("isOnsite").setValue(data.isOnsite == null ? null : data.isOnsite);
    });
  }

  loadCategoriesAndTags() {
    this.courseCategoryService.getAllCourseCategories().subscribe(data => {
      this.courseCategories = data;
    });

    this.courseCategoryService.getAllCourseTags().subscribe(data => {
      this.courseTags = data;
    });
  }

  onSubmit() {
    let courseDTO = new CourseDTO(
      this.getField("title"),
      this.getField("subTitle"),
      this.getField("description"),
      this.getField("length"),
      this.getField("cost"),
      this.getField("tagId"),
      this.getField("code"),
      this.getField("categoryId"),
      this.getField("isOnsite")
    );


    if (this.courseId == null) {
      this.courseService.add(courseDTO).subscribe(data => {
        if (!data.success) {
          this.toastr.error(data.errorMessage, "Error");
        }
        if (data.success) {
          this.toastr.success("Success", "Course created successfully!!")
          this.router.navigateByUrl('/sparkecademy-admin-course');
        }
      });
    } else {
      this.courseService.edit(this.courseId, courseDTO).subscribe(data => {
        if (data.success) {
          this.toastr.success("Success", "Course updated successfully!!")
          this.router.navigateByUrl('/sparkecademy-admin-course');
        }
      });
    }
  }

  reset() {
    this.router.navigateByUrl('/sparkecademy-admin-course');
  }

  getField(fieldName: string) {
    return this.createCourseForm.get(fieldName).value;
  }
}
