import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {ActivatedRoute, Router} from "@angular/router";
import {OrganizationService} from "../../../_services/adminOrganizationService/organization-service";
import {OrganizationDTO} from "../../../types/OrganizationDTO";
import {AuthService} from "../../../../_services/authService/auth.service";

@Component({
  selector: 'app-e-admin-create-organization',
  templateUrl: './dashboard-organization.html',
  styleUrls: ['./dashboard-organization.component.css']
})
export class eAdminDashboardOrganizationComponent implements OnInit {

  organizationId: string;
  organizationDTO: OrganizationDTO;
  memberCourses: any;
  coursesInProgress: any;
  completedCourses: any;

  constructor(private fb: FormBuilder,
              private organizationService: OrganizationService,
              private toastr: ToastrService,
              private router: Router,
              private authService: AuthService,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.organizationId = this.route.snapshot.paramMap.get('id');
    if (this.organizationId != null) {
      this.loadData(this.organizationId);
    }
  }

  loadData(organizationId) {
    this.organizationService.get(organizationId).subscribe(data => {
      let name = data.name;
      let isCreditsRequired = data.isCreditsRequired;
      let credits = data.credits;
      let isActive = data.isActive;
      let joinCode = data.joinCode;
      let userCount = data.userCount;
      let courseCount = data.courseCount;
      let managers = data.managers;
      let id = data.id;
      this.organizationDTO = new OrganizationDTO(name, joinCode, credits, isActive, isCreditsRequired, userCount, courseCount, id, managers);
    });


    this.organizationService.getMemberCourses(this.organizationId).subscribe(data => {
      this.memberCourses = data.courseLessonContents;
      this.coursesInProgress = this.memberCourses.filter(course => course.dateCompleted === null);
      this.completedCourses = this.memberCourses.filter(course => course.dateCompleted !== null);

    });
  }

}
