import { Component, OnInit } from '@angular/core';

export class SelfRegistrationDTO {
  email: string;
  firstName: string;
  lastName: string;
  school: string
  address: string;
  city: string;
  state: string;
  zip: string;
  expDate: string;
  types:  Array<string>;
  constructor(email: string, firstName: string, lastName: string, school:string,
              expDate: string, address: string, city:string, state: string, zip:string,
              types: Array<string>){
    this.email = email;
    this.firstName= firstName;
    this.lastName = lastName;
    this.school = school;
    this.expDate = expDate;
    this.address = address;
    this.city = city;
    this.state = state;
    this.zip = zip;
    this.types = types;
  }


}
