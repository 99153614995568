import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {AdminCurriculumService} from "../../../../../../_services/admin/adminCurriculumService/admin-curriculum.service";
import {ModuleFile} from "../../../../../../types/ModuleFile";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Component({
  selector: 'app-edit-module-file',
  templateUrl: './edit-module-file.html',
  styleUrls: ['./edit-module-file.component.css']
})
export class EditModuleFileComponent implements OnInit {


  editModuleFile = this.fb.group({
    NAV_TEXT:[''],
    TYPE: '',
    tags: '',
    file:[null],
    trialAccessible:false,
    videoEmbed:['']
  }, {validator: []});

  _moduleFile : ModuleFile;

  _moduleFileType : string;

  types: object[];


  editorLoaded : boolean = false;

  safeHtml: SafeHtml;

  @Output() closeEditModuleFileEmit=new EventEmitter<boolean>();




  callReturned : boolean = false;
  message : string = null;


  constructor(private fb: FormBuilder, private adminCurriculumService : AdminCurriculumService, private sanitizer: DomSanitizer) {}

  ngOnInit(){
  }

  @Input()
  set moduleFile(moduleFile){
    console.log("set module file test log");
    this._moduleFile = moduleFile;

    if(this._moduleFile){
      console.log(this._moduleFile);
      this.types = [{name: 'PDF', checked: (!this._moduleFile.video && !this._moduleFile.music
          && !this._moduleFile.downloadable && !this._moduleFile.folder)},
        {name: 'VIDEO', checked:this._moduleFile.video},
        {name: 'MUSIC', checked:this._moduleFile.music}, {name: 'DOWNLOADABLE', checked:this._moduleFile.downloadable},
        {name: 'FOLDER', checked:this._moduleFile.folder}];


      if(this._moduleFile.video){
        //this.editModuleFile
        this.editModuleFile.get('TYPE').setValue('VIDEO');
      }
      if(this._moduleFile.music){
        this.editModuleFile.get('TYPE').setValue('MUSIC');
      }
      if(this._moduleFile.downloadable){
        this.editModuleFile.get('TYPE').setValue('DOWNLOADABLE');
      }
      if(this._moduleFile.folder){
        this.editModuleFile.get('TYPE').setValue('FOLDER');
      }
      if(this._moduleFile.trialAccessible){
        this.editModuleFile.get('trialAccessible').setValue(this._moduleFile.trialAccessible);
      }
      if(!this._moduleFile.video && !this._moduleFile.music
        && !this._moduleFile.downloadable && !this._moduleFile.folder){
        this.editModuleFile.get('TYPE').setValue('PDF');
      }
      console.log(this.editModuleFile);
      console.log("");
      if(this._moduleFile.video) {
        this.editModuleFile.controls['videoEmbed'].setValue(this._moduleFile.fileName);
        this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(moduleFile.fileName);
      }
      this.editModuleFile.controls['NAV_TEXT'].setValue(this._moduleFile.navText);
      this.editModuleFile.controls['tags'].setValue(this._moduleFile.tags);

      this.editorLoaded = true;
    }
  }

  @Input()
  set moduleFileType(moduleFileType){
    this._moduleFileType= moduleFileType;
  }


  onFileChange(event) {
    console.log("onfilechange");
    if(event.target.files.length > 0) {
      let file = event.target.files[0];
      this.editModuleFile.get('file').setValue(file);
      console.log(this.editModuleFile);
    }
  }


  prepareSave() :any{

    /* let dto : CreateNewModuleDTO = new CreateNewModuleDTO(this.createNewModule.controls['NAV_TEXT'].value, Number(this._curriculum.id));
     console.log(dto);
     console.log(this._curriculum);
     console.log(this.createNewModule);
     this.adminCurriculumService.createNewModule(dto).subscribe(data =>{
       this.newModuleFileCreated.emit(true);
     });*/
    console.log("prepare save");
    console.log(this.editModuleFile);

    let input = new FormData();
    input.append('NAV_TEXT', this.editModuleFile.get('NAV_TEXT').value);
    input.append('tags', this.editModuleFile.get('tags').value);
    input.append('TYPE', this.editModuleFile.get('TYPE').value);
    input.append('trialAccessible', this.editModuleFile.get('trialAccessible').value);

    if(this.editModuleFile.get('videoEmbed').value) {
      input.append('videoEmbed', this.editModuleFile.get('videoEmbed').value);
    }
    if(this.editModuleFile.get('file').value) {
      input.append('FILE', this.editModuleFile.get('file').value);
    }
    input.append('ID', this._moduleFile.id + '');
    input.append('TABLE_TYPE', this._moduleFileType);
    console.log(input.getAll('NAV_TEXT'));
    console.log(input.getAll('tags'));
    console.log(input.getAll('TABLE_TYPE'));
    console.log(input.getAll('videoEmbed'));
    console.log(input.getAll('FILE'));
    console.log(input.getAll('TYPE'));
    console.log(input.getAll('trialAccessible'));
    return input;

  }


  saveEditModuleFile(){
    this.callReturned = false;
    this.message = null;

      this.adminCurriculumService.updateModuleFile(this.prepareSave()).subscribe(data => {
        console.log(data);
        if (data.success) {
          this.message = "Changes saved";
          this.newModuleFileCreated();
        } else {
          this.message = data.errorMessage;
        }

        this.callReturned = true;
      });

  }

  closeEditModuleFile(){
    this.closeEditModuleFileEmit.emit(true);
  }


  newModuleFileCreated(){
    this.closeEditModuleFileEmit.emit(true);
  }

}
