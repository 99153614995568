import {UserRoleInfo} from "./UserRoleInfo";
import {last} from "rxjs/operators";

export class CreateNewSecondaryModuleDTO {

  curriculumId : number;
  type : string;
  parentType : string;
  parentId : string;
  url : string;
  constructor(curriculumId : number, type: string, parentType: string, parentId : string, url : string){
    this.curriculumId = curriculumId;
    this.type = type;
    this.url = url;
    this.parentId = parentId;
    this.parentType = parentType;
  }

}
