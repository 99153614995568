import {UserRoleInfo} from "./UserRoleInfo";
import {last} from "rxjs/operators";

export class UpdateModuleDTO {

  navText : string;
  moduleId : number;
  trialAccessible : boolean;

  constructor(navText : string, moduleId : number, trialAccessible : boolean){
    this.navText = navText;
    this.moduleId = moduleId;
    this.trialAccessible = trialAccessible;
  }

}
