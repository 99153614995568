import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {AdminCurriculumService} from "../../../_services/admin/adminCurriculumService/admin-curriculum.service";
import {Curriculum} from "../../../types/Curriculum";
import {EmailValidation} from "../../../helpers/validators";
import {NavEntryUpdateDTO} from "../../../types/NavEntryUpdateDTO";

@Component({
  selector: 'app-admin-nav-entry-editor-create-new',
  templateUrl: './admin-nav-entry-editor-create-new.html',
  styleUrls: ['./admin-nav-entry-editor-create-new.component.css']
})
export class AdminNavEntryEditorCreateNewComponent implements OnInit {



  createNewNavEntry = this.fb.group({
    NAV_TEXT: [''],
  }, {validator: []});

  createNewLoaded : boolean =  false;

  typedCurriculum : Array<Array<Curriculum>> = new Array<Array<Curriculum>>();

  @Output() closeNewNavEntryEmit=new EventEmitter<boolean>();

  constructor(private curriculumService : AdminCurriculumService, private fb: FormBuilder) { }

  ngOnInit() {
    this.curriculumService.getUserCurriculums().subscribe(data => {
        let count : number = 0;
        data.forEach(curriculum => {
          let curriculumTyped = new Curriculum('true', '', curriculum.name,
            curriculum.navText, curriculum.bodyText, curriculum.id,
            curriculum.restricted + '', curriculum.bannerLink, curriculum.useHtml, curriculum.accessingViaTrial);
            if(count % 2 == 0 ){
              this.typedCurriculum.push(new Array<Curriculum>());
            }

            let temp : Array<Curriculum> = new Array<Curriculum>();
            temp = this.typedCurriculum.pop();
            temp.push(curriculumTyped);
            this.typedCurriculum.push(temp);
            count++;

          this.createNewNavEntry.addControl(curriculum.navText, new FormControl(false));
        });

        console.log(this.typedCurriculum);
        this.createNewLoaded = true;
    });

  }

  saveNewNavEntry(){

    let idEnabledMap = new Map<number, boolean>();
    this.typedCurriculum.forEach(list => {
      list.forEach(curriculum => {
        idEnabledMap.set(Number(curriculum.id), this.createNewNavEntry.get(curriculum.navText).value);
      });
    });
    console.log(idEnabledMap);

    let updateDTO = new NavEntryUpdateDTO(null, this.createNewNavEntry.get('NAV_TEXT').value,
      null, idEnabledMap);

      this.curriculumService.createNewNavEntry(updateDTO).subscribe(data => {
        console.log("create New Nav Entry call has returned");
        this.closeNewNavEntryEmit.emit(true);
      });

  }

  closeNewNavEntry(){
    this.closeNewNavEntryEmit.emit(true);

  }
}
