export class UserRoleInfo{d

  roleName : string;
  roleType : string;
  roleId : number;
  shouldDisplay : string;
  hasRole : string;

  constructor (roleName : string, roleType : string, roleId : number, hasRole : string){
    this.roleName = roleName;
    this.roleType = roleType;
    this.roleId = roleId;
    this.hasRole = hasRole;
  }
}
