import {Component, Input, OnInit} from '@angular/core';
import {AdminUserService} from "../../../_services/admin/adminUserService/admin-user.service";
import {AdminCurriculumService} from "../../../_services/admin/adminCurriculumService/admin-curriculum.service";

@Component({
  selector: 'app-e-admin-dashboard',
  templateUrl: './dashboard.html',
  styleUrls: ['./dashboard.component.css']
})
export class eAdminDashboardComponent implements OnInit {
  @Input() activePage: string;


  licenseInfo: boolean = false;
  userManagement: boolean = false;
  updateProfile: boolean = false;

  summary: any;

  constructor(private adminUserService: AdminUserService,
              private adminCurriculumService: AdminCurriculumService) {
  }

  ngOnInit() {
    this.adminUserService.dashboardSummary().subscribe(data => {
      this.summary = data;
    });
  }
}
