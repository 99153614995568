export class Curriculum {
  success : boolean;
  error : string;

  name: string;
  navText: string;
  bodyText: string;
  id: string;
  restricted: boolean;
  bannerLink : string;
  useHtml : boolean;

  accessingViaTrial: boolean;
  constructor(success: string , error: string, name: string, navText: string, bodyText : string, id: string, restricted: string, bannerLink: string, useHtml: boolean, accessingViaTrial:boolean){
    console.log('in constructor');
    this.success = success == 'true' ? true : false;
    this.error = error;

    this.name = name;
    this.navText = navText;
    this.bodyText = bodyText;
    this.id = id;
    this.restricted = restricted == 'true' ? true : false;
    this.bannerLink = bannerLink;
    this.useHtml = useHtml;
    this.accessingViaTrial = accessingViaTrial;
  }



}
