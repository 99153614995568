
export class DisplayCrumb {
  displayName : string;
  url : string;

  constructor(displayName : string, url : string){
    this.displayName = displayName;
    this.url = url;
  }
}
