import {Component, OnInit} from '@angular/core';

export class SearchUserDTO {
  email: string;
  name: string;
  emailDomain: string;
  licenseKey: string;
  schoolName: string;
  orderNumber: string;
  accountId: string;

  constructor(email: string, name: string, emailDomain: string, licenseKey: string, schoolName: string, orderNumber: string, accountId: string) {
    this.email = email;
    this.name = name;
    this.emailDomain = emailDomain;
    this.licenseKey = licenseKey;
    this.schoolName = schoolName;
    this.orderNumber = orderNumber;
    this.accountId = accountId;
  }
}
