import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DisplayMessageService {


  displayMessage: string[] = [];

  add(message: string) {
    this.displayMessage.push(message);
  }

  addAll(messages: string[]) {
    for(let message in messages) {
      this.add(messages[message]);
    }
  }
  clear() {
    this.displayMessage = [];
  }

}
