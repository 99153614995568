import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {ActivatedRoute, Router} from "@angular/router";
import {OrganizationService} from "../../../_services/adminOrganizationService/organization-service";
import {OrganizationDTO} from "../../../types/OrganizationDTO";

@Component({
  selector: 'app-e-admin-create-organization',
  templateUrl: './create-organization.html',
  styleUrls: ['./create-organization.component.css']
})
export class eAdminCreateOrganizationComponent implements OnInit {

  header: string = "Add New Organization";
  organizationId: string;

  createOrganizationForm = this.fb.group({
    name: ['', Validators.required],
    credits: [''],
    joinCode: [''],
    isActive: [''],
    isCreditsRequired: [''],
  }, {validator: []});

  constructor(private fb: FormBuilder,
              private organizationService: OrganizationService,
              private toastr: ToastrService,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.organizationId = this.route.snapshot.paramMap.get('id');
    if (this.organizationId != null) {
      this.header = "Edit Organization";
      this.loadData(this.organizationId);
    }
  }

  loadData(organizationId) {

    this.organizationService.get(organizationId).subscribe(data => {
      this.createOrganizationForm.get("name").setValue(data.name);
      this.createOrganizationForm.get("isCreditsRequired").setValue(data.isCreditsRequired);
      this.createOrganizationForm.get("credits").setValue(data.credits);
      this.createOrganizationForm.get("isActive").setValue(data.isActive);
      this.createOrganizationForm.get("joinCode").setValue(data.joinCode);
    });
  }

  onSubmit() {
    let organizationDTO = new OrganizationDTO(
      this.getField("name"),
      this.getField("joinCode"),
      this.getField("credits"),
      this.getField("isActive"),
      this.getField("isCreditsRequired"),
      null,
      null
    );


    if (this.organizationId == null) {
      this.organizationService.add(organizationDTO).subscribe(data => {
        if (!data.success) {
          this.toastr.error(data.errorMessage, "Error");
        }
        if (data.success) {
          this.toastr.success("Success", "Organization created successfully!!")
          this.router.navigateByUrl('/sparkecademy-admin-organization');
        }
      });
    } else {
      this.organizationService.edit(this.organizationId, organizationDTO).subscribe(data => {
        if (data.success) {
          this.toastr.success("Success", "Organization updated successfully!!")
          this.router.navigateByUrl('/sparkecademy-admin-organization');
        }
      });
    }
  }

  getField(fieldName: string) {
    return this.createOrganizationForm.get(fieldName).value;
  }
}
