import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, Validators} from "@angular/forms";
import {CourseTagDTO} from "../../types/CourseTagDTO";
import {CourseCategoryDTO} from "../../types/CourseCategoryDTO";
import {LicenseService} from "../../_services/adminLicenseService/license-service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-course-catalog',
  templateUrl: 'license-catalog.html',
  styleUrls: ['license-catalog.component.css']
})
export class eAssignLicenseComponent implements OnInit {

  courseTags: CourseTagDTO[];
  courseCategories: CourseCategoryDTO[];

  assignLicense = this.fb.group({
    licenseKey: ['', Validators.required]
  }, {validator: []});


  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private toastr: ToastrService,
              private licenseService: LicenseService
  ) {}

  ngOnInit() {}

  assignLicenseSubmit() {
    let licenseKey = this.assignLicense.get("licenseKey").value;
    let formData = {"licenseKey": licenseKey};
    this.licenseService.assign(formData).subscribe(data => {
      if (data.success) {
        this.toastr.success("Success", "Credits added successfully!!")
        this.router.navigateByUrl("/sparkecademy-user-dashboard");
      }
    });
  }

}
