import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {EmailValidation, RepeatEmailValidator} from "../../../../helpers/validators";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {CreateNewUserComponent} from "../../../../user-site/create-new-user/create-new-user.component";
import {RegistrationDTO} from "../../../../types/RegistrationDTO";
import {AdminUserService} from "../../../../_services/admin/adminUserService/admin-user.service";
import {AdminRegistrationDTO} from "../../../../types/AdminRegistrationDTO";

@Component({
  selector: 'app-admin-create-new-user',
  templateUrl: './admin-create-new-user.html',
  styleUrls: ['./admin-create-new-user.component.css']
})
export class AdminCreateNewUserComponent implements OnInit {

  callInProgress : boolean = false;

  callSuccess : boolean = false;
  callCompleted : boolean = false;
  errorMessage : string;
  accountId : number;
  newUserForm = this.fb.group({
    firstName:['', Validators.required],
    lastName: ['', Validators.required],
    email: ['', EmailValidation],
    emailConfirm: ['', EmailValidation],
    school: ['', Validators.required]
  }, {validator: [RepeatEmailValidator]});





  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<AdminCreateNewUserComponent>, private adminUserService : AdminUserService,@Inject(MAT_DIALOG_DATA) data) {
    console.log(data);
    this.accountId = data.id;
  }

  ngOnInit() {
  }

  onSubmit(){
    let dto : AdminRegistrationDTO = new AdminRegistrationDTO(this.newUserForm.get('email').value,
      this.newUserForm.get('firstName').value,
      this.newUserForm.get('lastName').value,
      null,
      this.newUserForm.get('school').value,
      null, this.accountId);
    this.callCompleted = false;
    this.adminUserService.createNewUser(dto).subscribe(data => {
      console.log(data);
      if(data.success == true){
        console.log("data success true");
        this.dialogRef.close(true);
      } else {
        this.callSuccess = false;
        this.errorMessage = data.errorMessage||data.message;
      }
      this.callCompleted = true;
    });
  }


  close(){
    this.dialogRef.close(false);
  }
}
