import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from "@angular/router";
import {AdminAuthService} from "../../_services/admin/adminAuthService/admin-auth.service";

@Injectable({
  providedIn: 'root'
})
export class AdminDashboardGuard {

  constructor(
    private router: Router,
    private authService: AdminAuthService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot):  Promise<boolean>  {
    return new Promise((resolve) =>
    {
      if (localStorage.getItem("gotoUser") != null) {
        this.router.navigate(['home']);
        localStorage.removeItem("gotoUser");
        return ;
      }

      console.log("Check is logged in");
      const currentUser = this.authService.getCurrentUserValue();
      console.log(currentUser);
      if(!currentUser) {
        this.router.navigate(['admin']);
        return resolve(false);
      }

      this.authService.isAdminUser().subscribe(data => {
        console.log(data);
        if(data && data.isAdmin){
          return resolve(true);
        } else {
          this.router.navigate(['admin']);
          return resolve(false);
        }
      });
    });

  }
}
