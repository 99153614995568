import {Injectable} from '@angular/core';
import {UserFilterDTO} from "../../types/UserFilterDTO";
import {UserDTO} from "../../types/UserDTO";
import {Observable, of} from "rxjs";
import {catchError} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {ErrorMessageService} from "../../../_services/errorMessageService/error-message.service";
import {ToastrService} from "ngx-toastr";
import {PageEvent} from "@angular/material/paginator";
import {CourseDTO} from "../../types/CourseDTO";
import {CourseFilterDTO} from "../../types/CourseFilterDTO";
import {CourseEnrollmentFilterDTO} from "../../types/CourseEnrollmentFilterDTO";
import {CourseEnrollmentDTO} from "../../types/CourseEnrollmentDTO";
import {CourseCategoryDTO} from "../../types/CourseCategoryDTO";
import {CourseTagDTO} from "../../types/CourseTagDTO";

@Injectable({
  providedIn: 'root'
})
export class CourseCategoryService {

  // private urlBase: string = "http://localhost:8050/";
  private urlBase: string = "https://sparkfamily.org/api/";


  private courseCategoryUrl: string = this.urlBase + "admin/courseCategory";
  private getAllCourseCategoriesUrl: string = this.urlBase + "courseCategories";

  private courseTagUrl: string = this.urlBase + "admin/courseTag";
  private getAllCourseTagsUrl: string = this.urlBase + "courseTags";

  constructor(private http: HttpClient, private router: Router, private errorService: ErrorMessageService, private toastr: ToastrService) {
  }

  addCourseCategory(courseCategoryDTO: CourseCategoryDTO) {
    return this.http.post<any>(this.courseCategoryUrl, courseCategoryDTO).pipe(catchError(this.handleError('ManageCourseService: AddCourse', [])));
  }

  editCourseCategory(id: string, courseCategoryDTO: CourseCategoryDTO) {
    let url = this.courseCategoryUrl + "/" + id;
    return this.http.post<any>(url, courseCategoryDTO).pipe(catchError(this.handleError('ManageCourseService: EditCourse', [])));
  }

  getCourseCategory(id: string) {
    let url = this.courseCategoryUrl + "/" + id;
    return this.http.get<any>(url).pipe(catchError(this.handleError('ManageCourseService: GetCourse', [])));
  }

  getAllCourseCategories(includeInactive?: boolean) {
    let url = this.getAllCourseCategoriesUrl;
    if (typeof includeInactive !== 'undefined') {
      url = url + "?includeInactive=true"
    }
    return this.http.get<any>(url).pipe(catchError(this.handleError('UserCourseService: getCategories', [])));
  }


  addCourseTag(courseTagDTO: CourseTagDTO) {
    return this.http.post<any>(this.courseTagUrl, courseTagDTO).pipe(catchError(this.handleError('ManageCourseService: AddCourse', [])));
  }

  editCourseTag(id: string, courseTagDTO: CourseTagDTO) {
    return this.http.post<any>(this.courseTagUrl + "/" + id, courseTagDTO).pipe(catchError(this.handleError('ManageCourseService: EditCourse', [])));
  }

  getCourseTag(id: string) {
    return this.http.get<any>(this.courseTagUrl + '/' + id).pipe(catchError(this.handleError('ManageCourseService: GetCourse', [])));
  }

  getAllCourseTags(includeInactive?: boolean) {
    let url = this.getAllCourseTagsUrl;
    if (typeof includeInactive !== 'undefined') {
      url = url + "?includeInactive=true"
    }
    return this.http.get<any>(url).pipe(catchError(this.handleError('UserCourseService: getTags', [])));
  }

  clear() {
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log("course service catch error");
      console.log("error1");
      console.error(error);
      console.log("error1");
      this.errorService.add("Error generating content");
      this.toastr.error(error.error, "Error")
      return of(result as T);
    };
  }
}
