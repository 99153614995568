import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import {CourseLessonQuizDTO} from "../../../../types/CourseLessonQuizDTO";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {CourseContentService} from "../../../../_services/adminCourseService/course-content-service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-e-admin-quiz-nav',
  templateUrl: './admin-quiz-nav.html',
  styleUrls: ['./admin-quiz-nav.component.css']
})
export class eAdminQuizNavComponent implements OnInit {
  @Input() createCourseQuizForm: FormGroup;

  @Input() courseId: string;
  @Input() lessonId: string;

  modalRef: BsModalRef;


  @ViewChild('confirmation')
  private confirmationTemplate: TemplateRef<any>;

  constructor(private fb: FormBuilder,
              private courseContentService: CourseContentService,
              private router: Router,
              private modalService: BsModalService) {
  }

  ngOnInit() {
  }

  get courseQuizFormArr(): FormArray {
    return this.createCourseQuizForm.get('courseQuiz') as FormArray;
  }

  addNewCourseQuizData(data: CourseLessonQuizDTO) {
    this.courseQuizFormArr.push(
      this.fb.group({
        question: [data.question],
        answers: [data.answers],
        correctAnswer: [data.correctAnswer],
        questionType: [data.questionType],
        id: [data.id]
      }));
  }

  addBooleanQuestionForm() {
    this.addNewCourseQuizData(new CourseLessonQuizDTO("BOOLEAN", null, ""));
  }

  addMultipleChoiceQuestionForm() {
    this.addNewCourseQuizData(new CourseLessonQuizDTO("MULTIPLE_CHOICE", null, '', "", ""));
  }

  removeLesson() {
    this.modalRef = this.modalService.show(this.confirmationTemplate, {class: 'modal-sm'});
  }

  closeLesson() {
    this.router.navigateByUrl("/sparkecademy-admin-course/" + this.courseId + "/contents");
  }

  confirm(): void {
    this.courseContentService.removeLesson(this.courseId, this.lessonId).subscribe(data => {
      this.closeLesson();
    });
    this.modalRef.hide();
  }

  decline(): void {
    this.modalRef.hide();
  }
}
