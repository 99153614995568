import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Curriculum} from "../../../../types/Curriculum";
import {FormBuilder} from "@angular/forms";
import {AdminCurriculumService} from "../../../../_services/admin/adminCurriculumService/admin-curriculum.service";

@Component({
  selector: 'app-admin-curriculum-editor',
  templateUrl: `./admin-curriculum-editor.html`,
  styleUrls: ['./admin-curriculum-editor.component.css']
})
export class AdminCurriculumEditorComponent implements OnInit {

  _curriculum : Curriculum;


  editCurriculum = this.fb.group({
    NAV_TEXT:[''],
    BODY_TEXT:[''],
    file:[null]
  }, {validator: []});

  message : string;
  success : boolean;

  callReturned : boolean = true;

  @Output() closeEditor=new EventEmitter<boolean>();

  @Output() reloadEditor=new EventEmitter<Curriculum>();



  constructor(private fb: FormBuilder, private adminCurriculumService : AdminCurriculumService) { }

  ngOnInit() {
  }

  @Input()
  set curriculum(curriculum){
    this._curriculum = curriculum;
    console.log(this._curriculum);
    this.editCurriculum.controls['NAV_TEXT'].setValue(this._curriculum.navText);
    this.editCurriculum.controls['BODY_TEXT'].setValue(this._curriculum.bodyText)
    console.log(this.editCurriculum);
  }

  onFileChange(event) {
    if(event.target.files.length > 0) {
      let file = event.target.files[0];
      this.editCurriculum.get('file').setValue(file);
      console.log(this.editCurriculum);
    }
  }


  private prepareSave(): any {
    let input = new FormData();
    // This can be done a lot prettier; for example automatically assigning values by looping through `this.form.controls`, but we'll keep it as simple as possible here
    input.append('NAV_TEXT', this.editCurriculum.get('NAV_TEXT').value);
    input.append('BODY_TEXT', this.editCurriculum.get('BODY_TEXT').value);
    if(this.editCurriculum.get('file').value) {
      input.append('FILE', this.editCurriculum.get('file').value);
    }
    input.append('CURRICULUM_ID', this._curriculum.id);
    return input;
  }

  onSubmit() {
    this.callReturned = false;
    this.message = null;
    this.adminCurriculumService.update( this.prepareSave()).subscribe(data => {
      console.log(data);
      if(data.success){
        this.message = "Changes saved";
        this.reloadEditor.emit(this._curriculum);
      } else {
        this.message = data.errorMessage;
      }

      this.callReturned = true;
    });
  }

  back() {
    this.closeEditor.emit(true);
  }
}
