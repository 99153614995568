import {Component, Input, OnInit, SimpleChanges, TemplateRef, ViewChild} from '@angular/core';
import {UserDTO} from "../../../types/UserDTO";
import {UserService} from "../../../_services/adminUserService/user-service";
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";

import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {MatPaginator, MatPaginatorModule, PageEvent} from '@angular/material';
import {UserFilterDTO} from "../../../types/UserFilterDTO";
import {Sort} from '@angular/material/sort';
import {PaginationUserDTO} from "../../../types/PaginationUserDTO";
import {MatMenuTrigger} from "@angular/material/menu";
import {AuthService} from "../../../../_services/authService/auth.service";


@Component({
  selector: 'app-e-admin-list-user',
  templateUrl: 'list-user.html',
  styleUrls: ['list-user.component.css']
})
export class eAdminListUserComponent implements OnInit {

  private _userFilterDto: UserFilterDTO;

  @Input() set userFilterDto_(_userFilterDto: UserFilterDTO) {

    this._userFilterDto = _userFilterDto;

    this.pageEvent_ = new PageEvent();
    this.pageEvent_.pageSize = 50;
    this.pageEvent_.length = 0;
    this.pageEvent_.pageIndex = 0;
    this.sortAndFilterRecords();
  }

  get userFilterDto_(): UserFilterDTO {
    return this._userFilterDto;
  }

  paginationUserList: PaginationUserDTO;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  pageEvent?: Event;
  pageEvent_?: PageEvent;
  sort: Sort;

  @ViewChild('confirmation')
  private confirmationTemplate: TemplateRef<any>;

  modalRef: BsModalRef;

  recordToDelete: number[];

  constructor(private userService: UserService,
              private toastr: ToastrService,
              private router: Router,
              private modalService: BsModalService,
              private authService: AuthService) {
  }

  sortAndFilterRecords() {
    let userFilterDto;

    if (typeof this.pageEvent_ !== 'undefined') {
      userFilterDto = new UserFilterDTO(this.pageEvent_.pageIndex, this.pageEvent_.pageSize, this.pageEvent_.length);
    }

    if (typeof this.userFilterDto_ !== 'undefined') {
      userFilterDto.showOnlySuperUsers = this.userFilterDto_.showOnlySuperUsers;
      userFilterDto.organization = this.userFilterDto_.organization;
      userFilterDto.name = this.userFilterDto_.name;
      userFilterDto.email = this.userFilterDto_.email;
    }

    if (typeof this.sort != 'undefined') {
      userFilterDto.sortBy = this.sort.active;
      userFilterDto.sortOrder = this.sort.direction;
    }

    this.userService.filter(userFilterDto).subscribe(data => {
      this.paginationUserList = data;
    });
    return event;
  }

  findRecords(event?: PageEvent) {
    this.pageEvent_ = event;
    return this.sortAndFilterRecords();
  }

  sortRecords(sort: Sort) {
    this.sort = sort;
    this.sortAndFilterRecords();
  }

  setSelected(userDTO: UserDTO) {
    this.paginationUserList.users.filter(e => e.id == userDTO.id).forEach(e => {
      e.isSelected = !e.isSelected;
    });
  }

  removeSelected() {
    let recordCountToRemove = this.paginationUserList.users.filter(e => e.isSelected).length;
    if (recordCountToRemove) {
      this.modalRef = this.modalService.show(this.confirmationTemplate, {class: 'modal-sm'});
    } else {
      this.toastr.info("Please select record/s to remove", "")
    }
  }

  edit(user) {
    this.router.navigateByUrl('sparkecademy-admin-user/edit/' + user.id).then(r => {
    });
  }

  view(user) {
    this.router.navigateByUrl('sparkecademy-admin-user/view/' + user.id).then(r => {
    });
  }

  activate(user) {
    this.paginationUserList.users.filter(e => e == user).map(e => e.status = 'Active');
    this.userService.activate(user.id).subscribe(e => {
      this.toastr.success("Users activated successfully!!", "Success")
    });
  }

  impersonate(user) {
    this.authService.impersonateUser(user.email).subscribe(user => {});
  }

  deactivate(user) {
    this.paginationUserList.users.filter(e => e == user).map(e => e.status = 'Inactive');
    this.userService.deactivate(user.id).subscribe(e => {
      this.toastr.success("Users Inactivated successfully!!", "Success")
    });
  }

  remove(user) {
    this.modalRef = this.modalService.show(this.confirmationTemplate, {class: 'modal-sm'});
    this.recordToDelete = user.userId;
  }

  confirm(): void {
    let recordToRemove = this.paginationUserList.users.filter(e => e.isSelected).map(e => e.id);
    this.userService.removeMultiple(recordToRemove).subscribe(data => {
      if (data.response == 'success') {
        this.toastr.success("Users deleted successfully!!", "Success")
        this.paginationUserList.users = this.paginationUserList.users.filter(item => !recordToRemove.includes(item.id));
      }
    });
    this.modalRef.hide();
  }

  decline(): void {
    this.modalRef.hide();
  }

  ngOnInit() {
  }


  @ViewChild(MatMenuTrigger) ddTrigger: MatMenuTrigger;

  value: string;
  selectedValue: string;

  cancelClick(ev: MouseEvent) {
    ev.stopPropagation();
  }

  onCancel() {
    this.value = undefined;
    this.ddTrigger.closeMenu();
  }

  onSave() {
    this.selectedValue = this.value;
    this.value = undefined;
    this.ddTrigger.closeMenu();
  }
}
