import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {AdminAuthService} from "../../_services/admin/adminAuthService/admin-auth.service";

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.html',
  styleUrls: ['./admin-login.component.css']
})
export class AdminLoginComponent implements OnInit {

  loginForm = this.fb.group({
    email: ['', Validators.required],
    password: ['', Validators.required]
  })


  constructor(private fb: FormBuilder, private authService : AdminAuthService) { }

  ngOnInit() {
  }


  onSubmit() {
    // TODO: Use EventEmitter with form value
    console.warn(this.loginForm.value);
    this.authService.login(this.loginForm.get("email").value, this.loginForm.get("password").value).subscribe();
  }
}
