import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {CourseTagDTO} from "../../../types/CourseTagDTO";
import {CourseCategoryDTO} from "../../../types/CourseCategoryDTO";
import {OnSiteCourseService} from "../../../_services/adminLiveCourseService/on-site-course.service";
import {FormBuilder, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {LicenseService} from "../../../_services/adminLicenseService/license-service";

@Component({
  selector: 'app-e-assign-onSite-course',
  templateUrl: './assign-onSite-course.html',
  styleUrls: ['./assign-onSite-course.component.css']
})
export class eAssignOnSiteCourseComponent implements OnInit {
  courseTags: CourseTagDTO[];
  courseCategories: CourseCategoryDTO[];


  assignLiveCourseForm = this.fb.group({
    code: ['', Validators.required],
    year: ['', Validators.required],
    month: ['', Validators.required],
    day: ['', Validators.required],
  }, {validator: []});


  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private licenseService: LicenseService
  ) {
  }

  ngOnInit() {

  }

  onSubmit() {
    let code = this.getField("code");
    let year = this.getField("year");
    let month = this.getField("month");
    let day = this.getField("day");


    let date = month + "/" + day + "/" + year;
    let formData = {"code": code, "date": date};
    this.licenseService.addEnrollment(formData).subscribe(data => {
      if (data.success) {
        this.toastr.success("Success", "You have successfully enrolled in course")
        this.router.navigateByUrl("/sparkecademy-user-dashboard");
      }
    });
  }

  getField(fieldName: string) {
    return this.assignLiveCourseForm.get(fieldName).value;
  }
}
