import { Injectable } from '@angular/core';


import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {UserCurriculumService} from "../../_services/userCurriculumService/user-curriculum.service";
import {Observable} from "rxjs";
import {ModuleFile} from "../../types/ModuleFile";
import {Curriculum} from "../../types/Curriculum";

@Injectable({
  providedIn: 'root'
})
export class ShowEulaPopupResolver implements Resolve<Observable<Curriculum>> {
  constructor(private curriculumService: UserCurriculumService) {}

  resolve(route: ActivatedRouteSnapshot) : Observable<Curriculum>{
    console.log("ccr param" + route.params['id']);
    return this.curriculumService.getShowEula(route.params['id']);
  }
}
