import {Injectable} from '@angular/core';
import {Observable, of} from "rxjs";
import {catchError} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {ErrorMessageService} from "../../../_services/errorMessageService/error-message.service";
import {ToastrService} from "ngx-toastr";
import {PageEvent} from "@angular/material/paginator";
import {OrganizationDTO} from "../../types/OrganizationDTO";
import {OrganizationFilterDTO} from "../../types/OrganizationFilterDTO";
import {OrganizationCourseDTO} from "../../types/OrganizationCourseDTO";
import {DistributeCreditRequestDTO} from "../../types/DistributeCreditRequestDTO";
import {UserDTO} from "../../types/UserDTO";

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  private urlBase : string = "https://sparkfamily.org/api/admin/organization/";
  // private urlBase: string = "http://localhost:8050/admin/organization/";

  private addOrganizationUrl: string = this.urlBase + "add";
  private editOrganizationUrl: string = this.urlBase + "edit";
  private getOrganizationUrl: string = this.urlBase + "se/get";
  private removeOrganizationUrl: string = this.urlBase + "remove";
  private filterOrganizationUrl: string = this.urlBase + "findAll";
  private creditTransactionHistoryUrl: string = this.urlBase + "se/@id/credits";

  private coursesUrl: string = this.urlBase + "se/@id/courses";
  private addCourseUrl: string = this.urlBase + "se/@id/course";
  private removeCourseUrl: string = this.urlBase + "se/@id/course/remove/@organizationCourseId";
  private editCourseUrl: string = this.urlBase + "se/@id/course/@organizationCourseId";

  private distributeCreditsUrl: string = this.urlBase + "se/@id/distribute-credits";

  private addCreditsTransactionsUrl: string = this.urlBase + "@id/addTransaction";

  private membersUrl: string = this.urlBase + "se/@id/members";
  private getMemberUrl: string = this.urlBase + "se/@id/member";
  private addMemberUrl: string = this.urlBase + "se/@id/member";
  private editMemberUrl: string = this.urlBase + "se/@id/member";
  private deActivateMemberUrl: string = this.urlBase + "se/@id/member/deActivate";

  private memberCoursesUrl: string = this.urlBase + "se/@id/memberCourses";
  private bulkUpdateUsersUrl: string =  this.urlBase + "bulkUpdate";

  constructor(private http: HttpClient, private router: Router, private errorService: ErrorMessageService, private toastr: ToastrService) {
  }

  add(organizationDTO: OrganizationDTO) {
    return this.http.post<any>(this.addOrganizationUrl, organizationDTO).pipe(catchError(this.handleError('ManageOrganizationService: AddOrganization', [])));
  }

  edit(id: string, organizationDTO: OrganizationDTO) {
    return this.http.post<any>(this.editOrganizationUrl + "/" + id, organizationDTO).pipe(catchError(this.handleError('ManageOrganizationService: EditOrganization', [])));
  }

  get(id: string) {
    return this.http.get<any>(this.getOrganizationUrl + '/' + id).pipe(catchError(this.handleError('ManageOrganizationService: GetOrganization', [])));
  }

  remove(id: number) {
    return this.http.delete<any>(this.removeOrganizationUrl + '/' + id).pipe(catchError(this.handleError('DocumentService: RemoveDocument', [])));
  }

  filter(dto: OrganizationFilterDTO): Observable<any> {
    return this.http.post<any>(this.filterOrganizationUrl, dto).pipe(catchError(this.handleError('ManageOrganizationService: FilterRecords', [])));
  }

  findAll(event?: PageEvent): Observable<any> {
    return this.http.get<any>(this.filterOrganizationUrl, null).pipe(catchError(this.handleError('ManageOrganizationService: FindAll', [])));
  }

  getCreditTransactionHistory(id: string): Observable<any> {
    return this.http.get<any>(this.creditTransactionHistoryUrl.replace("@id", id)).pipe(catchError(this.handleError('ManageOrganizationService: getCreditTransactionHistory', [])));
  }

  getCourses(id: string): Observable<any> {
    return this.http.get<any>(this.coursesUrl.replace("@id", id)).pipe(catchError(this.handleError('ManageOrganizationService: getCourses', [])));
  }

  addCourse(id: string, organizationCourse: OrganizationCourseDTO): Observable<any> {
    return this.http.post<any>(this.addCourseUrl.replace("@id", id), organizationCourse).pipe(catchError(this.handleError('ManageOrganizationService: getCourses', [])));
  }

  removeCourse(organizationId: string, organizationCourseId: number): Observable<any> {
    let url = this.removeCourseUrl.replace("@id", organizationId);
    url = url.replace("@organizationCourseId", organizationCourseId.toString());
    return this.http.delete<any>(url).pipe(catchError(this.handleError('ManageOrganizationService: getCourses', [])));
  }

  editCourse(organizationId: string, organizationCourseId: string, organizationCourse: OrganizationCourseDTO): Observable<any> {
    let url = this.editCourseUrl.replace("@id", organizationId);
    url = url.replace("@organizationCourseId", organizationCourseId);
    return this.http.post<any>(url, organizationCourse).pipe(catchError(this.handleError('ManageOrganizationService: getCourses', [])));
  }

  getCourse(organizationId: string, organizationCourseId: string): Observable<any> {
    let url = this.editCourseUrl.replace("@id", organizationId);
    url = url.replace("@organizationCourseId", organizationCourseId);
    return this.http.get<any>(url).pipe(catchError(this.handleError('ManageOrganizationService: getCourses', [])));
  }

  distributeCredits(organizationId: string, distributeCreditRequestDTO: DistributeCreditRequestDTO): Observable<any> {
    let url = this.distributeCreditsUrl.replace("@id", organizationId);
    return this.http.post<any>(url, distributeCreditRequestDTO).pipe(catchError(this.handleError('ManageOrganizationService: getCourses', [])));
  }

  addCreditsTransaction(organizationId: string, amount: number): Observable<any> {
    let url = this.addCreditsTransactionsUrl.replace("@id", organizationId);
    return this.http.post<any>(url, amount).pipe(catchError(this.handleError('ManageOrganizationService: addCreditsTransaction', [])));
  }

  getMemberCourses(organizationId: string): Observable<any> {
    let url = this.memberCoursesUrl.replace("@id", organizationId);
    return this.http.get<any>(url).pipe(catchError(this.handleError('ManageOrganizationService: getMemberCourses', [])));
  }


  getMembers(organizationId: string): Observable<any> {
    let url = this.membersUrl.replace("@id", organizationId);
    return this.http.get<any>(url).pipe(catchError(this.handleError('ManageOrganizationService: getCourses', [])));
  }

  getMember(organizationId: string, userId: string) {
    let url = this.getMemberUrl.replace("@id", organizationId);
    return this.http.get<any>(url + "/" + userId).pipe(catchError(this.handleError('ManageUserService: EditUser', [])));
  }

  deactivateMember(organizationId: string, userId: string) {
    let url = this.deActivateMemberUrl.replace("@id", organizationId);
    return this.http.get<any>(url + "/" + userId).pipe(catchError(this.handleError('ManageUserService: RemoveUser', [])));
  }

  addMember(organizationId: string, userDTO: UserDTO, isShiftToOtherOrganization: boolean) {
    let url = this.addMemberUrl.replace("@id", organizationId) + "?isShiftToOtherOrganization=" + isShiftToOtherOrganization;
    return this.http.post<any>(url, userDTO).pipe(catchError(this.handleError('ManageUserService: AddUser', [])));
  }

  editMember(organizationId: string, userId: string, userDTO: UserDTO) {
    let url = this.editMemberUrl.replace("@id", organizationId);
    return this.http.post<any>(url + "/" + userId, userDTO).pipe(catchError(this.handleError('ManageUserService: EditUser', [])));
  }

  bulkUpdateUsers(updateData) {
    return this.http.post<any>(this.bulkUpdateUsersUrl, updateData);
  }

  clear() {
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log("Organization service catch error");
      console.log("error1");
      console.error(error);
      console.log("error1");
      this.errorService.add("Error generating content");
      if (typeof error.error === 'object') {
        this.toastr.error(error.error.message, "Error")
      } else {
        this.toastr.error(error.error, "Error")
      }
      return of(result as T);
    };
  }
}
