import {Component, Input, OnInit} from '@angular/core';
import {OrganizationFilterDTO} from "../../types/OrganizationFilterDTO";

@Component({
  selector: 'app-e-admin-manage-organization',
  templateUrl: './manage-organization.html',
  styleUrls: ['./manage-organization.component.css']
})
export class eAdminManageOrganizationComponent implements OnInit {

  organizationFilterDto_: OrganizationFilterDTO;

  dataToFilter($event) {
    this.organizationFilterDto_ = $event;
  }

  constructor() {
  }

  ngOnInit() {
  }
}
