import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AdminAccountUsersDTO} from "../../../../types/AdminAccountUsersDTO";
import {AdminLicenseService} from "../../../../_services/admin/adminLicenseService/admin-license.service";
import {IDDTO} from "../../../../types/IDDTO";
import {LicenseDTO} from "../../../../types/LicenseDTO";
import {RoleInfo} from "../../../../types/RoleInfo";

@Component({
  selector: 'app-admin-account-license-display',
  templateUrl: './admin-account-license-display.html',
  styleUrls: ['./admin-account-license-display.component.css']
})
export class AdminAccountLicenseDisplayComponent implements OnInit {

  _accountId : number;


  licenseDTOList : Array<LicenseDTO>;

  typedDTOList : Array<LicenseDTO> = new Array<LicenseDTO>();

  showEditorMap : Map<number, boolean> = new Map<number, boolean>();

  @Output() reloadLicenseDisplay=new EventEmitter<boolean>();

  constructor(private accountLicenseService : AdminLicenseService) { }



  ngOnInit() {
  }

  @Input()
  set accountId(accountId : number){
    this._accountId = accountId;
    console.log("In set account id");
    console.log(this._accountId);
    if(this._accountId) {
        let iddto = new IDDTO(this._accountId);
        console.log(iddto);
        this.accountLicenseService.getAccountLicenseInfo(iddto).subscribe(data => {
          console.log(data);

          this.licenseDTOList = data.dtos;

          this.licenseDTOList.forEach(
            licenseDTO =>{
              console.log(licenseDTO.roleInfoList);
              licenseDTO.roleInfoList = licenseDTO.roleInfoList.filter(role => role.type !== 'ADMIN');
              console.log(licenseDTO.roleInfoList);
            }
          );


          console.log(this.licenseDTOList);

          this.licenseDTOList.forEach( licenseDTO => {

            let typedDTO : LicenseDTO;
            typedDTO = new LicenseDTO(licenseDTO.licenseKey, licenseDTO.expirationDate, licenseDTO.roleInfoList);
            typedDTO.revoked = licenseDTO.revoked;
            typedDTO.licenseId = licenseDTO.licenseId;
            this.showEditorMap.set(typedDTO.licenseId, false);
            let count = 0;

            typedDTO.roleInfoList.forEach(roleInfo => {

              if(count % 2 == 0){
                typedDTO.processedRoleInfoList.push(new Array<RoleInfo>());
              }

              let temp : Array<RoleInfo> = new Array<RoleInfo>();
              temp = typedDTO.processedRoleInfoList.pop();
              temp.push(roleInfo);
              typedDTO.processedRoleInfoList.push(temp);
              count++;
            });

            this.typedDTOList.push(typedDTO);
          });
        });
    }
  }

  loadEditor(dto){
    console.log("load editor");
    this.showEditorMap.set(dto.licenseId, true);
  }

  closeEditorNoReload(dto){
    console.log("load editor");
    this.showEditorMap.set(dto.licenseId, false);
  }

  closeEditorReload(dto){
    this.reloadLicenseDisplay.emit(true);
  }

  removeLicense(dto){
    this.typedDTOList = this.typedDTOList.filter(typedDto => typedDto !== dto);
  }
}
