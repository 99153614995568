export class CourseEnrollmentDTO {

  id: number;
  userName: string;
  courseName: string;
  dateEnrolled: string;
  progress: string;
  dateCompleted: string;
  dateCertificate: string;

  constructor(dateCertificate: string,
              id?: number,
              userName?: string,
              courseName?: string,
              dateEnrolled?: string,
              progress?: string,
              dateCompleted?: string,
  ) {
    this.id = id;
    this.userName = userName;
    this.courseName = courseName;
    this.dateEnrolled = dateEnrolled;
    this.progress = progress;
    this.dateCompleted = dateCompleted;
    this.dateCertificate = dateCertificate;
  }
}
