import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {ActivatedRoute, Router} from "@angular/router";
import {PageEvent} from "@angular/material/paginator";
import {LicenseService} from "../../../_services/adminLicenseService/license-service";
import {CreditLicenseDTO} from "../../../types/CreditLicenseDTO";

@Component({
  selector: 'app-e-admin-create-license',
  templateUrl: './admin-create-license.html',
  styleUrls: ['./admin-create-license.component.css']
})
export class eAdminCreateLicenseComponent implements OnInit {

  header: string = "Add New License";

  createLicenseForm = this.fb.group({
    oracleOrderNumber: ['', Validators.required],
    sku: ['', Validators.required],
    email: ['', Validators.required],
    credits: ['', Validators.required],
  }, {validator: []});

  constructor(private fb: FormBuilder,
              private licenseService: LicenseService,
              private toastr: ToastrService,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
  }

  onSubmit() {

    let oracleOrderNumber = this.getField("oracleOrderNumber");
    let sku = this.getField("sku");
    let email = this.getField("email");
    let credits = this.getField("credits");
    let creditLicenseDTO = new CreditLicenseDTO(oracleOrderNumber, sku, email, credits);

    this.licenseService.create(creditLicenseDTO).subscribe(data => {
      if (!data.success) {
        this.toastr.error(data.errorMessage, "Error");
      }
      if (data.success) {
        this.toastr.success("Success", "License created successfully!!")
        this.router.navigateByUrl('/sparkecademy-admin-license');
      }
    });
  }

  getField(fieldName: string) {
    return this.createLicenseForm.get(fieldName).value;
  }
}
