import { Injectable } from '@angular/core';


import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Observable} from "rxjs";
import {Breadcrumb} from "../../types/Breadcrumb";
import {UserCurriculumService} from "../../_services/userCurriculumService/user-curriculum.service";

@Injectable({
  providedIn: 'root'
})
export class ModuleBreadcrumbResolver implements Resolve<Observable<Breadcrumb[]>> {
  constructor(private curriculumService : UserCurriculumService) {}

  resolve(route: ActivatedRouteSnapshot) : Observable<Breadcrumb[]>{

    console.log(route);
    console.log("in breadcrumb resolver");
    console.log("cmr param" + route.params['moduleId']);

    return this.curriculumService.getBreadcrumb(route.params['moduleId'],"module", false, false);
  }
}

@Injectable({
  providedIn: 'root'
})
export class ModuleFolderBreadcrumbResolver implements Resolve<Observable<Breadcrumb[]>> {
  constructor(private curriculumService : UserCurriculumService) {}

  resolve(route: ActivatedRouteSnapshot) : Observable<Breadcrumb[]>{

    console.log(route);
    console.log("in module folder breadcrumb resolver");
    console.log("cmr param" + route.params['parentId']);

    return this.curriculumService.getBreadcrumb(route.params['parentId'],"folder", false, false);
  }
}


@Injectable({
  providedIn: 'root'
})
export class TTModuleBreadcrumbResolver implements Resolve<Observable<Breadcrumb[]>> {
  constructor(private curriculumService : UserCurriculumService) {}

  resolve(route: ActivatedRouteSnapshot) : Observable<Breadcrumb[]>{

    console.log(route);
    console.log("in breadcrumb resolver");
    console.log("cmr param" + route.params['moduleId']);

    return this.curriculumService.getBreadcrumb(route.params['moduleId'],"module", true, false);
  }
}

@Injectable({
  providedIn: 'root'
})
export class TTModuleFolderBreadcrumbResolver implements Resolve<Observable<Breadcrumb[]>> {
  constructor(private curriculumService : UserCurriculumService) {}

  resolve(route: ActivatedRouteSnapshot) : Observable<Breadcrumb[]>{

    console.log(route);
    console.log("in module folder breadcrumb resolver");
    console.log("cmr param" + route.params['parentId']);

    return this.curriculumService.getBreadcrumb(route.params['parentId'],"folder", true, false);
  }
}

@Injectable({
  providedIn: 'root'
})
export class SecondaryModuleFolderBreadcrumbResolver implements Resolve<Observable<Breadcrumb[]>> {
  constructor(private curriculumService : UserCurriculumService) {}

  resolve(route: ActivatedRouteSnapshot) : Observable<Breadcrumb[]>{

    console.log(route);
    console.log("in module folder breadcrumb resolver");
    console.log("cmr param" + route.params['parentId']);

    return this.curriculumService.getBreadcrumb(route.params['parentId'],"folder", false, true);
  }
}

