import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserFilterDTO} from "../../../types/UserFilterDTO";
import {FormBuilder} from "@angular/forms";
import {UserService} from "../../../_services/adminUserService/user-service";
import {OrganizationService} from "../../../_services/adminOrganizationService/organization-service";
import {PageEvent} from "@angular/material/paginator";
import {PaginationOrganizationDTO} from "../../../types/PaginationOrganizationDTO";
import {OrganizationFilterDTO} from "../../../types/OrganizationFilterDTO";
import {OrganizationDTO} from "../../../types/OrganizationDTO";

@Component({
  selector: 'app-e-admin-filter-user',
  templateUrl: 'filter-user.html',
  styleUrls: ['filter-user.component.css']
})
export class eAdminFilterUserComponent implements OnInit {

  organizations: OrganizationDTO[];

  @Output() userFilterDto_ = new EventEmitter<UserFilterDTO>();
  filterUsers = this.fb.group({
    showOnlySuperUsers: [false],
    organization: [''],
    name: [''],
    email: [''],
  }, {validator: []});

  constructor(private fb: FormBuilder,
              private organizationService: OrganizationService) {
  }

  ngOnInit() {
    let organizationFilterDTO = new OrganizationFilterDTO(0, 1000, 0);
    this.organizationService.filter(organizationFilterDTO).subscribe(data => {
      this.organizations = data.organizations;
    });
  }

  onSubmit() {
    let showOnlySuperUsers = this.filterUsers.get("showOnlySuperUsers").value;
    let organization = this.filterUsers.get("organization").value;
    let name = this.filterUsers.get("name").value;
    let email = this.filterUsers.get("email").value;

    name = (name == '') ? null : name;
    email = (email == '') ? null : email;
    organization = (organization == '') ? null : organization;


    let userFilterDto = new UserFilterDTO(0, 20, 0, showOnlySuperUsers, organization, name, email);
    this.userFilterDto_.emit(userFilterDto);
  }

  resetForm() {
    let userFilterDto = new UserFilterDTO(0, 20, 0);
    this.userFilterDto_.emit(userFilterDto);
  }
}
