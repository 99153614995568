import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {OrganizationService} from "../../../_services/adminOrganizationService/organization-service";
import {OrganizationFilterDTO} from "../../../types/OrganizationFilterDTO";

@Component({
  selector: 'app-e-admin-filter-organization',
  templateUrl: 'filter-organization.html',
  styleUrls: ['filter-organization.component.css']
})
export class eAdminFilterOrganizationComponent implements OnInit {

  @Output() organizationFilterDto_ = new EventEmitter<OrganizationFilterDTO>();
  filterOrganizations = this.fb.group({
    name: ['']
  }, {validator: []});

  constructor(private fb: FormBuilder, private organizationService: OrganizationService) {
  }

  ngOnInit() {
  }

  onSubmit() {
    let name = this.filterOrganizations.get("name").value;
    name = (name == '') ? null : name;
    let organizationFilterDTO = new OrganizationFilterDTO(0, 20, 0, name);
    this.organizationFilterDto_.emit(organizationFilterDTO);
  }

  resetForm() {
    let organizationFilterDTO = new OrganizationFilterDTO(0, 20, 0);
    this.organizationFilterDto_.emit(organizationFilterDTO);
  }
}
