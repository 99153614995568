import {Component, Input, OnInit} from '@angular/core';
import {DocumentDTO} from "../../../types/DocumentDTO";
import {FormBuilder, Validators} from "@angular/forms";
import {DocumentService} from "../../../_services/adminDocumentService/document-service";
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";
import {ActivatedRoute} from '@angular/router';
import {Observable} from "rxjs";

@Component({
  selector: 'app-e-admin-create-document',
  templateUrl: './create-document.html',
  styleUrls: ['./create-document.component.css']
})
export class eAdminCreateDocumentComponent implements OnInit {

  header: string = "Add New Document";
  documentId: string;

  createDocumentForm = this.fb.group({
    tag: ['', Validators.required],
    name: ['', Validators.required],
    description: [''],
    url: [''],
    file: [null, Validators.required]
  }, {validator: []});


  constructor(private fb: FormBuilder,
              private documentService: DocumentService,
              private toastr: ToastrService,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.documentId = this.route.snapshot.paramMap.get('id');
    if (this.documentId != null) {
      this.header = "Edit Document";
      this.loadData(this.documentId);
      this.createDocumentForm.get("file").clearValidators();
    }
  }

  loadData(documentId) {
    this.documentService.get(documentId).subscribe(data => {
      this.createDocumentForm.get("name").setValue(data.name);
      this.createDocumentForm.get("tag").setValue(data.tag);
      this.createDocumentForm.get("description").setValue(data.description);
      this.createDocumentForm.get("url").setValue(data.url);

    });
  }

  onSubmit() {
    let input = new FormData();
    input.append('name', this.createDocumentForm.get('name').value);
    input.append('tag', this.createDocumentForm.get('tag').value);
    input.append('description', this.createDocumentForm.get('description').value);
    input.append('file', this.createDocumentForm.get('file').value);
    if (this.documentId == null) {
      this.documentService.add(input).subscribe(data => {
        if (data.length) {
          this.toastr.success("Success", "Document created successfully!!")
          this.router.navigateByUrl('/sparkecademy-admin-document');
        }
      });
    } else {
      this.documentService.edit(this.documentId, input).subscribe(data => {
        if (data.length) {
          this.toastr.success("Success", "Document updated successfully!!")
          this.router.navigateByUrl('/sparkecademy-admin-document');
        }
      });
    }
  }

  onFileChange($event) {
    if ($event.target.files.length > 0) {
      let file = $event.target.files[0];
      this.createDocumentForm.get('file').setValue(file);
    }
  }

  reset(){
    this.router.navigateByUrl('/sparkecademy-admin-document');
  }

}
