import { Component, OnInit } from '@angular/core';
import {UpdateUserDTO} from "../../types/UpdateUserDTO";
import {FormBuilder} from "@angular/forms";
import {AdminUserService} from "../../_services/admin/adminUserService/admin-user.service";
import {IDDTO} from "../../types/IDDTO";

@Component({
  selector: 'app-admin-account-user-lookup-by-account-id',
  templateUrl: './admin-account-user-lookup-by-account-id.html',
  styleUrls: ['./admin-account-user-lookup-by-account-id.component.css']
})
export class AdminAccountUserLookupByAccountIdComponent implements OnInit {
  findUser = this.fb.group({
    accountId: [''],
  }, {validator: []});

  hasResponse : boolean = false;
  success : boolean = false;
  errorMessage: string;

  updateUserDTOsList : Array<UpdateUserDTO> = new Array<UpdateUserDTO>();


  editorLoaded : boolean = false;

  loadUser : UpdateUserDTO = null;
  constructor(private fb: FormBuilder, private adminUserService : AdminUserService) { }

  ngOnInit() {
  }

  findUserByAccountId(){
    if(this.findUser.get('accountId').value) {
      this.hasResponse = false;
      this.editorLoaded = false;
      this.updateUserDTOsList = new Array<UpdateUserDTO>();
      console.log(this.findUser.get('accountId').value);
      this.adminUserService.findUsersByAccountId(new IDDTO(this.findUser.get('accountId').value)).subscribe( data => {
        console.log(data);
        this.success = data.success;
        this.errorMessage = data.errorMessage;
        if(data.success == true) {
          data.users.forEach( user => {
            let userDTO : UpdateUserDTO;

            userDTO = new UpdateUserDTO();
            userDTO.email = user.email;
            userDTO.firstName = user.firstName;
            userDTO.lastName = user.lastName;
            userDTO.school = user.school;
            userDTO.id = user.id;
            userDTO.accountId = user.accountId;

            this.updateUserDTOsList.push(userDTO);
          });

          console.log(this.updateUserDTOsList);
        }
      });
      this.hasResponse = true;
    }
  }

  loadUserEditor(id : number){
    console.log("loading for id: " + id);


    this.updateUserDTOsList.forEach(user => {
        if(!this.loadUser) {
          if (user.id == id) {
            this.loadUser = new UpdateUserDTO();
            this.loadUser.email = user.email;
            this.loadUser.school = user.school;
            this.loadUser.firstName = user.firstName;
            this.loadUser.lastName = user.lastName;
            this.loadUser.id = user.id;
            console.log(this.loadUser);
          }
        }
      }
    );

    this.editorLoaded = true;
  }

  onUserChange(value){
    console.log("on user change");
    console.log(value);
    this.findUser.get('email').setValue(value.email);

    this.updateUserDTOsList.forEach(user => {
      if(user.id == value.id){
        user.school = value.school;
        user.email = value.email;
        user.firstName = value.firstName;
        user.lastName = value.lastName;
      }
    })
  }

  closeEditor(closeEditor){
    if(closeEditor) {
      this.editorLoaded = false;
      this.loadUser = null;
      this.findUserByAccountId();
    }
  }
}
