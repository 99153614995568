import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {NavEntry} from "../../../types/NavEntry";
import {AdminCurriculumService} from "../../../_services/admin/adminCurriculumService/admin-curriculum.service";
import {Curriculum} from "../../../types/Curriculum";
import {DisplayOrderUpdateDTO} from "../../../types/DisplayOrderUpdateDTO";
import {UpdateUserDTO} from "../../../types/UpdateUserDTO";

@Component({
  selector: 'app-admin-nav-entry-editor-curriculum-order-edit',
  templateUrl: './admin-nav-entry-editor-curriculum-order-edit.html',
  styleUrls: ['./admin-nav-entry-editor-curriculum-order-edit.component.css']
})
export class AdminNavEntryEditorCurriculumOrderEditComponent implements OnInit {

  orderDisplayLoaded : boolean = false;

  displayOrderForm: FormGroup  = this.fb.group({
  }, {validator: []});

  @Output() closeNavEntryOrderEditEmit=new EventEmitter<boolean>();


  @Output() closeDisplayOrderEditorEmit=new EventEmitter<boolean>();

  @Input() inputNavEntry : NavEntry;

  constructor(private curriculumService : AdminCurriculumService, private fb: FormBuilder) { }

  ngOnInit() {
    console.log(this.inputNavEntry);
    this.inputNavEntry.navEntryCurriculums.forEach(data => {
      this.displayOrderForm.addControl(data.curriculums.navText, new FormControl(false));
      this.displayOrderForm.controls[data.curriculums.navText].setValue(data.displayOrder);
    });

    console.log(this.displayOrderForm);
    this.orderDisplayLoaded = true;
  }

  saveDisplayOrderChanges(){
    let idOrderMap :  Map<String, Number> = new Map<String, Number>();
    this.inputNavEntry.navEntryCurriculums.forEach(data => {
      idOrderMap.set(data.navEntryCurriculumId + '', this.displayOrderForm.controls[data.curriculums.navText].value);
    });

    idOrderMap.forEach((val : number, key: string) => {
      idOrderMap[key] = val
    });

    let dtos : DisplayOrderUpdateDTO = new DisplayOrderUpdateDTO(idOrderMap);

    this.curriculumService.updateNavEntryCurriculumDisplayOrder(dtos).subscribe(data =>{
      this.closeDisplayOrderEditorEmit.emit(true);
    });
  }

  closeDisplayOrderEditor(){
    this.closeDisplayOrderEditorEmit.emit(true);
  }

}
