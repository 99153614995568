import { Component, OnInit } from '@angular/core';
import {ErrorStateMatcher} from "@angular/material";
import {FormBuilder, FormControl, FormGroupDirective, NgForm, Validators} from "@angular/forms";
import {
  EmailValidation,
  PasswordValidation,
  RepeatEmailValidator,
  RepeatPasswordValidator
} from "../../helpers/validators";
import {SelfRegistrationDTO} from "../../types/SelfRegistrationDTO";
import {AuthService} from "../../_services/authService/auth.service";
import {ErrorMessageService} from "../../_services/errorMessageService/error-message.service";
import {AccountService} from "../../_services/accountService/account.service";
import {UpdateAccountRoleDTO} from "../../types/UpdateAccountRoleDTO";


@Component({
  selector: 'app-self-register',
  templateUrl: './selfRegister.html',
  styleUrls: ['./self-register.component.css']
})
export class SelfRegisterComponent implements OnInit {

  displayProgressBar : boolean = false;
  displaySecondProgressBar : boolean = false;

  emailVerified : boolean = false;

  emailNotFound : boolean = false;

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  registrationForm = this.fb.group({
    firstName:['', Validators.required],
    lastName: ['', Validators.required],
    email: ['', EmailValidation],
    emailConfirm: ['', EmailValidation],
    password: ['', PasswordValidation],
    passwordConfirm: [''],
    school: ['', Validators.required],
    K2PE: false,
    PE36:false,
    MSPE:false,
    HSPE:false,
    IPE:false,
    ABCCA:false,
    AS:false,
    EC:false
  }, {validator: [RepeatPasswordValidator, RepeatEmailValidator]});

  constructor(private fb: FormBuilder, private errorMessageService: ErrorMessageService, private accountService: AccountService) { }

  ngOnInit() {
  }

  checkEmail(){
    this.displayProgressBar = true;
    this.accountService.verifyEmail(this.emailFormControl.value).subscribe(
      data => {
        console.log("response");
        console.log(data);
        if(data.success){
          this.emailVerified = true;
        } else {
          this.emailNotFound = true;
          this.displayProgressBar = false;
        }
      }
    );
  }

  createAccount(){

    let types : Array<string> = new Array<string>();

    this.registrationForm.get('K2PE').value ? types.push('K2PE'): null;
    this.registrationForm.get('PE36').value ? types.push('PE36'): null;
    this.registrationForm.get('MSPE').value ? types.push('MSPE'): null;
    this.registrationForm.get('HSPE').value ? types.push('HSPE'): null;
    this.registrationForm.get('IPE').value ? types.push('IPE'): null;
    this.registrationForm.get('ABCCA').value ? types.push('ABCCA'): null;
    this.registrationForm.get('AS').value ? types.push('AS'): null;
    this.registrationForm.get('EC').value ? types.push('EC'): null;
    console.log(types);
    /*let dto : SelfRegistrationDTO = new SelfRegistrationDTO(this.registrationForm.get('email').value, this.emailFormControl.value,
      this.registrationForm.get('firstName').value,this.registrationForm.get('lastName').value,this.registrationForm.get('password').value,
      this.registrationForm.get('school').value, types);

      this.errorMessageService.clear();
      console.log(dto);
    this.accountService.selfRegisterAccount(dto).subscribe(
      data => {
        if(data.success + '' === 'true') {
          console.log("good signup info");
          this.authService.login(dto.email, dto.password).subscribe();
        } else {
          console.log("bad signup info");
          this.errorMessageService.add(data.error);
        }
      }
    );*/
  }
}
