import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  UrlSegment,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from "../../_services/authService/auth.service";

@Injectable({
  providedIn: 'root'
})
export class LoginPageGuard {
  constructor(
    private router: Router,
    private authService: AuthService
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot):  Promise<boolean>  {
    return new Promise((resolve) =>
    {
      console.log("in login guard");
      const currentUser = this.authService.getCurrentUserValue();
      console.log(currentUser);
      if(!currentUser) {
        return resolve(true);
      }

      console.log("auth login false redirect");
      this.router.navigate(['home']);
      return resolve(false);
    });

  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }
}
