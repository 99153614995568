import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


import {IntroComponent} from "./user-site/intro/intro.component";
import {LoginComponent} from "./user-site/login/login.component";
import {SignupComponent} from "./user-site/signup/signup.component";
import {HomeComponent} from "./user-site/home/home.component";
import {AuthGuard} from "./helpers/guards/auth.guard";
import {NavComponent} from "./user-site/nav/nav.component";
import {NavEntryResolver, NavResolver} from "./helpers/resolvers/nav.resolver";
import {ModuleComponent} from "./user-site/module/module.component";
import {RestrictedcurriculumComponent} from "./user-site/restrictedcurriculum/restrictedcurriculum.component";
import {RestrictedCurriculumGuard} from "./helpers/guards/restricted.curriculum.guard";
import {GUARDS} from "./types/Guards";
import {MasterGuard} from "./helpers/guards/master.guard";
import {ModuleListResolver} from "./helpers/resolvers/modulelist.resolver";
import {ModuleFileListComponent} from "./user-site/module-file-list/module-file-list.component";
import {ModuleFileListResolver} from "./helpers/resolvers/moduleFileList.resolver";
import {UpdateProfileComponent} from "./user-site/update-profile/update-profile.component";
import {LicenseInfoComponent} from "./user-site/license-info/license-info.component";
import {UserManagementComponent} from "./user-site/user-management/user-management.component";
import {EarlierLicenseExpirationDateResolver, LicenseResolver} from "./helpers/resolvers/license.resolver";
import {AccountUserResolver} from "./helpers/resolvers/accountuser.resolver";
import {RoleCountsResolver} from "./helpers/resolvers/roleCounts.resolver";
import {ResetPasswordComponent} from "./user-site/reset-password/reset-password.component";
import {ForgotPasswordComponent} from "./user-site/forgot-password/forgot-password.component";
import {AdminRestrictedComponent} from "./user-site/admin-restricted/admin-restricted.component";
import {ModuleFolderContentsComponent} from "./user-site/module-folder-contents/module-folder-contents.component";
import {
  ModuleFolderContentsResolver,
  SecondaryModuleFolderContentsResolver,
  TTModuleFolderContentsResolver
} from "./helpers/resolvers/moduleFolderContents.resolver";
import {
  CurrentFolderParentResolver,
  CurrentFolderResolver, CurrentSecondaryFolderResolver,
  CurrentTTFolderResolver
} from "./helpers/resolvers/currentFolder.resolver";
import {CurrentCurriculumResolver} from "./helpers/resolvers/currentCurriculum.resolver";
import {
  ModuleBreadcrumbResolver,
  ModuleFolderBreadcrumbResolver, SecondaryModuleFolderBreadcrumbResolver,
  TTModuleBreadcrumbResolver, TTModuleFolderBreadcrumbResolver
} from "./helpers/resolvers/breadcrumb.resolver";
import {
  CurrentFolderSecondaryPageResolver,
  CurrentModuleResolver,
  CurrentTTModuleResolver
} from "./helpers/resolvers/currentModule.resolver";
import {LandingComponent} from "./user-site/landing/landing.component";
import {SelfRegisterComponent} from "./user-site/self-register/self-register.component";
import {ComingSoonComponent} from "./user-site/coming-soon/coming-soon.component";
import {InfoCaptureComponent} from "./user-site/info-capture/info-capture.component";
import {FaqComponent} from "./user-site/faq/faq.component";
import {AdminLoginComponent} from "./admin-dashboard/admin-login/admin-login.component";
import {AdminHomeComponent} from "./admin-dashboard/admin-home/admin-home.component";
import {
  CurrentSecondaryModuleResolver, FolderSecondaryModuleListResolver,
  SecondaryModuleFileListResolver,
  SecondaryModuleListResolver
} from "./helpers/resolvers/secondaryModuleResolver.resolver";
import {SecondaryModuleFileListComponent} from "./user-site/secondary-module-file-list/secondary-module-file-list.component";
import {UniversityModuleComponent} from "./user-site/university-module/university-module.component";
import {UniversityLinksResolver} from "./helpers/resolvers/university-links.resolver";
import {TtPermissionsResolver} from "./helpers/resolvers/ttPermissions.resolver";
import {TtModuleResolver} from "./helpers/resolvers/ttModule.resolver";
import {TtModuleFileListComponent} from "./user-site/tt-module-file-list/tt-module-file-list.component";
import {TtModuleFileResolver} from "./helpers/resolvers/ttModuleFile.resolver";
import {TtModuleFolderContentsComponent} from "./user-site/tt-module-folder-contents/tt-module-folder-contents.component";
import {DodeaComponent} from "./user-site/dodea/dodea.component";
import {IsAdminResolver} from "./helpers/resolvers/isAdmin.resolver";
import {HomePageResolver} from "./helpers/resolvers/homePage.resolver";
import {SecondaryModuleFolderContentsComponent} from "./user-site/secondary-module-folder-contents/secondary-module-folder-contents.component";
import {
  AccessingModuleFolderContentsViaTrialResolver,
  AccessingModuleViaTrialResolver,
  AccessingViaTrialResolver
} from "./helpers/resolvers/accessingViaTrial.resolver";
import {FreeTrialComponent} from "./user-site/free-trial/free-trial.component";
import {PrivacyPolicyComponent} from "./user-site/privacy-policy/privacy-policy.component";
import {ShowEulaPopupResolver} from "./helpers/resolvers/showEulaPopup.resolver";
import {eAdminDashboardComponent} from "./spark-ecademy/admin/dashboard/dashboard.component";
import {eAdminManageDocumentComponent} from "./spark-ecademy/admin/document/manage-document.component";
import {eAdminCreateDocumentComponent} from "./spark-ecademy/admin/document/create/create-document.component";

import {eAdminManageUserComponent} from "./spark-ecademy/admin/user/manage-user.component";
import {eAdminCreateUserComponent} from "./spark-ecademy/admin/user/create/create-user.component";
import {eAdminViewUserComponent} from "./spark-ecademy/admin/user/view/view-user.component";

import {eAdminManageCourseComponent} from "./spark-ecademy/admin/course/manage-course.component";
import {eAdminCreateCourseComponent} from "./spark-ecademy/admin/course/create/create-course.component";
import {eAdminViewCourseComponent} from "./spark-ecademy/admin/course/view/view-course.component";
import {eAdminManageOrganizationComponent} from "./spark-ecademy/admin/organization/manage-organization.component";
import {eAdminCreateOrganizationComponent} from "./spark-ecademy/admin/organization/create/create-organization.component";
import {eAdminViewOrganizationComponent} from "./spark-ecademy/admin/organization/view/view-organization.component";
import {eAdminListCourseContentsComponent} from "./spark-ecademy/admin/course/courseContents/list/list-course-contents.component";
import {eAdminCreateCourseContentComponent} from "./spark-ecademy/admin/course/courseContents/createLesson/create-course-contents.component";
import {eAdminCreateCourseQuizComponent} from "./spark-ecademy/admin/course/courseContents/createQuiz/create-course-quiz.component";
import {eAdminDashboardOrganizationComponent} from "./spark-ecademy/admin/organization/dashboard/dashboard-organization.component";
import {eAdminCreateOrganizationCourseComponent} from "./spark-ecademy/admin/organization/course/create/create-organization-course.component";
import {eAdminListOrganizationCourseComponent} from "./spark-ecademy/admin/organization/course/list/list-organization-course.component";
import {eAdminOrganizationDistributeCreditsComponent} from "./spark-ecademy/admin/organization/credit/distribute/distribute-credit.component";
import {eAdminOrganizationManageCreditsComponent} from "./spark-ecademy/admin/organization/credit/history/credit-history.component";
import {eAdminCourseEnrollmentListComponent} from "./spark-ecademy/admin/course/enrollments/list/list-course-enrollments.component";
import {eAdminEditCourseEnrollmentsComponent} from "./spark-ecademy/admin/course/enrollments/edit/edit-course-enrollments.component";
import {eAdminListOrganizationMemberComponent} from "./spark-ecademy/admin/organization/manageMembers/list/list-organization-member.component";
import {eDashboardComponent} from "./spark-ecademy/user/dashboard/dashboard.component";
import {CourseCategoryResolver, CourseTagResolver} from "./spark-ecademy/helpers/resolvers/nav.resolver";
import {eCourseCatalogComponent} from "./spark-ecademy/user/course/catalog/course-catalog.component";
import {eCourseSignupComponent} from "./spark-ecademy/user/course/signup/course-signup.component";
import {eCourseDetailsComponent} from "./spark-ecademy/user/course/details/course-details.component";
import {eCourseSummaryComponent} from "./spark-ecademy/user/course/summary/course-summary.component";
import {eUserManageDocumentComponent} from "./spark-ecademy/user/document/manage-document.component";
import {eAssignLicenseComponent} from "./spark-ecademy/user/license/license-catalog.component";
import {eAdminAssignLicenseComponent} from "./spark-ecademy/admin/license/list/admin-license-catalog.component";
import {eAdminCreateLicenseComponent} from "./spark-ecademy/admin/license/create/admin-create-license.component";
import {eAdminCourseCatalogComponent} from "./spark-ecademy/admin/course/catalog/course-catalog.component";
import {eAdminCreateOnSiteCourseComponent} from "./spark-ecademy/admin/onsiteCourse/create/admin-create-onSite-course.component";
import {eAdminViewOnSiteCourseComponent} from "./spark-ecademy/admin/onsiteCourse/view/admin-view-onSite-course.component";
import {eAssignOnSiteCourseComponent} from "./spark-ecademy/user/onSiteCourse/assign/assign-onSite-course.component";
import {eAdminCreateOrganizationMemberComponent} from "./spark-ecademy/admin/organization/manageMembers/create/create-organization-member.component";
import {eAdminViewOrganizationMemberComponent} from "./spark-ecademy/admin/organization/manageMembers/view/view-organization-member.component";
import {eAdminCourseCategoryComponent} from "./spark-ecademy/admin/course/category/list/course-catalog.component";
import {eAdminCreateCourseCategoryComponent} from "./spark-ecademy/admin/course/category/create/create-course-category.component";
import {eAdminCourseTagComponent} from "./spark-ecademy/admin/course/tag/list/course-tag.component";
import {eAdminCreateCourseTagComponent} from "./spark-ecademy/admin/course/tag/create/create-course-tag.component";
import {eAdminDashboardMessageComponent} from "./spark-ecademy/admin/dashboardMessage/dashboardMessage.component";
import {SearchLessonTagsResolver} from "./helpers/resolvers/searchLessonTags.resolver";
import {BulkUserUpdateComponent} from "./user-site/bulk-user-update/bulk-user-update.component";
import {eAdminBulkUpdateOrganizationMemberComponent} from "./spark-ecademy/admin/organization/manageMembers/bulkUpload/bulk-upload-organization-member.component";


const routes: Routes = [
  { path: '', redirectTo: '/intro', pathMatch: 'full' },
  { path: 'intro', component: IntroComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.LOGINGUARD]}},
  { path: 'login', component: LoginComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.LOGINGUARD]}},
  { path: 'signup', component: SignupComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.LOGINGUARD]}},
  // { path: 'free-trial', component: FreeTrialComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.LOGINGUARD]}}, Remove from login page
  { path: 'signup/:key', component: SignupComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.LOGINGUARD]}},
  { path: 'coming-soon', component: ComingSoonComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.LOGINGUARD]}},
  { path: 'selfRegister', component: SelfRegisterComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.LOGINGUARD]}},
  { path: 'home', component: HomeComponent, canActivate: [MasterGuard], data:  {guards: [GUARDS.AUTHGUARD]}, resolve: {curriculums: NavResolver, navEntries: NavEntryResolver, isAdmin: IsAdminResolver, hpc: HomePageResolver, licenseExpirationDate: EarlierLicenseExpirationDateResolver}},
  { path: 'module/:id', component: ModuleComponent, canActivate: [MasterGuard], data:  {guards: [GUARDS.AUTHGUARD, GUARDS.RESTRICTEDCURRICULUMGUARD]}, resolve: {curriculums: NavResolver, navEntries: NavEntryResolver, modules: ModuleListResolver, currentCurriculum: CurrentCurriculumResolver, hasTTPermission: TtPermissionsResolver, ttModuleList: TtModuleResolver, accessingViaTrial : AccessingViaTrialResolver, showEulaPopup: ShowEulaPopupResolver, licenseExpirationDate: EarlierLicenseExpirationDateResolver, searchLessonTags: SearchLessonTagsResolver}},
  { path: 'module/:id/:fileid', component: ModuleComponent, canActivate: [MasterGuard], data:  {guards: [GUARDS.AUTHGUARD, GUARDS.RESTRICTEDCURRICULUMGUARD]}, resolve: {curriculums: NavResolver, navEntries: NavEntryResolver, currentCurriculum: CurrentCurriculumResolver, hasTTPermission: TtPermissionsResolver, ttModuleList: TtModuleResolver, licenseExpirationDate: EarlierLicenseExpirationDateResolver}},
  { path: 'moduleFileList/:moduleId/:curriculumId', component: ModuleFileListComponent, canActivate: [MasterGuard], data:  {guards: [GUARDS.AUTHGUARD]}, resolve: {curriculums: NavResolver, navEntries: NavEntryResolver, moduleFiles: ModuleFileListResolver, breadcrumbs : ModuleBreadcrumbResolver, currentModule : CurrentModuleResolver, secondaryModules: SecondaryModuleListResolver, accessingViaTrial : AccessingModuleViaTrialResolver, licenseExpirationDate: EarlierLicenseExpirationDateResolver}},
  { path: 'moduleFolderContents/:parentId', component: ModuleFolderContentsComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.AUTHGUARD]}, resolve: {curriculums: NavResolver, navEntries: NavEntryResolver, moduleFiles: ModuleFolderContentsResolver, currentFolder: CurrentFolderResolver, breadcrumbs : ModuleFolderBreadcrumbResolver, secondaryModules: FolderSecondaryModuleListResolver, accessingViaTrial : AccessingModuleFolderContentsViaTrialResolver, licenseExpirationDate: EarlierLicenseExpirationDateResolver}},
  { path: 'restrictedcurriculum/:id', component: RestrictedcurriculumComponent, canActivate: [MasterGuard], data:  {guards: [GUARDS.AUTHGUARD]}, resolve: {curriculums:NavResolver, navEntries: NavEntryResolver, licenseExpirationDate: EarlierLicenseExpirationDateResolver}},
  { path: 'updateProfile', component: UpdateProfileComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.AUTHGUARD]}, resolve: {curriculums: NavResolver, navEntries: NavEntryResolver, licenseExpirationDate: EarlierLicenseExpirationDateResolver}},
  { path: 'licenseInfo', component: LicenseInfoComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.AUTHGUARD]}, resolve: {curriculums: NavResolver, navEntries: NavEntryResolver, licenseDTOList : LicenseResolver, licenseExpirationDate: EarlierLicenseExpirationDateResolver}},
  { path: 'userManagement', component: UserManagementComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.AUTHGUARD, GUARDS.ADMINGUARD]}, resolve: {curriculums: NavResolver, navEntries: NavEntryResolver, schoolGroups: AccountUserResolver, roleCounts: RoleCountsResolver, licenseExpirationDate: EarlierLicenseExpirationDateResolver}},
  { path: 'bulkUserUpdate', component: BulkUserUpdateComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.AUTHGUARD, GUARDS.ADMINGUARD]}, resolve: {curriculums: NavResolver, navEntries: NavEntryResolver, schoolGroups: AccountUserResolver, roleCounts: RoleCountsResolver, licenseExpirationDate: EarlierLicenseExpirationDateResolver}},
  { path: 'reset-password/:token', component: ResetPasswordComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.RESETPASSWORDGUARD]}},
  { path: 'forgot-password/:message', component: ForgotPasswordComponent},
  { path: 'forgot-password', component: ForgotPasswordComponent},
  { path: 'reset-password', component: ForgotPasswordComponent},
  { path: 'admin-restricted', component: AdminRestrictedComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.AUTHGUARD]}, resolve: {curriculums:NavResolver, navEntries: NavEntryResolver, licenseExpirationDate: EarlierLicenseExpirationDateResolver}},
  { path: 'admin', component: AdminLoginComponent},
  { path: 'admin-home', component: AdminHomeComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.ADMINDASHBOARDGUARD]}},
  { path: 'faq', component: FaqComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.AUTHGUARD]}, resolve: {curriculums:NavResolver, navEntries: NavEntryResolver, licenseExpirationDate: EarlierLicenseExpirationDateResolver}},
  { path: 'university/:id', component: UniversityModuleComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.AUTHGUARD]}, resolve: {curriculums:NavResolver, navEntries: NavEntryResolver, uniLinks: UniversityLinksResolver, currentCurriculum: CurrentCurriculumResolver, licenseExpirationDate: EarlierLicenseExpirationDateResolver}},
  { path: 'secondary-module-file-list/:secondaryModuleId/:id/:moduleId', component: SecondaryModuleFileListComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.AUTHGUARD]}, resolve: {curriculums:NavResolver, navEntries: NavEntryResolver, secondaryModuleFiles: SecondaryModuleFileListResolver, currentSecondaryModule: CurrentSecondaryModuleResolver, currentCurriculum: CurrentCurriculumResolver,  currentModule : CurrentModuleResolver, currentFolder: CurrentFolderSecondaryPageResolver, currentFolderParent: CurrentFolderParentResolver, licenseExpirationDate: EarlierLicenseExpirationDateResolver}},
  { path: 'secondaryFolderContents/:parentId', component: SecondaryModuleFolderContentsComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.AUTHGUARD]}, resolve: {curriculums: NavResolver, navEntries: NavEntryResolver, moduleFiles: SecondaryModuleFolderContentsResolver, currentFolder: CurrentSecondaryFolderResolver, breadcrumbs : SecondaryModuleFolderBreadcrumbResolver, licenseExpirationDate: EarlierLicenseExpirationDateResolver}},
  { path: 'ttModuleFileList/:moduleId/:curriculumId', component: TtModuleFileListComponent, canActivate: [MasterGuard], data:  {guards: [GUARDS.AUTHGUARD]}, resolve: {curriculums: NavResolver, navEntries: NavEntryResolver, moduleFiles: TtModuleFileResolver, breadcrumbs : TTModuleBreadcrumbResolver, currentModule : CurrentTTModuleResolver, licenseExpirationDate: EarlierLicenseExpirationDateResolver}},
  { path: 'ttModuleFolderContents/:parentId', component: TtModuleFolderContentsComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.AUTHGUARD]}, resolve: {curriculums: NavResolver, navEntries: NavEntryResolver, moduleFiles: TTModuleFolderContentsResolver, currentFolder: CurrentTTFolderResolver, breadcrumbs : TTModuleFolderBreadcrumbResolver, licenseExpirationDate: EarlierLicenseExpirationDateResolver}},
  { path: 'bucketFolderContents/:parentId', component: SecondaryModuleFolderContentsComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.AUTHGUARD]}, resolve: {curriculums: NavResolver, navEntries: NavEntryResolver, moduleFiles: SecondaryModuleFolderContentsResolver, currentFolder: CurrentSecondaryFolderResolver, breadcrumbs : SecondaryModuleFolderBreadcrumbResolver, licenseExpirationDate: EarlierLicenseExpirationDateResolver}},
  { path: 'privacy-policy', component: PrivacyPolicyComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.AUTHGUARD]}, resolve: {curriculums: NavResolver, navEntries: NavEntryResolver, licenseExpirationDate: EarlierLicenseExpirationDateResolver}},

  { path: 'sparkecademy-admin-catalog/:courseUrl', component: eAdminCourseCatalogComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.ADMINDASHBOARDGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},
  { path: 'sparkecademy-admin-catalog', component: eAdminCourseCatalogComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.ADMINDASHBOARDGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},

  { path: 'sparkecademy-admin-home', component: eAdminDashboardComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.ADMINDASHBOARDGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},
  { path: 'sparkecademy-admin-document', component: eAdminManageDocumentComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.ADMINDASHBOARDGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},
  { path: 'sparkecademy-admin-document/new', component: eAdminCreateDocumentComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.ADMINDASHBOARDGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},
  { path: 'sparkecademy-admin-document/edit/:id', component: eAdminCreateDocumentComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.ADMINDASHBOARDGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},


  { path: 'sparkecademy-admin-user', component: eAdminManageUserComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.ADMINDASHBOARDGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},
  { path: 'sparkecademy-admin-user/new', component: eAdminCreateUserComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.ADMINDASHBOARDGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},
  { path: 'sparkecademy-admin-user/edit/:id', component: eAdminCreateUserComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.ADMINDASHBOARDGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},
  { path: 'sparkecademy-admin-user/view/:id', component: eAdminViewUserComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.ADMINDASHBOARDGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},


  { path: 'sparkecademy-admin-organization', component: eAdminManageOrganizationComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.ADMINDASHBOARDGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},
  { path: 'sparkecademy-admin-organization/new', component: eAdminCreateOrganizationComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.ADMINDASHBOARDGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},
  { path: 'sparkecademy-admin-organization/edit/:id', component: eAdminCreateOrganizationComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.ADMINDASHBOARDGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},
  { path: 'sparkecademy-admin-organization/view/:id', component: eAdminViewOrganizationComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.ADMINDASHBOARDGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},

  { path: 'sparkecademy-admin-organization/dashboard/:id', component: eAdminDashboardOrganizationComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.ADMINORMANAGERDASHBOARDGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},
  { path: 'sparkecademy-admin-organization/:id/courses', component: eAdminListOrganizationCourseComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.ADMINORMANAGERDASHBOARDGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},
  { path: 'sparkecademy-admin-organization/:id/course/create', component: eAdminCreateOrganizationCourseComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.ADMINORMANAGERDASHBOARDGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},
  { path: 'sparkecademy-admin-organization/:id/course/:organizationCourseId', component: eAdminCreateOrganizationCourseComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.ADMINORMANAGERDASHBOARDGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},
  { path: 'sparkecademy-admin-organization/:id/distribute-credits', component: eAdminOrganizationDistributeCreditsComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.ADMINORMANAGERDASHBOARDGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},
  { path: 'sparkecademy-admin-organization/:id/manage-credits', component: eAdminOrganizationManageCreditsComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.ADMINORMANAGERDASHBOARDGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},
  { path: 'sparkecademy-admin-organization/:id/manage-members', component: eAdminListOrganizationMemberComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.ADMINORMANAGERDASHBOARDGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},
  { path: 'sparkecademy-admin-organization/:id/member/create', component: eAdminCreateOrganizationMemberComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.ADMINORMANAGERDASHBOARDGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},
  { path: 'sparkecademy-admin-organization/:id/member/bulk-upload', component: eAdminBulkUpdateOrganizationMemberComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.ADMINORMANAGERDASHBOARDGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},
  { path: 'sparkecademy-admin-organization/:id/member/edit/:memberId', component: eAdminCreateOrganizationMemberComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.ADMINORMANAGERDASHBOARDGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},
  { path: 'sparkecademy-admin-organization/:id/member/view/:memberId', component: eAdminViewOrganizationMemberComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.ADMINORMANAGERDASHBOARDGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},

  { path: 'sparkecademy-admin-license', component: eAdminAssignLicenseComponent , canActivate: [MasterGuard], data: {guards: [GUARDS.ADMINDASHBOARDGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},
  { path: 'sparkecademy-admin-create-license', component: eAdminCreateLicenseComponent , canActivate: [MasterGuard], data: {guards: [GUARDS.ADMINDASHBOARDGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},

  { path: 'sparkecademy-admin-course/:id/sparkecademy-admin-create-onsite-course-token', component: eAdminCreateOnSiteCourseComponent , canActivate: [MasterGuard], data: {guards: [GUARDS.ADMINDASHBOARDGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},
  { path: 'sparkecademy-admin-course/:id/sparkecademy-admin-onsite-course-token/edit/:onSiteCourseTokenId', component: eAdminCreateOnSiteCourseComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.ADMINDASHBOARDGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},
  { path: 'sparkecademy-admin-course/:id/sparkecademy-admin-onsite-course-token/view/:onSiteCourseTokenId', component: eAdminViewOnSiteCourseComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.ADMINDASHBOARDGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},

  { path: 'sparkecademy-admin-course', component: eAdminManageCourseComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.ADMINDASHBOARDGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},
  { path: 'sparkecademy-admin-course/new', component: eAdminCreateCourseComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.ADMINDASHBOARDGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},
  { path: 'sparkecademy-admin-course/edit/:id', component: eAdminCreateCourseComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.ADMINDASHBOARDGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},
  { path: 'sparkecademy-admin-course/:id/contents', component: eAdminListCourseContentsComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.ADMINDASHBOARDGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},
  { path: 'sparkecademy-admin-course/:courseId/lesson/:lessonId/content/edit', component: eAdminCreateCourseContentComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.ADMINDASHBOARDGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},
  { path: 'sparkecademy-admin-course/:courseId/lesson/:lessonId/quiz/edit', component: eAdminCreateCourseQuizComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.ADMINDASHBOARDGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},
  { path: 'sparkecademy-admin-course/view/:id', component: eAdminViewCourseComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.ADMINDASHBOARDGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},
  { path: 'sparkecademy-admin-course/:id/enrollments', component: eAdminCourseEnrollmentListComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.ADMINDASHBOARDGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},
  { path: 'sparkecademy-admin-course/:id/enrollments/:enrollmentId', component: eAdminEditCourseEnrollmentsComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.ADMINDASHBOARDGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},


  { path: 'sparkecademy-admin-course-category', component: eAdminCourseCategoryComponent , canActivate: [MasterGuard], data: {guards: [GUARDS.ADMINDASHBOARDGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},
  { path: 'sparkecademy-admin-course-category/new', component: eAdminCreateCourseCategoryComponent , canActivate: [MasterGuard], data: {guards: [GUARDS.ADMINDASHBOARDGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},
  { path: 'sparkecademy-admin-course-category/edit/:id', component: eAdminCreateCourseCategoryComponent , canActivate: [MasterGuard], data: {guards: [GUARDS.ADMINDASHBOARDGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},

  { path: 'sparkecademy-admin-course-tag', component: eAdminCourseTagComponent , canActivate: [MasterGuard], data: {guards: [GUARDS.ADMINDASHBOARDGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},
  { path: 'sparkecademy-admin-course-tag/new', component: eAdminCreateCourseTagComponent , canActivate: [MasterGuard], data: {guards: [GUARDS.ADMINDASHBOARDGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},
  { path: 'sparkecademy-admin-course-tag/edit/:id', component: eAdminCreateCourseTagComponent , canActivate: [MasterGuard], data: {guards: [GUARDS.ADMINDASHBOARDGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},

  { path: 'sparkecademy-admin-dashboard-contents', component: eAdminDashboardMessageComponent , canActivate: [MasterGuard], data: {guards: [GUARDS.ADMINDASHBOARDGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},



  { path: 'sparkecademy-user-dashboard', component: eDashboardComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.AUTHGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},
  { path: 'sparkecademy-catalog/:courseUrl', component: eCourseCatalogComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.AUTHGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},
  { path: 'sparkecademy-catalog', component: eCourseCatalogComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.AUTHGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},

  { path: 'sparkecademy-catalog/courses/:courseUrl', component: eCourseSummaryComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.AUTHGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},
  { path: 'sparkecademy-catalog/courses/:courseUrl/enrollments/new', component: eCourseSignupComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.AUTHGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},
  { path: 'sparkecademy-catalog/courses/:courseUrl/lessons/:lessonId', component: eCourseDetailsComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.AUTHGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},

  { path: 'sparkecademy-user-document', component: eUserManageDocumentComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.AUTHGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},
  { path: 'sparkecademy-user-license', component: eAssignLicenseComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.AUTHGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},

  { path: 'sparkecademy-user-onsite-course', component: eAssignOnSiteCourseComponent, canActivate: [MasterGuard], data: {guards: [GUARDS.AUTHGUARD]}, resolve: {courseTags: CourseTagResolver, courseCategories: CourseCategoryResolver}},


  { path: '**', redirectTo: '/home' }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
