import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {Curriculum} from "../../types/Curriculum";
import {LicenseExpirationDateDTO} from "../../types/LicenseExpirationDateDTO";

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {


  curriculums: Curriculum[];

  licenseExpirationDate: LicenseExpirationDateDTO;

  navEntries : any;


  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.data.subscribe(data =>
    {
      console.log(data);
      this.curriculums = data.curriculums;
      console.log(this.curriculums);
      this.navEntries = data.navEntries;
      console.log(this.curriculums);
      this.licenseExpirationDate = data.licenseExpirationDate;
    });
  }


}
