import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {CourseLessonContentDTO} from "../../../../types/CourseLessonContentDTO";
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import {CourseContentService} from "../../../../_services/adminCourseService/course-content-service";
import {Router} from "@angular/router";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-e-admin-lesson-nav',
  templateUrl: './admin-lesson-nav.html',
  styleUrls: ['./admin-lesson-nav.component.css']
})
export class eAdminLessonNavComponent implements OnInit {
  @Input() createCourseContentsForm: FormGroup;

  @Input() courseId: string;
  @Input() lessonId: string;

  modalRef: BsModalRef;

  @ViewChild('confirmation')
  private confirmationTemplate: TemplateRef<any>;

  constructor(private fb: FormBuilder,
              private courseContentService: CourseContentService,
              private router: Router,
              private modalService: BsModalService) {
  }

  ngOnInit() {
  }

  get courseContentFormArr(): FormArray {
    return this.createCourseContentsForm.get('courseContent') as FormArray;
  }

  addNewCourseContentData(data: CourseLessonContentDTO) {
    this.courseContentFormArr.push(
      this.fb.group({
        caption: [data.caption],
        type: [data.type],
        file: [data.file],
        fileUrl: [data.fileUrl],
        id: [data.id]
      }));
  }

  addEmbeddedVideoForm() {
    this.addNewCourseContentData(new CourseLessonContentDTO(null, "", "VIDEO_CODE", ""));
  }

  addFreeTextForm() {
    this.addNewCourseContentData(new CourseLessonContentDTO(null, "", "TEXT", ""));
  }

  addDocumentUploadForm() {
    this.addNewCourseContentData(new CourseLessonContentDTO(null, "", "FILE", ""));
  }

  removeLesson() {
    this.modalRef = this.modalService.show(this.confirmationTemplate, {class: 'modal-sm'});
  }

  closeLesson() {
    this.router.navigateByUrl("/sparkecademy-admin-course/" + this.courseId + "/contents");
  }

  confirm(): void {
    this.courseContentService.removeLesson(this.courseId, this.lessonId).subscribe(data => {
      this.closeLesson();
    });
    this.modalRef.hide();
  }

  decline(): void {
    this.modalRef.hide();
  }
}
