import {Component, Input, OnInit} from '@angular/core';
import {DocumentDTO} from "../../../types/DocumentDTO";
import {FormBuilder, Validators} from "@angular/forms";
import {DocumentService} from "../../../_services/adminDocumentService/document-service";
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";
import {ActivatedRoute} from '@angular/router';
import {Observable} from "rxjs";
import {User} from "../../../../types/User";
import {UserService} from "../../../_services/adminUserService/user-service";
import {RepeatPasswordValidator} from "../../../../helpers/validators";
import {UserDTO} from "../../../types/UserDTO";
import {OrganizationDTO} from "../../../types/OrganizationDTO";
import {OrganizationFilterDTO} from "../../../types/OrganizationFilterDTO";
import {OrganizationService} from "../../../_services/adminOrganizationService/organization-service";

@Component({
  selector: 'app-e-user-create-document',
  templateUrl: './create-user.html',
  styleUrls: ['./create-user.component.css']
})
export class eAdminCreateUserComponent implements OnInit {

  header: string = "Add New User";

  organizations: OrganizationDTO[];

  userId: string;

  createUserForm = this.fb.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    email: ['', Validators.required],
    gradeLevel: [''],
    organization: [''],
    school: [''],
    password: [''],
    passwordConfirm: [''],
    canUserLogIn: [''],
    isManager: [false],
  });

  constructor(private fb: FormBuilder,
              private userService: UserService,
              private organizationService: OrganizationService,
              private toastr: ToastrService,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.userId = this.route.snapshot.paramMap.get('id');
    if (this.userId != null) {
      this.header = "Edit User";
      this.loadData(this.userId);
    } else {
      this.createUserForm.setValidators(RepeatPasswordValidator);
    }

    let organizationFilterDTO = new OrganizationFilterDTO(0, 1000, 0);
    this.organizationService.filter(organizationFilterDTO).subscribe(data => {
      this.organizations = data.organizations;
    });
  }

  loadData(userId) {
    this.userService.get(userId).subscribe(data => {
      this.createUserForm.get("firstName").setValue(data.firstName);
      this.createUserForm.get("lastName").setValue(data.lastName);
      this.createUserForm.get("email").setValue(data.email);
      this.createUserForm.get("gradeLevel").setValue(data.gradeLevel);
      this.createUserForm.get("school").setValue(data.school);
      this.createUserForm.get("organization").setValue(data.organization);
      this.createUserForm.get("isManager").setValue(data.isManager);
    });
  }

  onSubmit() {
    let userDTO = new UserDTO(
      this.getField("firstName"),
      this.getField("lastName"),
      this.getField("email"),
      this.getField("gradeLevel"),
      this.getField("school"),
      null, null,
      this.getField("password"),
      this.getField("canUserLogIn"),
      this.getField("isManager"),
      "SPARK_ECADEMY",
      this.getField("organization"),
    );


    if (this.userId == null) {
      this.userService.add(userDTO).subscribe(data => {
        if (!data.success) {
          this.toastr.error(data.errorMessage, "Error");
        }
        if (data.success) {
          this.toastr.success("Success", "User created successfully!!")
          this.router.navigateByUrl('/sparkecademy-admin-user');
        }
      });
    } else {
      this.userService.edit(this.userId, userDTO).subscribe(data => {
        if (data.response == 'success') {
          this.toastr.success("Success", "User updated successfully!!")
          this.router.navigateByUrl('/sparkecademy-admin-user');
        }
      });
    }
  }

  getField(fieldName: string) {
    return this.createUserForm.get(fieldName).value;
  }

  reset() {
    this.router.navigateByUrl('/sparkecademy-admin-user');
  }
}
