import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {ActivatedRoute, Router} from "@angular/router";
import {OnSiteCourseDTO} from "../../../types/OnSiteCourseDTO";
import {OnSiteCourseService} from "../../../_services/adminLiveCourseService/on-site-course.service";

@Component({
  selector: 'app-e-admin-create-onSite-course',
  templateUrl: './admin-create-onSite-course.html',
  styleUrls: ['./admin-create-onSite-course.component.css']
})
export class eAdminCreateOnSiteCourseComponent implements OnInit {

  header: string = "Add New Course Token";
  onSiteCourseId: string;
  courseId: string;

  createCourseTokenForm = this.fb.group({
    courseYear: ['', Validators.required],
    courseMonth: ['', Validators.required],
    courseDay: ['', Validators.required],

    validFromYear: ['', Validators.required],
    validFromMonth: ['', Validators.required],
    validFromDay: ['', Validators.required],

    validToYear: ['', Validators.required],
    validToMonth: ['', Validators.required],
    validToDay: ['', Validators.required],

    enrollmentAllowed: ['', Validators.required]


  }, {validator: []});

  constructor(private fb: FormBuilder,
              private onSiteCourseService: OnSiteCourseService,
              private toastr: ToastrService,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.courseId = this.route.snapshot.paramMap.get('id');
    this.onSiteCourseId = this.route.snapshot.paramMap.get('onSiteCourseTokenId');
    if (this.onSiteCourseId != null) {
      this.loadData();
    }
  }

  loadData() {
    this.onSiteCourseService.get(parseInt(this.onSiteCourseId)).subscribe(data => {

      let validFrom = data.validFrom.split("/");
      let validTo = data.validTo.split("/");
      let courseDate = data.courseDate.split("/");

      this.createCourseTokenForm.get("courseYear").setValue(courseDate[2]);
      this.createCourseTokenForm.get("courseMonth").setValue(courseDate[0]);
      this.createCourseTokenForm.get("courseDay").setValue(courseDate[1]);

      this.createCourseTokenForm.get("validFromYear").setValue(validFrom[2]);
      this.createCourseTokenForm.get("validFromMonth").setValue(validFrom[0]);
      this.createCourseTokenForm.get("validFromDay").setValue(validFrom[1]);

      this.createCourseTokenForm.get("validToYear").setValue(validTo[2]);
      this.createCourseTokenForm.get("validToMonth").setValue(validTo[0]);
      this.createCourseTokenForm.get("validToDay").setValue(validTo[1]);

      this.createCourseTokenForm.get("enrollmentAllowed").setValue(data.enrollmentAllowed);
    });
  }

  onSubmit() {

    let courseYear = this.getField("courseYear");
    let courseMonth = this.getField("courseMonth");
    let courseDay = this.getField("courseDay");

    let validFromYear = this.getField("validFromYear");
    let validFromMonth = this.getField("validFromMonth");
    let validFromDay = this.getField("validFromDay");

    let validToYear = this.getField("validToYear");
    let validToMonth = this.getField("validToMonth");
    let validToDay = this.getField("validToDay");

    let enrollmentAllowed = this.getField("enrollmentAllowed");

    let validFromDate = validFromMonth + "/" + validFromDay + "/" + validFromYear;
    let validToDate = validToMonth + "/" + validToDay + "/" + validToYear;
    let courseDate = courseMonth + "/" + courseDay + "/" + courseYear;

    let creditLicenseDTO = new OnSiteCourseDTO(validFromDate,
      validToDate,
      courseDate,
      enrollmentAllowed,
      this.courseId
    );

    if (new Date(validFromDate).getTime() > new Date(validToDate).getTime()) {
      this.toastr.error("End date must be greater than valid Start date", "Error");
      return;
    }

    if (this.onSiteCourseId == null) {

      this.onSiteCourseService.create(creditLicenseDTO).subscribe(data => {
        if (!data.success) {
          this.toastr.error(data.errorMessage, "Error");
        }
        if (data.success) {
          this.toastr.success("Success", "Course Code created successfully!!")
          this.router.navigateByUrl('/sparkecademy-admin-course/view/' + this.courseId);
        }
      });

    } else {

      this.onSiteCourseService.edit(parseInt(this.onSiteCourseId), creditLicenseDTO).subscribe(data => {
        if (!data.success) {
          this.toastr.error(data.errorMessage, "Error");
        }
        if (data.success) {
          this.toastr.success("Success", "Course Code updated successfully!!")
          this.router.navigateByUrl('/sparkecademy-admin-course/view/' + this.courseId);
        }
      });

    }
  }

  getField(fieldName: string) {
    return this.createCourseTokenForm.get(fieldName).value;
  }
}
