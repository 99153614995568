import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {ActivatedRoute, Router} from "@angular/router";
import {CourseCategoryService} from "../../../../_services/adminCourseService/course-category-service";
import {CourseCategoryDTO} from "../../../../types/CourseCategoryDTO";

@Component({
  selector: 'app-e-admin-create-course-category',
  templateUrl: './create-course-category.html',
  styleUrls: ['./create-course-category.component.css']
})
export class eAdminCreateCourseCategoryComponent implements OnInit {

  header: string = "Add New Course Category";
  courseCategoryId: string;

  createCourseCategoryForm = this.fb.group({
    name: ['', Validators.required],
    colorCode: ['', Validators.required],
    url: ['', Validators.required],
    img: ['', Validators.required],
    isActive: [false]
  }, {validator: []});

  constructor(private fb: FormBuilder,
              private courseCategoryService: CourseCategoryService,
              private toastr: ToastrService,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.courseCategoryId = this.route.snapshot.paramMap.get('id');
    if (this.courseCategoryId != null) {
      this.header = "Edit Course Category";
      this.loadData();
    }
  }

  loadData() {
    this.courseCategoryService.getCourseCategory(this.courseCategoryId).subscribe(data => {
      this.createCourseCategoryForm.get("name").setValue(data.name);
      this.createCourseCategoryForm.get("colorCode").setValue(data.colorCode);
      this.createCourseCategoryForm.get("url").setValue(data.url);
      this.createCourseCategoryForm.get("img").setValue(data.img);
      this.createCourseCategoryForm.get("isActive").setValue(data.isActive == null ? null : data.isActive);
    });
  }

  onSubmit() {
    let courseCategoryDTO = new CourseCategoryDTO(
      null,
      this.getField("name"),
      this.getField("colorCode"),
      this.getField("url"),
      this.getField("img"),
      this.getField("isActive")
    );


    if (this.courseCategoryId == null) {
      this.courseCategoryService.addCourseCategory(courseCategoryDTO).subscribe(data => {
        if (!data.success) {
          this.toastr.error(data.errorMessage, "Error");
        }
        if (data.success) {
          this.toastr.success("Success", "Course Category created successfully!!")
          this.router.navigateByUrl('/sparkecademy-admin-course-category');
        }
      });
    } else {
      this.courseCategoryService.editCourseCategory(this.courseCategoryId, courseCategoryDTO).subscribe(data => {
        if (data.success) {
          this.toastr.success("Success", "Course Category updated successfully!!")
          this.router.navigateByUrl('/sparkecademy-admin-course-category');
        }
      });
    }
  }

  reset() {
    this.router.navigateByUrl('/sparkecademy-admin-course-category');
  }

  getField(fieldName: string) {
    return this.createCourseCategoryForm.get(fieldName).value;
  }
}
