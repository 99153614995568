import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';


@Component({
  selector: 'app-file-audio',
  templateUrl: './file-audio.html',
  styleUrls: ['./file-audio.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileAudioComponent implements OnInit {


  _fileAudio1 : string;
  _fileAudio2 : string;
  _displayName : string;

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit() {
  }



  @Input()
  set fileAudio(fileName : string){
    if(fileName && !(this._fileAudio1)) {
      console.log(fileName);
      this._fileAudio1 = "/assets/" + fileName;
      this.cdr.detectChanges();
      this._fileAudio2 = null;
    } else {
      console.log(fileName);
      this._fileAudio2 = "/assets/" + fileName;
      this.cdr.detectChanges();
      this._fileAudio1 = null;
    }
  }

  @Input()
  set displayName(displayName : string){
    if(displayName) {
      console.log(displayName);
      this._displayName = displayName;
      this.cdr.detectChanges();
    }
  }
}
