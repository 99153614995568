import {Component, OnInit} from '@angular/core';

export class User {
  email: string;
  firstName: string;
  lastName: string;
  token: string;
  school: string;
  organization: number;
  isManager: boolean;
  credits: number

  constructor(email: string, firstName: string, lastName: string, token: string, school: string) {
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.token = token;
    this.school = school;
  }
}
