import {Component, Input, OnInit, SimpleChanges, TemplateRef, ViewChild} from '@angular/core';
import {DocumentDTO} from "../../../types/DocumentDTO";
import {DocumentService} from "../../../_services/adminDocumentService/document-service";
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";

import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-e-admin-list-document',
  templateUrl: 'list-document.html',
  styleUrls: ['list-document.component.css']
})
export class eAdminListDocumentComponent implements OnInit {

  @Input() documentList: DocumentDTO[];

  @ViewChild('confirmation')
  private confirmationTemplate: TemplateRef<any>;

  modalRef: BsModalRef;

  recordToDelete: number;

  constructor(private documentService: DocumentService,
              private toastr: ToastrService,
              private router: Router,
              private modalService: BsModalService) {
    documentService.findAll().subscribe(data => {
      this.documentList = data;
    });
  }

  edit(document) {
    this.router.navigateByUrl('sparkecademy-admin-document/edit/' + document.resourceId).then(r => {
    });
  }

  remove(document) {
    this.modalRef = this.modalService.show(this.confirmationTemplate, {class: 'modal-sm'});
    this.recordToDelete = document.resourceId;
  }

  confirm(): void {
    this.documentService.remove(this.recordToDelete).subscribe(data => {
      if (data.length) {
        this.toastr.success("Document deleted successfully!!", "Success")
        this.documentList = data;
      }
    });
    this.modalRef.hide();
  }

  decline(): void {
    this.modalRef.hide();
  }

  ngOnInit() {
  }
}
