import { Component, OnInit } from '@angular/core';
import {Curriculum} from "../../types/Curriculum";
import {ActivatedRoute} from "@angular/router";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {LicenseExpirationDateDTO} from "../../types/LicenseExpirationDateDTO";

@Component({
  selector: 'app-home',
  templateUrl: `./home.html`,
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  curriculums: Curriculum[];

  licenseExpirationDate: LicenseExpirationDateDTO;

  navEntries : any;

  isAdmin : boolean;

  about: SafeHtml;
  what: SafeHtml;
  new: SafeHtml;
  aboutAdmin: SafeHtml;
  whatAdmin: SafeHtml;
  newAdmin: SafeHtml;

  constructor(private route: ActivatedRoute, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    console.log("curriculum value");
    //this.route.data.subscribe(data => this.curriculums = data.valueOf("curriculums").curriculums);
    this.route.data.subscribe(data =>
    {
      console.log("data stuff");
      console.log(data);
      this.licenseExpirationDate = data.licenseExpirationDate;
      this.curriculums = data.curriculums;
      console.log(this.curriculums);
      this.navEntries = data.navEntries;
      console.log(this.navEntries);
      this.isAdmin = data.isAdmin;
      console.log("is admin: " + this.isAdmin);

      console.log(data.hpc);

      console.log(data.hpc);
      Object.keys(data.hpc).forEach(key => {
        console.log(data.hpc[key]);
          if ("ABOUT" === key) {
            this.about = this.sanitizer.bypassSecurityTrustHtml(data.hpc[key].columnContent);
        }
        if ("WHAT" === key) {
          this.what = this.sanitizer.bypassSecurityTrustHtml(data.hpc[key].columnContent);
        }
        if ("NEW" === key) {
          this.new = this.sanitizer.bypassSecurityTrustHtml(data.hpc[key].columnContent);
        }
        if ("ABOUT_ADMIN" === key) {
          this.aboutAdmin = this.sanitizer.bypassSecurityTrustHtml(data.hpc[key].columnContent);
        }
        if ("WHAT_ADMIN" === key) {
          this.whatAdmin = this.sanitizer.bypassSecurityTrustHtml(data.hpc[key].columnContent);
        }
        if ("NEW_ADMIN" === key) {
          this.newAdmin = this.sanitizer.bypassSecurityTrustHtml(data.hpc[key].columnContent);
        }
        }
      );

    });

  }

}
