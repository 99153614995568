import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Sort} from "@angular/material/sort";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ToastrService} from "ngx-toastr";
import {ActivatedRoute, Router} from "@angular/router";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {OrganizationService} from "../../../../_services/adminOrganizationService/organization-service";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PaginationOrganizationMemberDTO} from "../../../../types/PaginationOrganizationMemberDTO";
import {DistributeCreditDTO} from "../../../../types/DistributeCreditDTO";
import {DistributeCreditRequestDTO} from "../../../../types/DistributeCreditRequestDTO";
import {AuthService} from "../../../../../_services/authService/auth.service";

@Component({
  selector: 'app-e-admin-organization-distribute-credit',
  templateUrl: 'distribute-credit.html',
  styleUrls: ['distribute-credit.component.css']
})
export class eAdminOrganizationDistributeCreditsComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  sort: Sort;

  @ViewChild('confirmation')
  private confirmationTemplate: TemplateRef<any>;

  modalRef: BsModalRef;

  organizationId: string;

  recordToDelete: number;

  isChecked: boolean = false;

  distributeCreditRequest: DistributeCreditRequestDTO;

  paginationOrganizationMemberList: PaginationOrganizationMemberDTO;

  distributeCreditForm = this.fb.group({
    distributeCredits: [],
    userCredits: this.fb.array([
      this.fb.group({
        isChecked: [false],
        userId: [''],
        name: [''],
        credits: [''],
        updatedCredits: [''],
        creditsSum: [''],
        role: ['']
      })
    ])
  }, {validator: []});

  constructor(private fb: FormBuilder,
              private organizationService: OrganizationService,
              private toastr: ToastrService,
              private router: Router,
              private authService: AuthService,
              private modalService: BsModalService,
              private route: ActivatedRoute) {
  }


  get distributeCreditFormArr(): FormArray {
    return this.distributeCreditForm.get('userCredits') as FormArray;
  }

  edit(organization) {
    this.router.navigateByUrl('sparkecademy-admin-organization/edit/' + organization.id).then(r => {
    });
  }

  getCreditsSum(recordIndex) {
    let credits = this.distributeCreditFormArr.at(recordIndex).get("credits").value;
    let updatedCredits = this.distributeCreditFormArr.at(recordIndex).get("updatedCredits").value;
    if (!isNaN(credits) && !isNaN(updatedCredits)) {
      return "Total Credits : " + (credits + updatedCredits);
    }
  }

  dashboard(organization) {
    this.router.navigateByUrl('sparkecademy-admin-organization/dashboard/' + organization.id).then(r => {
    });
  }

  view(organization) {
    this.router.navigateByUrl('sparkecademy-admin-organization/view/' + organization.id).then(r => {
    });
  }

  remove(organization) {
    this.modalRef = this.modalService.show(this.confirmationTemplate, {class: 'modal-sm'});
    this.recordToDelete = organization.id;
  }

  distribute() {
    const creditPerRecord = this.distributeCreditForm.get("distributeCredits").value;
    for (let i = 0; i < this.distributeCreditFormArr.length; i++) {
      if (this.distributeCreditFormArr.at(i).get("isChecked").value) {
        this.distributeCreditFormArr.at(i).patchValue({"updatedCredits": creditPerRecord});
      }
    }
  }

  checkAll() {
    if (this.distributeCreditFormArr.length > 0) {
      for (let i = 0; i < this.distributeCreditFormArr.length; i++) {
        this.distributeCreditFormArr.at(i).patchValue({"isChecked": true});
      }
      this.isChecked = true;
    }
  }

  unCheckAll() {
    if (this.distributeCreditFormArr.length > 0) {
      for (let i = 0; i < this.distributeCreditFormArr.length; i++) {
        this.distributeCreditFormArr.at(i).patchValue({"isChecked": false});
      }
      this.isChecked = false;
    }
  }

  ngOnInit() {
    this.organizationId = this.route.snapshot.paramMap.get('id');
    if (this.organizationId != null) {
      this.loadData(this.organizationId);
    }
  }

  submit() {
    try {
      let distributeCredits = new Array();

      for (let i = 0; i < this.distributeCreditFormArr.length; i++) {
        let userId = this.distributeCreditFormArr.at(i).get("userId").value;
        let credits = this.distributeCreditFormArr.at(i).get("updatedCredits").value;
        if (credits != '' && !isNaN(credits)) {
          let distributeCreditDTO = new DistributeCreditDTO(userId, credits);
          distributeCredits.push(distributeCreditDTO);
        }
      }
      if (distributeCredits.length > 0) {
        this.distributeCreditRequest = new DistributeCreditRequestDTO(distributeCredits);
        this.organizationService.distributeCredits(this.organizationId, this.distributeCreditRequest).subscribe(data => {
          if (data.success) {
            this.toastr.success("Success", "Credits Distributed successfully!!")
            this.router.navigateByUrl('/sparkecademy-admin-organization/dashboard/' + this.organizationId);
          }
        });
      }
    } catch (e) {
      alert(e);
    }
  }

  loadData(organizationId) {
    this.distributeCreditFormArr.removeAt(0);
    this.organizationService.getMembers(organizationId).subscribe(data => {
      this.paginationOrganizationMemberList = data;
      let records = this.paginationOrganizationMemberList.organizationMembers;
      for (let i = 0; i < records.length; i++) {
        this.distributeCreditFormArr.push(
          this.fb.group({
            isChecked: [false],
            userId: [records[i].id],
            credits: [records[i].credits],
            updatedCredits: [''],
            creditsSum: [],
            name: [records[i].name],
            role: [records[i].role],
          }));
      }
    });
  }
}
