export class CourseFilterDTO {

  title: string;
  categoryId: number;
  tagId: number;
  sortBy: string;
  sortOrder: string;
  pageIndex: number;
  pageSize: number = 50;
  length: number;
  categoryUrl: string;
  tagUrl: string;


  constructor(pageIndex: number,
              pageSize: number,
              length: number,
              title?: string,
              categoryId?: number,
              tagId?: number,
              sortBy?: string,
              sortOrder?: string
  ) {
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;
    this.length = length;
    this.title = title;
    this.categoryId = categoryId;
    this.tagId = tagId;
    this.sortBy = sortBy;
    this.sortOrder = sortOrder;
  }
}
