import {RegistrationDTO} from "./RegistrationDTO";

export class AdminRegistrationDTO extends RegistrationDTO{
  accountId : number;

  constructor(email: string, firstName: string, lastName: string, password:string, school:string, license:string, accountId:number){
    super(email, firstName, lastName, password, school, license);
    this.accountId = accountId;
  }
}
