import { Component, OnInit } from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {AdminUserService} from "../../../_services/admin/adminUserService/admin-user.service";
import {UpdateUserDTO} from "../../../types/UpdateUserDTO";
import {IDDTO} from "../../../types/IDDTO";
import {AccountUserDTO} from "../../../types/AccountUserDTO";
import {UserRoleInfo} from "../../../types/UserRoleInfo";
import {AdminAccountUsersDTO} from "../../../types/AdminAccountUsersDTO";
import { saveAs } from "file-saver";

@Component({
  selector: 'app-admin-account-edit-find-account',
  templateUrl: './admin-account-edit-find-account.html',
  styleUrls: ['./admin-account-edit-find-account.component.css']
})
export class AdminAccountEditFindAccountComponent implements OnInit {

  findAccount = this.fb.group({
    accountId: [''],
  }, {validator: []});


  hasResponse : boolean = false;
  success : boolean = false;
  errorMessage: string;

  toggle : boolean = false;

  editorLoaded : boolean = false;

  adminAccountUsersDTO : AdminAccountUsersDTO;

  index : number = 0;
  constructor(private fb: FormBuilder, private adminUserService : AdminUserService) { }

  ngOnInit() {



  }

  editAccount(){
    this.toggle = !this.toggle;
    if(this.findAccount.get('accountId').value) {
      this.hasResponse = false;
      this.editorLoaded = false;
      //this.updateUserDTOsList = new Array<UpdateUserDTO>();
      console.log(this.findAccount.get('accountId').value);
      this.adminUserService.findAccount(new IDDTO(this.findAccount.get('accountId').value)).subscribe( data => {
        console.log(data);
        this.success = data.success;
        this.errorMessage = data.errorMessage;
        console.log(Object.keys(data.adminAccountUsers.schoolUserMap).map((key) =>{return data.adminAccountUsers.schoolUserMap[key]}));
        if(data.success == true) {
          let id : number;
          let map : Map<String, Array<AccountUserDTO>> = new Map<String, Array<AccountUserDTO>>();
          id = data.adminAccountUsers.id;

          Object.keys(data.adminAccountUsers.schoolUserMap).map((key) =>
          {
            map.set(key, this.createDTO(data.adminAccountUsers.schoolUserMap[key]));
            //return data.adminAccountUsers.schoolUserMap[key]
          }
          );

          console.log(map);
          this.adminAccountUsersDTO = new AdminAccountUsersDTO(id, map);
          this.index=0;
          this.loadAccountEditor();
        }
      });
      this.hasResponse = true;
    }
  }

  downloadAccount(){
    this.adminUserService.downloadAccountUsers(this.findAccount.get('accountId').value).subscribe(blob => saveAs(blob, "users_for_account_id_"+this.findAccount.get('accountId').value+".csv"));
  }

  editAccountFocusLicense(){
    this.toggle = !this.toggle;
    if(this.findAccount.get('accountId').value) {
      this.hasResponse = false;
      this.editorLoaded = false;
      //this.updateUserDTOsList = new Array<UpdateUserDTO>();
      console.log(this.findAccount.get('accountId').value);
      this.adminUserService.findAccount(new IDDTO(this.findAccount.get('accountId').value)).subscribe( data => {
        console.log(data);
        this.success = data.success;
        this.errorMessage = data.errorMessage;
        console.log(Object.keys(data.adminAccountUsers.schoolUserMap).map((key) =>{return data.adminAccountUsers.schoolUserMap[key]}));
        if(data.success == true) {
          let id : number;
          let map : Map<String, Array<AccountUserDTO>> = new Map<String, Array<AccountUserDTO>>();
          id = data.adminAccountUsers.id;

          Object.keys(data.adminAccountUsers.schoolUserMap).map((key) =>
            {
              map.set(key, this.createDTO(data.adminAccountUsers.schoolUserMap[key]));
              //return data.adminAccountUsers.schoolUserMap[key]
            }
          );

          console.log(map);
          this.adminAccountUsersDTO = new AdminAccountUsersDTO(id, map);
          this.index=1;
          this.loadAccountEditor();
        }
      });
      this.hasResponse = true;
    }
  }
  createDTO(data){
    console.log(data);
    let dtos : Array<AccountUserDTO>= new Array<AccountUserDTO>();
    data.forEach( user => {
      let roleInfoArray : Array<UserRoleInfo> = new Array<UserRoleInfo>();
      let accountUserDTO : AccountUserDTO;
       user.roleInfo.forEach(roleInfo => {
        roleInfoArray.push(new UserRoleInfo(roleInfo.roleName, roleInfo.roleType, roleInfo.roleId, roleInfo.hasRole));
       });
      accountUserDTO = new AccountUserDTO(user.firstName, user.lastName, user.email, user.school, user.userId, roleInfoArray);
      dtos.push(accountUserDTO);
    });

    return dtos;
  }

  loadAccountEditor(){
    console.log("loading for id: " + this.adminAccountUsersDTO.id);
    this.editorLoaded = true;
  }



  closeEditor(closeEditor){
    if(closeEditor) {
      this.editorLoaded = false;
    }
  }

  reloadEditor(reloadEditor){
    if(reloadEditor){
      this.editAccount();
    }
  }
  reloadEditorFocusLicense(reloadEditor){
    if(reloadEditor){
      this.editAccountFocusLicense();
    }
  }
}
