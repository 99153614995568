export class OrganizationFilterDTO {

  name: string;
  onlyActiveRecords: boolean;
  sortBy: string;
  sortOrder: string;
  pageIndex: number;
  pageSize: number = 50;
  length: number;


  constructor(pageIndex: number,
              pageSize: number,
              length: number,
              name?: string,
              onlyActiveRecords?: boolean,
              sortBy?: string,
              sortOrder?: string
  ) {
    this.name = name;
    this.onlyActiveRecords = onlyActiveRecords;
    this.sortBy = sortBy;
    this.sortOrder = sortOrder;
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;
    this.length = length;
  }
}
