import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AdminUserService} from "../../../../_services/admin/adminUserService/admin-user.service";
import {MergeIDDTO} from "../../../../types/MergeIDDTO";

@Component({
  selector: 'app-account-merge-form',
  templateUrl: `./account-merge-form.html`,
  styleUrls: ['./account-merge-form.component.css']
})
export class AccountMergeFormComponent implements OnInit {


  @Input() inputObj : any;

  @Output() reloadNoTabSelected = new EventEmitter<boolean>();

  @Output() reloadTabSelected = new EventEmitter<boolean>();

  userPage : boolean;
  constructor(private adminUserService : AdminUserService) { }

  ngOnInit() {
    this.userPage = this.inputObj.userResponse;
  }

  completeMerge(){
    //this.adminUserService.c

    if(this.userPage == true) {
      this.adminUserService.completeUserMerge(new MergeIDDTO(this.inputObj.accountMergeTo, this.inputObj.user.id)).subscribe(
        data =>{
          console.log(data);
          if(data.success){
          this.reloadNoTabSelected.emit(true);
          }
        }

      );
    } else {
      this.adminUserService.completeAccountMerge(new MergeIDDTO(this.inputObj.accountMergeTo, this.inputObj.accountMergeFrom)).subscribe(
        data =>{
          console.log(data);
          if(data.success){
            this.reloadNoTabSelected.emit(true);
          }
        }
      );
    }
  }

  back(){
    this.reloadTabSelected.emit(true);
  }
}
