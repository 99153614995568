import { Component, OnInit } from '@angular/core';

export class MergeIDDTO {
  currentAccountID: number;
  mergeIntoId: number;
  constructor(currentAccountID: number, mergeIntoId : number){
    this.currentAccountID = currentAccountID;
    this.mergeIntoId = mergeIntoId;
  }

}
