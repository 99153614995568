import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {OrganizationService} from "../../../../_services/adminOrganizationService/organization-service";

@Component({
  selector: 'app-e-admin-bulk-update-organization-member',
  templateUrl: './bulk-update-organization-member.html',
  styleUrls: ['./bulk-update-organization-member.component.css']
})
export class eAdminBulkUpdateOrganizationMemberComponent implements OnInit {

  bulkUpdateUserList = this.fb.group({
    file: [null]
  }, {validator: []});
  message: any;
  organizationId: string;
  callReturned: boolean = true;
  success: boolean;

  constructor(private route: ActivatedRoute, private fb: FormBuilder, private organizationService: OrganizationService,) {
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      let file = event.target.files[0];
      this.bulkUpdateUserList.get('file').setValue(file);
      console.log(this.bulkUpdateUserList);
    }
  }

  ngOnInit(): void {
    this.organizationId = this.route.snapshot.paramMap.get('id');
  }

  private prepareSave(): any {
    let input = new FormData();
    if (this.bulkUpdateUserList.get('file').value) {
      input.append('FILE', this.bulkUpdateUserList.get('file').value);
    }
    return input;
  }

  onSubmit() {
    this.callReturned = false;
    this.message = null;
    this.organizationService.bulkUpdateUsers(this.prepareSave()).subscribe(data => {
      console.log(data);
      if (data.success) {
        if (data.detailedLog) {
          this.message = data.detailedLog;
        } else {
          this.message = "Changes saved";
        }
      } else {
        this.message = data.errorMessage || data.message;
        if (typeof this.message === 'string') {
          this.message = [this.message];
        }
      }

      this.callReturned = true;
    });
  }
}
