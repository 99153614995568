export class SecondaryModuleFile{


  id: number;

  navText: string;

  tags: string;

  filePath: string;

  fileName: string;

  name: string;

  secondaryModuleId: number;

  moduleId: number;

  music : boolean;

  video: boolean;

  folder: boolean;

  downloadable: boolean;

  curriculumId: string;

  displayOrder : number;

  parentFolderId : string;

  constructor(id: number, navText: string, filePath: string, name: string, secondaryModuleId: number, music : boolean, video: boolean, curriculumId: string, downloadable : boolean, folder : boolean, parentFolderId : string, moduleId : number){

    this.id = id;
    this.navText = navText;
    this.filePath = filePath;
    this.fileName = filePath;
    this.name = name;
    this.secondaryModuleId = secondaryModuleId;
    this.music = music;
    this.video = video;
    this.folder = folder;
    this.curriculumId = curriculumId;
    this.downloadable = downloadable;
    this.parentFolderId = parentFolderId;
    this.moduleId = moduleId;
  }
}
