import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-account-edit-container',
  templateUrl: './admin-account-edit-container.html',
  styleUrls: ['./admin-account-edit-container.component.css']
})
export class AdminAccountEditContainerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }


}
