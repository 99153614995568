import {UserRoleInfo} from "./UserRoleInfo";
import {last} from "rxjs/operators";

export class CreateNewModuleDTO {

  navText : string;
  curriculumId : number;

  constructor(navText : string, curriculumId : number){
    this.navText = navText;
    this.curriculumId = curriculumId;
  }

}
