import {NavEntryCurriculums} from "./NavEntryCurriculums";

export class NavEntry {

  id:number;
  navText :string;
  singleDisplay : boolean;
  displayOrder :number;
  navEntryCurriculums :  Array<NavEntryCurriculums>;

  constructor(
    id:number,
    navText :string,
    singleDisplay : boolean,
    displayOrder :number,
    navEntryCurriculums : Array<NavEntryCurriculums>){
    this.id = id;
    this.navText = navText;
    this.singleDisplay = singleDisplay;
    this.displayOrder = displayOrder;
    this.navEntryCurriculums  = new Array<NavEntryCurriculums>();
    navEntryCurriculums.forEach(entry => {
      this.navEntryCurriculums.push(entry);
    });

  }


}
