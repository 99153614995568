import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModuleFile} from "../../../../../../types/ModuleFile";

@Component({
  selector: 'app-edit-module-folder-two',
  templateUrl: `./edit-module-folder-two.html`,
  styleUrls: ['./edit-module-folder-two.component.css']
})
export class EditModuleFolderTwoComponent implements OnInit {

  selected : number;

  _moduleFolder : ModuleFile;

  fileEditorLoaded : boolean = false;

  folderEditorLoaded : boolean = false;

  selectorToggle : boolean = false;

  createNewLoaded : boolean = false;

  _moduleFileToLoad : ModuleFile;

  @Output() closeModuleFolderEditorEmit = new EventEmitter<boolean>();

  @Input() curriculum;

  @Input() moduleFileType;

  @Input() parentType;

  constructor() { }

  ngOnInit() {
    this.folderEditorLoaded = false;
    this.createNewLoaded = false;
    this.fileEditorLoaded = false;
  }

  @Input()
  set moduleFolder(moduleFolder){
    console.log("edit module folder setting module folder");
    this._moduleFolder = moduleFolder;
    console.log(this._moduleFolder);
  }
  setSelectedValue(value){
    this.selected = value;
  }

  closeModuleFolderEditor(){
    this.closeModuleFolderEditorEmit.emit(true);
  }


  public loadCreateNew(){
    this.createNewLoaded = true;
  }

  reloadSelector(){
    this.selectorToggle = !this.selectorToggle;
  }
  closeNewModuleFile(){
    this.createNewLoaded = false;
    this.fileEditorLoaded = false;
    this.folderEditorLoaded = false;
    this.selectorToggle = !this.selectorToggle;
  }

  closeEditModuleFile(data){
    this.createNewLoaded = false;
    this.fileEditorLoaded = false;
    this.folderEditorLoaded = false;
    this.selectorToggle = !this.selectorToggle;
  }

  loadModuleFolderEditor(data){
    console.log("In load module folder editor");
    this._moduleFileToLoad = data;
    this.folderEditorLoaded = true;
    this.fileEditorLoaded = false;

  }

  loadModuleFileEditor(data){
    console.log("In load module file editor");
    this._moduleFileToLoad = data;
    this.fileEditorLoaded = true;
    this.folderEditorLoaded = false;
  }
}
