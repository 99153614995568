import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Curriculum} from "../../../../../../../types/Curriculum";
import {SecondaryModule} from "../../../../../../../types/SecondaryModule";
import {FormBuilder} from "@angular/forms";
import {AdminCurriculumService} from "../../../../../../../_services/admin/adminCurriculumService/admin-curriculum.service";
import {UpdateSecondaryModuleDTO} from "../../../../../../../types/UpdateSecondaryModuleDTO";

@Component({
  selector: 'app-admin-secondary-module-editor-form',
  templateUrl: './admin-secondary-module-editor-form.html',
  styleUrls: ['./admin-secondary-module-editor-form.component.css']
})
export class AdminSecondaryModuleEditorFormComponent implements OnInit {

  _curriculum: Curriculum;
  updateSecondaryModuleForm = this.fb.group({
    TYPE: '',
    URL: ['']
  }, {validator: []});

  _secondaryModule: SecondaryModule;

@Input() parentType: string;
@Output() newModuleCreated = new EventEmitter<boolean>();
@Output() closeEditModuleEmit = new EventEmitter<boolean>();
@Output() reloadEditor = new EventEmitter<boolean>();

  types: object[];


  constructor(private fb: FormBuilder, private adminCurriculumService: AdminCurriculumService) {
  }

  ngOnInit() {
  }

@Input()
set secondaryModule(secondaryModule) {
    console.log("set secondary moule in sec editor form");
    this._secondaryModule = secondaryModule;
    console.log(this._secondaryModule);
  this.types = [{name: 'ASSESSMENTS', checked: this._secondaryModule.secondaryModuleType == 'ASSESSMENTS'},
      {name: 'UNIT_PLANNING', checked:  this._secondaryModule.secondaryModuleType == 'UNIT_PLANNING'},
      {name: 'ACTIVITY_CARDS', checked: this._secondaryModule.secondaryModuleType == 'ACTIVITY_CARDS'},
      {name: 'MUSIC', checked: this._secondaryModule.secondaryModuleType == 'MUSIC'},
      {name: 'VIDEO', checked: this._secondaryModule.secondaryModuleType == 'VIDEO'},
      {name: 'EQUIPMENT', checked: this._secondaryModule.secondaryModuleType == 'EQUIPMENT'},
      {name: 'SOCIAL_DISTANCE', checked: this._secondaryModule.secondaryModuleType == 'SOCIAL_DISTANCE'}];
  }


  updateSecondaryModule() {

    let dto: UpdateSecondaryModuleDTO = new UpdateSecondaryModuleDTO(this._secondaryModule.id + '',
      Number(this._curriculum.id),
      this.updateSecondaryModuleForm.controls['TYPE'].value,
      this.updateSecondaryModuleForm.controls['URL'].value);


    console.log(dto);
    console.log(this._curriculum);
    console.log(this.updateSecondaryModuleForm);
    this.adminCurriculumService.updateSecondaryModule(dto).subscribe(data => {
      console.log("updateSecondaryModule");
      console.log(data);
      this.closeEditModuleEmit.emit(true);
    });
  }

  closeUpdateSecondaryModule() {
    this.closeEditModuleEmit.emit(true);
  }


@Input()
set curriculum(curriculum) {
    this._curriculum = curriculum;
  }

}
