import { Component, OnInit } from '@angular/core';
import {Curriculum} from "../../types/Curriculum";
import {ActivatedRoute} from "@angular/router";
import {LicenseExpirationDateDTO} from "../../types/LicenseExpirationDateDTO";

@Component({
  selector: 'app-dodea',
  templateUrl: './dodea.html',
  styleUrls: ['./dodea.component.css']
})
export class DodeaComponent implements OnInit {

  curriculums: Curriculum[];

  licenseExpirationDate: LicenseExpirationDateDTO;

  navEntries : any;


  isElem: boolean = false;
  isMs: boolean = false;
  isHs: boolean = false;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.data.subscribe(data =>
    {
      console.log(data);
      this.curriculums = data.curriculums;
      console.log(this.curriculums);
      this.navEntries = data.navEntries;
      console.log(this.curriculums);
      this.licenseExpirationDate = data.licenseExpirationDate;
    });
    window.scrollTo(0,0);
  }

  loadVideo(type : string){
    if('elem'===type){
      this.isElem = true;
      this.isMs = false;
      this.isHs = false;
    }

    if('ms'===type){
      this.isMs = true;
      this.isElem = false;
      this.isHs = false;
    }

    if('hs'===type){
      this.isHs = true;
      this.isElem = false;
      this.isMs = false;
    }

  }
}
