import {Component, OnInit} from '@angular/core';
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";
import {ActivatedRoute} from '@angular/router';
import {UserDTO} from "../../../../types/UserDTO";
import {CreditTransactionDTO} from "../../../../types/CreditTransactionDTO";
import {CourseEnrollmentDTO} from "../../../../types/CourseEnrollmentDTO";
import {UserService} from "../../../../_services/adminUserService/user-service";
import {FormBuilder} from "@angular/forms";

@Component({
  selector: 'app-e-admin-organization-member-basic-info',
  templateUrl: 'view-organization-member.html',
  styleUrls: ['view-organization-member.component.css']
})
export class eAdminViewOrganizationMemberComponent implements OnInit {

  addCredit = this.fb.group({
    credit: [''],
  }, {validator: []});


  userId: number;
  user: UserDTO;
  creditTransationDto: CreditTransactionDTO[];
  courseEnrollmentDto: CourseEnrollmentDTO[];

  constructor(private userService: UserService,
              private fb: FormBuilder,
              private toastr: ToastrService,
              private router: Router,
              private route: ActivatedRoute) {
    this.userId = parseInt(this.route.snapshot.paramMap.get('id'));
    if (this.userId != null) {
      this.loadData();
    }
  }

  ngOnInit() {

  }

  loadData() {
    this.userService.get(this.userId).subscribe(data => {
      this.user = data;
    });

    this.userService.getCreditTransactionByIdHistory(this.userId).subscribe(data => {
      this.creditTransationDto = data;
    });

    this.userService.getEnrolledCoursesById(this.userId).subscribe(data => {
      this.courseEnrollmentDto = data.courseEnrollments;
    });
  }

  submit() {
    let credit = this.addCredit.controls["credit"].value;
    if (credit == 0) {
      return;
    }
    if ((credit + this.user.credits) < 0) {
      this.toastr.error("Insufficient credits", "error");
      return;
    }
    this.userService.addCreditsTransaction(this.userId, credit).subscribe(data => {
      if (data.success) {
        if (credit < 0) {
          this.toastr.success("Credits subtracted successfully!!", "success");
        } else {
          this.toastr.success("Credits added successfully!!", "success");
        }
        this.loadData();
      }
    });
  }


  activate(user) {
    this.userService.activate(user.id).subscribe(e => {
      this.toastr.success("Users activated successfully!!", "Success")
    });
  }

  deactivate(user) {
    this.userService.deactivate(user.id).subscribe(e => {
      this.toastr.success("Users Inactivated successfully!!", "Success")
    });
  }
}
