import {DistributeCreditDTO} from "./DistributeCreditDTO";

export class DistributeCreditRequestDTO {

  distributeCredits: DistributeCreditDTO[];

  constructor(distributeCredits: DistributeCreditDTO[]) {
    this.distributeCredits = distributeCredits;
  }

}
