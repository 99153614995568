import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  FormGroupDirective,
  NgForm,
  AbstractControl, ValidationErrors
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export const EmailValidation = [Validators.required, Validators.email];
export const PasswordValidation = [
  Validators.required,
  Validators.minLength(6),
  Validators.maxLength(24),
];

export class RepeatPasswordEStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return (control && control.parent.get('password').value !== control.parent.get('passwordConfirm').value && control.dirty)
  }
}

export function CustomEmailValidator(control: AbstractControl): ValidationErrors {
  if (!control.value) {
    return null;
  }

  return Validators.email(control);
}

export function CustomPasswordValidator(control: AbstractControl): ValidationErrors {
  if (!control.value) {
    return null;
  }

  return Validators.email(control);
}
export function RepeatPasswordValidator(group: FormGroup){
  let password = group.controls.password.value;
  let passwordConfirmation = group.controls.passwordConfirm.value;
  //return password === passwordConfirmation && (group.controls.password.dirty === group.controls.passwordConfirm.dirty) ? null : { passwordsNotEqual: true }
  password === passwordConfirmation ? null : group.controls.passwordConfirm.setErrors([{ 'passwordsNotEqual': true }]);
  return password === passwordConfirmation ? null : { passwordsNotEqual: true }
}

export function RepeatEmailValidator(group: FormGroup){
  let email = group.controls.email.value;
  let emailConfirmation = group.controls.emailConfirm.value;
  console.log("IN Repeate email validator");
  console.log(email);
  console.log(emailConfirmation);
  console.log(email===emailConfirmation);

  email === emailConfirmation ? null : group.controls.emailConfirm.setErrors([{'emailNotEqual': true}]);
  return email === emailConfirmation ? null : {emailNotEqual: true};
}

export function CheckboxCount(group: FormGroup){
  let count:number = 0;
  console.log("validateCheckboxCount");
  console.log(group.controls);
  console.log(group);
  if(group.controls.K2PE.value){
    count++;
  }

  if(group.controls.PE36.value){
    count++;
  }

  if(group.controls.MSPE.value){
    count++;
  }

  if(group.controls.HSPE.value){
    count++;
  }

  if(group.controls.ABCCA.value){
    count++;
  }

  if(group.controls.AS.value){
    count++;
  }

  if(group.controls.EC.value){
    count++;
  }
  console.log(count);
  console.log(count >= 1 && count <=3 );
  return count >= 1 && count <=3 ? null : {wrongCurriculumCount: true};
}


