import { Component, OnInit } from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {AdminUserService} from "../../_services/admin/adminUserService/admin-user.service";

@Component({
  selector: 'app-app-admin-user-file-import',
  templateUrl: './app-admin-user-file-import.html',
  styleUrls: ['./app-admin-user-file-import.component.css']
})
export class AppAdminUserFileImportComponent implements OnInit {


  uploadUserList = this.fb.group({
      ACCOUNT_ID:[''],
      file:[null]
    }, {validator: []});



  message;
  success : boolean;

  callReturned : boolean = true;

  constructor(private fb: FormBuilder,  private adminUserService : AdminUserService) { }

  ngOnInit() {
  }



  onFileChange(event) {
    if(event.target.files.length > 0) {
      let file = event.target.files[0];
      this.uploadUserList.get('file').setValue(file);
      console.log(this.uploadUserList);
    }
  }

  private prepareSave(): any {
    let input = new FormData();
    // This can be done a lot prettier; for example automatically assigning values by looping through `this.form.controls`, but we'll keep it as simple as possible here
    if(this.uploadUserList.get('file').value) {
      input.append('FILE', this.uploadUserList.get('file').value);
    }
    input.append('ACCOUNT_ID', this.uploadUserList.get('ACCOUNT_ID').value);
    return input;
  }

  onSubmit() {
    this.callReturned = false;
    this.message = null;
    this.adminUserService.importUsersFromFile( this.prepareSave()).subscribe(data => {
      console.log(data);
      if(data.success){
        if(data.detailedLog){
         this.message =  data.detailedLog;
        }else {
          this.message = "Changes saved";
        }
      } else {
        this.message = data.errorMessage;
      }

      this.callReturned = true;
    });
  }

}
