import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LicenseDTO} from "../../../../types/LicenseDTO";
import {AccountUserDTO} from "../../../../types/AccountUserDTO";
import {AdminLicenseService} from "../../../../_services/admin/adminLicenseService/admin-license.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-admin-account-license-show',
  templateUrl: './admin-account-license-show.html',
  styleUrls: ['./admin-account-license-show.component.css']
})
export class AdminAccountLicenseShowComponent implements OnInit {

  _licenseDTO : LicenseDTO;

  @Output() loadLicense = new EventEmitter<LicenseDTO>();
  @Output() _removeLicense = new EventEmitter<LicenseDTO>();

  constructor(private adminLicenseService:AdminLicenseService, private toastr: ToastrService) { }

  ngOnInit() {
  }

  @Input()
  set licenseDTO(dto : LicenseDTO){
    this._licenseDTO = dto;
  }

  loadEditor(dto : LicenseDTO){
    this.loadLicense.emit(dto);
  }

  removeLicense(license: LicenseDTO){
    this.adminLicenseService.removeLicense(license.licenseId).subscribe(data => {
      this.toastr.success("License deleted successfully!!");
      this._removeLicense.emit(license);
    });
  }
}
