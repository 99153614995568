import { Component, OnInit } from '@angular/core';

export class UpdateAccountRoleDTO {

  roleId : string;
  hasRole : boolean;
  userId : number;



  constructor(roleId : string, hasRole : boolean, userId: number){
    this.roleId = roleId;
    this.hasRole = hasRole;
    this.userId = userId;
  }

}
