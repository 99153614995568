import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {AdminCurriculumService} from "../../../../../../../_services/admin/adminCurriculumService/admin-curriculum.service";
import {ModuleFile} from "../../../../../../../types/ModuleFile";
import {UpdateModuleDTO} from "../../../../../../../types/UpdateModuleDTO";

@Component({
  selector: 'app-edit-module-folder-form',
  templateUrl: './edit-module-folder-form.html',
  styleUrls: ['./edit-module-folder-form.component.css']
})
export class EditModuleFolderFormComponent implements OnInit {

  updateModuleFolder = this.fb.group({
    NAV_TEXT:[''],
    BODY_TEXT:[''],
    file:[null],
    trialAccessible:false,
  }, {validator: []});

  _moduleFolder : ModuleFile;

  successMessage :string = null;

  @Output() closeModuleFolderEditorEmit = new EventEmitter<boolean>();

  constructor(private fb: FormBuilder, private adminCurriculumService : AdminCurriculumService) { }

  ngOnInit() {
  }

  @Input()
  set moduleFolder(moduleFolder){
    this._moduleFolder = moduleFolder;
    if(this.updateModuleFolder){
      console.log(this.updateModuleFolder);
      this.updateModuleFolder.controls['NAV_TEXT'].setValue(this._moduleFolder.navText);
      this.updateModuleFolder.controls['trialAccessible'].setValue(this._moduleFolder.trialAccessible);
    }
  }

  saveChanges(){
    let dto : UpdateModuleDTO = new UpdateModuleDTO(this.updateModuleFolder.get('NAV_TEXT').value, Number(this._moduleFolder.id),
      this.updateModuleFolder.get('trialAccessible').value);
    console.log(dto);
    this.successMessage = null;
    this.adminCurriculumService.updateModuleFolderNavText(dto).subscribe(data =>{
      console.log(data);
      if(data.success){
        this.successMessage = "Changes Saved";
      }
    });
  }

  closeEditor(){
    this.closeModuleFolderEditorEmit.emit(true);
  }
}
