import { Component, OnInit } from '@angular/core';
import {Curriculum} from "../../types/Curriculum";
import {ActivatedRoute} from "@angular/router";
import {AccountUserDTO} from "../../types/AccountUserDTO";
import {ShoolGroupDTO} from "../../types/SchoolGroupDTO";
import {RoleInfo} from "../../types/RoleInfo";
import {UserRoleInfo} from "../../types/UserRoleInfo";
import {MatDialog, MatDialogConfig} from "@angular/material";
import {DeleteUserDialogComponent} from "../delete-user-dialog/delete-user-dialog.component";
import {CreateNewUserComponent} from "../create-new-user/create-new-user.component";
import {AccountService} from "../../_services/accountService/account.service";
import {LicenseExpirationDateDTO} from "../../types/LicenseExpirationDateDTO";

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit {
  curriculums : Curriculum[];

  licenseExpirationDate: LicenseExpirationDateDTO;

  navEntries : any;


  schoolGroups : ShoolGroupDTO[];


  selectedUserVal : AccountUserDTO;

  roleCounts : RoleInfo[];

  pageName : string = "UserManagement";

  constructor(private route: ActivatedRoute, private dialog: MatDialog, private accountService : AccountService) { }

  ngOnInit() {
    this.route.data.subscribe(data =>
    {
      console.log("in user management component");
      console.log(data);
      this.curriculums = data.curriculums;
      this.navEntries = data.navEntries;
      this.licenseExpirationDate = data.licenseExpirationDate;

      this.schoolGroups = data.schoolGroups;
      console.log(this.schoolGroups);

      this.roleCounts = data.roleCounts;
      console.log("role counts");
      console.log(this.roleCounts);
    });
  }

  loadUser(user: AccountUserDTO){
    this.selectedUserVal = user;
    window.scrollTo(0,0);
  }

  openDeleteUserDialog(userParam: AccountUserDTO){

    let deleteDialogConfig = new MatDialogConfig();

    deleteDialogConfig.autoFocus = true;
    deleteDialogConfig.disableClose = false;

    deleteDialogConfig.data = { user : userParam };

    this.dialog.open(DeleteUserDialogComponent, deleteDialogConfig);

  }

  openCreateNewUser(){

    let createNewUserDialogConfig = new MatDialogConfig();

    createNewUserDialogConfig.autoFocus = true;
    createNewUserDialogConfig.disableClose = true;

    this.dialog.open(CreateNewUserComponent, createNewUserDialogConfig);
    console.log("open create new user");
  }

  onSubmit(){
    console.log("in on submit.")
  }

  onComponentChange(value){
    console.log("Change emitted");
    console.log(value);
    console.log(this.roleCounts);
    //this.roleCounts = value;

    return this.accountService.getAccountUsers().subscribe(data => {
      this.schoolGroups = data;
    });

    this.accountService.getAccountRoleStats().subscribe(data => {
      this.roleCounts = data;
    });
  }

}
