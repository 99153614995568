import {Component, Input, OnInit} from '@angular/core';
import {CourseTagDTO} from "../../types/CourseTagDTO";
import {CourseCategoryDTO} from "../../types/CourseCategoryDTO";
import {ActivatedRoute} from "@angular/router";
import {UserCourseService} from "../../_services/userCourseService/user-course-service";
import {UserService} from "../../_services/adminUserService/user-service";
import {CreditTransactionDTO} from "../../types/CreditTransactionDTO";
import {AuthService} from "../../../_services/authService/auth.service";
import {UserCurriculumService} from "../../../_services/userCurriculumService/user-curriculum.service";

@Component({
  selector: 'app-e-dashboard',
  templateUrl: './dashboard.html',
  styleUrls: ['./dashboard.component.css']
})
export class eDashboardComponent implements OnInit {

  courseTags: CourseTagDTO[];
  courseCategories: CourseCategoryDTO[];

  enrolledCourses: any;
  completedCourses: any;
  creditTransationDto: CreditTransactionDTO[];
  header: string = 'Hello';

  contents: string;

  constructor(private route: ActivatedRoute,
              private userCourseService: UserCourseService,
              private authService: AuthService,
              private userService: UserService,
              private curriculumService: UserCurriculumService
  ) {
  }

  ngOnInit() {

    this.userCourseService.getEnrolledCourses().subscribe(data => {
      this.enrolledCourses = data;
    });

    this.userCourseService.getCompletedCourses().subscribe(data => {
      this.completedCourses = data;
    });

    this.userCourseService.getCreditTransactionHistory().subscribe(data => {
      this.creditTransationDto = data;
    });

    this.header = "Hello, " + this.authService.getCurrentUserValue().firstName + ' ' + this.authService.getCurrentUserValue().lastName;

    this.curriculumService.getSeHomePageContent().subscribe(data => {
      this.contents = data.contents;
    });
  }

  downloadCertificate(course) {
    this.userCourseService.downloadCertificate(course.key, course.value).subscribe(response => {
      console.log(response);
      let binaryData = [];
      binaryData.push(response.data);
      let url = window.URL.createObjectURL(new Blob(binaryData, {type: "application/pdf"}));
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.setAttribute('target', 'blank');
      a.href = url;
      a.download = response.filename;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();

    }, error => {

      console.log(error);
    });
  }
}

