import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Module} from "../../../../../../../types/Module";
import {ModuleFile} from "../../../../../../../types/ModuleFile";
import {FormControl, FormGroup} from "@angular/forms";
import {AdminCurriculumService} from "../../../../../../../_services/admin/adminCurriculumService/admin-curriculum.service";
import {DisplayOrderUpdateDTO} from "../../../../../../../types/DisplayOrderUpdateDTO";
import {SecondaryModule} from "../../../../../../../types/SecondaryModule";
import {SecondaryModuleFile} from "../../../../../../../types/SecondaryModuleFile";
import {IDDTO} from "../../../../../../../types/IDDTO";

@Component({
  selector: 'app-admin-secondary-module-file-selector',
  templateUrl: './admin-secondary-module-file-selector.html',
  styleUrls: ['./admin-secondary-module-file-selector.component.css']
})
export class AdminSecondaryModuleFileSelectorComponent implements OnInit {

  _secondaryModule : SecondaryModule;

  secondaryModuleFiles : SecondaryModuleFile[];
  mainSelectorIsLoaded : boolean = false;
  displayOrderForm: FormGroup;


  @Output() reloadSelector=new EventEmitter<boolean>();
  @Output() loadCreateNew=new EventEmitter<boolean>();
  @Output() loadModuleFolderEditorEmit = new EventEmitter<SecondaryModuleFile>();
  @Output() loadModuleFileEditorEmit = new EventEmitter<SecondaryModuleFile>();

  showConfirm : Map<string, boolean> = new Map<string, boolean>();

  constructor(private curriculumService : AdminCurriculumService) { }

  ngOnInit() {
  }


  @Input()
  set module(module){
    this._secondaryModule = module;
    this.curriculumService.getSecondaryModuleChildrenFiles(Number(this._secondaryModule.id)).subscribe(data => {
      console.log(data);
      this.secondaryModuleFiles = data;


      const formGroup = new FormGroup({ });

      this.secondaryModuleFiles.forEach(moduleFile => {
        this.showConfirm.set(moduleFile.id +'', false);
        console.log(moduleFile);
        formGroup.addControl(moduleFile.navText, new FormControl(moduleFile.id));
        formGroup.controls[moduleFile.navText].setValue(moduleFile.displayOrder);
      });

      this.displayOrderForm = formGroup;

      console.log(this.displayOrderForm);

      this.mainSelectorIsLoaded = true;
    });
  }

  loadModuleFileEditor(moduleFile){
    console.log("in load module file editor");
    this.loadModuleFileEditorEmit.emit(moduleFile);
  }
  loadModuleFolderEditor(moduleFolder){
    console.log("in load module folder editor");
    this.loadModuleFolderEditorEmit.emit(moduleFolder);
  }

  deleteModule(moduleFile){
    this.showConfirm.set(moduleFile.id, true);
  }
  confirmedDelete(moduleFile){
    /*console.log("Load module editor");
    console.log(module);
    this.loadModule.emit(module);*/
    this.curriculumService.deleteSecondaryModuleFile(new IDDTO(Number(moduleFile.id))).subscribe(data => {
      this.reloadSelector.emit(true);
    });
  }

  saveDisplayOrderChanges() {
    let updateMap : Map<String, Number> = new Map<String, Number>();
    this.secondaryModuleFiles.forEach( moduleFile => {
      updateMap.set(moduleFile.id +'', this.displayOrderForm.controls[moduleFile.navText].value);
    });
    updateMap.forEach((val : number, key: string) => {
      updateMap[key] = val
    });

    let displayUpdateDTO : DisplayOrderUpdateDTO = new DisplayOrderUpdateDTO(updateMap);

    this.curriculumService.updateSecondaryModuleFileDisplayOrder(displayUpdateDTO).subscribe(data => {
      console.log(data);
      this.reloadSelector.emit(true);
    });
  }

  createNewModuleFile(){
    this.loadCreateNew.emit(true);
  }

}
