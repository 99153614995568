import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {ActivatedRoute, Router} from "@angular/router";
import {CourseLessonQuizDTO} from "../../../../types/CourseLessonQuizDTO";
import {CourseContentService} from "../../../../_services/adminCourseService/course-content-service";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-e-admin-create-course-quiz',
  templateUrl: './create-course-quiz.html',
  styleUrls: ['./create-course-quiz.component.css']
})
export class eAdminCreateCourseQuizComponent implements OnInit {

  header: string = "Quiz Title";
  courseId: string;
  lessonId: string;
  recordIndexToDelete: number;
  courseLessonQuiz: CourseLessonQuizDTO[];

  modalRef: BsModalRef;

  @ViewChild('confirmation')
  private confirmationTemplate: TemplateRef<any>;

  createCourseQuizForm = this.fb.group({
    courseQuiz: this.fb.array([
      this.fb.group({
        question: [''],
        answers: [''],
        correctAnswer: [''],
        questionType: [''],
      })
    ])
  }, {validator: []});

  constructor(private fb: FormBuilder,
              private courseContentService: CourseContentService,
              private toastr: ToastrService,
              private router: Router,
              private route: ActivatedRoute,
              private modalService: BsModalService) {
  }

  ngOnInit() {
    this.courseId = this.route.snapshot.paramMap.get('courseId');
    this.lessonId = this.route.snapshot.paramMap.get('lessonId');
    this.courseContentService.findLessonQuiz(this.courseId, this.lessonId).subscribe(
      data => {
        this.header = data.lesson.title;
        this.courseLessonQuiz = data.lesson.lessonQuiz;

        for (let i = 0; i < this.courseLessonQuiz.length; i++) {
          this.addNewCourseQuizData(this.courseLessonQuiz[i]);
        }
      }
    );
  }

  get courseQuizFormArr(): FormArray {
    return this.createCourseQuizForm.get('courseQuiz') as FormArray;
  }

  addNewCourseQuizData(data: CourseLessonQuizDTO) {
    this.courseQuizFormArr.push(
      this.fb.group({
        question: [data.question],
        answers: [data.answers],
        correctAnswer: [data.correctAnswer],
        questionType: [data.questionType],
        id: [data.id]
      }));
  }

  onSubmit(index) {

    let formArray = this.courseQuizFormArr;
    let input = new FormData();
    let isEditRecord = formArray.at(index).get("id").value != null;
    let id = formArray.at(index).get("id").value;
    let question = formArray.at(index).get("question").value;
    let correctAnswer = formArray.at(index).get("correctAnswer").value;
    let answers = formArray.at(index).get("answers").value;
    let questionType = formArray.at(index).get("questionType").value;

    if (correctAnswer == null) {
      correctAnswer = 1;
    }

    let courseLessonQuizDTO: CourseLessonQuizDTO = new CourseLessonQuizDTO(questionType, id, question, answers, correctAnswer);

    this.courseContentService.addLessonQuiz(this.courseId, this.lessonId, courseLessonQuizDTO).subscribe(data => {
      try {
        formArray.at(index).patchValue({"id": data});
        if (isEditRecord) {
          this.toastr.success("Lesson Quiz Updated Successfully", "Success");
        } else {
          this.toastr.success("Lesson Quiz Created Successfully", "Success");
        }
      } catch (ex) {
        alert(ex);
      }
    });
  }

  onDelete(index) {
    this.recordIndexToDelete = index;
    this.modalRef = this.modalService.show(this.confirmationTemplate, {class: 'modal-sm'});
  }

  confirm(): void {

    let formArray = this.courseQuizFormArr;
    let id = formArray.at(this.recordIndexToDelete).get("id").value;
    if (id == null) {
      this.courseQuizFormArr.removeAt(this.recordIndexToDelete);
    } else {
      this.courseContentService.removeLessonQuiz(this.courseId, this.lessonId, id).subscribe(data => {
        this.courseQuizFormArr.removeAt(this.recordIndexToDelete);
      });
    }
    this.toastr.success("Lesson Quiz deleted successfully!!", "Success")
    this.modalRef.hide();
  }

  decline(): void {
    this.modalRef.hide();
  }
}
