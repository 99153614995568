import {UserRoleInfo} from "./UserRoleInfo";
import {last} from "rxjs/operators";

export class AccountUserDTO {

  firstName : string;
  email : string;
  lastName : string;
  school : string;
  userId : number;
  lastLoginDate : string;

  roleInfo : UserRoleInfo[];
  roleInfoArray : Array<UserRoleInfo>;
  constructor(firstName : string, lastName : string, email: string, school : string, userId : number, roleInfoArray :  Array<UserRoleInfo>){
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.school = school;
    this.userId = userId;
    this.roleInfoArray = roleInfoArray;

  }

}
