import { Component, OnInit } from '@angular/core';
import {DisplayMessageService} from "../../_services/displayMessageService/display-message.service";

@Component({
  selector: 'app-display-message',
  templateUrl: './display-message.html',
  styleUrls: ['./display-message.component.css']
})
export class DisplayMessageComponent implements OnInit {

  constructor(public displayMessageService : DisplayMessageService) { }

  ngOnInit() {
  }

}
