import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {UserService} from "../../../_services/adminUserService/user-service";
import {CourseFilterDTO} from "../../../types/CourseFilterDTO";
import {CourseTagDTO} from "../../../types/CourseTagDTO";
import {CourseCategoryDTO} from "../../../types/CourseCategoryDTO";
import {CourseCategoryService} from "../../../_services/adminCourseService/course-category-service";

@Component({
  selector: 'app-e-admin-filter-course',
  templateUrl: 'filter-course.html',
  styleUrls: ['filter-course.component.css']
})
export class eAdminFilterCourseComponent implements OnInit {

  courseTags: CourseTagDTO[];
  courseCategories: CourseCategoryDTO[];

  @Output() courseFilterDto_ = new EventEmitter<CourseFilterDTO>();
  filterCourses = this.fb.group({
    name: [''],
    categoryId: [''],
    tagId: ['']
  }, {validator: []});

  constructor(private fb: FormBuilder,
              private userService: UserService,
              private courseCategoryService: CourseCategoryService) {
  }

  ngOnInit() {

    this.courseCategoryService.getAllCourseCategories().subscribe(data => {
      this.courseCategories = data;
    });

    this.courseCategoryService.getAllCourseTags().subscribe(data => {
      this.courseTags = data;
    });
  }

  onSubmit() {
    let title = this.filterCourses.get("name").value;
    let categoryId = this.filterCourses.get("categoryId").value;
    let tagId = this.filterCourses.get("tagId").value;

    title = (title == '') ? null : title;
    categoryId = (categoryId == '') ? null : categoryId;
    tagId = (tagId == '') ? null : tagId;

    let courseFilterDto = new CourseFilterDTO(0, 50, 0, title, categoryId, tagId);
    this.courseFilterDto_.emit(courseFilterDto);
  }

  resetForm() {
    let courseFilterDto = new CourseFilterDTO(0, 50, 0);
    this.courseFilterDto_.emit(courseFilterDto);
  }
}
