import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Curriculum} from "../../../types/Curriculum";
import {NavEntry} from "../../../types/NavEntry";

@Component({
  selector: 'app-admin-nav-entry-editor-mid',
  templateUrl: './admin-nav-entry-editor-mid.html',
  styleUrls: ['./admin-nav-entry-editor-mid.component.css']
})
export class AdminNavEntryEditorMidComponent implements OnInit {


  _navEntry : NavEntry;

  @Output() closeEditorEmit=new EventEmitter<true>();
  @Output() reloadEditorEmit=new EventEmitter<NavEntry>();


  selected : number;


  constructor() { }

  ngOnInit() {
  }

  @Input()
  set navEntry(navEntry){
    this._navEntry = navEntry;
  }

  closeEditor(){
    this.closeEditorEmit.emit(true);
  }

  reloadEditor(event){
    this.reloadEditorEmit.emit(event);
  }

  setSelectedValue(value){
    this.selected = value;
  }

}
