import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Curriculum} from "../../../types/Curriculum";
import {UserCurriculumService} from "../../../_services/userCurriculumService/user-curriculum.service";
import {AdminCurriculumService} from "../../../_services/admin/adminCurriculumService/admin-curriculum.service";

@Component({
  selector: 'app-program-selector',
  templateUrl: `./program-selector.html`,
  styleUrls: ['./program-selector.component.css']
})
export class ProgramSelectorComponent implements OnInit {

  mainSelectorIsLoaded : boolean = false;


  curriculumArray : Curriculum[];


  @Output() loadCurriculum=new EventEmitter<Curriculum>();


  constructor( private curriculumService : AdminCurriculumService) { }

  ngOnInit() {

    this.mainSelectorIsLoaded = false;

    this.curriculumService.getUserCurriculums().subscribe(data => {
      console.log(data);
      this.curriculumArray = data;
      this.mainSelectorIsLoaded = true;
    });
  }


  loadCurriculumEditor(curr){
    console.log(curr);
    this.loadCurriculum.emit(curr);
  }
}
