import {Injectable} from '@angular/core';


import {Resolve} from '@angular/router';
import {Observable} from "rxjs";
import {LicenseDTO} from "../../types/LicenseDTO";
import {AccountService} from "../../_services/accountService/account.service";
import {LicenseExpirationDateDTO} from "../../types/LicenseExpirationDateDTO";

@Injectable({
  providedIn: 'root'
})
export class LicenseResolver implements Resolve<Observable<LicenseDTO[]>> {
  constructor(private accountService : AccountService) {}

  resolve() : Observable<LicenseDTO[]>{
    console.log("in license resolver");
    return this.accountService.getAccountLicenseInfo();
    //return this.curriculumService.getUserCurriculums();
  }
}


@Injectable({
  providedIn: 'root'
})
export class EarlierLicenseExpirationDateResolver implements Resolve<Observable<LicenseExpirationDateDTO>> {
  constructor(private accountService: AccountService) {
  }

  resolve(): Observable<LicenseExpirationDateDTO> {
    return this.accountService.getEarlierLicenseExpirationDate();
  }
}
