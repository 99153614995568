import { Component, OnInit } from '@angular/core';
import {TTModuleFile} from "../../types/TTModuleFile";
import {Curriculum} from "../../types/Curriculum";
import {Breadcrumb} from "../../types/Breadcrumb";
import {DisplayCrumb} from "../../types/DisplayCrumb";
import {ActivatedRoute, Router} from "@angular/router";
import {UserCurriculumService} from "../../_services/userCurriculumService/user-curriculum.service";
import {SecondaryModuleFile} from "../../types/SecondaryModuleFile";
import {LicenseExpirationDateDTO} from "../../types/LicenseExpirationDateDTO";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Component({
  selector: 'app-secondary-module-folder-contents',
  templateUrl: './secondary-module-folder-contents.html',
  styleUrls: ['./secondary-module-folder-contents.component.css']
})
export class SecondaryModuleFolderContentsComponent implements OnInit {
  moduleFilesArray : Array<SecondaryModuleFile> = [];
  curriculums : Curriculum[];
  licenseExpirationDate: LicenseExpirationDateDTO;
  navEntries : any;

  fileNameVal: string;
  audioFileNameVal: string;
  navTextVal: string;
  hasSpanishFileVal:boolean;
  isMusic: boolean;
  isVideo: boolean;
  isDownloadable: boolean;
  safeHtml: SafeHtml;
  currentFolderId: string;

  folderFrom: string;

  currentFolder: SecondaryModuleFile;

  currentFolderHasParent : boolean = false;

  breadCrumbs : Breadcrumb[];
  displayCrumb : Array<DisplayCrumb> = [];


  constructor(private route: ActivatedRoute, private curriculumService : UserCurriculumService,  private router: Router, private sanitizer: DomSanitizer) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit() {

    this.route.data.subscribe(data =>
    {
      console.log("in module folder contents component");
      console.log(data);

      if(data.moduleFiles) {
        data.moduleFiles.forEach(moduleFile => {
          let typedFile: SecondaryModuleFile;
          console.log(moduleFile);
          typedFile = new SecondaryModuleFile(moduleFile.id, moduleFile.navText, moduleFile.fileName,
            moduleFile.name, moduleFile.secondaryModuleId, moduleFile.music, moduleFile.video,
            moduleFile.curriculumId, moduleFile.downloadable, moduleFile.folder, moduleFile.parentFolderId,
            moduleFile.moduleId);
          this.moduleFilesArray.push(typedFile);
        });
      }
      console.log(this.moduleFilesArray);
      this.curriculums = data.curriculums;
      console.log(this.curriculums);
      this.navEntries = data.navEntries;
      this.licenseExpirationDate = data.licenseExpirationDate;

      this.currentFolder = new SecondaryModuleFile(data.currentFolder.id, data.currentFolder.navText, data.currentFolder.filePath,
        data.currentFolder.name, data.currentFolder.secondaryModuleId, data.currentFolder.music, data.currentFolder.video,
        data.currentFolder.curriculumId, data.currentFolder.downloadable, data.currentFolder.folder, data.currentFolder.parentFolderId,
        data.currentFolder.moduleId);
      console.log(this.currentFolder);

      this.breadCrumbs = data.breadcrumbs;
      console.log(this.breadCrumbs);

      this.breadCrumbs.forEach( breadCrumb => {
        /*let url : string;
        console.log(this.currentModule.curriculumId);
        url = '/module/' + this.currentModule.curriculumId;
        console.log(url);
        let display : DisplayCrumb;
        display = new DisplayCrumb(breadCrumb.displayName, url);
        this.displayCrumb.push(display);
        console.log(this.displayCrumb);*/
        let url : string;
        let display : DisplayCrumb;
        if("curriculum" == breadCrumb.type){
          console.log(breadCrumb.curriculumId);
          console.log("display name");
          console.log(breadCrumb.displayName);
          if(breadCrumb.displayName === 'University -> '){
            url = '/university/' + breadCrumb.curriculumId;
          } else {
            url = '/module/' + breadCrumb.curriculumId;
          }
          console.log(url);
          display = new DisplayCrumb(breadCrumb.displayName, url);
          this.displayCrumb.push(display);
          console.log(this.displayCrumb);
        } else if("module" == breadCrumb.type){
          console.log(breadCrumb.curriculumId);
          url = '/moduleFileList/'+breadCrumb.moduleId + '/' +breadCrumb.curriculumId;
          console.log(url);
          display = new DisplayCrumb(breadCrumb.displayName, url);
          this.displayCrumb.push(display);
          console.log(this.displayCrumb);
        } else if("folder" == breadCrumb.type){
          console.log(breadCrumb.curriculumId);
          url = '/secondaryFolderContents/'+breadCrumb.parentFolderId;
          console.log(url);
          display = new DisplayCrumb(breadCrumb.displayName, url);
          this.displayCrumb.push(display);
          console.log(this.displayCrumb);
        } else if("secondary-module" == breadCrumb.type){
          console.log(breadCrumb);
          url = '/secondary-module-file-list/' + breadCrumb.secondaryModuleId + '/' + breadCrumb.curriculumId + '/'
          + breadCrumb.moduleId;
          console.log(url);
          if(breadCrumb.displayName != 'SOCIALDISTANCE / ') {
            display = new DisplayCrumb(breadCrumb.displayName, url);
          } else {
            display = new DisplayCrumb("Social Distancing Activities / ", url);
          }
          this.displayCrumb.push(display)
        }
      });

      console.log(this.breadCrumbs);
      console.log(this.displayCrumb);
    });

    console.log(this.route.snapshot);
    if(this.route.snapshot.params['parentId']){
      console.log("current folder id")
      this.currentFolderId = this.route.snapshot.params['parentId'];
      console.log(this.currentFolderId);
      console.log("current folder object");
      console.log(this.currentFolder);
      this.currentFolderHasParent = (this.currentFolder.parentFolderId != null);
      console.log(this.currentFolderHasParent);


    }

  }

  loadFile(moduleFile : SecondaryModuleFile){
    console.log("in load file: " + moduleFile.filePath);
    console.log(moduleFile);

    this.isVideo = moduleFile.video;
    this.fileNameVal = moduleFile.filePath;
    this.audioFileNameVal = moduleFile.filePath;
    this.navTextVal = moduleFile.navText;
    this.isMusic = moduleFile.music;
    this.isDownloadable = moduleFile.downloadable;
    if(moduleFile.video) {
      this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(moduleFile.fileName);
    }
  }

}
