import { Component, OnInit } from '@angular/core';
import {EmailValidation} from "../../helpers/validators";
import {FormBuilder, Validators} from "@angular/forms";
import {AdminUserService} from "../../_services/admin/adminUserService/admin-user.service";
import {SearchUserDTO} from "../../types/SearchUserDTO";
import {UpdateUserDTO} from "../../types/UpdateUserDTO";

@Component({
  selector: 'app-admin-account-user-lookup',
  templateUrl: './admin-account-user-lookup.html',
  styleUrls: ['./admin-account-user-lookup.component.css']
})
export class AdminAccountUserLookupComponent implements OnInit {
  findUser = this.fb.group({
    email: [],
    name: [],
    emailDomain: [],
    licenseKey: [],
    schoolName: [],
    orderNumber: [],
    accountId: []
  }, {validator: []});

  hasResponse : boolean = false;
  success : boolean = false;
  errorMessage: string;

  updateUserDTOsList : Array<UpdateUserDTO> = new Array<UpdateUserDTO>();


  editorLoaded : boolean = false;

  loadUser : UpdateUserDTO = null;
  constructor(private fb: FormBuilder, private adminUserService : AdminUserService) { }

  ngOnInit() {
  }

  findUserBySearchParams() {
    let email = this.findUser.get('email').value;
    let name = this.findUser.get('name').value;
    let emailDomain = this.findUser.get('emailDomain').value;
    let licenseKey = this.findUser.get('licenseKey').value;
    let schoolName = this.findUser.get('schoolName').value;
    let orderNumber = this.findUser.get('orderNumber').value;
    let accountId = this.findUser.get('accountId').value;

    email = email == '' ? null : email;
    name = name == '' ? null : name;
    emailDomain = emailDomain == '' ? null : emailDomain;
    licenseKey = licenseKey == '' ? null : licenseKey;
    schoolName = schoolName == '' ? null : schoolName;
    orderNumber = orderNumber == '' ? null : orderNumber;
    accountId = accountId == '' ? null : accountId;


    if (email || name || emailDomain || licenseKey || schoolName || orderNumber || accountId) {
      this.hasResponse = false;
      this.editorLoaded = false;
      this.updateUserDTOsList = new Array<UpdateUserDTO>();
      console.log(this.findUser.get('email').value);
      this.adminUserService.findUsersByEmail(new SearchUserDTO(email, name, emailDomain, licenseKey, schoolName, orderNumber, accountId)).subscribe(data => {
        console.log(data);
        this.success = data.success;
        this.errorMessage = data.message;
        if (data.success == true) {
          data.users.forEach(user => {
            let userDTO: UpdateUserDTO;

            userDTO = new UpdateUserDTO();
            userDTO.email = user.email;
            userDTO.firstName = user.firstName;
            userDTO.lastName = user.lastName;
            userDTO.school = user.school;
            userDTO.id = user.id;
            userDTO.accountId = user.accountId;

            this.updateUserDTOsList.push(userDTO);
          });

          console.log(this.updateUserDTOsList);
        }
      });
      this.hasResponse = true;
    }
  }

  loadUserEditor(id : number){
    console.log("loading for id: " + id);


    this.updateUserDTOsList.forEach(user => {
      if(!this.loadUser) {
        if (user.id == id) {
          this.loadUser = new UpdateUserDTO();
          this.loadUser.email = user.email;
          this.loadUser.school = user.school;
          this.loadUser.firstName = user.firstName;
          this.loadUser.lastName = user.lastName;
          this.loadUser.id = user.id;
          console.log(this.loadUser);
        }
      }
      }
    );

    this.editorLoaded = true;
  }

  onUserChange(value){
    console.log("on user change");
    console.log(value);
    // this.findUser.get('email').setValue(value.email);

    this.updateUserDTOsList.forEach(user => {
      if(user.id == value.id){
        user.school = value.school;
        user.email = value.email;
        user.firstName = value.firstName;
        user.lastName = value.lastName;
      }
    })
  }

  closeEditor(closeEditor){
    if(closeEditor) {
      this.editorLoaded = false;
      this.loadUser = null;
      this.findUserBySearchParams();
    }
  }
}
