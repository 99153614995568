import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ToastrService} from "ngx-toastr";
import {ActivatedRoute, Router} from "@angular/router";
import {OrganizationService} from "../../../../_services/adminOrganizationService/organization-service";
import {OrganizationCourseDTO} from "../../../../types/OrganizationCourseDTO";
import {AuthService} from "../../../../../_services/authService/auth.service";

@Component({
  selector: 'app-e-admin-list-organization-course',
  templateUrl: 'list-organization-course.html',
  styleUrls: ['list-organization-course.css']
})
export class eAdminListOrganizationCourseComponent implements OnInit {


  modalRef: BsModalRef;

  organizationId: string;

  organizationCourses: OrganizationCourseDTO[];

  recordToDelete: number;

  @ViewChild('confirmation')
  private confirmationTemplate: TemplateRef<any>;

  constructor(private organizationService: OrganizationService,
              private toastr: ToastrService,
              private router: Router,
              private authService: AuthService,
              private route: ActivatedRoute,
              private modalService: BsModalService) {

  }


  ngOnInit() {
    this.organizationId = this.route.snapshot.paramMap.get('id');
    this.organizationService.getCourses(this.organizationId).subscribe(data => {
      this.organizationCourses = data;
    });
  }

  edit(organizationCourse) {
    this.router.navigateByUrl('sparkecademy-admin-organization/' + this.organizationId + '/course/' + organizationCourse.id).then(r => {
    });
  }

  remove(organizationCourse) {
    this.modalRef = this.modalService.show(this.confirmationTemplate, {class: 'modal-sm'});
    this.recordToDelete = parseInt(organizationCourse.id);
  }

  confirm(): void {
    this.organizationService.removeCourse(this.organizationId, this.recordToDelete).subscribe(data => {
      if (data.success) {
        this.toastr.success("Course deleted successfully!!", "Success")
        this.organizationCourses = this.organizationCourses.filter(item => this.recordToDelete !== item.id);
      }
    });
    this.modalRef.hide();
  }

  decline(): void {
    this.modalRef.hide();
  }

}
