import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {CourseService} from "../../../../_services/adminCourseService/course-service";
import {ToastrService} from "ngx-toastr";
import {ActivatedRoute, Router} from "@angular/router";
import {CourseEnrollmentFilterDTO} from "../../../../types/CourseEnrollmentFilterDTO";
import {PaginationCourseEnrollmentDTO} from "../../../../types/PaginationCourseEnrollmentDTO";

@Component({
  selector: 'app-e-admin-list-course-enrollments',
  templateUrl: './list-course-enrollments.html',
  styleUrls: ['./list-course-enrollments.component.css']
})
export class eAdminCourseEnrollmentListComponent implements OnInit {

  header: string = "";
  courseId: string;

  courseEnrollmentFilterDto: CourseEnrollmentFilterDTO;

  filterCourseEnrollment = this.fb.group({
    userName: ['']
  }, {validator: []});

  paginationCourseEnrollment: PaginationCourseEnrollmentDTO;

  constructor(private fb: FormBuilder,
              private courseService: CourseService,
              private toastr: ToastrService,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.courseId = this.route.snapshot.paramMap.get('id');
    if (this.courseId != null) {
      this.loadData(this.courseId);
    }
  }

  loadData(courseId) {
    this.courseId = courseId;
    this.courseService.get(this.courseId).subscribe(data => {
      this.header = data.title + ' Enrollments';
      this.findEnrollments(null);
    });
  }


  findEnrollments(userName: any) {
    this.courseEnrollmentFilterDto = new CourseEnrollmentFilterDTO(0, 150, 0);
    if (userName != null) {
      this.courseEnrollmentFilterDto.userName = userName;
    }
    this.courseService.findEnrollments(this.courseId, this.courseEnrollmentFilterDto).subscribe(data => {
      this.paginationCourseEnrollment = data;
    });
  }

  updateCourseCompleteDate(courseEnrollment) {
    let enrollmentId = courseEnrollment.id;
    this.courseService.updateCourseCompleteDate(this.courseId, enrollmentId).subscribe(data => {
      if (data.success) {
        courseEnrollment.progress = 100;
        courseEnrollment.dateCompleted = this.getCurrentDate();
      }
    });
  }

  onSubmit() {
    let userName = this.filterCourseEnrollment.get("userName").value;
    this.findEnrollments(userName);
  }

  resetForm() {
  }

  getCurrentDate() {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = (today.getMonth() + 1).toString(); // Months start at 0!
    let dd = today.getDate().toString();

    if (parseInt(dd) < 10) dd = '0' + dd;
    if (parseInt(mm) < 10) mm = '0' + mm;

    return mm + '/' + dd + '/' + yyyy;
  }
}
