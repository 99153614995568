import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Curriculum} from "../../../../../types/Curriculum";
import {UserCurriculumService} from "../../../../../_services/userCurriculumService/user-curriculum.service";
import {AdminCurriculumService} from "../../../../../_services/admin/adminCurriculumService/admin-curriculum.service";
import {Module} from "../../../../../types/Module";
import {FormControl, FormGroup} from "@angular/forms";
import {DisplayOrderUpdateDTO} from "../../../../../types/DisplayOrderUpdateDTO";
import {IDDTO} from "../../../../../types/IDDTO";

@Component({
  selector: 'app-admin-module-editor-selector',
  templateUrl: './admin-module-editor-selector.html',
  styleUrls: ['./admin-module-editor-selector.component.css']
})
export class AdminModuleEditorSelectorComponent implements OnInit {

  _curriculum : Curriculum;


  mainSelectorIsLoaded : boolean = false;

  modules : Module[];

  @Output() loadModule=new EventEmitter<Module>();

  @Output() loadCreateNew=new EventEmitter<boolean>();

  @Output() reloadSelector=new EventEmitter<boolean>();

  displayOrderForm: FormGroup;

  showConfirm : Map<string, boolean> = new Map<string, boolean>();

  constructor(private curriculumService : AdminCurriculumService) { }

  ngOnInit() {

  }

  @Input()
  set curriculum(curriculum){
    console.log("module editor set c urriculum");
    this._curriculum = curriculum;

    this.curriculumService.getCurriculumModules(Number(this._curriculum.id)).subscribe(data => {
      console.log(data);
      this.modules = data;
      this.mainSelectorIsLoaded = true;

      const formGroup = new FormGroup({ });

      this.modules.forEach(module => {
        this.showConfirm.set(module.id, false);
        console.log(module);
        formGroup.addControl(module.navText, new FormControl(module.id));
        formGroup.controls[module.navText].setValue(module.displayOrder);
      });

      this.displayOrderForm = formGroup;

      console.log(this.displayOrderForm);
    });
  }


  loadModuleEditor(module){
    console.log("Load module editor");
    console.log(module);
    this.loadModule.emit(module);
  }

  deleteModule(module){
    this.showConfirm.set(module.id, true);
  }
  confirmedDelete(module){
    this.curriculumService.deleteModule(new IDDTO(Number(module.id))).subscribe(data => {
      console.log(data);
      this.reloadSelector.emit(true);
    });
  }
  saveDisplayOrderChanges(){
    let updateMap : Map<String, Number> = new Map<String, Number>();
    this.modules.forEach( module => {
      updateMap.set(module.id, this.displayOrderForm.controls[module.navText].value);
    });
    updateMap.forEach((val : number, key: string) => {
      updateMap[key] = val
    });

    let displayUpdateDTO : DisplayOrderUpdateDTO = new DisplayOrderUpdateDTO(updateMap);

    this.curriculumService.updateDisplayOrder(displayUpdateDTO).subscribe(data => {
      console.log(data);
      this.reloadSelector.emit(true);
    });

  }

  createNewModule(){
    this.loadCreateNew.emit(true);
  }
}
