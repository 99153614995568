import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DocumentDTO} from "../../types/DocumentDTO";

@Component({
  selector: 'app-e-admin-manage-document',
  templateUrl: './manage-document.html',
  styleUrls: ['./manage-document.component.css']
})
export class eAdminManageDocumentComponent implements OnInit {

  documentList: DocumentDTO[];

  dataToListDocuments($event) {
    this.documentList = $event;
  }

  constructor() {
  }

  ngOnInit() {
  }
}
