import {Component, Inject, OnInit} from '@angular/core';
import {AccountUserDTO} from "../../../../types/AccountUserDTO";
import {AdminUserService} from "../../../../_services/admin/adminUserService/admin-user.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {AccountService} from "../../../../_services/accountService/account.service";
import {ErrorMessageService} from "../../../../_services/errorMessageService/error-message.service";
import {Router} from "@angular/router";
import {IDDTO} from "../../../../types/IDDTO";

@Component({
  selector: 'app-admin-delete-user',
  templateUrl: './admin-delete-user.html',
  styleUrls: ['./admin-delete-user.component.css']
})
export class AdminDeleteUserComponent implements OnInit {
  userId : number;
  constructor(private adminUserService : AdminUserService, @Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialogRef<AdminDeleteUserComponent>) { }

  ngOnInit() {
    this.userId = this.data.id;
  }

  errorMessage : string;
  success : boolean = false;
  callFinsihed : boolean = false;

  deleteUser(id : number){
    console.log(id);
    this.callFinsihed = false;
    let dto : IDDTO = new IDDTO(id);
    this.adminUserService.deleteUser(dto).subscribe( data => {
      console.log("delete user response")
      console.log(data);

      if(!data.success){
        this.success = false;
        this.errorMessage = data.errorMessage;
      } else {
        this.success = true;
        this.dialogRef.close(true);
      }

      this.callFinsihed = true;
    });
  }

  close(){
    this.dialogRef.close(false);

  }

}
