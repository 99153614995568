import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Sort} from "@angular/material/sort";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";
import {MatMenuTrigger} from "@angular/material/menu";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {PaginationOrganizationDTO} from "../../../types/PaginationOrganizationDTO";
import {OrganizationService} from "../../../_services/adminOrganizationService/organization-service";
import {OrganizationFilterDTO} from "../../../types/OrganizationFilterDTO";

@Component({
  selector: 'app-e-admin-list-organization',
  templateUrl: 'list-organization.html',
  styleUrls: ['list-organization.component.css']
})
export class eAdminListOrganizationComponent implements OnInit {

  private _organizationFilterDto: OrganizationFilterDTO;

  @Input() set organizationFilterDto_(_organizationFilterDto: OrganizationFilterDTO) {

    this._organizationFilterDto = _organizationFilterDto;

    this.pageEvent_ = new PageEvent();
    this.pageEvent_.pageSize = 50;
    this.pageEvent_.length = 0;
    this.pageEvent_.pageIndex = 0;

    this.sortAndFilterRecords();
  }

  get organizationFilterDto_(): OrganizationFilterDTO {
    return this._organizationFilterDto;
  }

  paginationOrganizationList: PaginationOrganizationDTO;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  pageEvent?: Event;
  pageEvent_?: PageEvent;
  sort: Sort;

  @ViewChild('confirmation')
  private confirmationTemplate: TemplateRef<any>;

  modalRef: BsModalRef;

  recordToDelete: number;

  constructor(private organizationService: OrganizationService,
              private toastr: ToastrService,
              private router: Router,
              private modalService: BsModalService) {
  }

  sortAndFilterRecords() {
    let organizationFilterDto;

    if (typeof this.pageEvent_ !== 'undefined') {
      organizationFilterDto = new OrganizationFilterDTO(this.pageEvent_.pageIndex, this.pageEvent_.pageSize, this.pageEvent_.length);
    }

    if (typeof this.organizationFilterDto_ !== 'undefined') {
      organizationFilterDto.name = this.organizationFilterDto_.name;
      organizationFilterDto.onlyActiveRecords = this.organizationFilterDto_.onlyActiveRecords;
    }

    if (typeof this.sort != 'undefined') {
      organizationFilterDto.sortBy = this.sort.active;
      organizationFilterDto.sortOrder = this.sort.direction;
    }

    this.organizationService.filter(organizationFilterDto).subscribe(data => {
      this.paginationOrganizationList = data;
    });
    return event;
  }

  findRecords(event?: PageEvent) {
    this.pageEvent_ = event;
    return this.sortAndFilterRecords();
  }

  sortRecords(sort: Sort) {
    this.sort = sort;
    this.sortAndFilterRecords();
  }

  edit(organization) {
    this.router.navigateByUrl('sparkecademy-admin-organization/edit/' + organization.id).then(r => {
    });
  }

  dashboard(organization) {
    this.router.navigateByUrl('sparkecademy-admin-organization/dashboard/' + organization.id).then(r => {
    });
  }

  view(organization) {
    this.router.navigateByUrl('sparkecademy-admin-organization/view/' + organization.id).then(r => {
    });
  }

  remove(organization) {
    this.modalRef = this.modalService.show(this.confirmationTemplate, {class: 'modal-sm'});
    this.recordToDelete = organization.id;
  }

  confirm(): void {
    this.organizationService.remove(this.recordToDelete).subscribe(data => {
      if (data.success) {
        this.toastr.success("Organization deleted successfully!!", "Success")
        this.paginationOrganizationList.organizations = this.paginationOrganizationList.organizations.filter(item => this.recordToDelete !== item.id);
      }
    });
    this.modalRef.hide();
  }

  decline(): void {
    this.modalRef.hide();
  }

  ngOnInit() {
  }


  @ViewChild(MatMenuTrigger) ddTrigger: MatMenuTrigger;

  value: string;
  selectedValue: string;

  cancelClick(ev: MouseEvent) {
    ev.stopPropagation();
  }

  onCancel() {
    this.value = undefined;
    this.ddTrigger.closeMenu();
  }

  onSave() {
    this.selectedValue = this.value;
    this.value = undefined;
    this.ddTrigger.closeMenu();
  }
}
