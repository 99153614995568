import {Component, OnInit, ViewChild} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import {AccountService} from '../../_services/accountService/account.service';
import {RegistrationDTO} from "../../types/RegistrationDTO";
import {Observable} from "rxjs";
import {User} from "../../types/User";
import {AuthService} from "../../_services/authService/auth.service";
import {ErrorMessageComponent} from "../error-message/error-message.component";
import {ErrorMessageService} from "../../_services/errorMessageService/error-message.service";
import {ActivatedRoute, Router, RouterModule} from "@angular/router";
import {
  RepeatPasswordEStateMatcher,
  RepeatPasswordValidator,
  PasswordValidation,
  EmailValidation, RepeatEmailValidator
} from "../../helpers/validators";

@Component({
  selector: 'app-signup',
  templateUrl: `./signup.html`,
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  regInfo:RegistrationDTO;
  passwordsMatcher = new RepeatPasswordEStateMatcher;

  paramKey:string;

  registrationForm = this.fb.group({
    firstName:['', Validators.required],
    lastName: ['', Validators.required],
    email: ['', EmailValidation],
    emailConfirm: ['', EmailValidation],
    password: ['', PasswordValidation],
    passwordConfirm: [''],
    school: ['', Validators.required],
    license: ['']
  }, {validator: [RepeatPasswordValidator, RepeatEmailValidator]});


  constructor(private fb: FormBuilder, private accountService: AccountService, private authService: AuthService, private errorMessageService: ErrorMessageService,
              private router: Router, private route: ActivatedRoute) {

  }


  ngOnInit() {
    localStorage.removeItem("currentUser");
    this.paramKey = this.route.snapshot.params['key'];
    if(this.paramKey){
      this.registrationForm.setValue({firstName: null,
        lastName: null,
        email: null,
        emailConfirm: null,
        password: null,
        passwordConfirm: null,
        school: null,
        license: this.paramKey});
    }
  }

  onSubmit() {

    // TODO: Use EventEmitter with form value
    console.warn(this.registrationForm.value);
    this.regInfo = new RegistrationDTO(this.registrationForm.get('email').value,this.registrationForm.get('firstName').value,this.registrationForm.get('lastName').value,this.registrationForm.get('password').value,this.registrationForm.get('school').value, this.registrationForm.get('license').value);
    this.errorMessageService.clear();
    this.accountService.registerAccount(this.regInfo).subscribe(
      data => {
        if(data.success + '' === 'true') {
          console.log("good signup info");
          this.authService.login(this.regInfo.email, this.regInfo.password).subscribe();
        } else {
          console.log("bad signup info");
          this.errorMessageService.add(data.error);
        }
      }
    );



  }
}
