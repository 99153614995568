export class CourseDTO {
  id: number;
  title: string;
  subTitle: string;
  description: string;
  length: number;
  cost: number;
  category: string;
  categoryImg: string;
  categoryId: number;
  tagId: string;
  code: string;
  lessonCount: number;
  studentCount: number;
  isActiveStr: string;
  tag: string;
  isAlreadyEnrolled: boolean;
  isOnsite: boolean;

  constructor(title: string, subTitle: string, description: string, length: number,
              cost: number,tagId: string, code: string, categoryId: number, isOnsite: boolean, lessonCount?: number,
              studentCount?: number, isActiveStr?: string, category?: string, id?: number, tag? : string, categoryImg?: string, isAlreadyEnrolled?: boolean) {
    this.title = title;
    this.subTitle = subTitle;
    this.description = description;
    this.length = length;
    this.cost = cost;
    this.tagId = tagId;
    this.code = code;
    this.lessonCount = lessonCount;
    this.studentCount = studentCount;
    this.categoryId = categoryId;
    this.isOnsite = isOnsite;
    this.category = category;
    this.isActiveStr = isActiveStr;
    this.tag = tag;
    this.categoryImg = categoryImg;
    this.isAlreadyEnrolled = isAlreadyEnrolled;
  }
}
