import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {User} from "../../types/User";
import {BehaviorSubject, Observable, of} from "rxjs";
import {map} from "rxjs/operators";
import {Router} from "@angular/router";
import {ErrorMessageService} from "../errorMessageService/error-message.service";
import {RegistrationDTO} from "../../types/RegistrationDTO";
import {RegResponse} from "../../types/RegResponse";
import {ResetPasswordDTO} from "../../types/ResetPasswordDTO";
import {DisplayMessageService} from "../displayMessageService/display-message.service";
import {catchError, retry} from 'rxjs/operators';
import {ToastrService} from "ngx-toastr";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  //for local
  // private urlBase: string = "http://localhost:8050";
  //for qa
  // private urlBase : string = "http://sparkfamily.org:8050";
  //for production
  private urlBase : string = "https://sparkfamily.org/api";
  private loginUrl: string = this.urlBase + "/account/login";
  private resetTokenIsValidUrl: string = this.urlBase + "/account/resetTokenValid";
  private isAdminUrl: string = this.urlBase + "/account/isAdmin";
  private healthCheckUrl: string = this.urlBase + "/account/healthcheck";
  private impersonateUserUrl: string = this.urlBase + "/admin/user/impersonate";


  constructor(private http: HttpClient,
              private router: Router,
              private errorService: ErrorMessageService,
              private displayMessage: DisplayMessageService,
              private toastr: ToastrService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public isManager(): boolean {
    try {
      return this.getCurrentUserValue().isManager;
    }catch (e) {
      return false;
    }
  }


  public getCurrentUserName(): string {
    return this.getCurrentUserValue().firstName + " " + this.getCurrentUserValue().lastName;
  }

  public getCurrentUserValue(): User {
    console.log("getCurrentUserValue");
    console.log(localStorage.getItem('currentUser'));
    if (localStorage.getItem('currentUser')) {
      return this.currentUserSubject.value;
    }
    //local storage item was lost in some event that wasn't trigger via logout
    //logout now
    this.logout(false);
  }

  public getCurrentUserValueNoLogout(): User {
    console.log("getCurrentUserValue");
    console.log(localStorage.getItem('currentUser'));
    if (localStorage.getItem('currentUser')) {
      return this.currentUserSubject.value;
    }
  }


  login(email: string, password: string) {
    this.errorService.clear();
    return this.http.post<any>(this.loginUrl, {email, password}).pipe(map(user => {
      console.log("user " + user);
      console.log(user);
      if (user && user.token) {
        console.log("has token");
        console.log('putting user in session');
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        this.router.navigateByUrl('/home');
      }

      if (user && user.error) {
        console.log("has error");
        this.errorService.clear();
        this.errorService.add(user.error);
      }

    }));
  }

  public updateAccount(regInfo: RegistrationDTO) {
    return this.http.post<any>(this.urlBase + '/account/update', regInfo).pipe(map(user => {
      this.logout(false);
      console.log("in update account");
      console.log("user " + user);
      console.log(user);
      if (user && user.token) {
        console.log("has token");
        console.log('putting user in session');
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.displayMessage.add("Update successful");
        this.currentUserSubject.next(user);
      }

      if (user && user.error) {
        console.log("has error");
        this.errorService.clear();
        this.errorService.add(user.error);
      }

    }));

  }


  impersonateUser(userEmail: string): Observable<any> {
    let url = this.impersonateUserUrl;
    return this.http.post<any>(url, userEmail).pipe(map(user => {
      if (user && user.token) {
        this.toastr.success("You are logged in as " + user.email + ". You need to logout to return to your account")
        localStorage.setItem('currentAdminUser', localStorage.getItem("currentUser"));
        localStorage.setItem('currentUser', JSON.stringify(user));
        localStorage.setItem("gotoUser", "true");
        localStorage.removeItem('useFamAdmin');

        this.currentUserSubject.next(user);
        this.router.navigateByUrl("/sparkecademy-user-dashboard").then(data => {
            window.setTimeout(function () {
              location.href = "#/sparkecademy-user-dashboard"
            }, 500);
          }
        );
      }

      if (user && user.error) {
        console.log("has error");
        this.errorService.clear();
        this.errorService.add(user.error);
      }
    }));
  }


  public healthCheck() {
    return this.http.get<any>(this.healthCheckUrl).pipe(catchError(this.handleHealthCheckError()));
  }

  private handleHealthCheckError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log("user cur service catch error");
      console.log("error1");
      console.error(error);
      console.log("error1");
      let useProd: boolean = this.healthCheckUrl.includes("8045");
      if (useProd) {
        this.errorService.add("The site was unable to reach our backserver. This unfortunately means the site is not functioning correctly for you currently."
          + " A common cause for this is that you have outgoing traffic on port: 8045 blocked. " +
          "Please contact your IT service staff and ask them to open traffic on port: 8045");
      } else {
        this.errorService.add("The site was unable to reach our backserver. This unfortunately means the site is not functioning correctly for you currently."
          + " A common cause for this is that you have outgoing traffic on port: 8045 blocked. " +
          "Please contact your IT service staff and have them open up traffic on port: 8050");
      }

      return of(result as T);
    };
  }

  public updatePassword(regInfo: ResetPasswordDTO) {
    return this.http.post<any>(this.urlBase + '/account/updatePassword', regInfo).pipe(map(user => {
      this.logout(false);
      console.log("in update account");
      console.log("user " + user);
      console.log(user);
      if (user && user.token) {
        console.log("has token");
        console.log('putting user in session');
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        this.router.navigateByUrl("/home");
      }

      if (user && user.error) {
        console.log("has error");
        this.errorService.clear();
        this.errorService.add(user.error);
      }

    }));

  }

  public isResetTokenValid(token: string): Observable<any> {
    return this.http.post<any>(this.resetTokenIsValidUrl, token);
  }


  logout(redirect: boolean) {
    let adminUser = localStorage.getItem("currentAdminUser");
    if (adminUser != null) {
      localStorage.setItem("currentUser", adminUser);
      localStorage.removeItem("currentAdminUser");
      localStorage.setItem("gotoAdmin", "true");
      this.currentUserSubject.next(JSON.parse(adminUser));
      localStorage.setItem('useFamAdmin', 'true');
      this.toastr.success("You are logged in as " + JSON.parse(adminUser).email + ".")
      location.href = "#/admin-home"
      return;
    }
    console.log("logout: redirect: " + redirect);
    localStorage.removeItem("currentUser");
    this.currentUserSubject.next(null);
    if (redirect) {
      this.router.navigateByUrl('/intro');
    }
  }

  public isLogginedIn() {
    return localStorage.getItem("currentUser") != null;
  }

  public isloggedOut() {
    return !this.isLogginedIn();
  }


  public isUserAdmin(): Observable<any> {
    return this.http.get<any>(this.isAdminUrl);
  }


}
