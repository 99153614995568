import { Component, OnInit } from '@angular/core';
import {Curriculum} from "../../types/Curriculum";
import {Breadcrumb} from "../../types/Breadcrumb";
import {DisplayCrumb} from "../../types/DisplayCrumb";
import {ActivatedRoute, Router} from "@angular/router";
import {UserCurriculumService} from "../../_services/userCurriculumService/user-curriculum.service";
import {TTModuleFile} from "../../types/TTModuleFile";
import {LicenseExpirationDateDTO} from "../../types/LicenseExpirationDateDTO";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Component({
  selector: 'app-tt-module-folder-contents',
  templateUrl: 'tt-module-folder-contents.html',
  styleUrls: ['./tt-module-folder-contents.component.css']
})
export class TtModuleFolderContentsComponent implements OnInit {

  moduleFilesArray : Array<TTModuleFile> = [];
  curriculums : Curriculum[];
  licenseExpirationDate: LicenseExpirationDateDTO;
  navEntries : any;

  fileNameVal: string;
  audioFileNameVal: string;
  navTextVal: string;
  hasSpanishFileVal:boolean;
  isMusic: boolean;
  isVideo:boolean;
  safeHtml: SafeHtml;
  isDownloadable: boolean;

  currentFolderId: string;

  folderFrom: string;

  currentFolder: TTModuleFile;

  currentFolderHasParent : boolean = false;

  breadCrumbs : Breadcrumb[];
  displayCrumb : Array<DisplayCrumb> = [];


  constructor(private route: ActivatedRoute, private curriculumService : UserCurriculumService,  private router: Router, private sanitizer: DomSanitizer) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit() {

    this.route.data.subscribe(data =>
    {
      console.log("in module folder contents component");
      console.log(data);

      if(data.moduleFiles) {
        data.moduleFiles.forEach(moduleFile => {
          let typedFile: TTModuleFile;
          console.log(moduleFile);
          typedFile = new TTModuleFile(moduleFile.id, moduleFile.navText, moduleFile.fileName, moduleFile.success, moduleFile.error, moduleFile.folder, moduleFile.hasSpanishFile, moduleFile.curriculumId, moduleFile.moduleId, moduleFile.music, moduleFile.downloadable);
          typedFile.video = moduleFile.video;
          this.moduleFilesArray.push(typedFile);
        });
      }
      console.log(this.moduleFilesArray);
      this.curriculums = data.curriculums;
      console.log(this.curriculums);
      this.navEntries = data.navEntries;
      this.licenseExpirationDate = data.licenseExpirationDate;

      this.currentFolder = data.currentFolder;
      console.log(this.currentFolder);

      this.breadCrumbs = data.breadcrumbs;
      console.log(this.breadCrumbs);

      this.breadCrumbs.forEach( breadCrumb => {
        /*let url : string;
        console.log(this.currentModule.curriculumId);
        url = '/module/' + this.currentModule.curriculumId;
        console.log(url);
        let display : DisplayCrumb;
        display = new DisplayCrumb(breadCrumb.displayName, url);
        this.displayCrumb.push(display);
        console.log(this.displayCrumb);*/
        let url : string;
        let display : DisplayCrumb;
        if("curriculum" == breadCrumb.type){
          console.log(breadCrumb.curriculumId);
          console.log("display name");
          console.log(breadCrumb.displayName);
          if(breadCrumb.displayName === 'University -> '){
            url = '/university/' + breadCrumb.curriculumId;
          } else {
            url = '/module/' + breadCrumb.curriculumId;
          }
          console.log(url);
          display = new DisplayCrumb(breadCrumb.displayName, url);
          this.displayCrumb.push(display);
          console.log(this.displayCrumb);
        } else if("module" == breadCrumb.type){
          console.log(breadCrumb.curriculumId);
          url = '/ttModuleFileList/'+breadCrumb.moduleId + '/' +breadCrumb.curriculumId;
          console.log(url);
          display = new DisplayCrumb(breadCrumb.displayName, url);
          this.displayCrumb.push(display);
          console.log(this.displayCrumb);
        } else if("folder" == breadCrumb.type){
          console.log(breadCrumb.curriculumId);
          url = '/ttModuleFolderContents/'+breadCrumb.parentFolderId;
          console.log(url);
          display = new DisplayCrumb(breadCrumb.displayName, url);
          this.displayCrumb.push(display);
          console.log(this.displayCrumb);
        }
      });

      console.log(this.breadCrumbs);
      console.log(this.displayCrumb);
    });

    console.log(this.route.snapshot);
    if(this.route.snapshot.params['parentId']){
      console.log("current folder id")
      this.currentFolderId = this.route.snapshot.params['parentId'];
      console.log(this.currentFolderId);
      console.log("current folder object");
      console.log(this.currentFolder);

      if(this.currentFolder.parentFolderId != 'null'){
        this.currentFolderHasParent = true;
      }
    }

  }

  loadFile(moduleFile : TTModuleFile){
    console.log("in load file: " + moduleFile.fileName);
    console.log(moduleFile);

    this.fileNameVal = moduleFile.fileName;
    this.audioFileNameVal = moduleFile.fileName;
    this.navTextVal = moduleFile.navText;
    this.isMusic = moduleFile.music;
    this.isVideo = moduleFile.video;
    this.isDownloadable = moduleFile.downloadable;
    if(moduleFile.video) {
      this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(moduleFile.fileName);
    }
  }

}
