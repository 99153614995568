import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {AdminCurriculumService} from "../../../../../../_services/admin/adminCurriculumService/admin-curriculum.service";
import {CreateNewModuleDTO} from "../../../../../../types/CreateNewModuleDTO";

@Component({
  selector: 'app-create-new-module-file',
  templateUrl: './create-new-module-file.html',
  styleUrls: ['./create-new-module-file.component.css']
})
export class CreateNewModuleFileComponent implements OnInit {

  public MODULE_FILE : string = "MODULE_FILE";
  public TT_MODULE_FILE: string = "TT_MODULE_FILE";
  public SECONDARY_MODULE_FILE : string = "SECONDARY_MODULE_FILE";

  moduleFile : boolean = false;
  ttModuleFile : boolean = false;
  secondaryModuleFile : boolean = false;

  @Input() parentId;
  @Input() secondaryModuleParentId;
  @Input() parentType;

  callReturned : boolean = false;
  message : string = null;


  types: object[] = [{name: 'PDF', checked: true}, {name: 'VIDEO', checked:false},
    {name: 'MUSIC', checked:false}, {name: 'DOWNLOADABLE', checked:false}, {name: 'FOLDER', checked:false}];


  createNewModuleFile = this.fb.group({
    NAV_TEXT:[''],
    TYPE: 'PDF',
    file:[null],
    videoEmbed:['']
  }, {validator: []});


  @Output() closeNewModuleFileEmit=new EventEmitter<boolean>();

  constructor(private fb: FormBuilder, private adminCurriculumService : AdminCurriculumService) { }

  ngOnInit() {
  }

  @Input()
  set type(type : string){
    if(type == this.MODULE_FILE){
      this.moduleFile = true;
      this.ttModuleFile = false;
      this.secondaryModuleFile = false;
    }
    if(type == this.TT_MODULE_FILE){
      this.moduleFile = false;
      this.ttModuleFile = true;
      this.secondaryModuleFile = false;
    }
    if(type == this.SECONDARY_MODULE_FILE){
      this.moduleFile = false;
      this.ttModuleFile = false;
      this.secondaryModuleFile = true;
    }
  }

  onFileChange(event) {
    console.log("onfilechange");
    if(event.target.files.length > 0) {
      let file = event.target.files[0];
      this.createNewModuleFile.get('file').setValue(file);
      console.log(this.createNewModuleFile);
    }
  }


  prepareSave() :any{

   /* let dto : CreateNewModuleDTO = new CreateNewModuleDTO(this.createNewModule.controls['NAV_TEXT'].value, Number(this._curriculum.id));
    console.log(dto);
    console.log(this._curriculum);
    console.log(this.createNewModule);
    this.adminCurriculumService.createNewModule(dto).subscribe(data =>{
      this.newModuleFileCreated.emit(true);
    });*/
   console.log("prepare save");
   console.log(this.createNewModuleFile);

    let input = new FormData();
    // This can be done a lot prettier; for example automatically assigning values by looping through `this.form.controls`, but we'll keep it as simple as possible here
    input.append('NAV_TEXT', this.createNewModuleFile.get('NAV_TEXT').value);
    input.append('TYPE', this.createNewModuleFile.get('TYPE').value);
    if(this.createNewModuleFile.get('videoEmbed').value) {
      input.append('videoEmbed', this.createNewModuleFile.get('videoEmbed').value);
    }
    if(this.createNewModuleFile.get('file').value) {
      input.append('FILE', this.createNewModuleFile.get('file').value);
    }
    input.append('PARENT_ID', this.parentId + '');
    console.log(this.parentType);
    input.append('PARENT_TYPE', this.parentType);
    console.log(input.getAll('NAV_TEXT'));
    console.log(input.getAll('TYPE'));
    console.log(input.getAll('videoEmbed'));
    console.log(input.getAll('FILE'));
    console.log(input.getAll('PARENT_ID'));
    console.log(input.getAll('PARENT_TYPE'));
    return input;

  }

  saveNewModuleFile(){
    this.callReturned = false;
    this.message = null;

      this.adminCurriculumService.createNewModuleFile(this.prepareSave()).subscribe(data => {
        console.log(data);
        if (data.success) {
          this.message = "Changes saved";
          this.newModuleFileCreated();
        } else {
          this.message = data.errorMessage;
        }

        this.callReturned = true;
      });
  }
  closeNewModule(){
    this.closeNewModuleFileEmit.emit(true);
  }

  newModuleFileCreated(){
    this.closeNewModuleFileEmit.emit(true);
  }

}
