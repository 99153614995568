import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {EmailValidation} from "../../helpers/validators";
import {SelfRegistrationDTO} from "../../types/SelfRegistrationDTO";
import {CreateKeyDTO} from "../../types/CreateKeyDTO";
import {AdminLicenseService} from "../../_services/admin/adminLicenseService/admin-license.service";
import {ErrorMessageService} from "../../_services/errorMessageService/error-message.service";
import {SecondaryModule} from "../../types/SecondaryModule";

@Component({
  selector: 'app-admin-generate-license-key',
  templateUrl: './admin-generate-license-key.html',
  styleUrls: ['./admin-generate-license-key.component.css']
})
export class AdminGenerateLicenseKeyComponent implements OnInit {

  createLicense = this.fb.group({
    email: ['', EmailValidation],
    referenceNumber: [''],
    licenseKey: [''],
    licenseDuration: ['', Validators.required]
  }, {validator: []});

  /*    K2PEEnable: false,
    PE36Enable:false,
    MSPEEnable:false,
    HSPEEnable:false,
    IPEEnable:false,
    ABCCAEnable:false,
    ASEnable:false,
    ECEnable:false,
    K2PEQty: [{value:'', disabled: true}],
    PE36Qty: [{value:'', disabled: true}],
    MSPEQty: [{value:'', disabled: true}],
    HSPEQty: [{value:'', disabled: true}],
    IPEQty: [{value:'', disabled: true}],
    ABCCAQty: [{value:'', disabled: true}],
    ASQty: [{value:'', disabled: true}],
    ECQty: [{value:'', disabled: true}]*/
  //createLicense: FormGroup;

  @Output() reset = new EventEmitter<boolean>();

  submitted : boolean = false;

  spinProgressWheel : boolean = false;

  createdLicense : string;

  hadError: boolean = false;
  errorMessage : string;

  roles : string[];

  roleTypeList: Array<Array<String>> = [];

  @Input() isEditMode = false;
  @Input() existingLicenseInfo: any;
  @Output() editLicenseInitEvent = new EventEmitter<string>();

  constructor(private fb: FormBuilder, private licenseService: AdminLicenseService) {
  }

  ngOnInit() {

    this.licenseService.getFormRoles().subscribe(data => {
      console.log("get form roles");
      console.log(data);
      this.roles = data;
      console.log(this.roles);
      if(data){
        let count : number = 0;
        data.forEach( type => {
          let role : String;
          role = type;
          if(count % 2 == 0 ){
            this.roleTypeList.push(new Array<String>());
          }
          let temp : Array<String> = new Array<String>();
          temp = this.roleTypeList.pop();
          temp.push(role);
          this.roleTypeList.push(temp);
          count++;
        });

        data.forEach(type => {
          this.createLicense.addControl(type + 'Enable', this.fb.control(false));
          this.createLicense.addControl(type + 'Qty', this.fb.control(''));
          this.createLicense.get(type + 'Qty').disable();
        });

        data.forEach(type => {
          this.createLicense.get(type + 'Enable').valueChanges.subscribe(v => {
            if(v){
              this.createLicense.get(type + 'Qty').enable();
            } else {
              this.createLicense.get(type + 'Qty').disable();
            }
          });
        });
      }
      this.refreshData(this.existingLicenseInfo);
    });

    console.log("form");
    console.log(this.createLicense);
  }

  refreshData(existingLicenseInfo: any) {
    if (this.isEditMode) {
      this.submitted = false;
      this.existingLicenseInfo = existingLicenseInfo;
      this.roles.forEach(type => {
        this.createLicense.get(type + 'Qty').disable();
        this.createLicense.get(type + 'Enable').setValue(false);
        this.createLicense.get(type + 'Qty').setValue('');
      });
      this.createLicense.get('email').setValue(this.existingLicenseInfo.email);
      this.createLicense.get('licenseKey').setValue(this.existingLicenseInfo.licenseKey);
      this.createLicense.get('referenceNumber').setValue(this.existingLicenseInfo.referenceNumber);
      this.createLicense.get('licenseDuration').setValue(this.existingLicenseInfo.duration + ' ' + this.existingLicenseInfo.durationUnit);
      this.existingLicenseInfo.types.forEach((type: string) => {
        this.createLicense.get(type + 'Enable').setValue(true);
        this.createLicense.get(type + 'Qty').enable();
        this.createLicense.get(type + 'Qty').setValue(this.existingLicenseInfo.qty[type]);
      })
    }
  }

  createLicenseSubmit() {
    this.submitted = true;
    this.spinProgressWheel = true;

    let types: Array<string> = new Array<string>();
    let qty = new Map<string, string>();
    console.log(qty);

    this.roles.forEach(role => {
      if (this.createLicense.get(role + 'Enable').value) {
        types.push(role);
        qty.set(role, this.createLicense.get(role + 'Qty').value);
      }
    });

    console.log("types");
    console.log(types);
    console.log("Qty");
    console.log(qty);

    let dto : CreateKeyDTO = new CreateKeyDTO(this.createLicense.get('email').value,
      this.createLicense.get('referenceNumber').value,this.createLicense.get('licenseDuration').value,
      types, qty);
    dto.licenseKey = this.createLicense.get('licenseKey').value;


    this.licenseService.createLicenseCall(dto).subscribe( data => {
      if(data.error){
        this.hadError = true;
        this.spinProgressWheel = false;
        this.errorMessage = data.errorMessage;
      } else if(data.license) {
        this.createdLicense = data.license;
        this.spinProgressWheel = false;
      } else {
        this.hadError;
        this.errorMessage = "Error creating license key";
        this.spinProgressWheel = false;
      }
    });

  }

  resetComponent(){
    console.log("resetComponent");
    /*console.log("reset component");
    //this.createLicense.reset();
    //this.createLicense
    this.submitted = false;
    this.spinProgressWheel = false;
    this.createLicense = null;
    this.hadError = false;
    this.errorMessage = null;*/


    this.reset.emit(true);
  }
}
