import {Component, Input, OnInit} from '@angular/core';
import {UserFilterDTO} from "../../types/UserFilterDTO";
import {CourseFilterDTO} from "../../types/CourseFilterDTO";

@Component({
  selector: 'app-e-admin-manage-user',
  templateUrl: './manage-course.html',
  styleUrls: ['./manage-course.component.css']
})
export class eAdminManageCourseComponent implements OnInit {

  courseFilterDto_: CourseFilterDTO;

  dataToFilter($event) {
    this.courseFilterDto_ = $event;
  }

  constructor() {
  }

  ngOnInit() {
  }
}
