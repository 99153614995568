import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {OrganizationService} from "../../../../_services/adminOrganizationService/organization-service";
import {CreditTransactionDTO} from "../../../../types/CreditTransactionDTO";
import {AuthService} from "../../../../../_services/authService/auth.service";

@Component({
  selector: 'app-e-admin-organization-credit-history',
  templateUrl: 'credit-history.html',
  styleUrls: ['credit-history.component.css']
})
export class eAdminOrganizationManageCreditsComponent implements OnInit {
  header: string;
  organizationId: string;
  creditTransationDto: CreditTransactionDTO[];

  constructor(private organizationService: OrganizationService,
              private router: Router,
              private authService: AuthService,
              private route: ActivatedRoute) {
  }


  ngOnInit() {
    this.organizationId = this.route.snapshot.paramMap.get('id');
    this.organizationService.get(this.organizationId).subscribe(data => {
      this.header = data.name + ' Credit Transaction History';
    });

    this.organizationService.getCreditTransactionHistory(this.organizationId).subscribe(data => {
      this.creditTransationDto = data;
    });
  }
}
