import {Component, EventEmitter, Injectable, OnInit, Output} from '@angular/core';
import {UserCurriculumService} from "../../_services/userCurriculumService/user-curriculum.service";
import {Curriculum} from "../../types/Curriculum";
import {AdminCurriculumService} from "../../_services/admin/adminCurriculumService/admin-curriculum.service";

import {CollectionViewer, SelectionChange, DataSource} from '@angular/cdk/collections';
import {FlatTreeControl} from '@angular/cdk/tree';
import {BehaviorSubject, merge, Observable, Subscription} from "rxjs";
import {map} from "rxjs/operators";
import {CurriculumTreeRequestDTO} from "../../types/CurriculumTreeRequestDTO";
import {ModuleFile} from "../../types/ModuleFile";
import {TTModuleFile} from "../../types/TTModuleFile";
import {SecondaryModuleFile} from "../../types/SecondaryModuleFile";

export class DynamicFlatNode {
  constructor(
    public key: string,
    public value: string,
    public type: string,
    public subType: string,
    public level: number = 1,
    public expandable: boolean,
    public isLoading: boolean = false,
    public fileName: string,
    public hasSpanishFile: boolean,
    public music: boolean,
    public video: boolean,
    public downloadable: boolean,
    public trialAccessible: boolean
  ) {
  }
}

@Injectable({providedIn: 'root'})
export class DynamicDatabase {
  dataMap = new Map<string, string[]>([
    ['Fruits', ['Apple', 'Orange', 'Banana']],
    ['Vegetables', ['Tomato', 'Potato', 'Onion']],
    ['Apple', ['Fuji', 'Macintosh']],
    ['Onion', ['Yellow', 'White', 'Purple']],
  ]);

  rootLevelNodes: Array<DynamicFlatNode> = new Array<DynamicFlatNode>();

  constructor(public curriculumService: AdminCurriculumService) {
    this.curriculumService = curriculumService;
  }

  getChildren(node: DynamicFlatNode) {
    // return this.curriculumService.getCurriculumTree(this.getRequestBody(node)).subscribe();
  }

  isExpandable(node: string): boolean {
    return this.dataMap.has(node);
  }

  ngOnInit(dataSource: DynamicDataSource): void {
    let requestData = new CurriculumTreeRequestDTO();
    requestData.requestType = 'CURRICULUM';
    this.curriculumService.getCurriculumTree(requestData).subscribe(data => {
      data.forEach(curriculum => {
        let curriculumTyped = new DynamicFlatNode(curriculum.key, curriculum.value, curriculum.type,
          curriculum.type, 1, true, false, null, false,
          false, false, false, false);
        this.rootLevelNodes.push(curriculumTyped);
        dataSource.data = this.rootLevelNodes;
      });
    });
  }
}


export class DynamicDataSource implements DataSource<DynamicFlatNode> {
  dataChange = new BehaviorSubject<DynamicFlatNode[]>([]);


  get data(): DynamicFlatNode[] {
    return this.dataChange.value;
  }

  set data(value: DynamicFlatNode[]) {
    this._treeControl.dataNodes = value;
    this.dataChange.next(value);
  }

  constructor(
    private _treeControl: FlatTreeControl<DynamicFlatNode>,
    private _database: DynamicDatabase) {
  }

  connect(collectionViewer: CollectionViewer): Observable<DynamicFlatNode[]> {
    this._treeControl.expansionModel.changed.subscribe(change => {
      if (
        (change as SelectionChange<DynamicFlatNode>).added ||
        (change as SelectionChange<DynamicFlatNode>).removed
      ) {
        this.handleTreeControl(change as SelectionChange<DynamicFlatNode>);
      }
    });

    return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
  }

  disconnect(collectionViewer: CollectionViewer): void {
  }

  /** Handle expand/collapse behaviors */
  handleTreeControl(change: SelectionChange<DynamicFlatNode>) {
    if (change.added) {
      change.added.forEach(node => this.toggleNode(node, true));
    }
    if (change.removed) {
      change.removed
        .slice()
        .reverse()
        .forEach(node => this.toggleNode(node, false));
    }
  }

  getRequestBody(node: DynamicFlatNode) {

    let curriculumId = null;
    let moduleId = null;
    let moduleFolderId = null;
    let secondaryModuleId = null;
    let secondaryModuleFolderId = null;
    let ttModuleId = null;
    let ttModuleFolderId = null;
    let requestType = null;

    if (node.type == 'CURRICULUM' && node.subType == 'MODULE') {
      curriculumId = node.key;
      requestType = 'MODULE_BY_CURRICULUM_ID';
    } else if (node.type == 'CURRICULUM' && node.subType == 'TT_MODULE') {
      curriculumId = node.key;
      requestType = 'TT_MODULE_BY_CURRICULUM_ID';
    } else if (node.type == 'MODULE' && node.subType == 'MODULE_FILE') {
      moduleId = node.key;
      requestType = 'MODULE_FILE_BY_MODULE_ID';
    } else if (node.type == 'MODULE' && node.subType == 'SECONDARY_MODULE') {
      moduleId = node.key;
      requestType = 'SECONDARY_MODULE_BY_MODULE_ID';
    } else if (node.type == 'MODULE_FILE') {
      moduleFolderId = node.key;
      requestType = 'MODULE_FILE_BY_MODULE_FOLDER_ID';
    } else if (node.type == 'SECONDARY_MODULE') {
      secondaryModuleId = node.key;
      requestType = 'SECONDARY_MODULE_FILE_BY_SECONDARY_MODULE_ID';
    } else if (node.type == 'SECONDARY_MODULE_FILE') {
      secondaryModuleFolderId = node.key;
      requestType = 'SECONDARY_MODULE_FILE_BY_SECONDARY_MODULE_FOLDER_ID';
    } else if (node.type == 'TT_MODULE') {
      ttModuleId = node.key;
      requestType = 'TT_MODULE_FILE_BY_TT_MODULE_ID';
    } else if (node.type == 'TT_MODULE_FILE') {
      ttModuleFolderId = node.key;
      requestType = 'TT_MODULE_FILE_BY_TT_MODULE_FOLDER_ID';
    }


    let requestData = new CurriculumTreeRequestDTO(curriculumId, moduleId, moduleFolderId, secondaryModuleId, secondaryModuleFolderId, ttModuleId, ttModuleFolderId, requestType);
    return requestData;
  }

  addLevelTwoNodes(node, index, expand) {
    if (expand) {
      let nodes: Array<DynamicFlatNode> = new Array<DynamicFlatNode>();
      nodes.push(new DynamicFlatNode(node.key, "Module", node.type, "MODULE", node.level + 1, true, false, null, false,
        false, false, false, false));
      nodes.push(new DynamicFlatNode(node.key, "TTModule", node.type, "TT_MODULE", node.level + 1, true, false, null, false,
        false, false, false, false));
      this.data.splice(index + 1, 0, ...nodes);
      this.dataChange.next(this.data);
      node.isLoading = false;
    } else {
      let count = 0;
      for (
        let i = index + 1;
        i < this.data.length && this.data[i].level > node.level;
        i++, count++
      ) {
      }
      this.data.splice(index + 1, count);
      this.dataChange.next(this.data);
      node.isLoading = false;
    }
  }

  addLevelFourNodes(node, index, expand) {
    if (expand) {
      let nodes: Array<DynamicFlatNode> = new Array<DynamicFlatNode>();
      nodes.push(new DynamicFlatNode(node.key, "Module File", node.type, "MODULE_FILE", node.level + 1, true, false, null, false,
        false, false, false, false));
      nodes.push(new DynamicFlatNode(node.key, "Secondary Module", node.type, "SECONDARY_MODULE", node.level + 1, true, false, null, false,
        false, false, false, false));
      this.data.splice(index + 1, 0, ...nodes);
      this.dataChange.next(this.data);
      node.isLoading = false;
    } else {
      let count = 0;
      for (
        let i = index + 1;
        i < this.data.length && this.data[i].level > node.level;
        i++, count++
      ) {
      }
      this.data.splice(index + 1, count);
      this.dataChange.next(this.data);
      node.isLoading = false;
    }
  }

  /**
   * Toggle the node, remove from display list
   */
  toggleNode(node: DynamicFlatNode, expand: boolean) {
    this.dataChange.next(this.data);
    node.isLoading = true;
    const index = this.data.indexOf(node);
    if (node.level == 1) {
      this.addLevelTwoNodes(node, index, expand);
      return;
    } else if (node.level == 3 && node.subType == 'MODULE') {
      this.addLevelFourNodes(node, index, expand);
      return;
    }

    if (expand) {
      this._database.curriculumService.getCurriculumTree(this.getRequestBody(node)).subscribe((data => {
        if (!data || index < 0) {
          return;
        }
        let nodes: Array<DynamicFlatNode> = new Array<DynamicFlatNode>();
        data.forEach(curriculum => {
          let curriculumTyped = new DynamicFlatNode(curriculum.key, curriculum.value, curriculum.type, curriculum.type,
            node.level + 1, !curriculum.file, false, curriculum.fileName, curriculum.hasSpanishFile, curriculum.music,
            curriculum.video, curriculum.downloadable, curriculum.trialAccessible);
          nodes.push(curriculumTyped);
        });

        this.data.splice(index + 1, 0, ...nodes);
        this.dataChange.next(this.data);
        node.isLoading = false;
      }));
    } else {
      let count = 0;
      for (
        let i = index + 1;
        i < this.data.length && this.data[i].level > node.level;
        i++, count++
      ) {
      }
      this.data.splice(index + 1, count);
      this.dataChange.next(this.data);
      node.isLoading = false;
    }
  }
}

@Component({
  selector: 'app-admin-program-editor-new-top',
  templateUrl: './admin-program-editor-new-top.html',
  styleUrls: ['./admin-program-editor-new-top.component.css']
})
export class AdminProgramEditorNewTopComponent implements OnInit {

  database: DynamicDatabase;
  moduleFileEditorLoaded: boolean = false;
  secondaryModuleFileEditorLoaded: boolean = false;
  ttModuleFileEditorLoaded: boolean = false;

  @Output() closeEditModuleFileEmit=new EventEmitter<boolean>();

  constructor(database: DynamicDatabase) {
    this.treeControl = new FlatTreeControl<DynamicFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new DynamicDataSource(this.treeControl, database);
    this.database = database;
  }

  treeControl: FlatTreeControl<DynamicFlatNode>;

  dataSource: DynamicDataSource;

  _moduleFileToLoad: ModuleFile;
  _ttModuleFileToLoad: TTModuleFile;
  _secondaryModuleFileToLoad: SecondaryModuleFile;

  getLevel = (node: DynamicFlatNode) => node.level;

  isExpandable = (node: DynamicFlatNode) => node.expandable;

  hasChild = (_: number, _nodeData: DynamicFlatNode) => _nodeData.expandable;

  ngOnInit(): void {
    this.database.ngOnInit(this.dataSource);
  }


  closeModuleFileEditor(data) {
    this.moduleFileEditorLoaded = false;
    this.secondaryModuleFileEditorLoaded = false;
    this.ttModuleFileEditorLoaded = false;
    this.closeEditModuleFileEmit.emit(true);
  }

  currentNode: DynamicFlatNode = null;

  loadFile(node): void {
    this.currentNode = node;
    console.log('Node : ' + node);
    this.moduleFileEditorLoaded = false;
    this.secondaryModuleFileEditorLoaded = false;
    this.ttModuleFileEditorLoaded = false;
    if (node.type == 'MODULE_FILE') {
      this.moduleFileEditorLoaded = true;
      this._moduleFileToLoad = new ModuleFile(node.key, node.value, node.fileName, "success", null, false, node.hasSpanishFile, null, null, node.music, node.video, node.downloadable, node.trialAccessible);
    } else if (node.type == 'TT_MODULE_FILE') {
      this.ttModuleFileEditorLoaded = true;
      this._ttModuleFileToLoad = new TTModuleFile(node.key, node.value, node.fileName, "success", '', false, node.hasSpanishFile, null, null, node.music, node.downloadable);
      console.log("TT FILE : " + JSON.stringify(this._ttModuleFileToLoad));
    } else if (node.type == 'SECONDARY_MODULE_FILE') {
      this.secondaryModuleFileEditorLoaded = true;
      this._secondaryModuleFileToLoad = new SecondaryModuleFile(node.key, node.value, node.fileName, "ABC", null, node.music, node.video, null, node.downloadable, false, null, null);
    }

    console.log(this._moduleFileToLoad);
  }
}
