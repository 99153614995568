import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {RepeatPasswordValidator} from "../../../../helpers/validators";
import {CourseService} from "../../../_services/adminCourseService/course-service";
import {ToastrService} from "ngx-toastr";
import {ActivatedRoute, Router} from "@angular/router";
import {CourseDTO} from "../../../types/CourseDTO";
import {CourseEnrollmentFilterDTO} from "../../../types/CourseEnrollmentFilterDTO";
import {PaginationCourseEnrollmentDTO} from "../../../types/PaginationCourseEnrollmentDTO";
import {OnSiteCourseService} from "../../../_services/adminLiveCourseService/on-site-course.service";
import {BsModalRef, BsModalService} from "ngx-bootstrap";

@Component({
  selector: 'app-e-admin-view-course',
  templateUrl: './view-course.html',
  styleUrls: ['./view-course.component.css']
})
export class eAdminViewCourseComponent implements OnInit {

  header: string = "Add New Course";
  courseId: string;
  courseDTO: CourseDTO;
  onSiteCourseList: any;

  recordToDelete: number;

  paginationCourseEnrollment: PaginationCourseEnrollmentDTO;

  modalRef: BsModalRef;

  @ViewChild('confirmation')
  private confirmationTemplate: TemplateRef<any>;


  constructor(private fb: FormBuilder,
              private courseService: CourseService,
              private onSiteCourseService: OnSiteCourseService,
              private toastr: ToastrService,
              private router: Router,
              private route: ActivatedRoute,
              private modalService: BsModalService) {
  }

  ngOnInit() {
    this.courseId = this.route.snapshot.paramMap.get('id');
    if (this.courseId != null) {
      this.loadData(this.courseId);
      this.enrollments();
    }
  }

  loadData(courseId) {
    this.courseId = courseId;
    this.courseService.get(this.courseId).subscribe(data => {
      let title = data.title;
      let subTitle = data.subTitle;
      let code = data.code;
      let description = data.description;
      let categoryId = (data.categoryId == null ? null : data.categoryId.toString());
      let cost = data.cost;
      let length = data.length;
      let courseId = data.courseId;
      let categoryName = data.courseCategory.name;
      let tagName = data.courseTag.name;
      let isOnsite = data.isOnsite;
      let tagId = data.tagId == null ? null : data.tagId.toString();
      this.header = title;
      this.courseDTO = new CourseDTO(title, subTitle, description, length, cost, tagId, code, categoryId, isOnsite, null, null, null, categoryName, courseId, tagName);
      if (this.courseDTO.isOnsite) {
        this.onSiteCourseRecords();
      }
    });
  }

  onSiteCourseRecords() {
    this.onSiteCourseService.findAllByCourseId(this.courseId).subscribe(data => {
      this.onSiteCourseList = data;
    });
  }

  enrollments() {
    let courseEnrollmentFilterDTO = new CourseEnrollmentFilterDTO(0, 400, 0);
    this.courseService.findEnrollments(this.courseId, courseEnrollmentFilterDTO).subscribe(data => {
      this.paginationCourseEnrollment = data;
    });
  }

  removeOnSiteCourse(id) {
    this.recordToDelete = id;
    this.modalRef = this.modalService.show(this.confirmationTemplate, {class: 'modal-sm'});
  }

  edit(id) {
    this.router.navigateByUrl("sparkecademy-admin-course/" + this.courseId + "/sparkecademy-admin-onsite-course-token/edit/" + id);
  }

  view(id) {
    this.router.navigateByUrl("sparkecademy-admin-course/" + this.courseId + "/sparkecademy-admin-onsite-course-token/view/" + id);
  }

  confirm(): void {
    this.onSiteCourseService.remove(this.recordToDelete).subscribe(data => {
      this.onSiteCourseRecords();
    });
    this.modalRef.hide();
  }

  decline(): void {
    this.modalRef.hide();
  }
}

