import { Component, OnInit } from '@angular/core';

export class RegistrationDTO {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  license: string;
  school: string;
  constructor(email: string, firstName: string, lastName: string, password:string, school:string, license:string){
    this.email = email;
    this.firstName= firstName;
    this.lastName = lastName;
    this.email = email;
    this.password = password;
    this.license = license;
    this.school = school;
  }


}
