import { Component, OnInit } from '@angular/core';
import {Curriculum} from "../../types/Curriculum";
import {ActivatedRoute} from "@angular/router";
import {LicenseExpirationDateDTO} from "../../types/LicenseExpirationDateDTO";

@Component({
  selector: 'app-restrictedcurriculum',
  templateUrl: './restrictedCurriculum.html',
  styleUrls: ['./restrictedcurriculum.component.css']
})
export class RestrictedcurriculumComponent implements OnInit {

  curriculums: Curriculum[];

  licenseExpirationDate: LicenseExpirationDateDTO;

  navEntries : any;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    console.log("curriculum value");
    //this.route.data.subscribe(data => this.curriculums = data.valueOf("curriculums").curriculums);

    this.route.data.subscribe(data =>
    {
      this.curriculums = data.curriculums;
      this.navEntries = data.navEntries;
      this.licenseExpirationDate = data.licenseExpirationDate;
      console.log(this.curriculums);
    });

  }

}
