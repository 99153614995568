import { Injectable } from '@angular/core';


import { Resolve } from '@angular/router';
import {UserCurriculumService} from "../../_services/userCurriculumService/user-curriculum.service";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SearchLessonTagsResolver implements Resolve<Observable<String[]>> {
  constructor(private curriculumService: UserCurriculumService) {}

  resolve() : Observable<String[]>{
    console.log("in resolver");
    return this.curriculumService.getSearchLessonTags();
  }
}
