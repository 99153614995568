import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LicenseDTO} from "../../../../types/LicenseDTO";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {EmailValidation} from "../../../../helpers/validators";
import {AdminLicenseService} from "../../../../_services/admin/adminLicenseService/admin-license.service";
import {IDDTO} from "../../../../types/IDDTO";
import {UpdateLicenseDTO} from "../../../../types/UpdateLicenseDTO";

@Component({
  selector: 'app-admin-account-license-edit',
  templateUrl: './admin-account-license-edit.html',
  styleUrls: ['./admin-account-license-edit.component.css']
})
export class AdminAccountLicenseEditComponent implements OnInit {

  _licenseDTO : LicenseDTO;

  modifyLicense = this.fb.group({

  }, {validator: []});

  date = new FormControl(new Date());
  roleTypeList: Array<Array<String>> = [];
  roles : string[];


  roleTypeQty : Map<string, number> = new Map<string, number>();

  formReady : boolean = false;

  @Output() closeLicenseNoReload = new EventEmitter<boolean>();

  @Output() closeLicenseReload = new EventEmitter<boolean>();

  constructor(private fb: FormBuilder, private licenseService : AdminLicenseService) { }

  ngOnInit() {

    this.modifyLicense.addControl("expirationDate", new FormControl(new Date(this._licenseDTO.expirationDate)));

    this.licenseService.getFormRoles().subscribe(data => {

      this._licenseDTO.roleInfoList.forEach( info => {
        this.roleTypeQty.set(info.type, Number(info.qty));
      });

      console.log(this.modifyLicense);
      console.log("get form roles");
      console.log(data);
      console.log(this._licenseDTO)
      this.roles = data;
      console.log(this.roles);
      if(data){
        let count : number = 0;
        data.forEach( type => {
          let role : String;
          role = type;
          if(count % 2 == 0 ){
            this.roleTypeList.push(new Array<String>());
          }
          let temp : Array<String> = new Array<String>();
          temp = this.roleTypeList.pop();
          temp.push(role);
          this.roleTypeList.push(temp);
          count++;
        });
        data.forEach(type => {
          console.log(type);
          console.log(this.roleTypeQty.get(type));
          this.modifyLicense.addControl(type+'Qty', new FormControl([{value:this.roleTypeQty.get(type), disabled: false}]));
          this.modifyLicense.controls[type+'Qty'].setValue(this.roleTypeQty.get(type));
        });
      }
      console.log(this.modifyLicense);
      this.formReady = true;
    });

    console.log("form");
    console.log(this.modifyLicense);



    console.log(this.modifyLicense);

  }

  @Input()
  set licenseDTO(dto : LicenseDTO){
    this._licenseDTO = dto;
    console.log("set license dto");
    console.log(this._licenseDTO);
  }

  closeEditor(dto){
    this.closeLicenseNoReload.emit(dto);
  }

  revoke(dto){
    console.log(dto);
    this.licenseService.revokeLicense(new IDDTO(dto.licenseId)).subscribe( data => {
    this.closeLicenseReload.emit(true);
    });
  }

  unRevoke(dto){
    console.log(dto);
    this.licenseService.unRevokeLicense(new IDDTO(dto.licenseId)).subscribe( data => {
      this.closeLicenseReload.emit(true);
    }) ;
  }

  saveLicense(dto){
    console.log(dto);
    console.log(this.roles);
    console.log(this.roleTypeQty.keys());
    let updateMap : Map<string, number> =  new Map<string, number>();
    let expDate = this.modifyLicense.controls["expirationDate"].value;
    Array.from(this.roleTypeQty.keys()).forEach( type =>{
      console.log(this.modifyLicense.controls[type + "Qty"]);
      updateMap.set(type, this.modifyLicense.controls[type + "Qty"].value);
    });

    let updateDTO : UpdateLicenseDTO = new UpdateLicenseDTO(updateMap, expDate, this._licenseDTO.licenseId);
    this.licenseService.updateLicense(updateDTO).subscribe(data => {
      console.log(data);
      this.closeLicenseReload.emit(true);
    });
    console.log(updateMap);
    console.log(updateDTO);
  }

}
