import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CourseCategoryDTO} from "../../../../types/CourseCategoryDTO";
import {ActivatedRoute, Router} from "@angular/router";
import {CourseCategoryService} from "../../../../_services/adminCourseService/course-category-service";

@Component({
  selector: 'app-admin-course-category',
  templateUrl: 'admin-course-category.html',
  styleUrls: ['admin-course-category.component.css']
})
export class eAdminCourseCategoryComponent implements OnInit {

  courseCategories: CourseCategoryDTO[];
  header: string = " Course Categories";

  constructor(private route: ActivatedRoute,
              private router: Router,
              private courseCategoryService: CourseCategoryService) {
  }


  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.courseCategoryService.getAllCourseCategories(true).subscribe(data => {
      this.courseCategories = data;
    });
  }
}
