import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AdminCurriculumService} from "../../../_services/admin/adminCurriculumService/admin-curriculum.service";
import {NavEntry} from "../../../types/NavEntry";
import {NavEntryCurriculums} from "../../../types/NavEntryCurriculums";
import {Curriculum} from "../../../types/Curriculum";
import {FormControl, FormGroup} from "@angular/forms";
import {DisplayOrderUpdateDTO} from "../../../types/DisplayOrderUpdateDTO";
import {IDDTO} from "../../../types/IDDTO";

@Component({
  selector: 'app-admin-nav-entry-selector',
  templateUrl:'./admin-nav-entry-selector.html',
  styleUrls: ['./admin-nav-entry-selector.component.css']
})
export class AdminNavEntrySelectorComponent implements OnInit {

  mainSelectorIsLoaded : boolean = false;

  navEntriesTyped : Array<NavEntry>;

  @Output() loadNavEntry=new EventEmitter<NavEntry>();

  @Output() loadNavEntryForDisplayOrderEdit=new EventEmitter<NavEntry>();

  @Output() createNewLoaded=new EventEmitter<boolean>();

  @Output() reloadSelector=new EventEmitter<boolean>();



  displayOrderForm: FormGroup;



  constructor(private curriculumService : AdminCurriculumService) { }

  ngOnInit() {
    this.curriculumService.getAllNavEntries().subscribe(data => {

        console.log("@@@@@@@@@PROCESSING NAV ENTRIES");
        let navEntriesTypedArray;
        navEntriesTypedArray = new Array<NavEntry>();

        data.forEach( navEntry => {
          console.log(navEntry);

          let navCurriculumEntriesArray = new Array<NavEntryCurriculums>();
          navEntry.navEntryCurriculumDTOs.forEach(navEntryCurriculum => {
            console.log(navEntryCurriculum);

            let curriculumTyped = new Curriculum('true', '', navEntryCurriculum.curriculumDTO.name,
              navEntryCurriculum.curriculumDTO.navText, navEntryCurriculum.curriculumDTO.bodyText, navEntryCurriculum.curriculumDTO.id,
              navEntryCurriculum.curriculumDTO.restricted, navEntryCurriculum.curriculumDTO.bannerLink, navEntryCurriculum.curriculumDTO.useHtml,
              navEntryCurriculum.curriculumDTO.accessingViaTrial);

            console.log(curriculumTyped);

            let navCurriculumTypes = new NavEntryCurriculums(curriculumTyped, navEntryCurriculum.displayOrder, navEntryCurriculum.navEntryCurriculumId);

            console.log(navCurriculumTypes);
            navCurriculumEntriesArray.push(navCurriculumTypes);
            console.log(navCurriculumEntriesArray);
          });

          let navEntryTyped = new NavEntry(navEntry.id, navEntry.navText, navEntry.singleDisplay, navEntry.displayOrder, navCurriculumEntriesArray);
          navEntriesTypedArray.push(navEntryTyped);
        });

        console.log("FINAL");
        console.log(navEntriesTypedArray);
        this.navEntriesTyped = navEntriesTypedArray;

      const formGroup = new FormGroup({ });



      this.navEntriesTyped.forEach(navEntryType => {
        console.log(navEntryType);
        formGroup.addControl(navEntryType.navText, new FormControl(navEntryType.id));
        formGroup.controls[navEntryType.navText].setValue(navEntryType.displayOrder);
      });

      this.displayOrderForm = formGroup;


      this.mainSelectorIsLoaded = true;
    });
  }



  loadNavEntryEditor(navEntry){
    console.log(navEntry);
    this.loadNavEntry.emit(navEntry);
  }

  editCurriculumOrder(navEntry){
    console.log(navEntry);
    this.loadNavEntryForDisplayOrderEdit.emit(navEntry);
  }

  saveDisplayOrderChanges(){
    let updateMap : Map<String, Number> = new Map<String, Number>();
    this.navEntriesTyped.forEach( navEntry => {
      updateMap.set(navEntry.id +'', this.displayOrderForm.controls[navEntry.navText].value);
    });
    updateMap.forEach((val : number, key: string) => {
      updateMap[key] = val
    });

    let displayUpdateDTO : DisplayOrderUpdateDTO = new DisplayOrderUpdateDTO(updateMap);

    this.curriculumService.updateNavEntryDisplayOrder(displayUpdateDTO).subscribe(data => {
      console.log(data);
      this.reloadSelector.emit(true);
    });

  }

  createNewNavEntry(){
    this.createNewLoaded.emit(true);
  }

  deleteNavEntry(navEntry){
    let idDto : IDDTO = new IDDTO(navEntry.id);
    this.curriculumService.deleteNavEntry(idDto).subscribe(data => {
      this.reloadSelector.emit(true);
    });
  }

}
