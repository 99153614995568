import { Component, OnInit } from '@angular/core';
import {AccountService} from "../../_services/accountService/account.service";
import {MatDialogRef} from "@angular/material";
import {FormBuilder, Validators} from "@angular/forms";
import {
  EmailValidation,
  RepeatEmailValidator
} from "../../helpers/validators";
import {RegistrationDTO} from "../../types/RegistrationDTO";
import {ErrorMessageService} from "../../_services/errorMessageService/error-message.service";

@Component({
  selector: 'app-create-new-user',
  templateUrl: './create-new-user.html',
  styleUrls: ['./create-new-user.component.css']
})
export class CreateNewUserComponent implements OnInit {


  newUserForm = this.fb.group({
    firstName:['', Validators.required],
    lastName: ['', Validators.required],
    email: ['', EmailValidation],
    emailConfirm: ['', EmailValidation],
    school: ['', Validators.required]
  }, {validator: [RepeatEmailValidator]});


  constructor(private fb: FormBuilder, public accountService : AccountService, public errorService: ErrorMessageService,
              public dialogRef: MatDialogRef<CreateNewUserComponent>) { }

  ngOnInit() {
    this.errorService.clear();
  }

  close(){
    this.dialogRef.close();
  }

  onSubmit(){
    console.log("in on submit");


    let dto : RegistrationDTO = new RegistrationDTO(this.newUserForm.get('email').value,
      this.newUserForm.get('firstName').value,
      this.newUserForm.get('lastName').value,
      null,
      this.newUserForm.get('school').value,
      null);

    this.accountService.createNewUser(dto).subscribe( data => {
      console.log("create user response")
      console.log(data);

      if(data.error.length > 0){
        this.errorService.clear();
        this.errorService.add(data.error);
      } else {

        this.dialogRef.close();

        window.location.reload();
      }
    });

    };

}
