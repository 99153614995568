import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DocumentDTO} from "../../types/DocumentDTO";
import {CourseTagDTO} from "../../types/CourseTagDTO";
import {CourseCategoryDTO} from "../../types/CourseCategoryDTO";
import {ActivatedRoute} from "@angular/router";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-e-user-manage-document',
  templateUrl: './manage-document.html',
  styleUrls: ['./manage-document.component.css']
})
export class eUserManageDocumentComponent implements OnInit {

  documentList: DocumentDTO[];

  courseTags: CourseTagDTO[];
  courseCategories: CourseCategoryDTO[];


  dataToListDocuments($event) {
    this.documentList = $event;
  }

  constructor(private route: ActivatedRoute, private sanitizer: DomSanitizer) {

  }

  ngOnInit() {
  }
}
