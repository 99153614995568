import {Injectable} from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  UrlSegment,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from "../../_services/authService/auth.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    return new Promise((resolve) => {

      if (localStorage.getItem("gotoAdmin") != null) {
        this.router.navigate(['admin-home']);
        localStorage.removeItem("gotoAdmin");
        return ;
      }
      console.log("in auth guard");
      const currentUser = this.authService.getCurrentUserValue();

      console.log(currentUser);
      if (currentUser) {
        console.log("user logged in")
        return resolve(true);
      }

      console.log("auth guard false redirect");
      this.router.navigate(['intro'], {queryParams: {returnUrl: state.url}});
      return resolve(false);
    });

  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }
}
