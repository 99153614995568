import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Curriculum} from "../../../../../types/Curriculum";
import {Module} from "../../../../../types/Module";
import {ModuleFile} from "../../../../../types/ModuleFile";

@Component({
  selector: 'app-admin-module-editor',
  templateUrl: './admin-module-editor.html',
  styleUrls: ['./admin-module-editor.component.css']
})
export class AdminModuleEditorComponent implements OnInit {

  _curriculum : Curriculum;

  _module : Module;


  selected : number;

  @Output() closeEditorEmit=new EventEmitter<true>();
  @Output() reloadEditorEmit=new EventEmitter<Module>();

  selectorToggle : boolean = false;
  createNewLoaded : boolean = false;
  moduleFolderEditorLoaded : boolean = false;
  moduleFileEditorLoaded : boolean = false;

  _moduleFileToLoad : ModuleFile;

  constructor() { }

  ngOnInit() {
  }



  @Input()
  set curriculum(curriculum){
    this._curriculum = curriculum;
  }

  @Input()
  set module(module){
    console.log("Set mdoule in admin-module-editor");
    console.log(module);
    this._module = module;
  }


  setSelectedValue(value){
    this.selected = value;
  }

  closeEditor(){
    this.closeEditorEmit.emit(true);
  }

  reloadEditor(module){
    this.reloadEditorEmit.emit(module)
  }
  reloadSelector(data){
    this.selectorToggle = !this.selectorToggle;
  }

  loadCreateNew(data){
    this.createNewLoaded = true;
  }

  closeCreateNew(data){
    this.selectorToggle = !this.selectorToggle;
    this.createNewLoaded = false;
  }

  closeModuleFolderEditor(data){
    this.selectorToggle = !this.selectorToggle;
    this.moduleFolderEditorLoaded = false;
    this._moduleFileToLoad = null;
  }

  closeModuleFileEditor(data){
    this.selectorToggle = !this.selectorToggle;
    this.moduleFileEditorLoaded = false;
    this._moduleFileToLoad = null;
  }

  loadModuleFolderEditor(data){
    console.log("In load module folder editor");
    console.log(data);
    this._moduleFileToLoad = data;
    this.moduleFolderEditorLoaded = true;
    this.moduleFileEditorLoaded = false;
  }

  loadModuleFileEditor(data){
    console.log("In load module file editor");
    console.log(data);
    this._moduleFileToLoad = data;
    this.moduleFileEditorLoaded = true;
    this.moduleFolderEditorLoaded = false;
  }
}

