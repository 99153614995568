import {Curriculum} from "./Curriculum";

export class NavEntryCurriculums {

  navEntryCurriculumId: number;
  curriculums : Curriculum
  displayOrder : number;

  constructor(curriculums : Curriculum, displayOrder : number, navEntryCurriculumId : number){
    this.curriculums = curriculums;
    this.displayOrder = displayOrder;
    this.navEntryCurriculumId = navEntryCurriculumId;
  }


}
