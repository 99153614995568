export class LicenseByKeyDisplay{
  key : string;
  activated : boolean;
  revoked : boolean;

  constructor(key : string, activated : boolean, revoked : boolean){
    this.key = key;
    this.activated = activated;
    this.revoked = revoked;
  }
}
