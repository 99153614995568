import { Component, OnInit } from '@angular/core';
import {Curriculum} from "../../types/Curriculum";
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, Validators} from "@angular/forms";
import {AccountService} from "../../_services/accountService/account.service";
import {AuthService} from "../../_services/authService/auth.service";
import {ErrorMessageService} from "../../_services/errorMessageService/error-message.service";
import {RegistrationDTO} from "../../types/RegistrationDTO";
import {
  CustomEmailValidator,
  EmailValidation,
  PasswordValidation,
  RepeatEmailValidator,
  RepeatPasswordValidator
} from "../../helpers/validators";
import {DisplayMessageService} from "../../_services/displayMessageService/display-message.service";
import {LicenseExpirationDateDTO} from "../../types/LicenseExpirationDateDTO";

@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.html',
  styleUrls: ['./update-profile.component.css']
})
export class UpdateProfileComponent implements OnInit {
  curriculums : Curriculum[];

  licenseExpirationDate: LicenseExpirationDateDTO;

  navEntries : any;


  accountInfo:RegistrationDTO;

  origFirstName : string;
  origLastName  : string;
  origEmail : string;
  origSchool : string;

  pageName : string = "UpdateProfile";

  accountUpdateForm = this.fb.group({
    firstName:[''],
    lastName: [''],
    email: ['', CustomEmailValidator],
    emailConfirm: ['', CustomEmailValidator],
    password: [''],
    passwordConfirm: [''],
    school: ['']
  }, {validator: [RepeatPasswordValidator, RepeatEmailValidator]});

  constructor(private route: ActivatedRoute,private fb: FormBuilder, private accountService: AccountService, private authService: AuthService, private errorMessageService: ErrorMessageService,
  private router: Router, private displayMessageService : DisplayMessageService) { }

  ngOnInit() {
    this.errorMessageService.clear();
    this.displayMessageService.clear();

    this.origFirstName = this.authService.getCurrentUserValue().firstName;
    this.origLastName = this.authService.getCurrentUserValue().lastName;
    this.origEmail = this.authService.getCurrentUserValue().email;
    this.origSchool = this.authService.getCurrentUserValue().school;
    console.log(this.origLastName + " " + this.origLastName + " " + this.origEmail);
    this.accountUpdateForm.setValue({firstName: this.origFirstName,
    lastName: this.origLastName,
    school:this.origSchool,
    email: this.origEmail,
    emailConfirm: this.origEmail,
    password: null,
    passwordConfirm: null});

    this.route.data.subscribe(data =>
    {
      console.log("in update profile component");
      console.log(data);
      this.curriculums = data.curriculums;
      console.log(this.curriculums);
      this.navEntries = data.navEntries;
      this.licenseExpirationDate = data.licenseExpirationDate;
    });
  }

  reset(){
    this.accountUpdateForm.setValue({firstName: this.origFirstName,
      lastName: this.origLastName,
      school: this.origSchool,
      email: this.origEmail,
      emailConfirm: this.origEmail,
      password: null,
      passwordConfirm: null});
  }

  onSubmit() {
    // TODO: Use EventEmitter with form value
    console.warn(this.accountUpdateForm.value);
    console.log("in update profile submit function");

    let updateEmail : string;
    let updateFirstName: string;
    let updateLastName: string;
    let updatePassword: string;
    let updateSchool: string;

    if(this.origEmail != this.accountUpdateForm.get('email').value){
      updateEmail = this.accountUpdateForm.get('email').value;
    }

    if(this.origFirstName != this.accountUpdateForm.get('firstName').value){
      updateFirstName = this.accountUpdateForm.get('firstName').value;
    }

    if(this.origLastName != this.accountUpdateForm.get('lastName').value){
      updateLastName = this.accountUpdateForm.get('lastName').value;
    }

    if(this.accountUpdateForm.get('password').value){
      updatePassword = this.accountUpdateForm.get('password').value;
    }

    if(this.origSchool != this.accountUpdateForm.get('school').value){
      updateSchool = this.accountUpdateForm.get('school').value;
    }

    this.accountInfo = new RegistrationDTO(updateEmail,updateFirstName,updateLastName,updatePassword, updateSchool, null);
    this.errorMessageService.clear();
    this.authService.updateAccount(this.accountInfo).subscribe();

    if(updateEmail){
      this.origEmail = updateEmail;
    }

    if(updateFirstName){
      this.origFirstName = updateFirstName;
    }

    if(updateLastName){
      this.origLastName = updateLastName;
    }

  }
}
