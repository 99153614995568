import { Injectable } from '@angular/core';


import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {UserCurriculumService} from "../../_services/userCurriculumService/user-curriculum.service";
import {Curriculum} from "../../types/Curriculum";
import {Observable} from "rxjs";
import {Module} from "../../types/Module";

@Injectable({
  providedIn: 'root'
})
export class ModuleListResolver implements Resolve<Observable<Module[]>> {
  constructor(private curriculumService: UserCurriculumService) {}

  resolve(route: ActivatedRouteSnapshot) : Observable<Module[]>{
    console.log("in module list resolver");
    console.log("4|" + route.params['id']);
    return this.curriculumService.getCurriculumModules(route.params['id']);
  }
}
