import { Component, OnInit } from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {AdminUserService} from "../../_services/admin/adminUserService/admin-user.service";

@Component({
  selector: 'app-admin-bulk-user-update',
  templateUrl: './app-admin-bulk-user-update.html',
  styleUrls: ['./app-admin-bulk-user-update.component.css']
})
export class AppAdminBulkUserUpdateComponent implements OnInit {

  bulkUpdateUserList = this.fb.group({
      file:[null]
    }, {validator: []});


  message;
  success : boolean;

  callReturned : boolean = true;

  constructor(private fb: FormBuilder,  private adminUserService : AdminUserService) { }

  ngOnInit() {
  }



  onFileChange(event) {
    if(event.target.files.length > 0) {
      let file = event.target.files[0];
      this.bulkUpdateUserList.get('file').setValue(file);
      console.log(this.bulkUpdateUserList);
    }
  }

  private prepareSave(): any {
    let input = new FormData();
    // This can be done a lot prettier; for example automatically assigning values by looping through `this.form.controls`, but we'll keep it as simple as possible here
    if(this.bulkUpdateUserList.get('file').value) {
      input.append('FILE', this.bulkUpdateUserList.get('file').value);
    }
    return input;
  }

  onSubmit() {
    this.callReturned = false;
    this.message = null;
    this.adminUserService.bulkUpdateUsers( this.prepareSave()).subscribe(data => {
      console.log(data);
      if(data.success){
        if(data.detailedLog){
         this.message =  data.detailedLog;
        }else {
          this.message = "Changes saved";
        }
      } else {
        this.message = data.errorMessage;
        if (typeof this.message === 'string') {
          this.message = [this.message];
        }
      }

      this.callReturned = true;
    });
  }
}
