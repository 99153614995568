import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CourseTagDTO} from "../../../types/CourseTagDTO";
import {CourseCategoryDTO} from "../../../types/CourseCategoryDTO";
import {ActivatedRoute, Router} from "@angular/router";
import {CourseFilterDTO} from "../../../types/CourseFilterDTO";
import {UserCourseService} from "../../../_services/userCourseService/user-course-service";

@Component({
  selector: 'app-admin-course-catalog',
  templateUrl: 'admin-course-catalog.html',
  styleUrls: ['admin-course-catalog.component.css']
})
export class eAdminCourseCatalogComponent implements OnInit {

  courseTags: CourseTagDTO[];
  courseCategories: CourseCategoryDTO[];
  courseUrl: string;
  courseTag: string;
  header: string = " Course Catalog";

  courseCatalog: any;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private userCourseService: UserCourseService) {
  }


  ngOnInit() {

    this.route.params.subscribe(routeParams => {
      this.courseUrl = routeParams.courseUrl;
      if (typeof this.courseUrl !== 'undefined') {
        if (this.courseUrl != 'all') {
          this.header = "Courses in " + this.courseUrl;
        }else{
          this.courseUrl = null;
        }
        this.loadData();
      }
    });

    this.route.queryParams.subscribe(queryParams => {
      this.courseTag = queryParams.tag;
      if (typeof this.courseTag !== 'undefined') {
        this.loadData();
      }
    });

    this.route.data.subscribe(data => {
      this.courseTags = data.courseTags;
      this.courseCategories = data.courseCategories;
    });
  }

  loadData() {
    let courseFilterDTO = new CourseFilterDTO(0, 100, 0);
    if (this.courseUrl != null) {
      courseFilterDTO.categoryUrl = this.courseUrl;
    }

    if (this.courseTag != null) {
      courseFilterDTO.tagUrl = this.courseTag;
    }
    this.userCourseService.filter(courseFilterDTO).subscribe(data => {
      this.courseCatalog = data.courseMap;
    });
  }
}
