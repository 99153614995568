import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Curriculum} from "../../../../../types/Curriculum";
import {Module} from "../../../../../types/Module";
import {ModuleFile} from "../../../../../types/ModuleFile";
import {SecondaryModule} from "../../../../../types/SecondaryModule";

@Component({
  selector: 'app-admin-secondary-module-editor-container',
  templateUrl: './admin-secondary-module-editor-container.html',
  styleUrls: ['./admin-secondary-module-editor-container.component.css']
})
export class AdminSecondaryModuleEditorContainerComponent implements OnInit {


  _curriculum : Curriculum;

  _module : Module;
  _moduleFile : ModuleFile;

  _editorModule : SecondaryModule;

  _parentType : string;

  toggle : boolean = false;

  moduleEditorToggle : boolean = false;

  editorLoaded : boolean = false;

  createNewLoaded : boolean = false;

  constructor() { }

  ngOnInit() {
  }


  @Input()
  set curriculum(curriculum){
    console.log("in module editor container set curriculum method");
    this._curriculum = curriculum;
  }

  @Input()
  set module(module){
    console.log("in module editor container set curriculum method");
    this._module = module;
  }

  @Input()
  set moduleFile(moduleFile){
    console.log("in module file");
    this._moduleFile = moduleFile;
  }

  @Input()
  set parentType(parentType){
    this._parentType = parentType;
  }

  loadCreateNew(data){
    console.log("load create new secondary");
    this.createNewLoaded = true;
    this.editorLoaded = false;
  }

  reloadSelector(data){
    this.toggle = !this.toggle;
    this.createNewLoaded = false;
    this.editorLoaded = false;
  }

  loadModuleEditor(data){
    console.log("loadModuleEditor secondary container");
    console.log(data);
    this._editorModule = data;
    this.createNewLoaded = false;
    this.editorLoaded = true;
  }

  closeModuleEditor(data){
    console.log("in close module editor");
    this._editorModule = null;
    this.createNewLoaded = false;
    this.editorLoaded = false;
    this.toggle = !this.toggle;
  }

  closeNewModule(data){
    this.createNewLoaded = false;
    this.editorLoaded = false;
  }
}
