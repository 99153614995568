export class DistributeCreditDTO {

  userId: number;
  credits: number;

  constructor(userId: any,
              credits: any
  ) {
    this.userId = userId;
    this.credits = credits;
  }
}
