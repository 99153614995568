import { Injectable } from '@angular/core';


import { Resolve } from '@angular/router';
import {Observable, Subject} from "rxjs";
import {LicenseDTO} from "../../types/LicenseDTO";
import {AccountService} from "../../_services/accountService/account.service";
import {AuthService} from "../../_services/authService/auth.service";
import {UserCurriculumService} from "../../_services/userCurriculumService/user-curriculum.service";

@Injectable({
  providedIn: 'root'
})
export class HomePageResolver implements Resolve<any> {
  constructor(private curService : UserCurriculumService) {}

  resolve() : Observable<any>{
    return this.curService.getHomePageContent();
  }
}
