import {Component, Input, OnInit} from '@angular/core';
import {UserCurriculumService} from "../../_services/userCurriculumService/user-curriculum.service";
import {Curriculum} from "../../types/Curriculum";
import {AuthService} from "../../_services/authService/auth.service";
import {SecondaryModule} from "../../types/SecondaryModule";
import {NavEntryCurriculums} from "../../types/NavEntryCurriculums";
import {NavEntry} from "../../types/NavEntry";
import {RoleInfo} from "../../types/RoleInfo";
import {LicenseExpirationDateDTO} from "../../types/LicenseExpirationDateDTO";

@Component({
  selector: 'app-nav',
  templateUrl: './nav.html',
  styleUrls: ['./nav.component.css'],
})
export class NavComponent implements OnInit {

  @Input() curriculumArray: Curriculum[];

  @Input() licenseExpirationDate: LicenseExpirationDateDTO;

  @Input() navEntries : any;

  navEntriesTyped : Array<NavEntry>;

  mobileCurriculum : Array<Array<Curriculum>> = new Array<Array<Curriculum>>();

  smallScreenHiddenMap : Map<number, boolean> = new Map<number, boolean>();
  constructor( private userCurriculumService : UserCurriculumService, private authService: AuthService) { }

  ngOnInit() {
    console.log(this.curriculumArray);
    let count = 0;
    if(this.curriculumArray){
      this.curriculumArray.forEach( curriculum => {
        //let secondaryModuleType : SecondaryModule;
        let curriculumTyped : Curriculum;
        console.log(curriculum);
        curriculumTyped = new Curriculum("", null, curriculum.name, curriculum.navText, curriculum.bodyText, curriculum.id, curriculum.restricted+'', curriculum.bannerLink, curriculum.useHtml, curriculum.accessingViaTrial);
        console.log(curriculumTyped);
        if(count % 3 == 0 ){
          this.mobileCurriculum.push(new Array<Curriculum>());
        }
        let temp : Array<Curriculum> = new Array<Curriculum>();
        temp = this.mobileCurriculum.pop();
        temp.push(curriculumTyped);
        this.mobileCurriculum.push(temp);
        count++;
      })
    }

    if(this.navEntries){
      console.log("@@@@@@@@@PROCESSING NAV ENTRIES");
      let navEntriesTypedArray;
      navEntriesTypedArray = new Array<NavEntry>();

      this.navEntries.forEach( navEntry => {
        console.log(navEntry);

        let navCurriculumEntriesArray = new Array<NavEntryCurriculums>();
        navEntry.navEntryCurriculumDTOs.forEach(navEntryCurriculum => {
          console.log(navEntryCurriculum);

          let curriculumTyped = new Curriculum('true', '', navEntryCurriculum.curriculumDTO.name,
            navEntryCurriculum.curriculumDTO.navText, navEntryCurriculum.curriculumDTO.bodyText, navEntryCurriculum.curriculumDTO.id,
            navEntryCurriculum.curriculumDTO.restricted, navEntryCurriculum.curriculumDTO.bannerLink, navEntryCurriculum.curriculumDTO.useHtml, navEntryCurriculum.curriculumDTO.accessibleViaTrial);

          console.log(curriculumTyped);

          let navCurriculumTypes = new NavEntryCurriculums(curriculumTyped, navEntryCurriculum.displayOrder, navEntryCurriculum.navEntryCurriculumId);

          console.log(navCurriculumTypes);
          navCurriculumEntriesArray.push(navCurriculumTypes);
          console.log(navCurriculumEntriesArray);
        });

        let navEntryTyped = new NavEntry(navEntry.id, navEntry.navText, navEntry.singleDisplay, navEntry.displayOrder, navCurriculumEntriesArray);
        if(!navEntryTyped.singleDisplay){
          this.smallScreenHiddenMap.set(navEntryTyped.id, false);
        }
        navEntriesTypedArray.push(navEntryTyped);
      });

      console.log(navEntriesTypedArray);
      this.navEntriesTyped = navEntriesTypedArray;
    }


  }


  logOut(redirect : boolean){
    this.authService.logout(redirect);
  }


  toggleHidden(navEntryTyped){
    this.smallScreenHiddenMap.set(navEntryTyped.id, !this.smallScreenHiddenMap.get(navEntryTyped.id));
  }
}
